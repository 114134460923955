import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelbezeichnungsgeneratorDto } from './artikelbezeichnungsgeneratordto.entity';
//#endregion

export const ArtikelbezeichnungsgeneratorDtoConfig = {
  name: "ArtikelbezeichnungsgeneratorDto",
  annotations: [],
  fields: {
    artikelNr: {type: 'Edm.String'},
    sprachId: {type: 'Edm.Int32'},
    bezeichnungAlt: {type: 'Edm.String'},
    bezeichnungAltBearbeitet: {type: 'Edm.String'},
    etikettenBezeichnung: {type: 'Edm.String'},
    etikettenBezeichnungEdit: {type: 'Edm.String'},
    hauptwort: {type: 'Edm.String'},
    hauptwortId: {type: 'Edm.Int32'},
    synonymNr: {type: 'Edm.Int32'},
    isDimB: {type: 'Edm.Boolean', nullable: false},
    isDimE: {type: 'Edm.Boolean', nullable: false},
    isDimL: {type: 'Edm.Boolean', nullable: false},
    dimension: {type: 'Edm.String'},
    spezifikation: {type: 'Edm.String'},
    isSpeziB: {type: 'Edm.Boolean', nullable: false},
    isSpeziE: {type: 'Edm.Boolean', nullable: false},
    isSpeziL: {type: 'Edm.Boolean', nullable: false},
    isSpeziErw: {type: 'Edm.Boolean', nullable: false},
    bezug: {type: 'Edm.String'},
    isBezugB: {type: 'Edm.Boolean', nullable: false},
    isBezugE: {type: 'Edm.Boolean', nullable: false},
    isBezugL: {type: 'Edm.Boolean', nullable: false},
    zusammenstellung: {type: 'Edm.String'},
    isZusammenstellungB: {type: 'Edm.Boolean', nullable: false},
    isZusammenstellungE: {type: 'Edm.Boolean', nullable: false},
    isZusammenstellungL: {type: 'Edm.Boolean', nullable: false},
    color: {type: 'Edm.String'},
    isColorB: {type: 'Edm.Boolean', nullable: false},
    isColorE: {type: 'Edm.Boolean', nullable: false},
    isColorL: {type: 'Edm.Boolean', nullable: false},
    material: {type: 'Edm.String'},
    isMatB: {type: 'Edm.Boolean', nullable: false},
    isMatE: {type: 'Edm.Boolean', nullable: false},
    isMatL: {type: 'Edm.Boolean', nullable: false},
    oberflaeche: {type: 'Edm.String'},
    isOberfB: {type: 'Edm.Boolean', nullable: false},
    isOberfE: {type: 'Edm.Boolean', nullable: false},
    isOberfL: {type: 'Edm.Boolean', nullable: false},
    din: {type: 'Edm.String'},
    isDinB: {type: 'Edm.Boolean', nullable: false},
    isDinE: {type: 'Edm.Boolean', nullable: false},
    isDinL: {type: 'Edm.Boolean', nullable: false},
    teiligkeit: {type: 'Edm.String'},
    isTeiligkeitB: {type: 'Edm.Boolean', nullable: false},
    isTeiligkeitE: {type: 'Edm.Boolean', nullable: false},
    isTeiligkeitL: {type: 'Edm.Boolean', nullable: false},
    vpeEinheit: {type: 'Edm.String'},
    isvpeEinheitB: {type: 'Edm.Boolean', nullable: false},
    isvpeEinheitE: {type: 'Edm.Boolean', nullable: false},
    isvpeEinheitL: {type: 'Edm.Boolean', nullable: false},
    warengruppenNr: {type: 'Edm.String'},
    imShopSichtbar: {type: 'Edm.Boolean', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusColor: {type: 'Edm.String'},
    bemerkung: {type: 'Edm.String'},
    constructBez: {type: 'Edm.String'},
    constructEtikett: {type: 'Edm.String'},
    modelSimson: {type: 'Edm.String'},
    motorgrundtypSimson: {type: 'Edm.String'},
    modelMZ: {type: 'Edm.String'},
    motorgrundtypMZ: {type: 'Edm.String'},
    modelIWL: {type: 'Edm.String'},
    motorgrundtypIWL: {type: 'Edm.String'},
    modelEMW: {type: 'Edm.String'},
    motorgrundtypEMW: {type: 'Edm.String'},
    internetmarke: {type: 'Edm.String'},
    internetmarkeBeschr: {type: 'Edm.String'},
    warengruppenBeschr: {type: 'Edm.String'},
    modelle: {type: 'Edm.String'},
    einheitBez: {type: 'Edm.String'},
    einheitID: {type: 'Edm.Int32'},
    isEinheitE: {type: 'Edm.Boolean', nullable: false},
    etikettenTextLength: {type: 'Edm.Int32', nullable: false},
    warengruppeFalsch: {type: 'Edm.Boolean', nullable: false},
    teiligkeitValue: {type: 'Edm.Int32'},
    isStueckliste: {type: 'Edm.Boolean', nullable: false},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<ArtikelbezeichnungsgeneratorDto>;