import { Component, OnInit, Input } from '@angular/core';
import { AssetService, Asset } from 'mza-api-service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-asset-version',
  templateUrl: './asset-version.component.html',
  styleUrls: ['./asset-version.component.css']
})
export class AssetVersionComponent implements OnInit {
  @Input('assetId') assetId: number =0;  

  public width = '100%';
  public height = '100%';

  constructor(public _assetService: AssetService) { }
 
  public AssetHistItems: Asset[];
  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    if (isNullOrUndefined(this.assetId) )
      return;

   
  /*    let query = this._assetService.getAssetHist(this.assetId)
    .subscribe( ({entities, meta}) => {

    this.AssetHistItems = entities;
   
     } );  */
     let getAssetHist = this._assetService.getAssetHist();
     getAssetHist.call({assetId: this.assetId},"entities").subscribe( (Asset) => {

      this.AssetHistItems = Asset;
     
       } ); ;
  }

}
