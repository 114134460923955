import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupTree } from './assetgrouptree.entity';
//#endregion

export const AssetGroupTreeConfig = {
  name: "AssetGroupTree",
  annotations: [],
  fields: {
    parentId: {type: 'Edm.String'},
    childId: {type: 'Edm.String'},
    level: {type: 'Edm.Int16', nullable: false},
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32', nullable: false},
    isActive: {type: 'Edm.Boolean', nullable: false},
    hasChild: {type: 'Edm.Boolean', nullable: false},
    icon: {type: 'Edm.String'},
    image: {type: 'Edm.String'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assetGroupTreeAssigments: {type: 'entities.assets.AssetGroupTreeAssigment', collection: true, navigation: true},
    metaDatas: {type: 'entities.assets.AssetGroupTreeMetaData', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<AssetGroupTree>;