//#region ODataApi Imports
//#endregion

export const ISFLAGS_DATATYPE = false;
export enum DataType {
  //#region ODataApi Members
  IMAGE = 1,
  VIDEO = 2,
  AUDIO = 3,
  OFFICE = 4,
  //#endregion
}
