import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { BezeichnungDtoConfig } from './bezeichnungdto.entity.config';
import { KundenFilterDtoConfig } from './kundenfilterdto.entity.config';
import { RegistrationFilterDtoConfig } from './registrationfilterdto.entity.config';
import { MarkenFilterDtoConfig } from './markenfilterdto.entity.config';
//#endregion

export const DtosSchema = {
  namespace: "Mza.Entities.Dtos",
  enums: [],
  entities: [BezeichnungDtoConfig,
    KundenFilterDtoConfig,
    RegistrationFilterDtoConfig,
    MarkenFilterDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;