import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Asset } from 'mza-api-service';

@Component({
  selector: 'gpv-asset-downloads',
  templateUrl: './asset-downloads.component.html',
  styleUrls: ['./asset-downloads.component.css']
})
export class AssetDownloadsComponent implements OnInit {
  @Input('asset') asset: Asset;  
  constructor() { }

  ngOnInit() {
  }
  /* public saveOriginalImage(){
    const  headers = new  HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
    //https://localhost:44396/Streaming/GetAssetAsBase64/
          this.authService.get("https://edvsrv:9989/Streaming/File/" + this.fileName+"/"+ this.asset.name, ContentType.TEXT )  
           // .map(image => image.text())  
            .subscribe(  
              data => {  
             //   let result = 'data:image/png;base64,' + data;  
              //  this.assetDownloadFile = this.sanitizer.bypassSecurityTrustUrl(data);  
              }  
            );
  } */

    public getOriginalImagePath():string{
      return 'https://edvsrv:9989/Streaming/File/' + this.asset.fileName+'/'+ this.asset.name;
    }
    public getExportImage():string{
      return 'https://edvsrv:9989/Streaming/FileExport/shop/' + this.asset.fileName+'/'+ this.asset.name.replace('tif','jpg');
    }
}
