import {Directive, OnInit, Input, OnChanges, Inject} from '@angular/core';  
import {Http} from '@angular/http';  
import { HttpClient } from  "@angular/common/http";
import {ɵBROWSER_SANITIZATION_PROVIDERS, DomSanitizer} from '@angular/platform-browser'; 
import { AuthOidcService ,ContentType} from 'mza-auth-service';
import { OidcSecurityService} from 'angular-auth-oidc-client';
import { HttpHeaders } from '@angular/common/http';
@Directive({
  selector: '[asset-image]',
  providers: [ɵBROWSER_SANITIZATION_PROVIDERS],  
  host: {  
    '[src]': 'assetImageData'  
  }  
})
export class AssetImageDirective implements OnInit, OnChanges {
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.loadImage();
  }

  imageData: any;  
  assetImageData: any;  
  headers: any; 
  @Input('asset-image') assetId: string;  
  @Input('asset-size') assetSize: number = 1;  
  constructor(private http: Http,  private  httpClient:HttpClient,
              private sanitizer: DomSanitizer,
              @Inject('ASSET_URL') private assetUrl: string,
              private authService: AuthOidcService, private _securityService: OidcSecurityService) { }  
  
/*   ngOnInit() {   
  const token = this.authService.getToken();
  const tokenValue = 'Bearer ' + token;
    const  headers = new  HttpHeaders().set("X-CustomHttpHeader", "CUSTOM_VALUE").set('Authorization', tokenValue);
  	

    this.httpClient.get("https://localhost:44396/Streaming/GetAssetAsBase64/" + this.assetId+"/1", {  headers } )  
    //  .map(image => image.text())  
      .subscribe(  
        data => {  
          this.imageData = 'data:image/png;base64,' + data;  
          this.assetImageData = this.sanitizer.bypassSecurityTrustUrl(this.imageData);  
        }  
      );  
  }   */
  ngOnInit() {   
      this.loadImage();
    }
    
    private loadImage(){
      const  headers = new  HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
      //https://localhost:44396/Streaming/GetAssetAsBase64/
            this.authService.get(this.assetUrl+ "/Streaming/GetAssetAsBase64/" + this.assetId+"/"+ this.assetSize, ContentType.TEXT )  
             // .map(image => image.text())  
              .subscribe(  
                data => {  
                  this.imageData = 'data:image/png;base64,' + data;  
                  this.assetImageData = this.sanitizer.bypassSecurityTrustUrl(this.imageData);  
                }  
              );
    }
 
}
