import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Group } from './group.entity';
//#endregion

export const GroupConfig = {
  name: "Group",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    mainGroupId: {type: 'Edm.Int32', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    mainGroup: {type: 'entities.schema.Maingroup', navigation: true},
    groupTreeAssigments: {type: 'entities.schema.GroupTreeAssigment', collection: true, navigation: true},
    attributes: {type: 'entities.schema.GroupAttribute', collection: true, navigation: true},
    aktien: {type: 'entities.boerse.GroupAktie', collection: true, navigation: true},
    artikelgruppen: {type: 'entities.articles.ArtikelGroup', collection: true, navigation: true},
    produktTypGroups: {type: 'entities.produkte.ProduktTypGroup', collection: true, navigation: true},
    customerGroups: {type: 'entities.customers.CustomerGroup', collection: true, navigation: true},
    wettbewerberPreisGroups: {type: 'entities.schema.WettbewerberPreisGroup', collection: true, navigation: true},
    registrationGroups: {type: 'services.RegistrationGroup', collection: true, navigation: true},
    statusGroup: {type: 'services.StatusGroup', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Group>;