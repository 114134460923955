import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { State } from '@progress/kendo-data-query';
import { Report,ReportDataService } from 'mza-api-service';

@Component({
  selector: 'gpv-reports-overview',
  templateUrl: './reports-overview.component.html',
  styleUrls: ['./reports-overview.component.css']
})
export class ReportsOverviewComponent implements OnInit {

  public dialogOpened = false;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'name',
      dir: 'desc'
    }]
    // Initial filter descriptor
};

public _dataServiceReports: ReportDataService;
  constructor(public service: ReportDataService ,private notificationService: NotificationService) {
    this._dataServiceReports  = service;
   }

   ngOnInit() {
    this.dataBind();
}

public switchAnsicht(){
  this.dataBind();
}

private dataBind(){
  //var be : Beigabe;
  let reqReports = this._dataServiceReports.getQuery();

  reqReports.query.select(["id","name","dbObject","description","componentType","statusId"])
/*   reqBeigaben.query.expand( [{ kundenFilter: {select: ["bezeichnung"],expand: {kundenFilterErgebnise:{top:0,count:"true", select:["id"]} } }}, { beigabenArtikel: {select: ["artikelId","menge","mindestWarenwert","useAusloeser"],expand: {artikel: {select: ["artikelnr"]}}}}]);
  reqBeigaben.query.filter({typId: {eq: 1}});
  reqBeigaben.query.filter().push({isArchiv: {eq: this.showArchiv}}); */
  this._dataServiceReports.query(this.state,reqReports);  

//  this.serviceA.entities().get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
//  collection.get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
} 



public deleteReport(id: number){
  this._dataServiceReports.odataService.destroy({id: id}).subscribe( r => {
    this.dataBind();
    this.notificationService.show({
      content: 'Report gelöscht',
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 3000,
      //closable: true
  });
  },
      (error) => {  
        this.notificationService.show({
          content: 'Fehler beim Löschen des Reports',
          cssClass: 'save-error',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'error', icon: true },
         // hideAfter: this.hideAfter,
         closable: true
      });
      })
}

public close(component) {
  this[component + 'Opened'] = false;
}

public open(component, id) {
  this[component + 'Opened'] = true;
  this.selectedItemId = id;
}
private selectedItemId : number = 0
public action(status) {
  if(status && this.selectedItemId > 0)
    this.deleteReport(this.selectedItemId );

  this.selectedItemId = 0;
  this.dialogOpened = false;
}

}
