import { Component, OnInit, Input, forwardRef } from '@angular/core';
import {  ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
interface Item {
  text: string,
  value: number
}
@Component({
  selector: 'mza-beigaben-bestellart',
  templateUrl: './beigaben-bestellart.component.html',
  styleUrls: ['./beigaben-bestellart.component.css'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => BeigabenBestellartComponent),
      multi: true 
    }
  ]
})


export class BeigabenBestellartComponent implements OnInit, ControlValueAccessor  {
  writeValue(bestellart: number): void {
   this.art = bestellart;

  }

  onChange = (bestellart: number) => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched = () => {};
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @Input() disabled = false;
  @Input() art: number;

  public bestellArten: Array<Item> = [
    { text: "1-malige Bestellung", value: 1 },
    { text: "mehrmalige Bestellung", value: 2 },
    { text: "Direktversand", value: 3 }
  ];

  constructor() { }

  ngOnInit() {
  }
  valueChanged(value: any) {
    //this.art = value;
    this.onChange(value);
  }
}
