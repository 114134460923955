import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../../authentication';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit { 

  constructor(public authService: AuthService, private spinner: NgxSpinnerService) { }    
  
    title = "Login";
    auth = window.localStorage.getItem('access_token');
    claims=null;
    login() {     
      this.spinner.show();
      this.authService.login();
    }   
    logout(){
      this.authService.signout();
    }

    ngOnInit() {
    }
}
