import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HauptgruppenMainModule } from './hauptgruppen-main/hauptgruppen-main.module';
import { HauptgruppenAuswahlComponent } from './hauptgruppen-auswahl/hauptgruppen-auswahl.component';
import { HauptgruppenRoutingModule } from './hauptgruppen-routing.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';




@NgModule({
  declarations: [HauptgruppenAuswahlComponent],
  imports: [
    CommonModule,
    HauptgruppenMainModule,
    HauptgruppenRoutingModule,
    DropDownListModule
  ],exports: [HauptgruppenAuswahlComponent]
})
export class HauptgruppenModule { }
