import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupMetaData } from '../entities/assets/assetgroupmetadata.entity';
import { AssetGroup } from '../entities/assets/assetgroup.entity';
import { AssetGroupTreeAssigment } from '../entities/assets/assetgrouptreeassigment.entity';
import { CustomerAssetGroup } from '../entities/customers/customerassetgroup.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class AssetGroupService extends ODataEntityService<AssetGroup> {
  constructor(protected client: ODataClient) {
    super(client, 'AssetGroup', 'entities.assets.AssetGroup');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public assetGroups(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<AssetGroup> {
    return this.entity(entity).navigationProperty<AssetGroup>('assetGroups');
  }
  public assetGroupTreeAssigments(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<AssetGroupTreeAssigment> {
    return this.entity(entity).navigationProperty<AssetGroupTreeAssigment>('assetGroupTreeAssigments');
  }
  public customerAssetGroups(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<CustomerAssetGroup> {
    return this.entity(entity).navigationProperty<CustomerAssetGroup>('customerAssetGroups');
  }
  public metaDataList(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<AssetGroupMetaData> {
    return this.entity(entity).navigationProperty<AssetGroupMetaData>('metaDataList');
  }
  public status(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<AssetGroup>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
