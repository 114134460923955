import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input   } from '@angular/core';
import { Observable } from 'rxjs';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined } from 'util';
import { MarkenFilterDto } from 'mza-api-service';
import { MarkenFilterDtoService } from 'mza-api-service';

@Component({
  selector: 'mza-marken-name-multiselect',
  templateUrl: './marken-name-multiselect.component.html',
  styleUrls: ['./marken-name-multiselect.component.css']
})
export class MarkenNameMultiselectComponent implements OnInit {

  @Input() values: Array<string>;
  @Input() disabled: boolean = false;
  @Output() brandIds = new EventEmitter<Array<MarkenFilterDto>>();

  @ViewChild('markenFilterMultiSelect') public markenFilterMultiSelect: MultiSelectComponent;

  public data: Array<MarkenFilterDto>;
  public selectedItems: Array<MarkenFilterDto>;

  constructor(@Inject(MarkenFilterDtoService) private brandService: MarkenFilterDtoService) {
    //this.brandService.filterBrands("");//.subscribe(res => this.data = res);
   }

   handleFilter(value) {
    //if (value.length >= 0) {
    //this.brandService.filterBrands(value);//.subscribe(res => this.data = res);
  //}else {
  //    this.markenFilter.toggle(false);
  //}
  }
   public onValueChange(value: Array<MarkenFilterDto>) {
    if(isUndefined(value))
      this.brandIds.emit(null);
    else
      this.brandIds.emit(value);
}
  ngOnInit() {
/*     this.brandService.filterBrands(`${this.values}`).subscribe(res =>{
      this.data = res;
       if(this.values && this.data.length == 1){
          // this.selectedItems = this.data[0];
    }
    } ); */
   
  }

}
