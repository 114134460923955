import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, Inject, HostListener, AfterViewChecked } from '@angular/core';
import { GroupTreeService, GroupTreeAssigmentService, GroupTree,RefType } from 'mza-api-service';
import { TreeViewComponent, SelectableSettings, TreeItemDragEvent, TreeItemDropEvent, DropPosition, TreeItemAddRemoveArgs, CheckableSettings, TreeItemLookup, TreeItem } from '@progress/kendo-angular-treeview';
import { isNullOrUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { ContextAnzeige } from '../../../shared/types/context-types.enum';
import { DOCUMENT } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';


interface Settings{
  allowDragAndDrop : boolean ;
  allowTreeStructur : boolean ;
  allowMultiSelection : boolean ;
  allowGroupTreeAssignmentForParent: boolean;
  allowGroupTreeAssignment: boolean;
  allowGroupsInDifferentNodes: boolean;
  allowAddRemove: boolean;
  showValue: boolean;
  showDescription: boolean;
  useArtikel: boolean;
  useAttribute: boolean;
  useAssets: boolean;
  useKunden: boolean;
  usedMaingroups: string;
} 
@Component({
  selector: 'gpv-baum-gruppen-zuweisung',
  templateUrl: './baum-gruppen-zuweisung.component.html',
  styleUrls: ['./baum-gruppen-zuweisung.component.scss']

})
export class BaumGruppenZuweisungComponent implements OnInit, OnChanges, AfterViewChecked  {
  @ViewChild('inputTreeName')
    public input: ElementRef<HTMLInputElement>;

    @ViewChild('treeViewZw')
    public treeView: TreeViewComponent;

  public scrHeight:any;
  public scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
  }

  

  @Input() maingroupId: number;
  @Input() maingroupIdRef: number;
  @Input() selectedGroupTree: GroupTree;
  @Output("onParentDataBindTree") onParentDataBindTree: EventEmitter<any> = new EventEmitter(); 
  @Output() selectedChanged = new EventEmitter<GroupTree>();
  public GroupTreeData: any[];
  public parsedGroupTreeData: any[]; 
  private filterGroupTreeId: string ="";
  public field: Object ;

      //SETTINGS 
      public Setting: Settings ={
        allowDragAndDrop : false,
        allowTreeStructur : false,
        allowMultiSelection : false,
        allowGroupTreeAssignmentForParent: false,
        allowGroupTreeAssignment: false,
        allowGroupsInDifferentNodes: false,
        allowAddRemove: false,
        showValue: false,
        showDescription: false,
        useArtikel: false,
        useAttribute:false,
        useAssets: false,
        useKunden:false,
        usedMaingroups: ""
      };
      public selectableSettings: SelectableSettings = {
        mode: 'single'
      };
      public selectedDataItem: any;
      public dataItem : any;
      public selectedKeys: any[] = [];
      public expandedKeys: any[] = [];
      public selectedNodes: string[] = [];
      public groupId: number;


      //SETTING CHECKED TREE
      public enableCheck = true;
      public checkChildren = false;// true;
      public checkParents = false;//true;
      public checkOnClick = false;
      public checkMode: any = 'multiple';
      public selectionMode: any = 'single';
      public checkedKeys: any[] = [];

      public get checkableSettings(): CheckableSettings {
        return {
            checkChildren: this.checkChildren,
            checkParents: this.checkParents,
            enabled: this.enableCheck,
            mode: this.checkMode,
            checkOnClick: this.checkOnClick
        };
      }

//FilterTree

public filterTree: Array<GroupTree> = [];
public selectedfilterTree: Array<GroupTree> = [];


public onDeleteSelectedfilterTree(value){
console.log(value);
  this.deleteSelectedFilterTree(value.dataItem.childId);
}

private deleteSelectedFilterTree(childId : string){
  this.selectedfilterTree.forEach( (item, index) => {
    if(item.childId === childId) this.selectedfilterTree.splice(index,1);
  });
    this.filterTree.forEach( (item, index) => {
      if(item.childId === childId) this.filterTree.splice(index,1);
    });
    console.log(this.selectedfilterTree.length);
    if(this.selectedfilterTree.length > 0){
      
      this.filterGroupTreeId = this.selectedfilterTree[this.selectedfilterTree.length - 1].childId; 
      console.log("New filterGroupTreeFromRemove", this.filterGroupTreeId,this.selectedfilterTree[this.selectedfilterTree.length - 1].childId);
    }else{
      this.filterGroupTreeId = "";
    }
       
       
    this.onDataBindTree();
}

private filterSelectedItem(item: any){
  this.filterGroupTreeId = item.childId;
  this.onDataBindTree();
  this.filterTree.push(item);
  this.selectedfilterTree.push(item);//this.contextItem.name+ ' (' + this.contextItem.value +')'
}

public isToogleExpand: boolean = false;
public isToogleFilter: boolean = false;
public isToogleCheckedChilds: boolean = false;

public onToogleFilterSelectedItem(value: boolean){
 console.log(value);
 this.isToogleFilter = value;
  if(value)
     this.filterSelectedItem(this.getSelectedItem() );
  else
    this.deleteSelectedFilterTree(this.getSelectedItem().childId )
}
public onToogleExpandSelectedItem(value: boolean){
  this.isToogleExpand = value;
  if(value)
     this.expandTreeChilds(this.getSelectedItem().childId );
  else
    this.collapseTreeChilds(this.getSelectedItem().childId );   
}
public onToogleCheckChildrenItem(value: boolean){
  if(isNullOrUndefined( this.maingroupIdRef))
    return; 
    //ToogleButton bleibt sonst Rot, da momentan der Status in der Datasource falsch ist.
 this.isToogleCheckedChilds = value;
  if(value)
     this.addGroupTreeItem(this.getSelectedItem(),RefType.GROUP_REF_WITH_CHILD);
  else
    this.removeGroupTreeItem(this.getSelectedItem(),RefType.GROUP_REF_WITH_CHILD);   
}


// CONTEXT TREE 
public  contextItemsAnzeige: any[] = [{ text: ContextAnzeige.Expands }, { text: ContextAnzeige.Collapse }];
  
private contextItem: any;
@ViewChild('grouptreecontext', null) 
public contextMenuGroupTree: ContextMenuComponent;

    public onSelectTreeContext({ item }): void {
    if (item.text === ContextAnzeige.Expands){
       this.expandTreeChilds(this.contextItem.childId);
    }else if (item.text === ContextAnzeige.Collapse){
      this.collapseTreeChilds(this.contextItem.childId);
    }else if(item.text.substring(0,11) === "Filtern auf"){
      this.filterSelectedItem(this.contextItem  );//this.contextItem.name+ ' (' + this.contextItem.value +')'
    }

  }
  
  constructor(private dataService: GroupTreeService,
    private _groupTreeAssigmentService: GroupTreeAssigmentService) { 
      this.getScreenSize();
    }


  ngOnInit() {
    //this.loadRefKeysFromSelectedGrouptree();
    //  this.onDataBindTree();
  
  }
ngOnChanges(changes: SimpleChanges): void {

    this.loadRefKeysFromSelectedGrouptree();
    this.selectedKeys= [];
  }
  public onNodeClick(e: any): void {
    if (e.type === 'contextmenu') {
        const originalEvent = e.originalEvent;
  
        originalEvent.preventDefault();
        //this.selectedNodeText =  e.item.dataItem.name;
        this.contextItem = e.item.dataItem;
        if(e.item.dataItem.icon ==='folder'){
          
  
          var contextItemsFolder: any[] = [

        {text: "Knoten " + e.item.dataItem.name , items: this.contextItemsAnzeige},
        {text: "Filtern auf " + e.item.dataItem.name },
        {text: "Alle Gruppen in " + e.item.dataItem.name + "einfügen"}
      ];
  
        this.contextMenuGroupTree.items = contextItemsFolder;
        }
         

        this.contextMenuGroupTree.show({ left: originalEvent.pageX, top: originalEvent.pageY });
    }
  }
  private expandCheckedTreeItems(): void {
    
    if(isNullOrUndefined(this.GroupTreeData)) return;

    this.expandedKeys = [];
    if(isNullOrUndefined( this.checkedKeys) || this.checkedKeys.length == 0 ) return;
    
    this.checkedKeys.forEach((key) => {
      console.log(key);
      let treeItem : GroupTree = this.GroupTreeData.find((x) => x.id === key);
      this.GroupTreeData.forEach((item) => {
          //&& treeItem.childId.length <= item.parentId.length
        if(!isNullOrUndefined(treeItem) && !isNullOrUndefined(item.parentId) && !isNullOrUndefined(treeItem.childId)  && treeItem.childId.substring(0,item.parentId.length) === item.parentId && this.expandedKeys.indexOf(item.parentId) === -1 )
        {
         // console.log("Expand",item.parentId,treeItem.childId);
          this.expandedKeys.push(item.parentId)
        }
       /*     if(!isNullOrUndefined(item.c) && 
               item.parentId.length >= childId.length &&
               item.parentId.substring(0,childId.length) === childId && this.expandedKeys.indexOf(item.parentId) === -1){
                 this.expandedKeys.push(item.parentId)
       
           } */
                  
         }, []);
    });
  }
  private expandTreeChilds(childId :string): void {
    this.GroupTreeData.forEach((item) => {
  
      if(!isNullOrUndefined(item.parentId) && 
          item.parentId.length >= childId.length &&
          item.parentId.substring(0,childId.length) === childId && this.expandedKeys.indexOf(item.parentId) === -1){
            this.expandedKeys.push(item.parentId) 
      }            
    }, []);
  }
  private collapseTreeChilds(childId :string): void {
    this.GroupTreeData.forEach((item) => {
  
      let itemIdx = this.expandedKeys.indexOf(item.parentId);
      if(!isNullOrUndefined(item.parentId) && 
          item.parentId.length >= childId.length &&
          item.parentId.substring(0,childId.length) === childId && itemIdx !== -1){
            this.expandedKeys.splice(itemIdx,1);
  
      }
             
    }, []);
  }

  private loadRefKeysFromSelectedGrouptree(){
    let reqData = this.dataService.entities();
    reqData.select("refGroupTreeId");
   // query.filter({maingroupId: {"eq": this.maingroupIdRef}});
    reqData.query.filter("startswith(childId,'"+this.selectedGroupTree.childId+"')");
    reqData.query.filter().push({"refGroupTreeId": {"gt": 0} });
    reqData.get().subscribe( ({entities, meta}) => {
      this.checkedKeys = entities.map(a => a.refGroupTreeId);
      this.expandCheckedTreeItems();
    });
  }

  public onDataBindTree(){
 /*    let query = this.dataService.entities();

    query.filter({maingroupId: this.maingroupId});
    query.filter().push({"statusid": {"gt": 3} }); // Umändern 14 dynamisch bestimmen
    if(this.filterGroupTreeId.length > 0)
      query.filter("startswith(childId,'"+this.filterGroupTreeId+"')");
     //query.filter({ChildId: {"startswith": this.filterGroupTreeId}});

    query.get().subscribe( ([data, entity]) => {

    this.GroupTreeData = data;
    this.parsedGroupTreeData = data;
    this.field = { dataSource: this.GroupTreeData ,id: 'id', childId: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' };
    //this.tree.expandAll();
    this.parsedGroupTreeData =  this.setRootForGroupTreeData()
    this.loadRefKeysFromSelectedGrouptree();
   } ); */
 
  }

  /* private setRootForGroupTreeData(){
    console.log("SetRoot:",this.filterGroupTreeId.length);
    if(this.filterGroupTreeId.length > 0){
      console.log(this.GroupTreeData.find(f => f.childId == this.filterGroupTreeId ));
      this.GroupTreeData.find(f => f.childId == this.filterGroupTreeId ).parentId = null;
   
    }
      
    return this.GroupTreeData;
  } */

  public nodeSelected({ index, dataItem }: any): void {
    this.edit(dataItem,index);
    this.selectedKeys = [index];
    console.log("node selected",dataItem,index );
    this.dataItem = index; // getDataFromDatabase
    this.selectedDataItem = this.getSelectedItemByChildId( dataItem.childId);
    this.selectedChanged.emit(dataItem);
    //this.checkedKeys = [dataItem.id];
    this.isToogleExpand = this.expandedKeys.includes(dataItem.childId);
    this.isToogleFilter =  this.selectedfilterTree.some( item => item.childId === dataItem.childId);
   
    this.isToogleCheckedChilds = (dataItem.refType === RefType.GROUP_REF_WITH_CHILD);
    console.log(dataItem,this.dataItem.refType === RefType.GROUP_REF_WITH_CHILD,this.isToogleCheckedChilds );

    //e.nodeData;
 //   let hasGroupChildren = false; // Chekc has dataItem child
  //  this.allowParentZuordnung = this.isAllowMainGroupParentAssignment || hasGroupChildren ;

/*   console.log("get groupId from item",this.selectedDataItem );

    let query = this._groupTreeAssigmentService.entities();
    query.select("GroupId");
    query.filter({groupTreeId: this.selectedDataItem.id});
    query.get({withCount: false}).subscribe(([r,odata]) => {
      console.log("anzahl ergebnise", odata.count )
      if(r.length > 0){
         this.groupId = r[0].groupId;
      }else{
         this.groupId = null;
      }
         
    }
      ); */
    /* if(!isNullOrUndefined(this.selectedDataItem)){
      this.query =this. _dataServiceArtikelGroups.getQuery();
      this.query.expand({artikel: {select: ["id","artikelNr"]}});
      this.query.filter({groupId: this.selectedDataItem.groupId}); 
     } */
};

 private getSelectedItem() : GroupTree{
  return this.GroupTreeData.find(e => e.childId === this.selectedKeys[0]);
} 

public getSelectedItemByChildId(selectedId: any) : GroupTree{
  return this.GroupTreeData.find(e => e.childId === selectedId);
}
public iconClass({ icon, childId}: any): any {
  return {
   /*   'k-i-file-ascx': (icon === 'zip'), */ 
      'k-i-folder': (icon === 'folder' && !this.isNodeExpanded(childId)),
      'k-i-folder-open': (icon === 'folder' && this.isNodeExpanded(childId)),
//      'k-i-html': isOfType(icon, 'html'),
 //     'k-i-image': isOfType(icon, 'jpg|png'),
      'k-icon': true
  };
}

public isNodeExpanded(childId: string): boolean{
  return this.expandedKeys.indexOf(childId) >= 0
}


public handleChecking(itemLookup: TreeItemLookup): void {
  if(isNullOrUndefined( this.maingroupIdRef))
    return;  // speichern nicht möglich meldung oder check nicht setzen
 
 
  let dataItem : GroupTree = [itemLookup.item][0].dataItem;
  if(this.isItemChecked(dataItem.id))
  {
      this.addGroupTreeItem(dataItem);
  }
  else{
    this.removeGroupTreeItem(dataItem);
  }

 //this.selectedGroupTreeId
 
}

private addGroupTreeItem(dataItem : GroupTree, refType: RefType = RefType.GROUP_REF){
        let item: GroupTree = { maingroupId: this.maingroupIdRef, description: dataItem.description , id: 0, parentId: this.selectedGroupTree.childId,
          isActive: false,hasChild: false, name: dataItem.name , 
          childId: this.replaceMaingroupIdToRefId(dataItem.childId) ,level: 0,
          createdBy:"",value: dataItem.value,sortValue: dataItem.sortValue, 
          icon: dataItem.icon ,image: dataItem.image,statusId: dataItem.statusId,
          isNodeEditAllow:false, isNodePrivate:false, privatePersonId:null, useArtikel:false,useAttribute:false, useAssets:false, useKunden:false, useWettbewerber:false,
          useWettbewerberPreis:false, useArtikelPreis:false, useArtikelPreisVk:false, useRegistration:false, isSystemRelevant:false,useAktie: false,
          refGroupTreeId: dataItem.id, refType: refType}; //Ubergabe Status optimieren
      this.dataService.create(item).subscribe(
            r => {
                this.onParentDataBindTree.emit();
            }
      );
}

private removeGroupTreeItem(dataItem : GroupTree, refType: RefType = RefType.GROUP_REF){
  let item: GroupTree = { maingroupId: this.maingroupIdRef, description: dataItem.description , id: this.selectedGroupTree.id, parentId: this.selectedGroupTree.childId,
    isActive: false,hasChild: false, name: dataItem.name , 
    childId: this.replaceMaingroupIdToRefId(dataItem.childId) ,level: 0,
    createdBy:"",value: dataItem.value,sortValue: dataItem.sortValue, 
    icon: dataItem.icon ,image: dataItem.image,statusId: dataItem.statusId,
    isNodeEditAllow:false, isNodePrivate:false, privatePersonId:null, useArtikel:false,useAttribute:false, useAssets:false, useKunden:false, useWettbewerber:false,
    useWettbewerberPreis:false, useArtikelPreis:false, useArtikelPreisVk:false, useRegistration:false, isSystemRelevant:false,useAktie: false,
    refGroupTreeId: dataItem.id, refType: refType}; 

    this.dataService.destroy(item).subscribe(
      r => {
        this.onParentDataBindTree.emit();
      });

}

public isItemChecked(id: number) {
  return this.checkedKeys.indexOf(id) > -1 ? false : true;
}

private replaceMaingroupIdToRefId(nodeValue: string): string{
  if(isNullOrUndefined( this.maingroupIdRef))
    return "";

  let values = nodeValue.split("/")
  console.log(values);
  if(values.length > 1)
    values[1] = this.maingroupIdRef.toString();
  return values.join("/");
}


//SERACH TREEVIEW
public searchTerm = '';
public onkeyup(value: string): void {
  this.parsedGroupTreeData = this.searchTreeViewItems(this.GroupTreeData, value);
}
public searchTreeViewItems(items: any[], term: string): any[] {
  return items.reduce((acc, item) => {
      if (this.contains(item.name, term)) {
          acc.push(item);
          this.searchParentNodesFromSearchResult(acc,item);
      } 
     /*  else if (item.items && item.items.length > 0) {
          let newItems = this.searchTreeViewItems(item.items, term);

          if (newItems.length > 0) {
            //id: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' 
          acc.push({ name: item.name, items: newItems });
          }
      } */

      return acc;
  }, []);
}

//Hat noch optimierungspotenzial. 
public searchParentNodesFromSearchResult(accSearch: any, itemFound: any){
  this.GroupTreeData.forEach((item) => {
   // console.log(itemFound.childId,item.parendId);
      if(itemFound.parentId=== item.childId ){
        if(!this.containsNode(accSearch, item.childId))
            accSearch.push(item);
      
        this.searchParentNodesFromSearchResult(accSearch,item );
        return;
      }

  }, []);
}
public contains(text: string, term: string): boolean {
//  console.log(text,term);
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
}
public containsNode(accSearch: any,childId: string): boolean {
  let isFound= false;
  accSearch.forEach((item) => {
    if(item.childId === childId)
        isFound = true;
        return isFound;
        
  }, []);
    return isFound;
  }

//END SEARCH TREE VIEW


//TREE EDITING
public ngAfterViewChecked(): void {
  // focus the edited node after save
  if (this.focusIndex && !this.textFormControl) {
      this.treeView.focus(this.focusIndex);
      this.focusIndex = null;
  }

  // focus first input on node edit
  if (this.focusEditor && this.input) {
      this.input.nativeElement.focus();
      this.focusEditor = false;
      console.log("focus");
  }
  console.log("focusEnd",this.focusEditor);
}

public activeItem: TreeItem;
public focusEditor: boolean;
public focusIndex: string;
public textFormControl: FormControl;

public handleEditorFocusOut(event: FocusEvent, editorContainer: HTMLElement): void {
  const focusTarget = event.relatedTarget as HTMLElement;

  // close the editor if the new focus target is not part of it
  if (!editorContainer.contains(focusTarget)) {
      this.cancel();
  }
}
public edit(item: any,index : string): void {
  console.log("Edit",item)
  // skip editing if same node is passed
  if (this.activeItem && this.activeItem === item) {
      return;
  }

  this.activeItem = item;

  this.focusEditor = true; // focus editor on next render
  this.focusIndex = index; // book keeping of the edited node index

  this.textFormControl = new FormControl(item.name, Validators.required);
  console.log("Edit")
}

public cancel(): void {
  this.activeItem = null;
  this.textFormControl = null;
}
public save(): void {
  const { dataItem } = this.activeItem;

  // return focus to input if invalid
  if (this.textFormControl.invalid) {
      this.focusEditor = true;
      return;
  }

  // update the corresponding dataitem property
  dataItem.text = this.textFormControl.value;

  // close the editor
  this.cancel();
}

public handleEditorEnter(event: KeyboardEvent): void {
  // prevent reopening the editor due to enter press
  event.stopPropagation();

  // persist the changes
  this.save();
}

}
