import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { CustomerGroup } from '../entities/customers/customergroup.entity';
import { CustomerViewDto } from './customerviewdto.entity';
//#endregion

@Injectable()
export class CustomerViewDtoService extends ODataEntityService<CustomerViewDto> {
  constructor(protected client: ODataClient) {
    super(client, 'CustomerViewDto', 'services.CustomerViewDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getCustomers(): ODataFunctionResource<{groupId: number}, CustomerGroup> {
    const resource = this.entities().function<{groupId: number}, CustomerGroup>('services.GetCustomers');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
