// import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input, forwardRef } from '@angular/core';
// import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
// import { isUndefined } from 'util';
// import { Filter }from 'angular-odata';
// import { FormGroup, FormBuilder, FormControl, ControlContainer, FormGroupDirective, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
// import { ThrowStmt } from '@angular/compiler';
// import { ArtikelService } from 'mza-api-service';
// import { Artikel } from 'mza-api-service';

// @Component({
//   selector: 'mza-artikel-nr',
//   templateUrl: './artikel-nr.component.html',
//   styleUrls: ['./artikel-nr.component.css'],
//   providers: [ArtikelService,{ 
//     provide: NG_VALUE_ACCESSOR, 
//     useExisting: forwardRef(() => ArtikelNrComponent),
//     multi: true 
//   }]
//  // viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],

// })
// export class ArtikelNrComponent implements OnInit , ControlValueAccessor {
//   writeValue(artikelId: number): void {
//     //  this.art = bestellart;
//       this.artikelId = artikelId;
//     }
  
//     onChange = (bestellart: number) => {};
//     registerOnChange(fn: any): void {
//       this.onChange = fn;
//     }
  
//     onTouched = () => {};
//     registerOnTouched(fn: any): void {
//       this.onTouched = fn;
//     }
//     setDisabledState?(isDisabled: boolean): void {
//       this.disabled = isDisabled;
//     }

//   @Input() value: string;
//   @Input() disabled: boolean = false;
//   @Input() useOnlyShopArtikel = true;
//   @Input() artikelId: number = 0;
//   @Input()  formGroup: FormGroup;
  
//   @Output() artikelNr = new EventEmitter<string>();

//   @ViewChild('artikelFilter', null) public artikelFilter: ComboBoxComponent;

//   public data: Array<Artikel> ;
//   public selectedItem: Artikel;

// // , private formBuilder: FormBuilder,private parentForm: FormGroupDirective,
//   constructor(@Inject(ArtikelService) private artikelService: ArtikelService) {
//   /*  this.form = this.formBuilder.group({
//       artikel: [{id: 0, artikelNr: '', disabled: this.disabled}, Validators.required  ]
//     }); */
 
//    }

//   ngOnInit() {
//     // this.parentForm.form.addControl('artikelForm' ,this.form);
    
//     if(this.artikelId !== 0){
//       let data = this.artikelService.entity(this.artikelId);
//       data.select(["id","artikelNr","imShopSichtbar"]);
//       data.get().subscribe(([res,]) =>{
//         this.data.push(res) ;
  
//         this.selectedItem = res;
//         this.formGroup.setValue({
//           artikel: {id: res.id, artikelNr: res.artikelNr, disabled: this.disabled}
//         });
//         /* this.form = this.formBuilder.group({
//           artikel: {id: item.id, artikelNr: item.artikelNr, disabled: this.disabled} 
//         }); */
       
//     });
//     }
   
//   }

//   handleFilter(value) {
//     if (value.length >= 2) {
//     //this.artikelService.filterArtikel(value).subscribe(res => this.data = res);
//       let collection = this.artikelService.entities();
//       collection.select(["id","artikelNr","imShopSichtbar"]);
//       collection.filter({artikelNr: {startswith: value}} );
//      // collection.filter().push({warengruppenNr: {startswith: value}});
//     // collection.filter().push({internetmarke: {startswith: value}});
//       if(this.useOnlyShopArtikel){
//         collection.filter().push({imShopSichtbar: {eq: true}});
//       }

//       collection.get().subscribe(([s,]) => this.data = s);
//   }else {
//       this.artikelFilter.toggle(false);
//   }
//   }
//    public onValueChange(value: Artikel) {
//     if(isUndefined(value))
//       this.artikelNr.emit(null);
//     else
//       this.artikelNr.emit(value.artikelNr);
// }




// }
import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input, forwardRef, OnDestroy } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined, isNullOrUndefined } from 'util';
import { Filter }from 'angular-odata';
import { FormGroup, FormBuilder, FormControl, ControlContainer, FormGroupDirective, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { ArtikelService ,Artikel} from 'mza-api-service';
import { Subscription } from 'rxjs';

 /* export interface ArtikelDto {
  artikelNr: string;
  artikelId: number;
  imShopSichtbar: boolean;
}  */

@Component({
  selector: 'mza-artikel-nr',
  templateUrl: './artikel-nr.component.html',
  styleUrls: ['./artikel-nr.component.css'],
  providers: [ArtikelService,{ 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => ArtikelNrComponent),
    multi: true 
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ArtikelNrComponent),
    multi: true
  }]
 // viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],

})
export class ArtikelNrComponent implements OnInit , ControlValueAccessor, OnDestroy  {
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
/*   onChange(value: Artikel) {
    throw new Error("Method not implemented.");
  } */
  onChange: any = () => {};
  onTouched: any = () => {};
  get value():Artikel {
    return this.form.value;
  }
  set value(value :Artikel) {
    console.log("setValue: ",value);
  // this.form.setValue({id: value.id, artikelNr: value.artikelNr, imShopSichtbar: value.imShopSichtbar});
   this.form.setValue({selected: {id: value.id}});
 // this.form.setValue({id: value.id});
   //this.form.setValue(value);
    this.dataBound(value.id);
    this.onChange(value);
    this.onTouched();
  }

/*   get artikelIdControl() {
    return this.form.controls.artikelId;
  } */

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  form: FormGroup;
  subscriptions: Subscription[] = [];
  
  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
     // this.artikelId = artikelId;
    }
  
  /*   onChange = (bestellart: number) => {};
    registerOnChange(fn: any): void {
      this.onChange = fn;
    } */
  
  /*   onTouched = () => {};
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    } */
    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
    validate(_: FormControl) {
      return this.form.valid ? null : { artikel: { valid: false } };
    }
  //@Input() value: string;
  @Input() disabled: boolean = false;
  @Input() useOnlyShopArtikel = true;
  @Input() artikelId: number = 0;
  @Input() artikelNr: string = '';
  
  @Output() artikelNrChange = new EventEmitter<string>();

  @ViewChild('artikelFilter') public artikelFilter: ComboBoxComponent;

  public data: Array<Artikel> ;
  public selectedItem: Artikel;

// , private formBuilder: FormBuilder,private parentForm: FormGroupDirective,
  constructor(@Inject(ArtikelService) private artikelService: ArtikelService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      selected:  this.formBuilder.control({
      id: [0], 
      artikelNr: ['']
    })});
 


  /*  this.form = this.formBuilder.group({
      artikel: [{id: 0, artikelNr: '', disabled: this.disabled}, Validators.required  ]
    }); */
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
   }

  ngOnInit() {
    // this.parentForm.form.addControl('artikelForm' ,this.form);
    console.log(this.value);
    if(this.artikelId !== 0){
      let query = this.artikelService.entity(this.artikelId);
      query.select(["id","artikelNr"]);
      query.get().subscribe(({entity, meta}) =>{
        this.data = [] as Array<Artikel>;
        let artikel = <Artikel>{
          id: entity.id,
          artikelNr : entity.artikelNr
        }
     
        this.data.push(artikel) ;
        this.selectedItem = artikel;

    });
    }
   
  }

  private dataBound(value: number){
    console.log(this.value);
    if(value !== 0){
      let query = this.artikelService.entity(value);
      query.select(["id","artikelNr"]);
      query.get().subscribe(({entity, meta}) =>{
        this.data = [] as Array<Artikel>;
        let artikel = <Artikel>{
          id: entity.id,
          artikelNr : entity.artikelNr
        }
     
        this.data.push(artikel) ;
        this.selectedItem = artikel;

    });
    }
  }

  handleFilter(value) {
    console.log("handleFiler:" , this.value);
    if (value.length >= 2) {
    //this.artikelService.filterArtikel(value).subscribe(res => this.data = res);
      let reqArtikel = this.artikelService.entities();
      reqArtikel.query.select(["id","artikelNr"]);
      reqArtikel.query.filter({artikelNr: {startswith: value}} );
     // collection.filter().push({warengruppenNr: {startswith: value}});
    // collection.filter().push({internetmarke: {startswith: value}});
      

      reqArtikel.get().subscribe(({entities, meta}) =>  this.data = entities
    );
  }else {
      this.artikelFilter.toggle(false);
  }
  }

  public currentArtikelNr(): string{
    if(isNullOrUndefined(this.form.controls.selected.value.artikelNr)){
          return this.selectedItem.artikelNr;
    }
    return this.form.controls.selected.value.artikelNr;
  }


    public onValueChange(value: Artikel) {
    if(isUndefined(value))
      this.artikelNrChange.emit(null);
    else{
        this.artikelNr = value.artikelNr;
        this.artikelNrChange.emit(value.artikelNr);
    }
     
}



}
