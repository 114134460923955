export enum  ContextDelete {
  Selected = "Nur Auswahl",
  SelectedAndChildren = "Auswahl und Kinder"
}
export enum  ContextCopy {
  All = "Alle Eigenschaften",
  WithAttribute = "Nur mit Attributen" ,
  WithArticle= "Nur mit Artikeln"
}

export enum  ContextAnzeige {
  Expands = "Alle aufklappen",
  Collapse = "Alle zuklappen"
}