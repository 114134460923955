import { Directive, Injectable, Inject } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';

import { GroupDataService } from '../data-services';

import { ODataBindingDirective } from './core/o-data-binding-directive';



@Directive({
    selector: '[groupBinding]'
})
export class GroupBindingDirective extends ODataBindingDirective  {
    

    constructor( @Inject(GroupDataService) protected dataService: GroupDataService, grid: GridComponent) {
        super(dataService,grid);
    }

}