import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { FirmaAdresse } from './firmaadresse.entity';
//#endregion

export const FirmaAdresseConfig = {
  name: "FirmaAdresse",
  annotations: [],
  fields: {
    firmaId: {type: 'Edm.Int32', nullable: false},
    isRechnungsAdresse: {type: 'Edm.Boolean', nullable: false},
    strasse: {type: 'Edm.String'},
    hausnummer: {type: 'Edm.String'},
    postleitzahl: {type: 'Edm.String'},
    ort: {type: 'Edm.String'},
    ortsteil: {type: 'Edm.String'},
    landId: {type: 'Edm.Int32', nullable: false},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<FirmaAdresse>;