import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input, forwardRef   } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined, isNullOrUndefined } from 'util';
import { KundenService, KundenFilterDto } from 'mza-api-service';
import { KundenFilter } from 'mza-api-service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
@Component({
  selector: 'mza-kunden-nr',
  templateUrl: './kunden-nr.component.html',
  styleUrls: ['./kunden-nr.component.css'],
  providers: [KundenService,{ 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => KundenNrComponent),
    multi: true 
  }]
})
export class KundenNrComponent implements OnInit {
  onChange: any = () => {};
  onTouched: any = () => {};
  @Input('value') _value: number = 0;

  get value():number {
    return this._value;
  }
  set value(value :number) {
    this._value = value;
    this.dataBound(value);
    this.onChange(value);
    this.onTouched();
  }
 writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
  
  @Input() isRequired: boolean = false; 
  @Input() disabled: boolean = false;
  //@Output() kundenNr = new EventEmitter<number>();
  @Output() valueChange = new EventEmitter<number>();

  @ViewChild('kundenFilter') public kundenFilter: ComboBoxComponent;

  public data: KundenFilterDto[];
 // public data2: Array<KundenFilter>;
  public selectedItem: KundenFilterDto;

  constructor(@Inject(KundenService) private customerService: KundenService) 
  { 

  // this.customerService.filterKunde("");
    //this.customerService.filterKunden("").subscribe(res => this.data = res);
   // this.data2 = this.customerService.kunden;
  }
 
  private dataBound(value: number){
    if(value !== 0  && !isNullOrUndefined(value)){
     // this.customerService.InputFilter(value.toString()).subscribe(({entities, meta}) => this.data = entities);
    }
  /*   else{
      this.value = value;
      this.valueChange.emit(value);
    } */
  }


  handleFilter(value) {
    if (value.length >= 3) {
    // this.customerService.InputFilter(value).subscribe(({entities, meta}) => this.data = entities);
  }else {
      this.kundenFilter.toggle(false);
  }
  }


   public onValueChange(value: number) {
    if(isUndefined(value))
      this.value = value;
      this.valueChange.emit(value);
    //else
      //this.kundenNr.emit(value.kundenNr);
}
  ngOnInit() {

  /*   if(!isNullOrUndefined(this.value)){
      this.customerService.getDataForInputFilter(`${this.value}`).subscribe(res =>{
        this.data = res
        if(this.value && this.data.length == 1){
            this.selectedItem = this.data[0];
      }
      } );
    } */
  }

}
