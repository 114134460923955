import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { BestellungConfig } from './bestellung.entity.config';
//#endregion

export const KaufabwicklungSchema = {
  namespace: "kaufabwicklung",
  enums: [],
  entities: [BestellungConfig],
  callables: [],
  containers: []
} as SchemaConfig;