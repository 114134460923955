import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'artikelnr-column',
  templateUrl: './artikelnr-column.component.html',
  styleUrls: ['./artikelnr-column.component.css']
})
export class ArtikelnrColumnComponent implements OnInit {

  @Input("artikelNr") artikelNr : string ="";
  @Input("imShopSichtbar") imShopSichtbar : boolean = false;
  @Input("warengruppenNr") warengruppenNr : string ="";
  @Input("alphaNr") alphaNr : string ="";
  constructor() { }

  ngOnInit() {
  }

}
