import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Status } from './status.entity';
//#endregion

export const StatusConfig = {
  name: "Status",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    typeId: {type: 'Edm.Int32', nullable: false},
    phase: {type: 'services.PhaseType', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    type: {type: 'entities.settings.StatusType', navigation: true}
  }
} as EntityConfig<Status>;