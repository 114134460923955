import { Injectable, Injector, Inject } from '@angular/core';
import { OidcSecurityService} from 'angular-auth-oidc-client';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class AuthOidcHttpInterceptor implements HttpInterceptor {
  private oidcSecurityService: OidcSecurityService;

  constructor(private injector: Injector,  @Inject('BASE_URL') private originUrl: string) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercepted request ... ');

    let requestToForward = request;

    if (this.oidcSecurityService === undefined) {
        this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }
    if (this.oidcSecurityService !== undefined) {
        let token = this.oidcSecurityService.getToken();
        if (token !== '') {
            let tokenValue = 'Bearer ' + token;
            requestToForward =  request.clone({

                headers: request.headers.set('Prefer', 'return=representation'  ).set('Authorization', tokenValue).set('Access-Control-Allow-Origin', this.originUrl.substring(0,this.originUrl.length-1)),
                //withCredentials: false
              });
        }
    } else {
        console.debug('OidcSecurityService undefined: NO auth header!');
    }

    return next.handle(requestToForward);
  }
}