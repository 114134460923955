import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OdataColumnSelectComponent } from './odata-column-select.component';
import { SplitButtonModule } from '@progress/kendo-angular-buttons';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';



@NgModule({
  declarations: [OdataColumnSelectComponent],
  imports: [
    CommonModule,SplitButtonModule,FormsModule,MultiSelectModule
  ],exports:[OdataColumnSelectComponent]
})
export class OdataColumnSelectModule { }
