import { Component, OnInit } from '@angular/core';
import { GroupDataService , Group} from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GridDataResult, SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {  ODataOptions }from 'angular-odata';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-gruppen-liste',
  templateUrl: './gruppen-liste.component.html',
  styleUrls: ['./gruppen-liste.component.css']
})
export class GruppenListeComponent implements OnInit {

  public isAllowEditGroupList: boolean = false;

  public groupForm: FormGroup;
  public gruppen: Observable<GridDataResult>;
  public maingroupId: number;
  //private query: ODataOptions;
  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'single'
};
  public selectedGroupId :  number[] = [];
  public groupId :  number
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};
  constructor(public _dataServiceGroups: GroupDataService,
             private formBuilder: FormBuilder,
             private router: Router,
             private route: ActivatedRoute) { 
              this.createGroupForm  = this.createGroupForm.bind(this);
             
             }

  ngOnInit() {
   /*  this.route.params.subscribe(params => {
      this.maingroupId = +params['id']; // (+) converts string 'id' to a number
   }); */
   this.maingroupId = +this.route.parent.snapshot.paramMap.get( "id" );
   this.groupId = +this.route.snapshot.paramMap.get( "id" );
   this.selectedGroupId = [this.groupId];
   this.state.filter= {
                   logic: 'and', 
                   filters: [{field: 'mainGroupId',operator: 'eq', value: this.maingroupId}]};
 
   /*  let query = this._dataServiceGroups.getQuery();
    query.filter({mainGroupId: this.maingroupId});
    this._dataServiceGroups.query(this.state, query );
    this.gruppen = this._dataServiceGroups.pipe(map(result => process(result.data, this.state))); */
  }
  public createGroupForm(args: any): FormGroup  {
      let data  = <Group>{ id: 0,
                           description: "",
                           name: "",
                           value: "",
                           sortValue: 0,
                           status: null,
                           createdBy:"",
                           mainGroupId: this.maingroupId};
      const item : Group = args.isNew ? data : args.dataItem;

      this.groupForm = this.formBuilder.group({
        id: item.id ,
        name: [item.name, [Validators.maxLength(40), Validators.required] ],
        description: [item.description, [Validators.maxLength(250)] ],
        value: [item.value, [Validators.maxLength(40)] ],
        sortValue: [item.sortValue],   
        status: [item.status],            
        mainGroupId: [item.mainGroupId]  ,
        createdBy:"",            
    });
    return this.groupForm;
  }
  public cellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited }) {
    if (!isEdited && this.isAllowEditGroupList ){
        sender.editCell(rowIndex, columnIndex, this.createGroupForm(dataItem));
    }
    
}
  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
  
    this._dataServiceGroups.saveChanges(this.state);
    //this.sortimentService.query(this.state, this.query);
  }
  
  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this._dataServiceGroups.cancelChanges();
  }
/*   public onStateChange(state: State) {
    let query = this._dataServiceGroups.getQuery();
    this._dataServiceGroups.query(state, query );
} */

public onSelectedKeysChange(e) {

  /* if(!isNullOrUndefined(this.selectedGroupId[0] )){
    this.router.navigate([ 'hauptgruppe/' + this.maingroupId + '/listen-ansicht/'+ this.selectedGroupId[0] + '/attributes' ]);
  } */
}
}
