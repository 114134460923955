import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { FlexLayoutModule } from '@angular/flex-layout';
import { WarengruppenModule } from './warengruppen/warengruppen.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';




//import { DropDownsModule } from '@progress/kendo-angular-dropdowns'; 



@NgModule({
  declarations: [ ],
  imports: [ BrowserModule,
    FormsModule,    FlexLayoutModule,
    ReactiveFormsModule,//ComboBoxModule,MultiSelectModule
    DropDownsModule
 ,WarengruppenModule
 //  MzaApiServiceModule,    
   
  ],
  exports: []
})
export class MzaComponentsModule { }
