import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Kunde } from './kunde.entity';
//#endregion

export const KundeConfig = {
  name: "Kunde",
  annotations: [],
  fields: {
    authenticationId: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    personId: {type: 'Edm.Int32'},
    firmaId: {type: 'Edm.Int32'},
    registrationId: {type: 'Edm.Int32'},
    kundengruppeId: {type: 'Edm.Int32'},
    kundensegmentId: {type: 'Edm.Int32'},
    person: {type: 'entities.customers.Person', navigation: true, field: 'personId', ref: 'id'},
    kundengruppe: {type: 'entities.customers.Kundengruppe', navigation: true, field: 'kundengruppeId', ref: 'id'},
    kundensegment: {type: 'entities.customers.Kundensegment', navigation: true, field: 'kundensegmentId', ref: 'id'},
    registration: {type: 'entities.schema.RegistrationInfo', navigation: true},
    beigabenKunden: {type: 'entities.produkte.BeigabenKunde', collection: true, navigation: true},
    customerAssets: {type: 'entities.customers.CustomerAssetGroup', collection: true, navigation: true},
    customerGroups: {type: 'entities.customers.CustomerGroup', collection: true, navigation: true}
  }
} as EntityConfig<Kunde>;