import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { PersonenSegmente } from './personensegmente.entity';
//#endregion

export const PersonenSegmenteConfig = {
  name: "PersonenSegmente",
  annotations: [],
  fields: {
    personId: {type: 'Edm.Int32'},
    kundensegmentId: {type: 'Edm.Int32'},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    person: {type: 'entities.customers.Person', navigation: true, field: 'personId', ref: 'id'},
    kundensegment: {type: 'entities.customers.Kundensegment', navigation: true, field: 'kundensegmentId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<PersonenSegmente>;