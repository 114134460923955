import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupMetaData } from './assetgroupmetadata.entity';
//#endregion

export const AssetGroupMetaDataConfig = {
  name: "AssetGroupMetaData",
  annotations: [],
  fields: {
    assetGroupId: {type: 'Edm.Int32'},
    metaDataId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assetGroup: {type: 'entities.assets.AssetGroup', navigation: true, field: 'assetGroupId', ref: 'id'},
    metaData: {type: 'entities.assets.MetaData', navigation: true, field: 'metaDataId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<AssetGroupMetaData>;