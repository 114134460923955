import { Component, OnInit, Inject, ViewChild, EventEmitter, Output, Input   } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined, debug, isNullOrUndefined } from 'util';
import { RegistrationFilterDtoService } from 'mza-api-service';
import { RegistrationFilterDto } from 'mza-api-service';
@Component({
  selector: 'mza-registrierung-nr',
  templateUrl: './registrierung-nr.component.html',
  styleUrls: ['./registrierung-nr.component.css'],
  providers: [RegistrationFilterDtoService]
})
export class RegistrierungNrComponent implements OnInit {
  @Input() value: number;
  @Input() disabled: boolean = false;
  @Output() registrationsId = new EventEmitter<number>();
  
  @ViewChild('registrierungsNrFilter',) public registrierungsNrFilter: ComboBoxComponent;

  public data: RegistrationFilterDto[];
  public selectedItem: RegistrationFilterDto;

  constructor(@Inject(RegistrationFilterDtoService) private registrationService: RegistrationFilterDtoService) 
  { 
   
  }
 
  handleFilter(value) {
    if (value.length >= 1 && value !== "0") {
      //this.registrationService.getDataForInputFilter(value).subscribe(res => this.data = res);
    
    }else {
      this.registrierungsNrFilter.toggle(false);
    }

  }

  public onValueChange(value: RegistrationFilterDto) {
    if(isUndefined(value))
      this.registrationsId.emit(null);
    else
      this.registrationsId.emit(value.id);
}
  ngOnInit() {
/*     if(!isNullOrUndefined(this.value)){
      this.registrationService.getDataForInputFilter(`${this.value}`).subscribe(res =>{
        this.data = res
        if(this.value && this.data.length == 1){
            this.selectedItem = this.data[0];
      }
      } );
    } */
 
  }

}
