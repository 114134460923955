//#region ODataApi Imports
//#endregion

export const ISFLAGS_REFTYPE = false;
export enum RefType {
  //#region ODataApi Members
  DEFAULT = "0",
  COPY = "1",
  REF = "2",
  GROUP_REF = "3",
  GROUP_REF_WITH_CHILD = "4",
  //#endregion
}
