import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { GroupAttributeService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class GroupAttributeDataService extends GridDataService{

  public odataService: GroupAttributeService;
  //public Sortimentsauswahl: string[] = [];
  
    constructor( @Inject(GroupAttributeService) service: GroupAttributeService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
