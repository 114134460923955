import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gpv-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public navigationItems: any[] = new Array();

    constructor(private router: Router) {
        let items = this.mapItems(router.config);

        items.forEach(element => {
          if( element.path.substring(0,8) === 'settings' ) 
          this.navigationItems.push( element);
        });
    }

    public onSelect({ item }): void {
        if (!item.items) {
            this.router.navigate([ item.path ]);
        }
    }

    // convert the routes to menu items.
    private mapItems(routes: any[], path?: string): any[] {

        return routes.map(item => {
     

            const result: any = {
                text: item.text,
                path: (path ? `${ path }/` : '') + item.path
            };

            if (item.children) {
                result.items = this.mapItems(item.children, item.path);
            }

            return result;
        });
    }

  ngOnInit(): void {
  }

}
