import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupMetaData } from '../entities/assets/assetgroupmetadata.entity';
import { AssetGroupTreeMetaData } from '../entities/assets/assetgrouptreemetadata.entity';
import { AssetMetaData } from '../entities/assets/assetmetadata.entity';
import { MetaData } from '../entities/assets/metadata.entity';
import { Type } from '../entities/ref/type.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class MetaDataService extends ODataEntityService<MetaData> {
  constructor(protected client: ODataClient) {
    super(client, 'MetaData', 'entities.assets.MetaData');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public assetGroupMetaDataList(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<AssetGroupMetaData> {
    return this.entity(entity).navigationProperty<AssetGroupMetaData>('assetGroupMetaDataList');
  }
  public assetGroupTreeMetaDataList(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<AssetGroupTreeMetaData> {
    return this.entity(entity).navigationProperty<AssetGroupTreeMetaData>('assetGroupTreeMetaDataList');
  }
  public assetMetaDataList(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<AssetMetaData> {
    return this.entity(entity).navigationProperty<AssetMetaData>('assetMetaDataList');
  }
  public status(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  public type(entity: EntityKey<MetaData>): ODataNavigationPropertyResource<Type> {
    return this.entity(entity).navigationProperty<Type>('type');
  }
  //#endregion
}
