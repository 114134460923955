import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeRoutingModule } from './attribute-routing.module';
import { AttributeListeComponent } from './attribute-listen/attribute-liste/attribute-liste.component';
import { AttributeListeGroupTreeComponent } from './attribute-listen/attribute-liste-group-tree/attribute-liste-group-tree.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [AttributeListeComponent, AttributeListeGroupTreeComponent],
  imports: [
    CommonModule,
    FormsModule ,
    AttributeRoutingModule,
    LayoutModule,TreeViewModule
  ],exports:[AttributeListeGroupTreeComponent]
})
export class AttributeModule { }
