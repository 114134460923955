import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitButtonStatusComponent } from './split-button-status.component';
import { SplitButtonModule } from '@progress/kendo-angular-buttons';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SplitButtonStatusComponent],
  imports: [
    CommonModule,SplitButtonModule,FormsModule
  ],exports:[SplitButtonStatusComponent]
})
export class SplitButtonStatusModule { }
