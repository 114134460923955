import { Component, Input, OnInit } from '@angular/core';
import { DashboardAssigment, DashboardAssigmentService, DashboardService,Dashboard } from 'mza-api-service';

@Component({
  selector: 'gpv-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @Input("dashboardId") dashboardId: number = 0;
  @Input("groupId") groupId: number = 0;

  order: string = 'sortValue';
  dashboard: Dashboard;
  public reports: Array<Partial<DashboardAssigment>>

  constructor(private _dashboardService: DashboardService, private _dashboardAssigmentService: DashboardAssigmentService) { }

  ngOnInit(): void {
    this.loadDashboardData();
    this.loadReports();
  }

private loadDashboardData(){
  if( !this.hasDashboardId()) return;
  let requestDashboard = this._dashboardService.entity(this.dashboardId);
  requestDashboard.query.select(["title","description"]);
  requestDashboard.get().subscribe(({entity}) => this.dashboard = entity);
}

private loadReports(){
  if( !this.hasDashboardId()) return;

    let requestReports = this._dashboardAssigmentService.entities();
    requestReports.query.select(["reportId","sortValue"]);
    requestReports.query.filter({dashboardId: this.dashboardId});
    requestReports.get().subscribe(({entities,meta}) => { this.reports = entities})
}

private hasDashboardId(): boolean
{
  return  !(this.dashboardId === 0 || this.dashboardId === undefined || this.dashboardId === null);
}

}
