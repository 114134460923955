import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { PersonService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class PersonDataService extends GridDataService {
    
  public odataService: PersonService;
  
  
    constructor(  @Inject(PersonService)  service: PersonService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }

}
