import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { MarkeDto } from './markedto.entity';
//#endregion

export const MarkeDtoConfig = {
  name: "MarkeDto",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    beschreibung: {type: 'Edm.String'},
    brandId: {type: 'Edm.String'},
    url: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<MarkeDto>;