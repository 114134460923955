<p>Marken: </p>
<kendo-multiselect #markenFilterMultiSelect
    [data]="data" 
    [(ngModel)]="selectedItems"
    [textField]="'BrandId'"
    [valueField]="'BrandId'"
    (valueChange)="onValueChange($event)"
    [filterable]="true"
    (filterChange)="handleFilter($event)"
    [placeholder]="'Marken auswählen...'"
>

<ng-template kendoComboBoxItemTemplate  let-dataItem>
    <div>
        <p>Id: <b>{{ dataItem.BrandId }}</b></p>
        <p>{{ dataItem.Name }}, {{dataItem.Url}}</p>
    </div>
  
</ng-template>
<ng-template kendoDropDownListNoDataTemplate>
    <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>

</ng-template>
</kendo-multiselect>