import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { SortResultDto } from './sortresultdto.entity';
//#endregion

export const SortResultDtoConfig = {
  name: "SortResultDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    level1From: {type: 'Edm.Int32', nullable: false},
    level1To: {type: 'Edm.Int32', nullable: false},
    level1Color: {type: 'Edm.String'},
    level2From: {type: 'Edm.Int32', nullable: false},
    level2To: {type: 'Edm.Int32', nullable: false},
    level2Color: {type: 'Edm.String'},
    level3From: {type: 'Edm.Int32', nullable: false},
    level3To: {type: 'Edm.Int32', nullable: false},
    level3Color: {type: 'Edm.String'},
    level4From: {type: 'Edm.Int32', nullable: false},
    level4To: {type: 'Edm.Int32', nullable: false},
    level4Color: {type: 'Edm.String'},
    level5From: {type: 'Edm.Int32', nullable: false},
    level5To: {type: 'Edm.Int32', nullable: false},
    level5Color: {type: 'Edm.String'},
    level6From: {type: 'Edm.Int32', nullable: false},
    level6To: {type: 'Edm.Int32', nullable: false},
    level6Color: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32', nullable: false}
  }
} as EntityConfig<SortResultDto>;