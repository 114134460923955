<div  class="s-kunden-nr">
    <div fxLayout="row" fxLayoutAlign="space-between end">
<kendo-textbox-container floatingLabel="Kunden-Nr" fxFlex="40%">
<kendo-combobox #kundenFilter
isRequired="isRequired"
[data]="data"
[textField]="'valueId'"
[valueField]="'valueId'"
(valueChange)="onValueChange($event)"
[valuePrimitive]="true"
[filterable]="true"
(filterChange)="handleFilter($event)"

[disabled]="disabled"
>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div>
            <p>Id: <b>{{ dataItem.kundenNr }}</b></p>
            <p>{{ dataItem.firma }}, {{ dataItem.ort }}</p>
        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox>
</kendo-textbox-container>
</div>
</div>  