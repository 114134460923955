import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KundenFilter } from './kundenfilter.entity';
//#endregion

export const KundenFilterConfig = {
  name: "KundenFilter",
  annotations: [],
  fields: {
    bezeichnung: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    beigaben: {type: 'entities.produkte.Beigabe', collection: true, navigation: true},
    beigabenKunden: {type: 'entities.produkte.BeigabenKunde', collection: true, navigation: true},
    kundenFilterErgebnise: {type: 'entities.customers.KundenFilterErgebnis', collection: true, navigation: true}
  }
} as EntityConfig<KundenFilter>;