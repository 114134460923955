import { Component, OnInit, OnChanges } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { MainGroupService , Maingroup} from 'mza-api-service';
import {  }from 'angular-odata';
import { isNullOrUndefined } from 'util';
import { Observable } from 'rxjs';

@Component({
  selector: 'gpv-hauptgruppen-auswahl',
  templateUrl: './hauptgruppen-auswahl.component.html',
  styleUrls: ['./hauptgruppen-auswahl.component.css']
})
export class HauptgruppenAuswahlComponent implements OnInit, OnChanges {


  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this._groupDataService.entities().get().subscribe(
      ({entities,meta})=> {this.items = entities}
    );
  }

  route: string;
  public items: Maingroup[] ;
  public selectedMaingroup: Maingroup;

  public placeHolder : Maingroup = {
    id: null,
    name: "Bitte auswählen..",
    status: null,
    description: "",
    value: "",createdBy: "",
    sortValue: 0, allowGroupTreeAssignment: false, allowTreeStructur: false, allowGroupsInDifferentNodes: false ,
    showValue: false,showDescription: false,useArtikel: false,useAttribute: false,useAssets: false,useKunden: false,useAktie: false,
    useWettbewerber: false, useWettbewerberPreis:false,useArtikelPreis:false,useArtikelPreisVk:false,useRegistration:false,isSystemRelevant:false,usedStatusTypeId:null
  };

  constructor(location: Location, 
              private router: Router,
              private _groupDataService: MainGroupService) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
    });

  }

  ngOnInit() {
       this._groupDataService.entities().get().subscribe(
      ({entities,meta})=> {this.items = entities}
    );
  }

  handleMainGroupChange(value: Maingroup) {
    if(!isNullOrUndefined(value)){
      this.selectedMaingroup = value;
      if(!isNullOrUndefined(value.id )){
        this.router.navigate([ 'gruppen/hauptgruppe/'+value.id ]);
      }
      
    }

  
  }

  refreshDDL(){
    this._groupDataService.entities().get().subscribe(
      ({entities,meta})=> {this.items = entities}
    );
  }

}
