import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BeigabenTyp } from './beigabentyp.entity';
//#endregion

export const BeigabenTypConfig = {
  name: "BeigabenTyp",
  annotations: [],
  fields: {
    bezeichnung: {type: 'Edm.String'},
    kurzBezeichnung: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    typ: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    beigabe: {type: 'entities.produkte.Beigabe', collection: true, navigation: true},
    beigabenKunden: {type: 'entities.produkte.BeigabenKunde', collection: true, navigation: true}
  }
} as EntityConfig<BeigabenTyp>;