import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BeigabenKunde } from './beigabenkunde.entity';
//#endregion

export const BeigabenKundeConfig = {
  name: "BeigabenKunde",
  annotations: [],
  fields: {
    beigabenId: {type: 'Edm.Int32', nullable: false},
    beigabenTypId: {type: 'Edm.Int32'},
    kundenFilterId: {type: 'Edm.Int32'},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    artikelId: {type: 'Edm.Int32'},
    menge: {type: 'Edm.Int32', nullable: false},
    mengeIndividuell: {type: 'Edm.Boolean', nullable: false},
    mindestWarenwert: {type: 'Edm.Decimal', nullable: false},
    bestellNr: {type: 'Edm.Int32', nullable: false},
    bestellWarenwert: {type: 'Edm.Decimal', nullable: false},
    erhaltenAt: {type: 'Edm.DateTimeOffset'},
    mengeIndividuellChangedBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'},
    beigabe: {type: 'entities.produkte.Beigabe', navigation: true},
    kunde: {type: 'services.Kunde', navigation: true},
    kundenFilter: {type: 'entities.customers.KundenFilter', navigation: true, field: 'kundenFilterId', ref: 'id'},
    beigabenTyp: {type: 'services.BeigabenTyp', navigation: true, field: 'beigabenTypId', ref: 'id'}
  }
} as EntityConfig<BeigabenKunde>;