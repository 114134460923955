import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import {  WettbewerberPreisService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class WettbewerberpreisDataService extends GridDataService {
    
  public odataService: WettbewerberPreisService;
  
  
    constructor(  @Inject(WettbewerberPreisService)  service: WettbewerberPreisService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
