import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ReportService,Report } from 'mza-api-service';
import { ReportSettingComponent } from '../report-setting/report-setting.component';

@Component({
  selector: 'gpv-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, OnChanges {

  @Input("reportId") reportId: number = 0;
  @Input("groupId") groupId: number = 125;

  public dataReport : Array<any> =[];
  public odataResult: string;
  public componentTyp: string = ""; 
  public report: Report;

  constructor(private _reportService: ReportService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadReport();
    this.loadReportConfig();
  }

  ngOnInit(): void {
    this.loadReport();
    this.loadReportConfig();
  }

  public loadReportConfig(){
    if( !this.hasReportId()) return;

    let requestReport = this._reportService.entity(this.reportId);
    requestReport.query.select(["componentType","description"]);
    requestReport.get().subscribe(({entity}) => { this.report = entity})
  }
  public loadReport(){
    if( !this.hasReportId()) return;

    this._reportService.getReportResult().call({reportId: this.reportId,groupId: this.groupId }, "property").subscribe((r) => {
      this.odataResult = r;
      this.dataReport = JSON.parse(r);
    } ); 
  }
  public getReportItem(index: number): string{
    if( this.dataReport === undefined || this.dataReport === null) return "";
    let item = this.dataReport[0];
    //return item;
   
   if( item === undefined || item === null) return "";
   let key = Object.keys(item)[index];
   let value = item[key];

   return key+ ", " + value;
    //return key + ", " +value
 }


 private hasReportId(): boolean
 {
   return  !(this.reportId === 0 || this.reportId === undefined || this.reportId === null);
 }

 
  
}
