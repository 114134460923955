import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { SortDto } from './sortdto.entity';
//#endregion

export const SortDtoConfig = {
  name: "SortDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    name: {type: 'Edm.String'}
  }
} as EntityConfig<SortDto>;