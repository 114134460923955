import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input   } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined } from 'util';
import { MarkenFilterDtoService } from 'mza-api-service';
import { MarkenFilterDto } from 'mza-api-service';

@Component({
  selector: 'mza-marken-name',
  templateUrl: './marken-name.component.html',
  styleUrls: ['./marken-name.component.css'],
  providers: [MarkenFilterDtoService]
})
export class MarkenNameComponent implements OnInit {

  @Input() value: string;
  @Input() disabled: boolean = false;
  @Output() brandId = new EventEmitter<string>();

  @ViewChild('markenFilter') public markenFilter: ComboBoxComponent;

  public data: Array<MarkenFilterDto>;
  public selectedItem: MarkenFilterDto;

  constructor(@Inject(MarkenFilterDtoService) private brandService: MarkenFilterDtoService) {
   // this.brandService.filterBrands("");//.subscribe(res => this.data = res);
   }

   handleFilter(value) {
 /*    if (value.length >= 3) {
    this.brandService.filterBrands(value);//.subscribe(res => this.data = res);
  }else {
      this.markenFilter.toggle(false);
  }
  }
   public onValueChange(value: MarkenFilter) {
    if(isUndefined(value))
      this.brandId.emit(null);
    else
      this.brandId.emit(value.brandId); */
}
  ngOnInit() {
/*     this.brandService.filterBrands(`${this.value}`).subscribe(res =>{
      this.data = res;
       if(this.value && this.data.length == 1){
           this.selectedItem = this.data[0];
    }
    } ); */
   
  }

}
