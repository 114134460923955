import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetTreeComponent } from './asset-tree/asset-tree.component';
import { AssetOverviewComponent } from './asset-overview/asset-overview.component';
import { AssetFiltersComponent } from './asset-filters/asset-filters.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { AssetImageDirective } from '../shared/asset-image.directive';
import { GridModule } from '@progress/kendo-angular-grid';
import { SliderModule, InputsModule } from '@progress/kendo-angular-inputs';
import { AssetImagePreviewDirective } from '../shared/asset-image-preview.directive';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FloatingLabelModule } from '@progress/kendo-angular-label';

import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { AssetDetailsComponent } from './asset-details/asset-details.component';
import { WindowModule, DialogModule } from '@progress/kendo-angular-dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { AssetVersionComponent } from './asset-details/asset-version/asset-version.component';
import { AssetInfosComponent } from './asset-details/asset-infos/asset-infos.component';
import { DateiinfosComponent } from './asset-details/asset-infos/dateiinfos/dateiinfos.component';
import { MetaDataComponent } from './asset-details/asset-infos/meta-data/meta-data.component';
import { AssetAssigmentsComponent } from './asset-details/asset-infos/asset-assigments/asset-assigments.component';
import { AssetDownloadsComponent } from './asset-details/asset-downloads/asset-downloads.component';
import { AssetUsecasesComponent } from './asset-details/asset-usecases/asset-usecases.component';
import { AssetMetaDataDirective } from 'mza-api-service';
import { AssetImageComponent } from '../shared/images/asset-image/asset-image.component';
import { AssetSelectComponent } from './controls/asset-select/asset-select.component';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { AssetsMetaDataComponent } from './asset-details/asset-infos/assets-meta-data/assets-meta-data.component';
import { TreeViewGroupModule } from '../shared/components/tree-view-group/tree-view-group.module';
@NgModule({
  declarations: [AssetTreeComponent, AssetOverviewComponent, AssetFiltersComponent, AssetImageComponent, AssetMetaDataDirective, AssetImageDirective, AssetImagePreviewDirective, AssetDetailsComponent, AssetVersionComponent, AssetInfosComponent, DateiinfosComponent, MetaDataComponent, AssetAssigmentsComponent, AssetDownloadsComponent, AssetUsecasesComponent, AssetSelectComponent, AssetsMetaDataComponent],
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, UploadsModule, TabStripModule, GridModule, SliderModule, ScrollViewModule, DropDownsModule, FloatingLabelModule, InputsModule, PopupModule, TooltipModule, WindowModule, DialogModule, ButtonModule, TreeViewGroupModule
  ], exports: [AssetTreeComponent, AssetOverviewComponent, AssetFiltersComponent, AssetDetailsComponent,AssetImageComponent, AssetVersionComponent, AssetInfosComponent, DateiinfosComponent, MetaDataComponent, AssetAssigmentsComponent, AssetDownloadsComponent, AssetUsecasesComponent, AssetSelectComponent]
})
export class AssetsModule { }
