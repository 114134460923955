import { Component, OnInit, Input, Output,EventEmitter  } from '@angular/core';


@Component({
  selector: 'gpv-asset-filters',
  templateUrl: './asset-filters.component.html',
  styleUrls: ['./asset-filters.component.css']
})
export class AssetFiltersComponent implements OnInit {

  @Input() imageSize: number = 1;
  @Output() imageSizeEvent: EventEmitter<number> = new EventEmitter<number>();
  constructor() { }

  ngOnInit() {
    //this.imageSizeEvent.emit(2);
  }

}
