import { NgModule } from '@angular/core';
import { MzaAuthServiceComponent } from './mza-auth-service.component';

@NgModule({
  declarations: [MzaAuthServiceComponent],
  imports: [
  ],
  exports: [MzaAuthServiceComponent]
})
export class MzaAuthServiceModule { }
