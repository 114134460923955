import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { MarkenFilterDto } from './markenfilterdto.entity';
//#endregion

export const MarkenFilterDtoConfig = {
  name: "MarkenFilterDto",
  annotations: [],
  fields: {
    brandId: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    url: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<MarkenFilterDto>;