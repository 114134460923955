import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { AssetMetaDataService } from '../odata-services/services/assetmetadata.service';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable({
  providedIn: 'root'
})
export class AssetMetaDataDataService extends GridDataService {
    
  public odataService: AssetMetaDataService;
  
  
    constructor( @Inject(AssetMetaDataService) service: AssetMetaDataService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
