import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { ArtikelGroup } from '../entities/articles/artikelgroup.entity';
import { Asset } from '../entities/assets/asset.entity';
import { AssetViewDto } from '../Mza/Service/Models/assetviewdto.entity';
//#endregion

@Injectable()
export class AssetViewDtoService extends ODataEntityService<AssetViewDto> {
  constructor(protected client: ODataClient) {
    super(client, 'AssetViewDto', 'Mza.Service.Models.AssetViewDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getAssets(): ODataFunctionResource<{groupId: number}, Asset> {
    const resource = this.entities().function<{groupId: number}, Asset>('services.GetAssets');
    return resource;
  }
  public getOverview(): ODataFunctionResource<null, AssetViewDto> {
    const resource = this.entities().function<null, AssetViewDto>('services.GetOverview');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
