import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Person } from './person.entity';
//#endregion

export const PersonConfig = {
  name: "Person",
  annotations: [],
  fields: {
    anrede: {type: 'Edm.String'},
    titel: {type: 'Edm.String'},
    alias: {type: 'Edm.String'},
    vorname: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    geburtsdatum: {type: 'Edm.DateTimeOffset', nullable: false},
    geburtsOrt: {type: 'Edm.String'},
    email: {type: 'Edm.String'},
    telefonNr: {type: 'Edm.String'},
    faxNr: {type: 'Edm.String'},
    mobilNr: {type: 'Edm.String'},
    isEinladungSend: {type: 'Edm.Boolean', nullable: false},
    tokenEinladung: {type: 'Edm.Guid'},
    isEinladungAccept: {type: 'Edm.Boolean', nullable: false},
    tokenEinladungGenerateAt: {type: 'Edm.DateTimeOffset'},
    einladungSendAt: {type: 'Edm.DateTimeOffset'},
    einladungSendByUser: {type: 'Edm.String'},
    einladungSendByContactTyp: {type: 'Edm.String'},
    einladungAcceptAt: {type: 'Edm.DateTimeOffset'},
    mitgliedsNr: {type: 'Edm.String'},
    athleteId: {type: 'Edm.Guid'},
    authenticationId: {type: 'Edm.String'},
    roles: {type: 'Edm.String', collection: true},
    firmaId: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    mitglied: {type: 'services.Kunde', navigation: true},
    personensegmente: {type: 'services.PersonenSegmente', collection: true, navigation: true},
    teamPersonen: {type: 'services.TeamPersonen', collection: true, navigation: true}
  }
} as EntityConfig<Person>;