//#region ODataApi Imports
//#endregion

export const ISFLAGS_DATAFORMAT = false;
export enum DataFormat {
  //#region ODataApi Members
  JPG = 1,
  JPEG = 2,
  PNG = 3,
  BMP = 4,
  TIF = 5,
  MP4 = 1000,
  MP3 = 2000,
  DOC = 3000,
  AI = 4000,
  MAX = 5000,
  //#endregion
}
