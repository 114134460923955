import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { GroupAktie } from '../entities/boerse/groupaktie.entity';
import { ArtikelGroup } from '../entities/articles/artikelgroup.entity';
import { CustomerGroup } from '../entities/customers/customergroup.entity';
import { GroupAttribute } from '../entities/schema/groupattribute.entity';
import { Group } from '../entities/schema/group.entity';
import { GroupTreeAssigment } from '../entities/schema/grouptreeassigment.entity';
import { Maingroup } from '../entities/schema/maingroup.entity';
import { WettbewerberPreisGroup } from '../entities/schema/wettbewerberpreisgroup.entity';
import { ProduktTypGroup } from '../entities/produkte/produkttypgroup.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class GroupService extends ODataEntityService<Group> {
  constructor(protected client: ODataClient) {
    super(client, 'Group', 'entities.schema.Group');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public aktien(entity: EntityKey<Group>): ODataNavigationPropertyResource<GroupAktie> {
    return this.entity(entity).navigationProperty<GroupAktie>('aktien');
  }
  public artikelgruppen(entity: EntityKey<Group>): ODataNavigationPropertyResource<ArtikelGroup> {
    return this.entity(entity).navigationProperty<ArtikelGroup>('artikelgruppen');
  }
  public attributes(entity: EntityKey<Group>): ODataNavigationPropertyResource<GroupAttribute> {
    return this.entity(entity).navigationProperty<GroupAttribute>('attributes');
  }
  public customerGroups(entity: EntityKey<Group>): ODataNavigationPropertyResource<CustomerGroup> {
    return this.entity(entity).navigationProperty<CustomerGroup>('customerGroups');
  }
  public groupTreeAssigments(entity: EntityKey<Group>): ODataNavigationPropertyResource<GroupTreeAssigment> {
    return this.entity(entity).navigationProperty<GroupTreeAssigment>('groupTreeAssigments');
  }
  public mainGroup(entity: EntityKey<Group>): ODataNavigationPropertyResource<Maingroup> {
    return this.entity(entity).navigationProperty<Maingroup>('mainGroup');
  }
  public produktTypGroups(entity: EntityKey<Group>): ODataNavigationPropertyResource<ProduktTypGroup> {
    return this.entity(entity).navigationProperty<ProduktTypGroup>('produktTypGroups');
  }
  public status(entity: EntityKey<Group>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Group>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  public wettbewerberPreisGroups(entity: EntityKey<Group>): ODataNavigationPropertyResource<WettbewerberPreisGroup> {
    return this.entity(entity).navigationProperty<WettbewerberPreisGroup>('wettbewerberPreisGroups');
  }
  //#endregion
}
