import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BookingObject } from './bookingobject.entity';
//#endregion

export const BookingObjectConfig = {
  name: "BookingObject",
  annotations: [],
  fields: {
    nr: {type: 'Edm.Int32', nullable: false},
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    place: {type: 'Edm.String'},
    firmaId: {type: 'Edm.Int32'},
    minOccupancy: {type: 'Edm.Int32', nullable: false},
    maxOccupancy: {type: 'Edm.Int32', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<BookingObject>;