import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DataMappingService } from '../odata-services/services/datamapping.service';


//import { Sortimentsauswahl } from '../models/sortimente/sortimentsauswahl.model';

@Injectable({
  providedIn: 'root'
})
export class DataMappingDataService extends GridDataService {
    
  public odataService: DataMappingService;
  
  
    constructor( @Inject(DataMappingService)  service: DataMappingService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
  
    //public SaveIsZugeordnet(item : Sortimentsauswahl, state: State) : void
   /*  public SaveIsZugeordnet(item : any, state: State) : void
    { 
        this.update(item);
        this.saveChanges(state);
    } */


}
