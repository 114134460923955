import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Kursziel } from './kursziel.entity';
//#endregion

export const KurszielConfig = {
  name: "Kursziel",
  annotations: [],
  fields: {
    aktieId: {type: 'Edm.Int32'},
    analystId: {type: 'Edm.Int32'},
    date: {type: 'Edm.DateTimeOffset', nullable: false},
    empfehlung: {type: 'Edm.Byte', nullable: false},
    value: {type: 'Edm.Decimal', nullable: false},
    isActive: {type: 'Edm.Boolean', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    aktie: {type: 'entities.boerse.Aktie', navigation: true, field: 'aktieId', ref: 'id'},
    analyst: {type: 'entities.boerse.Analyst', navigation: true, field: 'analystId', ref: 'id'}
  }
} as EntityConfig<Kursziel>;