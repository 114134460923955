import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtikelNrComponent } from './artikel-nr.component';
import { MultiSelectModule } from '@progress/kendo-angular-dropdowns';



@NgModule({
  declarations: [ArtikelNrComponent],
  imports: [
    CommonModule,MultiSelectModule
  ],exports:[ArtikelNrComponent]
})
export class ArtikelNrModule { }
