import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { DataStateChangeEvent,PageChangeEvent,SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { GruppeZuweisungItem } from './models/gruppe-zuweisung-item.model';
import { GroupTreeAssigmentDataService } from 'mza-api-service';

@Component({
  selector: 'gpv-gruppen-zuweisung',
  templateUrl: './gruppen-zuweisung.component.html',
  styleUrls: ['./gruppen-zuweisung.component.css']
})
export class GruppenZuweisungComponent implements OnInit, OnChanges  {

  @Input() groupId: string = "/1/";

  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public mySelection: number[] = [];
  public gridData: any;
  
  public state: State = {
    skip: 0,
    take: 100
};

  constructor(private _dataServiceGroupAssigment: GroupTreeAssigmentDataService) {
   }

  ngOnInit() {
   // this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  //GRID EVENTS
  public onSelectAllChange(checkedState: SelectAllCheckboxState) {

  }
  public onSelectedKeysChange(e) {
    const selectedItems = this._dataServiceGroupAssigment.selectedItems;
    this._dataServiceGroupAssigment.cancelChanges();
    let data = this._dataServiceGroupAssigment.getData();
    data.forEach((item: GruppeZuweisungItem)   => {
          
          if(item.IsSelected){
            item.IsSelected = false;
            selectedItems.forEach(s  => {         
             if(item.ValueId.toString() === s ){            
                 item.IsSelected = true;
               
             }     
            });
            if( item.IsSelected)
              this._dataServiceGroupAssigment.update(item);
            else
              this._dataServiceGroupAssigment.remove(item);
          }else{
           selectedItems.forEach(s  => {         
             if(item.ValueId.toString() === s ){            
                 item.IsSelected = true;
                 this._dataServiceGroupAssigment.update(item);
             }     
            });
          }
       
    });
}

    public pageChange(event: PageChangeEvent): void {
      this.state.skip = event.skip;
      this.loadData();   
    }

    public dataStateChange(state: DataStateChangeEvent): void {
      this.state = state;
      this.loadData(); 
    }

    public cancelChanges(grid: any): void {
     grid.cancelCell();
    }

    public saveHandler({ sender, formGroup, rowIndex }) {
      if (formGroup.valid) {
          sender.closeRow(rowIndex);
      } 
    }

    public saveChanges(grid: any): void {
      grid.closeCell();
      grid.cancelCell();
    
      if (!isNullOrUndefined(this.groupId) )
        //this._dataServiceSortimentsgruppenZuordnung.saveChanges(this.state,this._dataServiceSortimentsgruppenZuordnung.odataService.querySortimentszuordnung(this.sortimentsGruppenId) );
        console.debug("WIeder auskommentieren")
    }
  //END GRID EVENTS

  private loadData() {
    if (!isNullOrUndefined(this.groupId) ){

      let collection = this._dataServiceGroupAssigment.getQuery();
    //  collection.select(["id","bezeichnung","kundenFilterId","gueltigVon","gueltigBis","BestellArt","isAktiv","isDirektversand","showInWarenkorb","showInHinweistext"])
      collection.filter({GroupTreeId: {eq: this.groupId}});
      collection.expand({Group : {select: ["id","name"]}})
      this._dataServiceGroupAssigment.query(this.state,collection);  
    }
  }

}
