import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { DashboardService, DashboardAssigmentService,DashboardAssigment, Dashboard, Report,ReportService} from 'mza-api-service';
import { Location } from '@angular/common'

@Component({
  selector: 'gpv-dashboard-setting',
  templateUrl: './dashboard-setting.component.html',
  styleUrls: ['./dashboard-setting.component.css']
})
export class DashboardSettingComponent implements OnInit {

  public DashboardForm: FormGroup;
  public DashboardAssigmentForm: FormGroup;
  public isLoading: boolean = false;
  //@Input("reportId") reportId: number = 1;


  public dataDashboardAssigments: Array<DashboardAssigment> = new Array<DashboardAssigment>();
  public reportList: Array<Partial<Report>> = [];

  

  public isSave: boolean = false;
  constructor(private _dashboardService: DashboardService, private _dashboardAssigmentService: DashboardAssigmentService,
    private _reportService: ReportService,
    private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private location: Location,
    private notificationService: NotificationService) {

    //  this.createDashboardForm  = this.createGridDashboardForm.bind(this);
    this.createDashboardForm(null);
    this.createDashboardAssigmentForm = this.createDashboardAssigmentForm.bind(this);

  }

  private saveOk(){

    this.notificationService.show({
      content: 'Dashboard erstellt',
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: this.hideAfter,
      //closable: true
    });
  }

  private saveError(){
   
    this.notificationService.show({
      content: 'Fehler beim Speichern',
      cssClass: 'save-error',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
      // hideAfter: this.hideAfter,
      closable: true
    });
  }

 public onDashboardNameChange(value: string){
   if(!this.fc.name.valid) return;
   
   if (this.isNewData())
   this.addNewDashboard()
    else {
     this._dashboardService.assign({id: this.DashboardForm.value.id },{name: this.DashboardForm.value.name})
     .subscribe(
      r => {
          this.saveOk();
      },
      (error) => {
        this.saveError()
      });
 }
 }
 public onDashboardTitleChange(value: string){
  if(!this.fc.title.valid) return;
  
  if (this.isNewData()) return;
  
  this._dashboardService.assign({id: this.DashboardForm.value.id },{title: this.DashboardForm.value.title})
  .subscribe(
   r => {
       this.saveOk();
   },
   (error) => {
     this.saveError()
   });

}

 public onDashboardDescChange(value: string){
  if(!this.fc.description.valid) return;
  
  if (this.isNewData()) return;
  
    this._dashboardService.assign({id: this.DashboardForm.value.id },{description: this.DashboardForm.value.description})
    .subscribe(
     r => {
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });

}



  public createDashboardForm(item: Dashboard): FormGroup {
    this.DashboardForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.maxLength(100), Validators.required]],
      description: [0],
      title: [''],
      personId: [0],
      statusId: [0]

    });
    return this.DashboardForm;
  }

  public createDashboardAssigmentForm(args: any): FormGroup {
    let data  = <DashboardAssigment>{
      id: 0,
      dashboardId: this.DashboardId,
      reportId: 0,
      sortValue: 0
    };
    const item = args.isNew ? data : args.dataItem;

     this.DashboardAssigmentForm = this.formBuilder.group({
        'id': item.id,
        'reportId': [item.reportId, Validators.required],
        'inputName': item.inputName,
        'outputName': [item.inputName], //Output = Input
        'color': item.color,
        'from': item.from,
        'to': item.to,
        'title': item.title,
        'opacity': item.opacity,
        'typ': item.typ,
        'sortValue': item.sortValue,
    }); 

    return this.DashboardAssigmentForm;
}

  get mappings(): FormArray { return this.DashboardForm.get('mappings') as FormArray; }
 /*  get DataMapping(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      reportId: [0, Validators.required],
      inputName: [0],
      outputName: [0]
    });
  } */
 /*  addDataMapping() {
    this.mappings.push(this.DataMapping);
  }
 */


  private DashboardId: number = 0
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.DashboardId = +params['id']; 
        this.dataBind();
    });
    this.loadReports();

    //this.DashboardForm.valueChanges.subscribe(value =>  this.testValue = value['bestellArt']); 
  
  }
  /* ngAfterViewInit() {
  this.DashboardForm.addControl('artikel', this.artikelNrComponent.artikelForm);
  this.artikelNrComponent.artikelForm.setParent(this.DashboardForm);
  } */

 
  private dataBind() {
    if (this.DashboardId === 0) {
      this.createNewDataForm();
      return;
    }

  //  this.mappings.clear();

    let reqDashboard = this._dashboardService.entity(this.DashboardId)
    reqDashboard.query.select(["id", "name","title", "description", "personId", "statusId"]);
     reqDashboard.query.expand({ reportsAssigments: { select: ["id", "dashboardId", "sortValue", "reportId"] } });
    reqDashboard.get().subscribe(({ entity }) => {
/* 
      entity.mappings.forEach(element => {
        this.addDataMapping();
      }); */
      this.dataDashboardAssigments = entity.reportsAssigments;
      this.DashboardForm.setValue({
        id: entity.id,
        name: entity.name,
        description: entity.description,
        title: entity.title,
        personId: entity.personId,
        statusId: entity.statusId
     //   mappings: entity.mappings,
        //mindestWarenwert: data.DataMapping[0].mindestWarenwert
      });

    })


    //  this.serviceA.entities().get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
    //  collection.get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
  }

  private createNewDataForm() {
  //  this.addDataMapping();
    this.DashboardForm.setValue({
      id: 0,
      name: "",
      description: "",
      title: "",
      personId: null,
      statusId: 17,
     /*  mappings: [{
        id: 0,
        inputName: "",
        outputName: "",
        reportId: 0

      }], */

    });
  }


  get fc() {
    return this.DashboardForm.controls;
  }
  private hideAfter: number = 3000;



  public onCancel() {
    this.dataBind();

  }

  public onClose() {
    this.location.back();
  }

  public isNewData(): boolean {
    return this.DashboardForm.value.id === 0;
  }

  private addNewDashboard() {
   // let artikelDashboard = this.DashboardForm.value.mappings;
   // delete this.DashboardForm.value.mappings;
    this._dashboardService.create(this.DashboardForm.value).subscribe(
      r => {
        this.DashboardId = r.id;
        this.DashboardForm.patchValue({ id: r.id });
        this.notificationService.show({
          content: 'Dashboard erstellt',
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'success', icon: true },
          hideAfter: this.hideAfter,
          //closable: true
        });
      },
      (error) => {
        this.notificationService.show({
          content: 'Fehler beim Speichern der Dashboard',
          cssClass: 'save-error',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'error', icon: true },
          // hideAfter: this.hideAfter,
          closable: true
        });
      });
  }
  


private loadReports(){


  this._reportService.entities().get().subscribe(({entities,meta}) => {
    this.reportList = entities;   
   } );
}

public selectedComponent : string =null;


/* 
public createDashboardAssigment(){
  this.dataDashboardAssigments= [];
  this._dashboardService.getColumnsFromObject().call({objectName: this.DashboardForm.value.dbObject}, "entities").subscribe(r => {

    r.forEach(element => {
        let item: DashboardAssigment = {id: 0 , dashboardId: this.DashboardId, inputName: element.column, outputName: this.getComponentValue(element.column)};
        this.dataDashboardAssigments.push(item);
        if(item.outputName !== "")
          {
            this.createDashboardAssigmentItem(item);
          }
    });
    this.isLoading = false;
  } ); 
} */

/* private createDashboardAssigmentItem(item: DashboardAssigment){
  this._dashboardAssigmentService.create(item)
    .subscribe(
     r => {
         this.dataDashboardAssigments.find(f => f.inputName === item.inputName).id = r.id;
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });
}

 */

 


  


public onReportValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;
  dataItem.reportId = value;
  this.updateMapping({reportId: dataItem.reportId},dataItem);
}

public onSortValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;
  dataItem.sortValue = value;
  this.updateMapping({sortValue: dataItem.sortValue},dataItem);
}

private updateMapping( attrs: Partial<DashboardAssigment>,dataItem: DashboardAssigment){

  this._dashboardAssigmentService.assign({id: dataItem.id },attrs)
  .subscribe(
   r => {
       this.saveOk();
   },
   (error) => {
     this.saveError()
   });
}






public onRemoveDashboardAssigment(dataItem: DashboardAssigment){
  this._dashboardAssigmentService.destroy({id: dataItem.id}).subscribe( r => {
 
    this.dataDashboardAssigments = this.dataDashboardAssigments.filter(item => item.id !== dataItem.id);

    this.saveOk();
},
(error) => {
  this.saveError()
});
}

public onAddReport(){
  let item: DashboardAssigment = {id: 0 , dashboardId: this.DashboardId};

  this._dashboardAssigmentService.create(item)
  .subscribe(
   r => {
       item.id = r.id;
      
       this.dataDashboardAssigments.push(item);
   },
   (error) => {
     this.saveError()
   });
}



  //VORSCHAU
  public openedPreview = false;
  public windowTop = 100;
  public windowLeft = 50;

  public closePreview() {
    this.openedPreview = false;
  }

  public openPreview() {
    this.openedPreview = true;
  }

}
