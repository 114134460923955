import { ContainerConfig } from 'angular-odata';

//#region ODataApi Imports
import { AktieServiceConfig } from './aktie.service.config';
import { AktienTrendsServiceConfig } from './aktientrends.service.config';
import { AnalystServiceConfig } from './analyst.service.config';
import { GroupAktieServiceConfig } from './groupaktie.service.config';
import { KurszielServiceConfig } from './kursziel.service.config';
import { TageslinieServiceConfig } from './tageslinie.service.config';
import { TradegateKursServiceConfig } from './tradegatekurs.service.config';
import { ArtikelAssetServiceConfig } from './artikelasset.service.config';
import { ArtikelAttributeServiceConfig } from './artikelattribute.service.config';
import { ArtikelGroupFilterSettingsServiceConfig } from './artikelgroupfiltersettings.service.config';
import { ArtikelGroupServiceConfig } from './artikelgroup.service.config';
import { AssetServiceConfig } from './asset.service.config';
import { ArtikelServiceConfig } from './artikel.service.config';
import { ArtikelbezeichnungsaufbauServiceConfig } from './artikelbezeichnungsaufbau.service.config';
import { BezeichnungDtoServiceConfig } from './bezeichnungdto.service.config';
import { ArtikelbezeichnungsgeneratorBausteineServiceConfig } from './artikelbezeichnungsgeneratorbausteine.service.config';
import { ArtikelbezeichnungsgeneratorServiceConfig } from './artikelbezeichnungsgenerator.service.config';
import { WarengruppenServiceConfig } from './warengruppen.service.config';
import { AssetGroupMetaDataServiceConfig } from './assetgroupmetadata.service.config';
import { AssetGroupServiceConfig } from './assetgroup.service.config';
import { AssetGroupTreeAssigmentServiceConfig } from './assetgrouptreeassigment.service.config';
import { AssetGroupTreeMetaDataServiceConfig } from './assetgrouptreemetadata.service.config';
import { AssetGroupTreeServiceConfig } from './assetgrouptree.service.config';
import { AssetMetaDataServiceConfig } from './assetmetadata.service.config';
import { AssetViewDtoServiceConfig } from './assetviewdto.service.config';
import { DocumentformatServiceConfig } from './documentformat.service.config';
import { ImageformatServiceConfig } from './imageformat.service.config';
import { VideoformatServiceConfig } from './videoformat.service.config';
import { MetaDataServiceConfig } from './metadata.service.config';
import { BestellungServiceConfig } from './bestellung.service.config';
import { CustomerAssetGroupServiceConfig } from './customerassetgroup.service.config';
import { CustomerFilterServiceConfig } from './customerfilter.service.config';
import { CustomerFilterResultServiceConfig } from './customerfilterresult.service.config';
import { CustomerGroupServiceConfig } from './customergroup.service.config';
import { FirmaServiceConfig } from './firma.service.config';
import { KundengruppeServiceConfig } from './kundengruppe.service.config';
import { KundenServiceConfig } from './kunden.service.config';
import { WettbewerberPreisViewDtoServiceConfig } from './wettbewerberpreisviewdto.service.config';
import { KundenFilterDtoServiceConfig } from './kundenfilterdto.service.config';
import { KundensegmentServiceConfig } from './kundensegment.service.config';
import { PersonServiceConfig } from './person.service.config';
import { FirmaPersonenDtoServiceConfig } from './firmapersonendto.service.config';
import { RegistrationServiceConfig } from './registration.service.config';
import { RegistrierungenServiceConfig } from './registrierungen.service.config';
import { RegistrationFilterDtoServiceConfig } from './registrationfilterdto.service.config';
import { BeigabenArtikelServiceConfig } from './beigabenartikel.service.config';
import { BeigabenAusloeserServiceConfig } from './beigabenausloeser.service.config';
import { BeigabenKundeServiceConfig } from './beigabenkunde.service.config';
import { BeigabenServiceConfig } from './beigaben.service.config';
import { HerstellerServiceConfig } from './hersteller.service.config';
import { MarkenServiceConfig } from './marken.service.config';
import { MarkenFilterDtoServiceConfig } from './markenfilterdto.service.config';
import { ArtikelPreisServiceConfig } from './artikelpreis.service.config';
import { ArtikelPreisVkServiceConfig } from './artikelpreisvk.service.config';
import { KalkulationsGruppeServiceConfig } from './kalkulationsgruppe.service.config';
import { ProduktTypAttributeServiceConfig } from './produkttypattribute.service.config';
import { ProduktTypGroupServiceConfig } from './produkttypgroup.service.config';
import { ProduktTypServiceConfig } from './produkttyp.service.config';
import { KundensortimenteServiceConfig } from './kundensortimente.service.config';
import { KundensortimentDtoServiceConfig } from './kundensortimentdto.service.config';
import { SortimenteServiceConfig } from './sortimente.service.config';
import { PropertyServiceConfig } from './property.service.config';
import { TypeServiceConfig } from './type.service.config';
import { BookingServiceConfig } from './booking.service.config';
import { BookingViewDtoServiceConfig } from './bookingviewdto.service.config';
import { BookingObjectServiceConfig } from './bookingobject.service.config';
import { BookingTimesServiceConfig } from './bookingtimes.service.config';
import { AttributeServiceConfig } from './attribute.service.config';
import { GroupAttributeServiceConfig } from './groupattribute.service.config';
import { GroupServiceConfig } from './group.service.config';
import { GroupTreeAssigmentServiceConfig } from './grouptreeassigment.service.config';
import { GroupTreeAttributeServiceConfig } from './grouptreeattribute.service.config';
import { GroupTreeServiceConfig } from './grouptree.service.config';
import { MaingroupAttributeServiceConfig } from './maingroupattribute.service.config';
import { MainGroupServiceConfig } from './maingroup.service.config';
import { StatusGroupTreeServiceConfig } from './statusgrouptree.service.config';
import { StatusServiceConfig } from './status.service.config';
import { StatusTypeServiceConfig } from './statustype.service.config';
import { WettbewerberServiceConfig } from './wettbewerber.service.config';
import { WettbewerberPreisGroupServiceConfig } from './wettbewerberpreisgroup.service.config';
import { WettbewerberPreisServiceConfig } from './wettbewerberpreis.service.config';
import { CustomerViewDtoServiceConfig } from './customerviewdto.service.config';
//#endregion

export const ContainerContainer = {
  name: "Container",
  annotations: [],
  services: [
    AktieServiceConfig,
    AktienTrendsServiceConfig,
    AnalystServiceConfig,
    GroupAktieServiceConfig,
    KurszielServiceConfig,
    TageslinieServiceConfig,
    TradegateKursServiceConfig,
    ArtikelAssetServiceConfig,
    ArtikelAttributeServiceConfig,
    ArtikelGroupFilterSettingsServiceConfig,
    ArtikelGroupServiceConfig,
    AssetServiceConfig,
    ArtikelServiceConfig,
    ArtikelbezeichnungsaufbauServiceConfig,
    BezeichnungDtoServiceConfig,
    ArtikelbezeichnungsgeneratorBausteineServiceConfig,
    ArtikelbezeichnungsgeneratorServiceConfig,
    WarengruppenServiceConfig,
    AssetGroupMetaDataServiceConfig,
    AssetGroupServiceConfig,
    AssetGroupTreeAssigmentServiceConfig,
    AssetGroupTreeMetaDataServiceConfig,
    AssetGroupTreeServiceConfig,
    AssetMetaDataServiceConfig,
    AssetViewDtoServiceConfig,
    DocumentformatServiceConfig,
    ImageformatServiceConfig,
    VideoformatServiceConfig,
    MetaDataServiceConfig,
    BestellungServiceConfig,
    CustomerAssetGroupServiceConfig,
    CustomerFilterServiceConfig,
    CustomerFilterResultServiceConfig,
    CustomerGroupServiceConfig,
    FirmaServiceConfig,
    KundengruppeServiceConfig,
    KundenServiceConfig,
    WettbewerberPreisViewDtoServiceConfig,
    KundenFilterDtoServiceConfig,
    KundensegmentServiceConfig,
    PersonServiceConfig,
    FirmaPersonenDtoServiceConfig,
    RegistrationServiceConfig,
    RegistrierungenServiceConfig,
    RegistrationFilterDtoServiceConfig,
    BeigabenArtikelServiceConfig,
    BeigabenAusloeserServiceConfig,
    BeigabenKundeServiceConfig,
    BeigabenServiceConfig,
    HerstellerServiceConfig,
    MarkenServiceConfig,
    MarkenFilterDtoServiceConfig,
    ArtikelPreisServiceConfig,
    ArtikelPreisVkServiceConfig,
    KalkulationsGruppeServiceConfig,
    ProduktTypAttributeServiceConfig,
    ProduktTypGroupServiceConfig,
    ProduktTypServiceConfig,
    KundensortimenteServiceConfig,
    KundensortimentDtoServiceConfig,
    SortimenteServiceConfig,
    PropertyServiceConfig,
    TypeServiceConfig,
    BookingServiceConfig,
    BookingViewDtoServiceConfig,
    BookingObjectServiceConfig,
    BookingTimesServiceConfig,
    AttributeServiceConfig,
    GroupAttributeServiceConfig,
    GroupServiceConfig,
    GroupTreeAssigmentServiceConfig,
    GroupTreeAttributeServiceConfig,
    GroupTreeServiceConfig,
    MaingroupAttributeServiceConfig,
    MainGroupServiceConfig,
    StatusGroupTreeServiceConfig,
    StatusServiceConfig,
    StatusTypeServiceConfig,
    WettbewerberServiceConfig,
    WettbewerberPreisGroupServiceConfig,
    WettbewerberPreisServiceConfig,
    CustomerViewDtoServiceConfig
  ]
} as ContainerConfig;