import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Aktie } from '../entities/boerse/aktie.entity';
import { AktienTrends } from '../entities/boerse/aktientrends.entity';
import { GroupAktie } from '../entities/boerse/groupaktie.entity';
import { Kursziel } from '../entities/boerse/kursziel.entity';
import { TradegateKurs } from '../entities/boerse/tradegatekurs.entity';
//#endregion

@Injectable()
export class AktieService extends ODataEntityService<Aktie> {
  constructor(protected client: ODataClient) {
    super(client, 'Aktie', 'entities.boerse.Aktie');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public aktienTrends(entity: EntityKey<Aktie>): ODataNavigationPropertyResource<AktienTrends> {
    return this.entity(entity).navigationProperty<AktienTrends>('aktienTrends');
  }
  public groupAktien(entity: EntityKey<Aktie>): ODataNavigationPropertyResource<GroupAktie> {
    return this.entity(entity).navigationProperty<GroupAktie>('groupAktien');
  }
  public kursziele(entity: EntityKey<Aktie>): ODataNavigationPropertyResource<Kursziel> {
    return this.entity(entity).navigationProperty<Kursziel>('kursziele');
  }
  public tradegateKurse(entity: EntityKey<Aktie>): ODataNavigationPropertyResource<TradegateKurs> {
    return this.entity(entity).navigationProperty<TradegateKurs>('tradegateKurse');
  }
  //#endregion
}
