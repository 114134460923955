import { Directive, OnInit, OnDestroy, Input, Injectable, Inject, ViewContainerRef } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';;
import { ODataEntitySetResource } from 'angular-odata';
import { GridDataService } from '../../data-services/core/griddata.service';

@Injectable()
@Directive({selector: '[ODataBindingDirective]'})
export  class ODataBindingDirective extends DataBindingDirective {
    protected serviceSubscription: Subscription;
    public  query: ODataEntitySetResource<any>;
    /* ,private vcr: ViewContainerRef */
      constructor(@Inject(GridDataService) protected dataService: GridDataService, @Inject(GridComponent) grid: GridComponent) {
        super(grid);
    }

    public ngOnInit(): void {
        this.serviceSubscription = this.dataService.subscribe((result) => {
            this.grid.loading = false;
            this.grid.data = result;
            this.notifyDataChange();
           // this.rebind();
        });

        super.ngOnInit();

        
    }
 
    public ngOnDestroy(): void {
        if (this.serviceSubscription) {
            this.serviceSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public rebind(): void {
        this.grid.loading = true;
        this.dataService.query(this.state,this.query);
    }
    public ngOnChanges( c: any ) : void {
 
        console.log( "Prop-Query:", this.query );
 
    }
    
}