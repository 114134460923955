import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Bestellung } from './bestellung.entity';
//#endregion

export const BestellungConfig = {
  name: "Bestellung",
  annotations: [],
  fields: {
    bestellNr: {type: 'Edm.Int32', key: true, ref: 'bestellNr', nullable: false},
    datum: {type: 'Edm.DateTimeOffset', nullable: false},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    typ: {type: 'Edm.String'},
    gesamtbestellwert: {type: 'Edm.Decimal', nullable: false},
    dhlProdukt: {type: 'Edm.String'},
    dhlPremiumInternational: {type: 'Edm.Boolean', nullable: false}
  }
} as EntityConfig<Bestellung>;