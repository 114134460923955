import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { FirmaDto } from './firmadto.entity';
//#endregion

export const FirmaDtoConfig = {
  name: "FirmaDto",
  annotations: [],
  fields: {
    firmaName: {type: 'Edm.String'},
    isAndereFirmierung: {type: 'Edm.Boolean', nullable: false},
    andereFirmierung: {type: 'Edm.String'},
    umsatzSteuerNummer: {type: 'Edm.String'},
    lieferAdresseGleichRechnungsAdresse: {type: 'Edm.Boolean', nullable: false},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    inhaber: {type: 'services.PersonDto', navigation: true},
    adressen: {type: 'services.FirmaAdresseDto', collection: true, navigation: true}
  }
} as EntityConfig<FirmaDto>;