import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Kundensegment } from '../entities/customers/kundensegment.entity';
import { Person } from '../entities/customers/person.entity';
import { FirmaPersonenDto } from '../Mza/Service/Controllers/V1/Kunden/Models/firmapersonendto.entity';
//#endregion

@Injectable()
export class FirmaPersonenDtoService extends ODataEntityService<FirmaPersonenDto> {
  constructor(protected client: ODataClient) {
    super(client, 'FirmaPersonenDto', 'Mza.Service.Controllers.V1.Kunden.Models.FirmaPersonenDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public kundensegmente(entity: EntityKey<FirmaPersonenDto>): ODataNavigationPropertyResource<Kundensegment> {
    return this.entity(entity).navigationProperty<Kundensegment>('kundensegmente');
  }
  public person(entity: EntityKey<FirmaPersonenDto>): ODataNavigationPropertyResource<Person> {
    return this.entity(entity).navigationProperty<Person>('person');
  }
  //#endregion
}
