import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { AktieConfig } from './aktie.entity.config';
import { AktienTrendsConfig } from './aktientrends.entity.config';
import { AnalystConfig } from './analyst.entity.config';
import { GroupAktieConfig } from './groupaktie.entity.config';
import { KurszielConfig } from './kursziel.entity.config';
import { TageslinieConfig } from './tageslinie.entity.config';
import { TradegateKursConfig } from './tradegatekurs.entity.config';
//#endregion

export const BoerseSchema = {
  namespace: "entities.boerse",
  enums: [],
  entities: [AktieConfig,
    AktienTrendsConfig,
    AnalystConfig,
    GroupAktieConfig,
    KurszielConfig,
    TageslinieConfig,
    TradegateKursConfig],
  callables: [],
  containers: []
} as SchemaConfig;