import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Tageslinie } from './tageslinie.entity';
//#endregion

export const TageslinieConfig = {
  name: "Tageslinie",
  annotations: [],
  fields: {
    tage: {type: 'Edm.Int32', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    aktienTrends: {type: 'entities.boerse.AktienTrends', collection: true, navigation: true}
  }
} as EntityConfig<Tageslinie>;