import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HauptgruppenMainComponent } from './hauptgruppen-main/hauptgruppen-main.component';
import { GruppenComponent } from '../gruppen/gruppen.component';
import { AuthGuard } from 'mza-auth-service';

const routes: Routes = [
  {
      path: 'hauptgruppen',
      component: HauptgruppenMainComponent, canActivate: [AuthGuard]
/*       children: [
          {
              path: 'uebersicht',
              component: SortimentslisteComponent
              
          },
          {
              path: 'detail',
              component: SortimentslisteComponent
          }
      ] */
  },
  { path: 'hauptgruppe/:id', component: GruppenComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HauptgruppenRoutingModule { }

