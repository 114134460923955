import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { UserStateDtoConfig } from './userstatedto.entity.config';
import { WettbewerberPreisViewDtoConfig } from './wettbewerberpreisviewdto.entity.config';
import { FirmaPersonenDtoConfig } from './firmapersonendto.entity.config';
import { BookingViewDtoConfig } from './bookingviewdto.entity.config';
//#endregion

export const ModelsKundenSchema = {
  namespace: "Mza.Service.Controllers.V1.Kunden.Models",
  enums: [],
  entities: [UserStateDtoConfig,
    WettbewerberPreisViewDtoConfig,
    FirmaPersonenDtoConfig,
    BookingViewDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;