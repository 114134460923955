import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State, process } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  Attribute, GroupTreeAttributeDataService, GroupTreeAttribute, GroupTreeAttributeService, ExtendsAttributesDto } from 'mza-api-service';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ODataEntitySetResource, Filter, OrderBy } from 'angular-odata';

@Component({
  selector: 'gpv-baum-attribut-zuweisung',
  templateUrl: './baum-attribut-zuweisung.component.html',
  styleUrls: ['./baum-attribut-zuweisung.component.scss']
})
export class BaumAttributZuweisungComponent implements OnInit, OnChanges  {

  public groupAttributeForm: FormGroup;
  public gruppen: Observable<GridDataResult>;
  public dataFilterGroups: Array<string> = ["Nur Gruppe", "Inkl. Vererbung"];
  public dataAttributeExtends: Array<string> = ["Vererbbar", "Nicht Vererbbar"];
  public dataAttributeExtendStatus: Array<string> = ["Ist Vererbt", "Ausgeblendet"];
  public selectedDataFilterGroup: string = "Nur Gruppe";
  public reqData : ODataEntitySetResource<any>;

  //public groupTreeId : number;
  @Input() groupTreeId:  number; 
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};
  constructor(  public _dataServiceGroups: GroupTreeAttributeDataService, 
    private formBuilder: FormBuilder,private router: Router, private route: ActivatedRoute) {
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.createGroupForm  = this.createGroupForm.bind(this);
    this.loadData();
   }

  ngOnInit() {
   this.loadData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }
  public createGroupForm(args: any): FormGroup  {
    let data  = <GroupTreeAttribute>{ id: 0,
                         sortValue: 0,
                         status: null,
                         attributeId: 0,
                         attribute:  <Attribute>{ 
                           id: 0,
                           name: "",
                           isIdea: "true",
                           description: "",
                           label: "",
                           example: "",
                           status: null,
                           createdBy: ""
                         },
                         createdBy:"",
                         groupTreeId: this.groupTreeId};
    const item : GroupTreeAttribute = args.isNew ? data : args.dataItem;

    this.groupAttributeForm = this.formBuilder.group({
      id: item.id ,
      attributeId: [item.attributeId],
      sortValue: [item.sortValue],   
      status: [item.status],            
      groupTreeId: [item.groupTreeId] ,
      createdBy:[item.createdBy],  
      attribute: this.formBuilder.group( {
        name: [item.attribute.name],
        description: [item.attribute.description],
        label: [item.attribute.label]
      }  )        
  });
  return this.groupAttributeForm;
}

public saveChanges(grid: any): void {
  grid.closeCell();
  grid.cancelCell();

  this._dataServiceGroups.saveChanges(this.state);
  //this.sortimentService.query(this.state, this.query);
}

public cancelChanges(grid: any): void {
  grid.cancelCell();
  this._dataServiceGroups.cancelChanges();
}

private loadData() {
  if (isNullOrUndefined(this.groupTreeId) )
    return;

  this.loadDataWithCurrentGroup();
  this.loadDataWithExtendsGroups();

  

  
}

public onSelectionChangeFilterGroup(value: any): void {
  this.selectedDataFilterGroup= value;
  this.loadData();
}
private loadDataWithCurrentGroup(){
  if(this.selectedDataFilterGroup !=="Nur Gruppe")
    return;

    this.reqData = this._dataServiceGroups.getQuery();
    this.reqData.query.expand({attribute: {select: ["id","name", "description"]}});
    this.reqData.query.filter({groupTreeId: this.groupTreeId});
    //  this._dataServiceGroups.query(this.state, query );
   // this.gruppen = this._dataServiceGroups.pipe(map(result => process(result.data, this.state)));
}
private loadDataWithExtendsGroups(){
  if(this.selectedDataFilterGroup !=="Inkl. Vererbung")
    return;
  //UMSTELLEN SELECT WITH GROUP BY STATT UNIQUE FILTER
  let data: ExtendsAttributesDto[];
  this._dataServiceGroups.odataService.getExtendsAttributes().call({id:this.groupTreeId},"entity").subscribe(result => {
   
    data = result[0];
    const uniqueGroupTrees = [...new Set(data.map(item => item.id))];
    this.reqData = this._dataServiceGroups.getQuery();
    this.reqData.expand({GroupTree: {select: ["id","name"]}, attribute: {select: ["id","name", "description"]}});

    this.reqData.orderBy(["sortValue","GroupTreeId"]);
    let filter: Filter = {groupTreeId: {in : uniqueGroupTrees}};
    this.reqData.filter(filter);
  })
}



}
