import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KundenFilterErgebnis } from './kundenfilterergebnis.entity';
//#endregion

export const KundenFilterErgebnisConfig = {
  name: "KundenFilterErgebnis",
  annotations: [],
  fields: {
    kundenNr: {type: 'Edm.Int32', nullable: false},
    kundenFilterId: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kunde: {type: 'services.Kunde', navigation: true},
    kundenFilter: {type: 'entities.customers.KundenFilter', navigation: true, field: 'kundenFilterId', ref: 'id'}
  }
} as EntityConfig<KundenFilterErgebnis>;