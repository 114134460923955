import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupTreeAssigment } from './assetgrouptreeassigment.entity';
//#endregion

export const AssetGroupTreeAssigmentConfig = {
  name: "AssetGroupTreeAssigment",
  annotations: [],
  fields: {
    assetGroupId: {type: 'Edm.Int32'},
    assetGroupTreeId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assetGroup: {type: 'entities.assets.AssetGroup', navigation: true, field: 'assetGroupId', ref: 'id'},
    assetGroupTree: {type: 'entities.assets.AssetGroupTree', navigation: true, field: 'assetGroupTreeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<AssetGroupTreeAssigment>;