import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetViewDto } from './assetviewdto.entity';
//#endregion

export const AssetViewDtoConfig = {
  name: "AssetViewDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    fileName: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    artikelId: {type: 'Edm.Int32'},
    datatypeId: {type: 'Edm.Int32', nullable: false},
    dataformatId: {type: 'Edm.Int32', nullable: false},
    tags: {type: 'Edm.String'},
    fileSize: {type: 'Edm.Decimal', nullable: false},
    videoformatId: {type: 'Edm.Int32'},
    imageformatId: {type: 'Edm.Int32'},
    documentformatId: {type: 'Edm.Int32'},
    propertyId: {type: 'Edm.Int32'}
  }
} as EntityConfig<AssetViewDto>;