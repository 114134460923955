import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { GruppenListeComponent } from './gruppen-liste.component';
import { GruppenattributeListeComponent } from './gruppenattribute-liste/gruppenattribute-liste.component';

const routes: Routes = [
  {
      path: 'listen-ansicht/:id',
      component: GruppenListeComponent,
       children: [
          {
              path: 'attributes',
              component: GruppenattributeListeComponent
          }
      ] 
  },
  { path: 'listen-ansicht', component: GruppenListeComponent },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GruppenListeRoutingModule { }
