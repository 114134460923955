import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { RegistrationFilterDto } from './registrationfilterdto.entity';
//#endregion

export const RegistrationFilterDtoConfig = {
  name: "RegistrationFilterDto",
  annotations: [],
  fields: {
    valueId: {type: 'Edm.String'},
    firma: {type: 'Edm.String'},
    postleitzahl: {type: 'Edm.String'},
    ort: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<RegistrationFilterDto>;