import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelAssetConfig } from './artikelasset.entity.config';
import { ArtikelAttributeConfig } from './artikelattribute.entity.config';
import { ArtikelGroupFilterSettingsConfig } from './artikelgroupfiltersettings.entity.config';
import { ArtikelGroupConfig } from './artikelgroup.entity.config';
import { ArtikelBezeichnungAufbauViewConfig } from './artikelbezeichnungaufbauview.entity.config';
import { ArtikelbezeichnungsgeneratorBausteinDtoConfig } from './artikelbezeichnungsgeneratorbausteindto.entity.config';
import { ArtikelbezeichnungsgeneratorDtoConfig } from './artikelbezeichnungsgeneratordto.entity.config';
//#endregion

export const ArticlesSchema = {
  namespace: "entities.articles",
  enums: [],
  entities: [ArtikelAssetConfig,
    ArtikelAttributeConfig,
    ArtikelGroupFilterSettingsConfig,
    ArtikelGroupConfig,
    ArtikelBezeichnungAufbauViewConfig,
    ArtikelbezeichnungsgeneratorBausteinDtoConfig,
    ArtikelbezeichnungsgeneratorDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;