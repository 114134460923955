import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Property } from './property.entity';
//#endregion

export const PropertyConfig = {
  name: "Property",
  annotations: [],
  fields: {
    bezeichnung: {type: 'Edm.String'},
    kurzBezeichnung: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    typeId: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    type: {type: 'entities.ref.Type', navigation: true, field: 'typeId', ref: 'id'},
    assetMetaDataList: {type: 'entities.assets.AssetMetaData', collection: true, navigation: true},
    beigaben: {type: 'entities.produkte.Beigabe', collection: true, navigation: true}
  }
} as EntityConfig<Property>;