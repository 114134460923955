import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AktienTrends } from './aktientrends.entity';
//#endregion

export const AktienTrendsConfig = {
  name: "AktienTrends",
  annotations: [],
  fields: {
    aktieId: {type: 'Edm.Int32'},
    date: {type: 'Edm.DateTimeOffset', nullable: false},
    tageslinieId: {type: 'Edm.Int32'},
    kurs: {type: 'Edm.Decimal', nullable: false},
    bid: {type: 'Edm.Decimal', nullable: false},
    ask: {type: 'Edm.Decimal', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    aktie: {type: 'entities.boerse.Aktie', navigation: true, field: 'aktieId', ref: 'id'},
    tageslinie: {type: 'entities.boerse.Tageslinie', navigation: true, field: 'tageslinieId', ref: 'id'}
  }
} as EntityConfig<AktienTrends>;