import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KundenFilterDto } from './kundenfilterdto.entity';
//#endregion

export const KundenFilterDtoConfig = {
  name: "KundenFilterDto",
  annotations: [],
  fields: {
    kundenNr: {type: 'Edm.Int32', nullable: false},
    valueId: {type: 'Edm.String'},
    firma: {type: 'Edm.String'},
    plz: {type: 'Edm.String'},
    ort: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<KundenFilterDto>;