import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { FirmaConfig } from './firma.entity.config';
import { RegistrationInfoConfig } from './registrationinfo.entity.config';
import { BookingConfig } from './booking.entity.config';
import { BookingObjectConfig } from './bookingobject.entity.config';
import { BookingTimesConfig } from './bookingtimes.entity.config';
import { DashboardAssigmentConfig } from './dashboardassigment.entity.config';
import { DashboardConfig } from './dashboard.entity.config';
import { AttributeConfig } from './attribute.entity.config';
import { GroupAttributeConfig } from './groupattribute.entity.config';
import { GroupConfig } from './group.entity.config';
import { GroupTreeAssigmentConfig } from './grouptreeassigment.entity.config';
import { GroupTreeAttributeConfig } from './grouptreeattribute.entity.config';
import { GroupTreeConfig } from './grouptree.entity.config';
import { MaingroupAttributeConfig } from './maingroupattribute.entity.config';
import { MaingroupConfig } from './maingroup.entity.config';
import { WettbewerberConfig } from './wettbewerber.entity.config';
import { WettbewerberPreisGroupConfig } from './wettbewerberpreisgroup.entity.config';
//#endregion

export const SchemaSchema = {
  namespace: "entities.schema",
  enums: [],
    RegistrationInfoConfig,
    BookingConfig,
    BookingObjectConfig,
    BookingTimesConfig,
  entities: [DashboardAssigmentConfig,
    AttributeConfig,
    GroupAttributeConfig,
    GroupTreeAssigmentConfig,
    GroupTreeAttributeConfig,
    GroupTreeConfig,
    MaingroupAttributeConfig,
    MaingroupConfig,
    WettbewerberConfig,
    WettbewerberPreisGroupConfig],
  callables: [],
  containers: []
} as SchemaConfig;