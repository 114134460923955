import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Artikel } from './artikel.entity';
//#endregion

export const ArtikelConfig = {
  name: "Artikel",
  annotations: [],
  fields: {
    artikelNr: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    imageFileName: {type: 'Edm.String'},
    imageUrl: {type: 'Edm.String'},
    imageAssetId: {type: 'Edm.Int32', nullable: false},
    gesperrt: {type: 'Edm.Boolean', nullable: false},
    lagerbestand: {type: 'Edm.Decimal', nullable: false},
    isNeuerArtikel: {type: 'Edm.Boolean', nullable: false},
    qualitaetsId: {type: 'Edm.Int32', nullable: false},
    markeId: {type: 'Edm.Int32', nullable: false},
    herstellerId: {type: 'Edm.Int32'},
    maximaleMenge: {type: 'Edm.Int32', nullable: false},
    isWerbeartikel: {type: 'Edm.Boolean', nullable: false},
    preistipp: {type: 'Edm.Decimal', nullable: false},
    prognose: {type: 'Edm.Int32', nullable: false},
    isAuslaufArtikel: {type: 'Edm.Boolean', nullable: false},
    lieferantenNr: {type: 'Edm.String'},
    streckengeschaeft: {type: 'Edm.Boolean', nullable: false},
    streckengeschaeftAb: {type: 'Edm.Decimal', nullable: false},
    direktlieferung: {type: 'Edm.String'},
    lieferant: {type: 'Edm.String'},
    grundpreis: {type: 'Edm.Decimal', nullable: false},
    grundpreisAngabe: {type: 'Edm.String'},
    grundpreisMenge: {type: 'Edm.Decimal', nullable: false},
    gtin: {type: 'Edm.String'},
    isbn: {type: 'Edm.String'},
    einheit: {type: 'Edm.Int32'},
    isStueckliste: {type: 'Edm.Boolean', nullable: false},
    mgeEnthaltenInStuecklisten: {type: 'Edm.Int32', nullable: false},
    vpe: {type: 'Edm.Int32', nullable: false},
    stuecklisteAnzeige: {type: 'Edm.Boolean', nullable: false},
    produktTypId: {type: 'Edm.Int32'},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    hersteller: {type: 'entities.produkte.Hersteller', navigation: true},
    produkttyp: {type: 'entities.produkte.ProduktTyp', navigation: true, field: 'produktTypId', ref: 'id'},
    beigabenKunden: {type: 'entities.produkte.BeigabenKunde', collection: true, navigation: true},
    beigabenArtikel: {type: 'entities.produkte.BeigabenArtikel', collection: true, navigation: true},
    artikelbezeichnungen: {type: 'services.Artikelbezeichnung', collection: true, navigation: true},
    attributes: {type: 'entities.articles.ArtikelAttribute', collection: true, navigation: true},
    assets: {type: 'entities.articles.ArtikelAsset', collection: true, navigation: true},
    artikelgruppen: {type: 'entities.articles.ArtikelGroup', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Artikel>;