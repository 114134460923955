import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ReportService, DataMappingService, Report, DbObjectDto,DataMapping ,ColumnDto} from 'mza-api-service';
import { Location } from '@angular/common'


@Component({
  selector: 'gpv-report-setting',
  templateUrl: './report-setting.component.html',
  styleUrls: ['./report-setting.component.css']
})
export class ReportSettingComponent implements OnInit {
  public ReportForm: FormGroup;
  public MappingForm: FormGroup;
  public isLoading: boolean = false;
  //@Input("reportId") reportId: number = 1;

  public dbObjects: Array<DbObjectDto> = new Array<DbObjectDto>();
  public dbObjectColumns: Array<ColumnDto> = new Array<ColumnDto>();
  public dataMappings: Array<DataMapping> = new Array<DataMapping>();

  public componentenTypListe: Array<{ text: string, id: number }> = [
    { text: 'Grid', id: 1 },
    { text: 'Chart', id: 2 },
    { text: 'Bullet', id: 3 }
  ];

  public bulletTyps: Array<{ text: string}> = [
    { text: 'Bullet'},
    { text: 'PlotBand'},
    { text: 'Title'}
  ];


  public isSave: boolean = false;
  constructor(private _reportService: ReportService, private mappingservice: DataMappingService,
    private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private location: Location,
    private notificationService: NotificationService) {

    //  this.createReportForm  = this.createGridReportForm.bind(this);
    this.createReportForm(null);
    this.createMappingForm = this.createMappingForm.bind(this);

  }

  private saveOk(){

    this.notificationService.show({
      content: 'Report erstellt',
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: this.hideAfter,
      //closable: true
    });
  }

  private saveError(){
   
    this.notificationService.show({
      content: 'Fehler beim Speichern',
      cssClass: 'save-error',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
      // hideAfter: this.hideAfter,
      closable: true
    });
  }

 public onReportNameChange(value: string){
   if(!this.fc.name.valid) return;
   
   if (this.isNewData())
   this.addNewReport()
    else {
     this._reportService.assign({id: this.ReportForm.value.id },{name: this.ReportForm.value.name})
     .subscribe(
      r => {
          this.saveOk();
      },
      (error) => {
        this.saveError()
      });
 }
 }
 public onReportDescChange(value: string){
  if(!this.fc.description.valid) return;
  
  if (this.isNewData()) return;
  
    this._reportService.assign({id: this.ReportForm.value.id },{description: this.ReportForm.value.description})
    .subscribe(
     r => {
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });

}

private saveDbObject(){
  if(!this.fc.dbObject.valid) return;
  
  if (this.isNewData()) return;
  
    this._reportService.assign({id: this.ReportForm.value.id },{dbObject: this.ReportForm.value.dbObject})
    .subscribe(
     r => {
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });
}
private saveComponent(){
  if(!this.fc.dbObject.valid) return;
  
  if (this.isNewData()) return;
  
    this._reportService.assign({id: this.ReportForm.value.id },{componentType: this.ReportForm.value.componentType})
    .subscribe(
     r => {
         this.createDataMappings();
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });
}

  public createReportForm(item: Report): FormGroup {
    this.ReportForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.maxLength(100), Validators.required]],
      description: [0, Validators.required],
      dbObject: ['', Validators.required],
      isGroupIdRequired: [false],
      time: [false],
      date: [0, Validators.required],
      intervall: [0, Validators.required],
      intervallType: [0, Validators.required],
      componentType: [0, Validators.required],
      statusId: [0],


     // mappings: this.formBuilder.array([])
    });
    return this.ReportForm;
  }

  public createMappingForm(args: any): FormGroup {
    let data  = <DataMapping>{
      id: 0,
      inputName: "",
      outputName: "",
      reportId: this.ReportId,
      color: "",
      from: "",
      to: "",
      opacity: "",
      sortValue: 0,
      typ: "",
      title: ""

    };
    const item = args.isNew ? data : args.dataItem;

     this.MappingForm = this.formBuilder.group({
        'id': item.id,
        'reportId': [item.reportId, Validators.required],
        'inputName': item.inputName,
        'outputName': [item.inputName], //Output = Input
        'color': item.color,
        'from': item.from,
        'to': item.to,
        'title': item.title,
        'opacity': item.opacity,
        'typ': item.typ,
        'sortValue': item.sortValue,
    }); 

    return this.MappingForm;
}

  get mappings(): FormArray { return this.ReportForm.get('mappings') as FormArray; }
 /*  get DataMapping(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      reportId: [0, Validators.required],
      inputName: [0],
      outputName: [0]
    });
  } */
 /*  addDataMapping() {
    this.mappings.push(this.DataMapping);
  }
 */


  private ReportId: number = 0
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.ReportId = +params['id']; 
        this.dataBind();
    });
    this.dataBindDbObjects();

    //this.ReportForm.valueChanges.subscribe(value =>  this.testValue = value['bestellArt']); 
  
  }
  /* ngAfterViewInit() {
  this.ReportForm.addControl('artikel', this.artikelNrComponent.artikelForm);
  this.artikelNrComponent.artikelForm.setParent(this.ReportForm);
  } */

  private dataBindDbObjects() {
    this._reportService.getObjects().call(null, "entities").subscribe(r => this.dbObjects = r)
  }

  private dataBind() {
    if (this.ReportId === 0) {
      this.createNewDataForm();
      return;
    }

  //  this.mappings.clear();

    let reqReport = this._reportService.entity(this.ReportId)
    reqReport.query.select(["id", "name","componentType", "description", "dbObject", "isGroupIdRequired", "time", "date", "intervall", "intervallType", "statusId"]);
    //data.expand({DataMapping: {select: ["id","artikelId","menge","mindestWarenwert", "lagerortId","isEinmalig","isAktiv","isVerhaeltnis","useAusloeser","maxAbgabeMenge","maxAbgabeMengeGesamt"],expand: {artikel: {select: ["artikelnr","id"]}}}});
    reqReport.query.expand({ mappings: { select: ["id", "inputName", "outputName", "reportId", "to", "from", "color", "opacity", "sortValue", "typ","title"] } });
    reqReport.get().subscribe(({ entity }) => {
/* 
      entity.mappings.forEach(element => {
        this.addDataMapping();
      }); */
      this.dataMappings = entity.mappings;
      this.ReportForm.setValue({
        id: entity.id,
        name: entity.name,
        description: entity.description,
        dbObject: entity.dbObject,
        isGroupIdRequired: entity.isGroupIdRequired,
        time: entity.time,
        date: entity.date,
        componentType: entity.componentType,
        intervall: entity.intervall,
        intervallType: entity.intervallType,
        statusId: entity.statusId,
     //   mappings: entity.mappings,
        //mindestWarenwert: data.DataMapping[0].mindestWarenwert
      });
      this.loadDbObjectColumns();
    })


    //  this.serviceA.entities().get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
    //  collection.get().subscribe(entityset => this.artikelbezeichnungList = entityset.entities);
  }

  private createNewDataForm() {
  //  this.addDataMapping();
    this.ReportForm.setValue({
      id: 0,
      name: "",
      description: "",
      dbObject: "",
      isGroupIdRequired: true,
      time: null,
      date: null,
      componentType: "",
      intervall: 0,
      intervallType: 0,
      statusId: 17,
     /*  mappings: [{
        id: 0,
        inputName: "",
        outputName: "",
        reportId: 0

      }], */

    });
  }


  get fc() {
    return this.ReportForm.controls;
  }
  private hideAfter: number = 3000;

/*   onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.ReportForm.value);
    //delete this.ReportForm.value.id;

    if (this.isNewData())
      this.addNewReport()
    else {
      this.updateReport()
    }


  } */

  public onCancel() {
    this.dataBind();

  }

  public onClose() {
    this.location.back();
  }

  public isNewData(): boolean {
    return this.ReportForm.value.id === 0;
  }

  private addNewReport() {
   // let artikelReport = this.ReportForm.value.mappings;
   // delete this.ReportForm.value.mappings;
    this._reportService.create(this.ReportForm.value).subscribe(
      r => {
        this.ReportId = r.id;
        this.ReportForm.patchValue({ id: r.id });
        this.notificationService.show({
          content: 'Report erstellt',
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'success', icon: true },
          hideAfter: this.hideAfter,
          //closable: true
        });
      },
      (error) => {
        this.notificationService.show({
          content: 'Fehler beim Speichern der Report',
          cssClass: 'save-error',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'error', icon: true },
          // hideAfter: this.hideAfter,
          closable: true
        });
      });
  }
  public getComponentValue(outputName): string{
    if(this.selectedComponent === "Grid")
    return  outputName;
    else
    return "";
  }
  public getComponentValues(outputName): Array<string>{
    return   ['',outputName];
  }
  public onComponentFilterChange(value) {
    console.log(value);
}
public onComponentValueChange(value, dataItem) {
    let valueNew =  "";
    if(value !== null  && value !== undefined && value.length > 0)
        valueNew = value;

    this.mappingservice.assign({id: dataItem.id },{outputName: valueNew})
    .subscribe(
     r => {
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });

}
  public selectedDbObject : string=null;
  public handleDbObjectChange(value: string) {
    this.selectedDbObject = value;
    this.saveDbObject();
    this.isLoading = true;
    this.dataMappings= [];
    if(this.selectedComponent === null || this.selectedComponent ==="" ) {
      //this.selectedComponent ="Grid"
     // this.ReportForm.patchValue({componentType: "Grid"});
     return;
    };

   this._reportService.getColumnsFromObject().call({objectName: value}, "entities").subscribe(r => {
    this.dbObjectColumns = r;
     r.forEach(element => {
         let item: DataMapping = {id: 0 , reportId: this.ReportId, inputName: element.column, outputName: this.getComponentValue(element.column)};
         this.dataMappings.push(item);
     });
     this.isLoading = false;
   } );

}
private loadDbObjectColumns(){
  if(this.ReportForm.value.dbObject === '') return;

  this._reportService.getColumnsFromObject().call({objectName: this.ReportForm.value.dbObject}, "entities").subscribe(r => {
    this.dbObjectColumns = r;   
   } );
}

public selectedComponent : string =null;

public handleComponentChange(value: string) {
  this.selectedComponent = value;
  this.saveComponent();
  //this.isLoading = true;

}

public createDataMappings(){
  this.dataMappings= [];
  this._reportService.getColumnsFromObject().call({objectName: this.ReportForm.value.dbObject}, "entities").subscribe(r => {

    r.forEach(element => {
        let item: DataMapping = {id: 0 , reportId: this.ReportId, inputName: element.column, outputName: this.getComponentValue(element.column)};
        this.dataMappings.push(item);
        if(item.outputName !== "")
          {
            this.createDataMappingItem(item);
          }
    });
    this.isLoading = false;
  } ); 
}

private createDataMappingItem(item: DataMapping){
  this.mappingservice.create(item)
    .subscribe(
     r => {
         this.dataMappings.find(f => f.inputName === item.inputName).id = r.id;
         this.saveOk();
     },
     (error) => {
       this.saveError()
     });
}

private getOutputValue

  private updateReport() {
    this.isSave = true;
    let artikelReport = this.ReportForm.value.DataMapping;
    delete this.ReportForm.value.DataMapping;
    this._reportService.assign({ id: this.ReportForm.value.id }, this.ReportForm.value).subscribe(
      r => {
        artikelReport.forEach(element => {
          this.isSave = true;
          this.mappingservice.assign({ id: element.id }, element).subscribe(
            ba => {
              this.isSave = false;
              /*         this.notificationService.show({
                  content: 'Artikeländerung erfolgreich gespeichert',
                  cssClass: 'save-ok',
                  animation: { type: 'fade', duration: 400 },
                  position: { horizontal: 'right', vertical: 'top' },
                  type: { style: 'success', icon: true },
                  hideAfter: this.hideAfter,
                //  closable: true
              }); */
            },
            (error) => {
              this.isSave = false;
              this.notificationService.show({
                content: 'Fehler beim speichern der Artikeländerung',
                cssClass: 'save-error',
                animation: { type: 'fade', duration: 400 },
                position: { horizontal: 'right', vertical: 'top' },
                type: { style: 'error', icon: true },
                // hideAfter: this.hideAfter,
                closable: true

              });
            });
        });
        /*    this.createReportForm(null);
        this.dataBind(); */
        this.isSave = false;
        this.notificationService.show({
          content: 'Report gespeichert',
          cssClass: 'button-notification',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'success', icon: true },
          hideAfter: this.hideAfter,
          //closable: true
        });
      },
      (error) => {
        this.notificationService.show({
          content: 'Fehler beim Speichern der Report',
          cssClass: 'save-error',
          animation: { type: 'fade', duration: 400 },
          position: { horizontal: 'right', vertical: 'top' },
          type: { style: 'error', icon: true },
          // hideAfter: this.hideAfter,
          closable: true
        });
      });
  }


  //MAPPINGS
  
  public isMappingTitleVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }
  public isMappingAddButtonVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }
  public isMappingInputVisible(){
    return this.ReportForm.value.componentType === "Grid";
  }
  public isMappingOutputVisible(){
    return this.ReportForm.value.componentType === "Grid";
  }
  public isMappingFromVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }
  public isMappingToVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }
  public isMappingOpacityVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }
  public isMappingColorVisible(){
    return this.ReportForm.value.componentType === "Bullet" ||this.ReportForm.value.componentType === "Grid";
  }
  //Kann erweitert werden für GRID um auf vorgefertigte Spalten zu verweisen, (z.b Artikel-Spalte)
  public isMappingTypVisible(){
    return this.ReportForm.value.componentType === "Bullet";
  }


public onFromValueChange(value, dataItem,isNew: boolean) {
   if(isNew) return;
    dataItem.from = value;
    this.updateMapping({from: dataItem.from},dataItem);
}

public onToValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;
  dataItem.to = value;
  this.updateMapping({to: dataItem.to},dataItem);
 
}


public onColorValueChange(value, dataItem,isNew: boolean) {

  dataItem.color = value;
 // this.updateMapping({color: dataItem.color},dataItem);
}
public onColorChangedClose(event, dataItem,isNew: boolean){
  if(isNew) return;
  this.updateMapping({color: dataItem.color},dataItem);
}

public onOpacityValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;
  dataItem.opacity = value;
  this.updateMapping({opacity: dataItem.opacity},dataItem);
}

public onTitleValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;
  dataItem.title = value;
  this.updateMapping({title: dataItem.title},dataItem);
}

public onTypValueChange(value, dataItem,isNew: boolean) {
  if(isNew) return;

  dataItem.typ = value;
  this.updateMapping({typ: dataItem.typ},dataItem);
}

private updateMapping( attrs: Partial<DataMapping>,dataItem: DataMapping){
  if(dataItem.id === 0) {
    this.createBulletMappingItem(dataItem);
    return;
  }
  this.mappingservice.assign({id: dataItem.id },attrs)
  .subscribe(
   r => {
       this.saveOk();
   },
   (error) => {
     this.saveError()
   });
}




public createBulletMappingItem(dataItem: any){
  let item: DataMapping = {id: 0 , reportId: this.ReportId, from: dataItem.from, to: dataItem.to, color: dataItem.color, opacity: dataItem.opacity};

  this.mappingservice.create(item)
  .subscribe(
   r => {
       item.id = r.id;
       this.dataMappings = this.dataMappings.filter(item => item.id !== 0);
     /*   const index = this.dataMappings.indexOf(f => f.id === 0);
       if(index !== -1)
         this.dataMappings.splice(index,1); */

       this.dataMappings.push(item);
       this.saveOk();
   },
   (error) => {
     this.saveError()
   });

}

public onRemoveMapping(dataItem: DataMapping){
  this.mappingservice.destroy({id: dataItem.id}).subscribe( r => {
 
    this.dataMappings = this.dataMappings.filter(item => item.id !== dataItem.id);

    this.saveOk();
},
(error) => {
  this.saveError()
});
}

public onAddMapping(){
  let item: DataMapping = {id: 0 , reportId: this.ReportId};

  this.mappingservice.create(item)
  .subscribe(
   r => {
       item.id = r.id;
      
       this.dataMappings.push(item);
   },
   (error) => {
     this.saveError()
   });
}

public getDbObjectColumnsWithNumbers(): Array<ColumnDto>{
  return this.dbObjectColumns.filter(f => f.type === 'money' || f.type === 'decimal' || f.type === 'int' || f.type === 'real')
}


  //VORSCHAU
  public openedPreview = false;
  public windowTop = 100;
  public windowLeft = 50;

  public closePreview() {
    this.openedPreview = false;
  }

  public openPreview() {
    this.openedPreview = true;
  }

}
