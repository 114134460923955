import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mza-beigaben-info-status',
  templateUrl: './beigaben-info-status.component.html',
  styleUrls: ['./beigaben-info-status.component.css']
})
export class BeigabenInfoStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
