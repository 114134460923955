import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { DbObjectDto } from '../Mza/Service/Models/dbobjectdto.entity';
import { ColumnDto } from '../Mza/Service/Models/columndto.entity';
import { Report } from '../entities/Reports/report.entity';
import { DataMapping } from '../entities/settings/datamapping.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class ReportService extends ODataEntityService<Report> {
  constructor(protected client: ODataClient) {
    super(client, 'Report', 'entities.Reports.Report');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getObjects(): ODataFunctionResource<null, DbObjectDto> {
    const resource = this.entities().function<null, DbObjectDto>('services.GetObjects');
    return resource;
  }
  public getColumnsFromObject(): ODataFunctionResource<{objectName: string}, ColumnDto> {
    const resource = this.entities().function<{objectName: string}, ColumnDto>('services.GetColumnsFromObject');
    return resource;
  }

  public getReportResult(): ODataFunctionResource<{reportId: number, groupId: number}, string> {
    const resource = this.entities().function<{reportId: number, groupId: number}, string>('services.GetReportResult');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public mappings(entity: EntityKey<Report>): ODataNavigationPropertyResource<DataMapping> {
    return this.entity(entity).navigationProperty<DataMapping>('mappings');
  }
  public status(entity: EntityKey<Report>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Report>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
