import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { BookingViewDto } from '../Mza/Service/Controllers/V1/Kunden/Models/bookingviewdto.entity';
//#endregion

@Injectable()
export class BookingViewDtoService extends ODataEntityService<BookingViewDto> {
  constructor(protected client: ODataClient) {
    super(client, 'BookingViewDto', 'Mza.Service.Controllers.V1.Kunden.Models.BookingViewDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
