<!-- <p>Artikel-Nr</p>
<div class="artikelinput" formGroupName="artikelForm">
<kendo-combobox #artikelFilter

formControlName="artikel" 
[data]="data"
[textField]="'artikelNr'"
[valueField]="'id'"
(valueChange)="onValueChange($event)"
[(ngModel)]="artikelId"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Artikelnr wählen...'"

>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div [className]="dataItem.imShopSichtbar === true ? 'im-shop' : ''">
            <p><b>{{ dataItem.artikelNr }}</b></p>

        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox>
<mza-artikel-bild [artikelNr]="form.controls.artikel.value?.artikelNr" ></mza-artikel-bild>
</div> -->
<!-- <p>Artikel-Nr</p> -->
<div [formGroup]="form">
<!--   <input formControlName="artikelId" id="artikelIdInput" />   -->


<kendo-textbox-container floatingLabel="Artikelnr">
  <kendo-combobox #artikelFilter
  [data]="data"
  formControlName="selected"
  
  [textField]="'artikelNr'"
  [valueField]="'id'"

  [filterable]="true"
  (filterChange)="handleFilter($event)"
  [placeholder]="'Artikelnr wählen...'"
  >
  <ng-template kendoComboBoxItemTemplate  let-dataItem>
          <div [className]="dataItem.imShopSichtbar === true ? 'im-shop' : ''">
              <p><b>{{ dataItem.artikelNr }}</b></p>
              <!-- <p>Shop: <b>{{ dataItem.imShopSichtbar }}</b></p> -->
              <!-- <p>{{ dataItem.warengruppenNr }}, {{ dataItem.internetmarke }}</p> -->
          </div>
        
      </ng-template>
      <ng-template kendoDropDownListNoDataTemplate>
          <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
      </ng-template>
  </kendo-combobox>
</kendo-textbox-container>

<!-- 
<kendo-combobox #artikelFilter
[data]="data"
formControlName="artikelId"

[textField]="'artikelNr'"
[valueField]="'id'"
(valueChange)="onValueChange($event)"
[(ngModel)]="selectedItem"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Artikelnr wählen...'"
>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div [className]="dataItem.imShopSichtbar === true ? 'im-shop' : ''">
            <p><b>{{ dataItem.artikelNr }}</b></p>

        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox> -->
<!--<mza-artikel-bild [artikelNr]="selectedItem?.artikelNr" ></mza-artikel-bild> -->

</div>
<mza-artikel-bild [artikelNr]="currentArtikelNr()"></mza-artikel-bild> 


