import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { ArtikelAttribute } from '../entities/articles/artikelattribute.entity';
import { Artikel } from '../entities/artikel.entity';
import { Attribute } from '../entities/schema/attribute.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class ArtikelAttributeService extends ODataEntityService<ArtikelAttribute> {
  constructor(protected client: ODataClient) {
    super(client, 'ArtikelAttribute', 'entities.articles.ArtikelAttribute');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public artikel(entity: EntityKey<ArtikelAttribute>): ODataNavigationPropertyResource<Artikel> {
    return this.entity(entity).navigationProperty<Artikel>('artikel');
  }
  public attribute(entity: EntityKey<ArtikelAttribute>): ODataNavigationPropertyResource<Attribute> {
    return this.entity(entity).navigationProperty<Attribute>('attribute');
  }
  public status(entity: EntityKey<ArtikelAttribute>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<ArtikelAttribute>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
