import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { RegistrationInfo } from './registrationinfo.entity';
//#endregion

export const RegistrationInfoConfig = {
  name: "RegistrationInfo",
  annotations: [],
  fields: {
    registrationClientIP: {type: 'Edm.Guid', nullable: false},
    confirmationClientIP: {type: 'Edm.Guid', nullable: false},
    clientIP: {type: 'Edm.Guid', nullable: false},
    altKundenNummer: {type: 'Edm.Int32'},
    isConfirmedByVertrieb: {type: 'Edm.Boolean', nullable: false},
    confirmationVertriebBy: {type: 'Edm.String'},
    confirmationVertriebAt: {type: 'Edm.DateTimeOffset'},
    confirmationVertriebBemerkung: {type: 'Edm.String'},
    confirmationVertriebBemerkungChangedAt: {type: 'Edm.DateTimeOffset'},
    confirmationVertriebBemerkungChangedBy: {type: 'Edm.String'},
    isConfirmedByAussendienst: {type: 'Edm.Boolean', nullable: false},
    confirmationAussendienstBy: {type: 'Edm.String'},
    confirmationAussendienstAt: {type: 'Edm.DateTimeOffset'},
    confirmationAussendienstBemerkung: {type: 'Edm.String'},
    confirmationAussendienstBemerkungChangedAt: {type: 'Edm.DateTimeOffset'},
    confirmationAussendienstBemerkungChangedBy: {type: 'Edm.String'},
    isEmailConfirmed: {type: 'Edm.Boolean', nullable: false},
    emailConfirmationToken: {type: 'Edm.Guid', nullable: false},
    emailConfirmationReceivedAt: {type: 'Edm.DateTimeOffset'},
    firmaId: {type: 'Edm.Int32'},
    personId: {type: 'Edm.Int32'},
    websiteURL: {type: 'Edm.String'},
    gewerbeArtId: {type: 'Edm.Int32', nullable: false},
    zahlungsArtId: {type: 'Edm.Int32', nullable: false},
    leistungsProfilHaendlerId: {type: 'Edm.Int32'},
    newsletterSubscription: {type: 'Edm.Boolean', nullable: false},
    isConfirmedAGB: {type: 'Edm.Boolean', nullable: false},
    gewerbeNachweis: {type: 'Edm.Binary'},
    scoreData: {type: 'Edm.String'},
    scoreInteger: {type: 'Edm.Int32'},
    scoreAmpel: {type: 'Edm.String'},
    scoreGeneratedBy: {type: 'Edm.String'},
    scoreGeneratedAt: {type: 'Edm.DateTimeOffset'},
    isKundeCalled: {type: 'Edm.Boolean'},
    isKundeVisited: {type: 'Edm.Boolean'},
    isInternetSuspicion: {type: 'Edm.Boolean'},
    isKundeDenied: {type: 'Edm.Boolean'},
    isEmailKundeDenied: {type: 'Edm.Boolean', nullable: false},
    emailKundeDeniedReceivedAt: {type: 'Edm.DateTimeOffset'},
    emailKundeDeniedReceivedBy: {type: 'Edm.String'},
    besuchKw: {type: 'Edm.Int32'},
    gewerbeNachweisMimeType: {type: 'Edm.String'},
    isExportCsvCreated: {type: 'Edm.Boolean'},
    isRegistDoppelt: {type: 'Edm.Boolean'},
    isEmailAussendienst: {type: 'Edm.Boolean', nullable: false},
    emailAussendienstReceivedAt: {type: 'Edm.DateTimeOffset'},
    emailAussendienstReceivedBy: {type: 'Edm.String'},
    konditionenInfoAccept: {type: 'Edm.Boolean', nullable: false},
    kundengruppeId: {type: 'Edm.Int32'},
    kundensegmentId: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    person: {type: 'entities.customers.Person', navigation: true, field: 'personId', ref: 'id'},
    kundengruppe: {type: 'entities.customers.Kundengruppe', navigation: true, field: 'kundengruppeId', ref: 'id'},
    kundensegment: {type: 'entities.customers.Kundensegment', navigation: true, field: 'kundensegmentId', ref: 'id'},
    kunden: {type: 'services.Kunde', collection: true, navigation: true},
    leistungsprofilAnsprechpartner: {type: 'services.LeistungsprofilAnsprechpartner', collection: true, navigation: true},
    leistungsprofilHaendlers: {type: 'services.LeistungsprofilHaendler', collection: true, navigation: true},
    leistungsprofilHaendler: {type: 'services.LeistungsprofilHaendler', navigation: true},
    leistungsprofilHaendlerAngabefelder: {type: 'services.LeistungsprofilHaendlerAngabefelder', collection: true, navigation: true},
    leistungsprofilHaendlerBereichsangabe: {type: 'services.LeistungsprofilHaendlerBereichsangabe', collection: true, navigation: true},
    leistungsprofilInternetverdachtHaendler: {type: 'services.LeistungsprofilInternetverdachtHaendler', collection: true, navigation: true},
    leistungsprofilWebshopadressen: {type: 'services.LeistungsprofilWebshopadressen', collection: true, navigation: true},
    registrationAbgleich: {type: 'services.RegistrationAbgleich', collection: true, navigation: true},
    registrationGroups: {type: 'services.RegistrationGroup', collection: true, navigation: true}
  }
} as EntityConfig<RegistrationInfo>;