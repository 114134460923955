<kendo-textbox-container floatingLabel="Kundenfilter" fxFlex="70%">

<kendo-combobox #kundenfilterFilter

isRequired="isRequired"
[data]="data"
[value]="value"

[textField]="'bezeichnung'"
[valueField]="'id'"
[valuePrimitive]="true"
(valueChange)="onValueChange($event)"

[filterable]="true"
(filterChange)="handleFilter($event)"


>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div [className]="dataItem.imShopSichtbar === true ? 'im-shop' : ''">
            <p><b>{{ dataItem.bezeichnung }}</b></p>

        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox>
</kendo-textbox-container>

