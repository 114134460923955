<p>Warengruppen-Nr</p>
<!-- <kendo-combobox #WgNrFilter
[data]="data"
[textField]="'IdValue'"
[valueField]="'IdValue'"
(valueChange)="onValueChange($event)"
[(ngModel)]="selectedItem"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Warengruppe wählen...'"
[disabled]="disabled"
>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div>
            <p>Id: <b>{{ dataItem.Id }}</b></p>
            <p>{{ dataItem.Firma }}, {{ dataItem.Postleitzahl }} {{ dataItem.Ort }}</p>
        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox> -->