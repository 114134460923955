import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GruppenListeComponent } from './gruppen-liste/gruppen-liste.component';
import { BaumListeComponent } from './baum-liste/baum-liste.component';
import { GruppenZuweisungComponent } from './baum-liste/gruppen-zuweisung/gruppen-zuweisung.component';
import { GruppenComponent } from './gruppen.component';
import { GruppenRoutingModule } from './gruppen-routing.module';

import { InputsModule } from '@progress/kendo-angular-inputs';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AttributeModule } from '../attribute/attribute.module';
import { GruppenattributeListeComponent } from './gruppen-liste/gruppenattribute-liste/gruppenattribute-liste.component';
import { GruppenListeRoutingModule } from './gruppen-liste/gruppen-liste-routing.module';
import { GruppenartikelListeComponent } from './gruppen-liste/gruppenartikel-liste/gruppenartikel-liste.component';
//import { GroupTreeAssigmentService, GroupTreeAssigmentDataService, MzaApiServiceModule } from 'mza-api-service';
import { BaumAttributZuweisungComponent } from './baum-liste/baum-attribut-zuweisung/baum-attribut-zuweisung.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ContextMenuModule, ContextMenuComponent } from '@progress/kendo-angular-menu';
import { ArtikelGroupBindingDirective, GroupBindingDirective, GroupTreeAttributeDataBindingDirective } from 'mza-api-service';
import { ArtikelModule } from '../artikel/artikel.module';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { AssetsModule } from '../assets/assets.module';
import { KundenModule } from '../kunden/kunden.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HauptgruppenModule } from '../hauptgruppen/hauptgruppen.module';
import { GruppeOverviewComponent } from './gruppe-overview/gruppe-overview.component';
import { BaumGruppenZuweisungComponent } from './baum-liste/baum-gruppen-zuweisung/baum-gruppen-zuweisung.component';
import { WettbewerbModule } from '../kunden/wettbewerb/wettbewerb.module';
import { TreeViewGroupModule } from '../shared/components/tree-view-group/tree-view-group.module';
import { SplitButtonStatusModule } from '../shared/components/split-button-status/split-button-status.module';
import { ReportsModule } from '../reports/reports.module';
import { DashboardsModule } from '../dashboards/dashboards.module';

@NgModule({
  declarations: [GruppenListeComponent, BaumListeComponent, GruppenZuweisungComponent, GruppenComponent, GruppenattributeListeComponent, GruppenartikelListeComponent, BaumAttributZuweisungComponent, GroupBindingDirective, GroupTreeAttributeDataBindingDirective, GruppeOverviewComponent, BaumGruppenZuweisungComponent],
  imports: [
    CommonModule,
    ArtikelModule,
    AssetsModule,
    FlexLayoutModule,
    KundenModule,
    GruppenRoutingModule,
    GruppenListeRoutingModule,
    AttributeModule,
    TreeViewModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule, MatButtonModule, MatCheckboxModule,MatInputModule,MatBadgeModule,MatTabsModule,MatExpansionModule,ContextMenuModule,
    GridModule,LayoutModule,DropDownsModule, DialogsModule,HauptgruppenModule,TreeViewGroupModule,SplitButtonStatusModule,
    ReportsModule,DashboardsModule
    //MzaApiServiceModule
  ]
    ,
   /*  providers: [
    GroupTreeAssigmentService,
    GroupTreeAssigmentDataService
    ], */
  exports: [GruppenComponent, GruppeOverviewComponent]
})
export class GruppenModule { }
