import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input   } from '@angular/core';
import { Observable } from 'rxjs';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined } from 'util';
import { Artikel } from 'mza-api-service';
import { ArtikelService } from 'mza-api-service';


@Component({
  selector: 'mza-artikel-nr-multiselect',
  templateUrl: './artikel-nr-multiselect.component.html',
  styleUrls: ['./artikel-nr-multiselect.component.css']
})
export class ArtikelNrMultiselectComponent implements OnInit {

  @Input() values: Array<string>;
  @Input() disabled: boolean = false;
  @Output() brandIds = new EventEmitter<Array<Artikel>>();

  @ViewChild('artikelnrFilterMultiSelect') public artikelnrFilterMultiSelect: MultiSelectComponent;

  public data: Array<Artikel>;
  public selectedItems: Array<Artikel>;

  constructor(@Inject(ArtikelService) private artikelService: ArtikelService) {
    // this.artikelService.filterArtikel("").subscribe(res => this.data = res);
   }

   handleFilter(value) {
    //if (value.length >= 0) {
    // this.artikelService.filterArtikel(value).subscribe(res => this.data = res);
  //}else {
  //    this.markenFilter.toggle(false);
  //}
  }
   public onValueChange(value: Array<Artikel>) {
    if(isUndefined(value))
      this.brandIds.emit(null);
    else
      this.brandIds.emit(value);
}
  ngOnInit() {
/*     this.artikelService.filterArtikel(`${this.values}`).subscribe(res =>{
      this.data = res
       if(this.values && this.data.length == 1){
           //this.selectedItems = this.data[0];
    }
    } ); */
  }

}
