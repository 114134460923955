import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ODataEntitySetResource } from 'angular-odata';
import { AssetMetaData, MetaData, AssetMetaDataDataService, PropertyService, MetaDataService } from 'mza-api-service';
import { State } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-assets-meta-data',
  templateUrl: './assets-meta-data.component.html',
  styleUrls: ['./assets-meta-data.component.scss']
})
export class AssetsMetaDataComponent implements OnInit {

  @Input('assetId') assetId: number = null;
  public metaDataForm: FormGroup;
  public query : ODataEntitySetResource<any>;
  
  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};


  constructor(public _dataServiceAssetMetaData: AssetMetaDataDataService, 
    private _propertyService: PropertyService,
    private _metadataService: MetaDataService,
    private formBuilder: FormBuilder) { 
      this.createAssetMetaForm  = this.createAssetMetaForm.bind(this);
 
    }

    ngOnInit() {
      this.loadData();
      this.loadProperties()
     }
     ngOnChanges(changes: SimpleChanges): void {
       this.loadData();
     }

  public createAssetMetaForm(args: any): FormGroup  {
    let data  = <AssetMetaData>{ id: 0,
                         sortValue: 0,
                         statusBy: "",
                         statusAt: null,
                         statusId: null,
                         statusTypeId: null, 
                         assetId: 0,
                         metaDataId: 0,
                         metaData:  <MetaData>{ 
                           id: 0,
                           name: "",                        
                           description: "",
                           label: "",
                           typeId: 0
                         },
                   
                         value: "",
                         propertyId: 0
                         };
    const item : AssetMetaData = args.isNew ? data : args.dataItem;

    this.metaDataForm = this.formBuilder.group({
      id: item.id ,
      sortValue: [item.sortValue],   
      statusBy:  [item.statusBy],
      statusAt:  [item.statusAt],
      statusId:  [item.statusId],
      statusTypeId: [item.statusTypeId],
      assetId:  [item.assetId],
      metaDataId:  [item.metaDataId],
      metaData: this.formBuilder.group( {
        id: [item.metaData.id],
        name: [item.metaData.name],
        description: [item.metaData.description],
        label: [item.metaData.label],
        typeId: [item.metaData.typeId]
      }  )        
  });
  return this.metaDataForm;
}

private loadData() {
  if (isNullOrUndefined(this.assetId) )
    return;

    this.query = this._dataServiceAssetMetaData.getQuery();
    this.query.query.expand({metaData: {select: ["id","name", "description","label","typeId"]}});
    this.query.query.filter({assetId: this.assetId});
  
}
private loadProperties() {
  if (isNullOrUndefined(this.assetId) )
    return;

    this.query = this._propertyService.entities();
    this.query.query.expand({metaData: {select: ["id","name", "description","label","typeId"]}});
    this.query.query.filter({assetId: this.assetId});
  
}

}
