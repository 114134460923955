import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { KundenFilterComponent } from './kunden-filter.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
@NgModule({
  declarations: [KundenFilterComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DropDownsModule,
    FloatingLabelModule,InputsModule
  ],exports: [KundenFilterComponent]
})
export class KundenFilterModule { }
