import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelGroup } from './artikelgroup.entity';
//#endregion

export const ArtikelGroupConfig = {
  name: "ArtikelGroup",
  annotations: [],
  fields: {
    artikelId: {type: 'Edm.Int32'},
    groupId: {type: 'Edm.Int32'},
    artikelGroupFilterSettingsId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'},
    group: {type: 'entities.schema.Group', navigation: true, field: 'groupId', ref: 'id'},
    artikelGroupFilterSettings: {type: 'entities.articles.ArtikelGroupFilterSettings', navigation: true, field: 'artikelGroupFilterSettingsId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<ArtikelGroup>;