import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { WettbewerberPreisViewDto } from './wettbewerberpreisviewdto.entity';
//#endregion

export const WettbewerberPreisViewDtoConfig = {
  name: "WettbewerberPreisViewDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    name: {type: 'Edm.String'},
    artikelNr: {type: 'Edm.String'},
    artikelId: {type: 'Edm.Int32', nullable: false},
    wettbewerberId: {type: 'Edm.Int32'},
    preis: {type: 'Edm.Decimal', nullable: false},
    groupId: {type: 'Edm.Int32', nullable: false},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    statusTypeId: {type: 'Edm.Int32', nullable: false},
    statusId: {type: 'Edm.Int32', nullable: false}
  }
} as EntityConfig<WettbewerberPreisViewDto>;