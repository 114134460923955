import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { WettbewerberPreis } from './wettbewerberpreis.entity';
//#endregion

export const WettbewerberPreisConfig = {
  name: "WettbewerberPreis",
  annotations: [],
  fields: {
    artikelId: {type: 'Edm.Int32', nullable: false},
    wettbewerberId: {type: 'Edm.Int32'},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    groupId: {type: 'Edm.Int32'},
    preis: {type: 'Edm.Decimal', nullable: false},
    wettbewerberArtikelNr: {type: 'Edm.String'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    wettbewerber: {type: 'entities.schema.Wettbewerber', navigation: true, field: 'wettbewerberId', ref: 'id'},
    kunde: {type: 'services.Kunde', navigation: true},
    group: {type: 'entities.schema.Group', navigation: true, field: 'groupId', ref: 'id'},
    wettbewerberPreisGroups: {type: 'entities.schema.WettbewerberPreisGroup', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<WettbewerberPreis>;