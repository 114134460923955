import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { KundensortimentDto } from '../entities/produkte/kundensortimentdto.entity';
//#endregion

@Injectable()
export class KundensortimenteService extends ODataEntityService<KundensortimentDto> {
  constructor(protected client: ODataClient) {
    super(client, 'Kundensortimente', 'entities.produkte.KundensortimentDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public kundenzuordnung(): ODataFunctionResource<{kundenNr: number}, KundensortimentDto> {
    const resource = this.entities().function<{kundenNr: number}, KundensortimentDto>('services.Kundenzuordnung');
    return resource;
  }
  public registrierungszuordnung(): ODataFunctionResource<{regId: number}, KundensortimentDto> {
    const resource = this.entities().function<{regId: number}, KundensortimentDto>('services.Registrierungszuordnung');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
