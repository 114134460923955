import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gpv-asset-tree',
  templateUrl: './asset-tree.component.html',
  styleUrls: ['./asset-tree.component.css']
})
export class AssetTreeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
