import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Aktie } from './aktie.entity';
//#endregion

export const AktieConfig = {
  name: "Aktie",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    title: {type: 'Edm.String'},
    stooqId: {type: 'Edm.String'},
    isin: {type: 'Edm.String'},
    wkn: {type: 'Edm.String'},
    symbol: {type: 'Edm.String'},
    cusip: {type: 'Edm.String'},
    isWatching: {type: 'Edm.Boolean', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    groupAktien: {type: 'entities.boerse.GroupAktie', collection: true, navigation: true},
    tradegateKurse: {type: 'entities.boerse.TradegateKurs', collection: true, navigation: true},
    aktienTrends: {type: 'entities.boerse.AktienTrends', collection: true, navigation: true},
    kursziele: {type: 'entities.boerse.Kursziel', collection: true, navigation: true}
  }
} as EntityConfig<Aktie>;