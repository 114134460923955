import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ArtikelNrComponent } from './artikel-nr.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ArtikelBildModule } from '../artikel-bild/artikel-bild.module';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
@NgModule({
  declarations: [ArtikelNrComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DropDownsModule,
    ArtikelBildModule,
    FloatingLabelModule,InputsModule
  ],exports: [ArtikelNrComponent]
})
export class ArtikelNrModule { }
