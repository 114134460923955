import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BookingTimes } from './bookingtimes.entity';
//#endregion

export const BookingTimesConfig = {
  name: "BookingTimes",
  annotations: [],
  fields: {
    start: {type: 'Edm.TimeOfDay', nullable: false},
    end: {type: 'Edm.TimeOfDay', nullable: false},
    day: {type: 'Edm.DateTimeOffset'},
    weekDay: {type: 'Edm.Int32'},
    allowCustomTime: {type: 'Edm.Boolean', nullable: false},
    firmaId: {type: 'Edm.Int32'},
    typeId: {type: 'Edm.Int32'},
    customTimeRange: {type: 'Edm.Int32', nullable: false},
    minTime: {type: 'Edm.Int32', nullable: false},
    maxTime: {type: 'Edm.Int32', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    bookingObjects: {type: 'entities.schema.BookingObject', collection: true, navigation: true},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    type: {type: 'entities.ref.Type', navigation: true, field: 'typeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<BookingTimes>;