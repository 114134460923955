import { Component, OnInit } from '@angular/core';
import { HerstellerDataService , Hersteller, DataResult} from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {  ODataOptions }from 'angular-odata';
@Component({
  selector: 'gpv-hersteller',
  templateUrl: './hersteller.component.html',
  styleUrls: ['./hersteller.component.css']
})
export class HerstellerComponent implements OnInit {

  public herstellerForm: FormGroup;
  public herstellerListe: Observable<DataResult>;
  //private query: ODataOptions;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};


constructor(public _dataServiceHersteller: HerstellerDataService,
  private formBuilder: FormBuilder) { 
   this.createHerstellerForm  = this.createHerstellerForm.bind(this);
  
  }

  ngOnInit() {
  }

  public createHerstellerForm(args: any): FormGroup  {
    let data  = <Hersteller>{ herstellerID: 0,
                        //  description: "",
                        bezeichnung: "",
    /*                      value: "",
                         sortValue: 0,
                         status: null */
              
                         };
    const item : Hersteller = args.isNew ? data : args.dataItem;

    this.herstellerForm = this.formBuilder.group({
      id: item.herstellerID ,
      bezeichnung: [item.bezeichnung, [Validators.maxLength(40), Validators.required] ],
/*       description: [item.description, [Validators.maxLength(250)] ],
      value: [item.value, [Validators.maxLength(40)] ],
      sortValue: [item.sortValue],   
      status: [item.status],  */           
   
  });
  return this.herstellerForm;
}

public saveChanges(grid: any): void {
  this._dataServiceHersteller.saveChanges(this.state);
}

public cancelChanges(grid: any): void {
  this._dataServiceHersteller.cancelChanges();
}

}
