import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { AssetMetaData } from '../entities/assets/assetmetadata.entity';
import { Beigabe } from '../entities/produkte/beigabe.entity';
import { Property } from '../entities/ref/property.entity';
import { Type } from '../entities/ref/type.entity';
//#endregion

@Injectable()
export class PropertyService extends ODataEntityService<Property> {
  constructor(protected client: ODataClient) {
    super(client, 'Property', 'entities.ref.Property');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public assetMetaDataList(entity: EntityKey<Property>): ODataNavigationPropertyResource<AssetMetaData> {
    return this.entity(entity).navigationProperty<AssetMetaData>('assetMetaDataList');
  }
  public beigaben(entity: EntityKey<Property>): ODataNavigationPropertyResource<Beigabe> {
    return this.entity(entity).navigationProperty<Beigabe>('beigaben');
  }
  public type(entity: EntityKey<Property>): ODataNavigationPropertyResource<Type> {
    return this.entity(entity).navigationProperty<Type>('type');
  }
  //#endregion
}
