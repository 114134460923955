import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gpv-kunden-zuweisung',
  templateUrl: './kunden-zuweisung.component.html',
  styleUrls: ['./kunden-zuweisung.component.css']
})
export class KundenZuweisungComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
