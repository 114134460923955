import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Status, StatusService,StatusGroupTreeService,StatusGroupTree } from 'mza-api-service';

@Component({
  selector: 'split-button-status',
  templateUrl: './split-button-status.component.html',
  styleUrls: ['./split-button-status.component.css']
})
export class SplitButtonStatusComponent implements OnInit, OnChanges {

  @Input() typeStatusId : number = 0;
  @Input() groupTreeId : number = null;
  constructor(private statusService: StatusService,
              private statusGroupTreeService:StatusGroupTreeService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadTreeStatus();
  }
  public valueZoom:boolean = false;
  public data: Array<Status> = [];
  public dataStatusGroupTree: Array<StatusGroupTree> = [];
 /*  public data: Array<any> = [{
      text: 'Save as',
    value: false,
    click:  () => { this.valueZoom = !this.valueZoom ; }
  }, {
      text: 'Upload to drive',
    value: false,
  }]; */
  ngOnInit(): void {
    let regStatus = this.statusService.entities();
    regStatus.query.filter({typeId: this.typeStatusId});
    regStatus.get().subscribe(({entities, meta}) => this.data = entities);
    this.loadTreeStatus();
  
  }

  private loadTreeStatus(){
    let regStatusTreeGroup = this.statusGroupTreeService.entities();
    regStatusTreeGroup.query.filter({groupTreeId: this.groupTreeId});
    regStatusTreeGroup.get().subscribe(({entities, meta}) => this.dataStatusGroupTree = entities); 
  }

  public isStatusSet(id: number):boolean{
    return this.dataStatusGroupTree.findIndex(x => x.statusId === id) >= 0 ? true : false;
  }

  public setStatus(id: number){
    console.log("setStatus", id);
    let idx = this.dataStatusGroupTree.findIndex(x => x.statusId === id) ;
   if( idx >= 0){
    this.statusGroupTreeService.destroy({id: this.dataStatusGroupTree[idx].id}).subscribe((result) => this.dataStatusGroupTree.splice(idx,1));
   }else{
      this.statusGroupTreeService.create({groupTreeId: this.groupTreeId,statusId: id})
      .subscribe((entity) => {
        //odata-meta noch als property enthalten
        this.dataStatusGroupTree.push(entity);
      });
   }
 
  }

}
