import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BezeichnungDto } from './bezeichnungdto.entity';
//#endregion

export const BezeichnungDtoConfig = {
  name: "BezeichnungDto",
  annotations: [],
  fields: {
    value: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<BezeichnungDto>;