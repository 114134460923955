import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeViewGroupComponent } from './tree-view-group.component';
import { TreeViewModule, ExpandStateService, NodeChildrenService,SelectionService ,TreeViewLookupService, NavigationService, DataChangeNotificationService } from '@progress/kendo-angular-treeview';
import { MultiSelectModule  } from '@progress/kendo-angular-dropdowns';
import { ButtonModule, ButtonGroupModule, SplitButtonModule } from '@progress/kendo-angular-buttons';
import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@NgModule({
  declarations: [TreeViewGroupComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TreeViewModule,
    DialogsModule,
    MultiSelectModule,
    ButtonModule,
    ButtonGroupModule,
    SplitButtonModule,
    ContextMenuModule
  ],providers:[ExpandStateService,NavigationService,NodeChildrenService,SelectionService,TreeViewLookupService,LocalizationService, { provide: L10N_PREFIX, useValue: '' },DataChangeNotificationService]
  ,exports:[TreeViewGroupComponent]
})
export class TreeViewGroupModule { }
