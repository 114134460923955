import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { StatusType } from './statustype.entity';
//#endregion

export const StatusTypeConfig = {
  name: "StatusType",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    statusList: {type: 'entities.settings.Status', collection: true, navigation: true}
  }
} as EntityConfig<StatusType>;