import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Imageformat } from './imageformat.entity';
//#endregion

export const ImageformatConfig = {
  name: "Imageformat",
  annotations: [],
  fields: {
    title: {type: 'Edm.String'},
    isCustomFormat: {type: 'Edm.Boolean', nullable: false},
    bpp: {type: 'Edm.Int32', nullable: false},
    horizontalResolution: {type: 'Edm.Double', nullable: false},
    verticalResolution: {type: 'Edm.Double', nullable: false},
    pixelX: {type: 'Edm.Int32', nullable: false},
    pixelY: {type: 'Edm.Int32', nullable: false},
    dpi: {type: 'Edm.Int32', nullable: false},
    colorDepth: {type: 'Edm.Int32', nullable: false},
    megapixel: {type: 'Edm.Int32', nullable: false},
    pixel: {type: 'Edm.Int32', nullable: false},
    quality: {type: 'Edm.Int32', nullable: false},
    colorSpace: {type: 'Edm.String'},
    compression: {type: 'Edm.String'},
    units: {type: 'Edm.String'},
    format: {type: 'Edm.String'},
    interlace: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assets: {type: 'entities.assets.Asset', collection: true, navigation: true}
  }
} as EntityConfig<Imageformat>;