import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BookingViewDto } from './bookingviewdto.entity';
//#endregion

export const BookingViewDtoConfig = {
  name: "BookingViewDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    userId: {type: 'Edm.Int32', nullable: false},
    tokenEinladung: {type: 'Edm.String'},
    title: {type: 'Edm.String'},
    firma: {type: 'Edm.String'},
    firmaImageUrl: {type: 'Edm.String'},
    person: {type: 'Edm.String'},
    start: {type: 'Edm.DateTimeOffset', nullable: false},
    end: {type: 'Edm.DateTimeOffset', nullable: false},
    occupiedCapacity: {type: 'Edm.Int32', nullable: false},
    freeCapacity: {type: 'Edm.Int32', nullable: false},
    bookingObjectId: {type: 'Edm.Int32', nullable: false},
    bookingTimesId: {type: 'Edm.Int32', nullable: false},
    isBooked: {type: 'Edm.Boolean', nullable: false}
  }
} as EntityConfig<BookingViewDto>;