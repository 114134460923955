import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { ArtikelBezeichnungAufbauView } from '../entities/articles/artikelbezeichnungaufbauview.entity';
import { BezeichnungDto } from '../Mza/Entities/Dtos/bezeichnungdto.entity';
//#endregion

@Injectable()
export class ArtikelbezeichnungsaufbauService extends ODataEntityService<ArtikelBezeichnungAufbauView> {
  constructor(protected client: ODataClient) {
    super(client, 'Artikelbezeichnungsaufbau', 'entities.articles.ArtikelBezeichnungAufbauView');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getHauptwort(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetHauptwort');
    return resource;
  }
  public getSpezifikation(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetSpezifikation');
    return resource;
  }
  public getColor(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetColor');
    return resource;
  }
  public getMaterial(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetMaterial');
    return resource;
  }
  public getOberflaeche(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetOberflaeche');
    return resource;
  }
  public getDin(): ODataFunctionResource<null, BezeichnungDto> {
    const resource = this.entities().function<null, BezeichnungDto>('services.GetDin');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
