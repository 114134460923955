import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelPreisVk } from './artikelpreisvk.entity';
//#endregion

export const ArtikelPreisVkConfig = {
  name: "ArtikelPreisVk",
  annotations: [],
  fields: {
    vkId: {type: 'Edm.Int32', nullable: false},
    artikelId: {type: 'Edm.Int32'},
    artikelNr: {type: 'Edm.String'},
    bezeichnung: {type: 'Edm.String'},
    stueckpreis: {type: 'Edm.Decimal'},
    sonderpreis: {type: 'Edm.Decimal'},
    sonderpreisVon: {type: 'Edm.DateTimeOffset'},
    sonderpreisBis: {type: 'Edm.DateTimeOffset'},
    alterpreis: {type: 'Edm.Decimal'},
    preisDatum: {type: 'Edm.DateTimeOffset', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<ArtikelPreisVk>;