import { Component, OnInit, Input } from '@angular/core';
import { Collision } from '@progress/kendo-angular-popup';
import { ShowOption } from '@progress/kendo-angular-tooltip/dist/es2015/models/show.option.type';

@Component({
  selector: 'gpv-asset-image',
  templateUrl: './asset-image.component.html',
  styleUrls: ['./asset-image.component.css']
})
export class AssetImageComponent implements OnInit {
  @Input('filename') fileName: string ="";  
  @Input('assetsize') assetSize: number =1;  
  @Input('showOption') showOption: ShowOption = "click";
  private collision: Collision = { horizontal: 'flip', vertical: 'fit' };
  constructor() { }

  ngOnInit() {
  }

}
