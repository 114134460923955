import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { CustomerViewDto } from './customerviewdto.entity';
//#endregion

export const CustomerViewDtoConfig = {
  name: "CustomerViewDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    groupId: {type: 'Edm.Int32', nullable: false},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    name: {type: 'Edm.String'},
    statusTypeId: {type: 'Edm.Int32', nullable: false},
    statusId: {type: 'Edm.Int32', nullable: false}
  }
} as EntityConfig<CustomerViewDto>;