import { NgModule } from '@angular/core';

//#region ODataApi Imports
import { ContainerService } from './services/container.service';
import { AktieService } from './services/aktie.service';
import { AktienTrendsService } from './services/aktientrends.service';
import { AnalystService } from './services/analyst.service';
import { GroupAktieService } from './services/groupaktie.service';
import { KurszielService } from './services/kursziel.service';
import { TageslinieService } from './services/tageslinie.service';
import { TradegateKursService } from './services/tradegatekurs.service';
import { ArtikelAssetService } from './services/artikelasset.service';
import { ArtikelAttributeService } from './services/artikelattribute.service';
import { ArtikelGroupFilterSettingsService } from './services/artikelgroupfiltersettings.service';
import { ArtikelGroupService } from './services/artikelgroup.service';
import { AssetService } from './services/asset.service';
import { ArtikelService } from './services/artikel.service';
import { ArtikelbezeichnungsaufbauService } from './services/artikelbezeichnungsaufbau.service';
import { BezeichnungDtoService } from './services/bezeichnungdto.service';
import { ArtikelbezeichnungsgeneratorBausteineService } from './services/artikelbezeichnungsgeneratorbausteine.service';
import { ArtikelbezeichnungsgeneratorService } from './services/artikelbezeichnungsgenerator.service';
import { WarengruppenService } from './services/warengruppen.service';
import { AssetGroupMetaDataService } from './services/assetgroupmetadata.service';
import { AssetGroupService } from './services/assetgroup.service';
import { AssetGroupTreeAssigmentService } from './services/assetgrouptreeassigment.service';
import { AssetGroupTreeMetaDataService } from './services/assetgrouptreemetadata.service';
import { AssetGroupTreeService } from './services/assetgrouptree.service';
import { AssetMetaDataService } from './services/assetmetadata.service';
import { AssetViewDtoService } from './services/assetviewdto.service';
import { DocumentformatService } from './services/documentformat.service';
import { ImageformatService } from './services/imageformat.service';
import { VideoformatService } from './services/videoformat.service';
import { MetaDataService } from './services/metadata.service';
import { BestellungService } from './services/bestellung.service';
import { CustomerAssetGroupService } from './services/customerassetgroup.service';
import { CustomerFilterService } from './services/customerfilter.service';
import { CustomerFilterResultService } from './services/customerfilterresult.service';
import { CustomerGroupService } from './services/customergroup.service';
import { FirmaService } from './services/firma.service';
import { KundengruppeService } from './services/kundengruppe.service';
import { KundenService } from './services/kunden.service';
import { WettbewerberPreisViewDtoService } from './services/wettbewerberpreisviewdto.service';
import { KundenFilterDtoService } from './services/kundenfilterdto.service';
import { KundensegmentService } from './services/kundensegment.service';
import { PersonService } from './services/person.service';
import { FirmaPersonenDtoService } from './services/firmapersonendto.service';
import { RegistrationService } from './services/registration.service';
import { RegistrierungenService } from './services/registrierungen.service';
import { RegistrationFilterDtoService } from './services/registrationfilterdto.service';
import { BeigabenArtikelService } from './services/beigabenartikel.service';
import { BeigabenAusloeserService } from './services/beigabenausloeser.service';
import { BeigabenKundeService } from './services/beigabenkunde.service';
import { BeigabenService } from './services/beigaben.service';
import { HerstellerService } from './services/hersteller.service';
import { MarkenService } from './services/marken.service';
import { MarkenFilterDtoService } from './services/markenfilterdto.service';
import { ArtikelPreisService } from './services/artikelpreis.service';
import { ArtikelPreisVkService } from './services/artikelpreisvk.service';
import { KalkulationsGruppeService } from './services/kalkulationsgruppe.service';
import { ProduktTypAttributeService } from './services/produkttypattribute.service';
import { ProduktTypGroupService } from './services/produkttypgroup.service';
import { ProduktTypService } from './services/produkttyp.service';
import { KundensortimenteService } from './services/kundensortimente.service';
import { KundensortimentDtoService } from './services/kundensortimentdto.service';
import { SortimenteService } from './services/sortimente.service';
import { PropertyService } from './services/property.service';
import { TypeService } from './services/type.service';
import { BookingService } from './services/booking.service';
import { BookingViewDtoService } from './services/bookingviewdto.service';
import { BookingObjectService } from './services/bookingobject.service';
import { BookingTimesService } from './services/bookingtimes.service';
import { AttributeService } from './services/attribute.service';
import { GroupAttributeService } from './services/groupattribute.service';
import { GroupService } from './services/group.service';
import { GroupTreeAssigmentService } from './services/grouptreeassigment.service';
import { GroupTreeAttributeService } from './services/grouptreeattribute.service';
import { GroupTreeService } from './services/grouptree.service';
import { MaingroupAttributeService } from './services/maingroupattribute.service';
import { MainGroupService } from './services/maingroup.service';
import { StatusGroupTreeService } from './services/statusgrouptree.service';
import { StatusService } from './services/status.service';
import { StatusTypeService } from './services/statustype.service';
import { WettbewerberService } from './services/wettbewerber.service';
import { WettbewerberPreisGroupService } from './services/wettbewerberpreisgroup.service';
import { WettbewerberPreisService } from './services/wettbewerberpreis.service';
import { CustomerViewDtoService } from './services/customerviewdto.service';
//#endregion

@NgModule({
  providers: [
    ContainerService,
    AktieService,
    AktienTrendsService,
    AnalystService,
    GroupAktieService,
    KurszielService,
    TageslinieService,
    TradegateKursService,
    ArtikelAssetService,
    ArtikelAttributeService,
    ArtikelGroupFilterSettingsService,
    ArtikelGroupService,
    AssetService,
    ArtikelService,
    ArtikelbezeichnungsaufbauService,
    BezeichnungDtoService,
    ArtikelbezeichnungsgeneratorBausteineService,
    ArtikelbezeichnungsgeneratorService,
    WarengruppenService,
    AssetGroupMetaDataService,
    AssetGroupService,
    AssetGroupTreeAssigmentService,
    AssetGroupTreeMetaDataService,
    AssetGroupTreeService,
    AssetMetaDataService,
    AssetViewDtoService,
    DocumentformatService,
    ImageformatService,
    VideoformatService,
    MetaDataService,
    BestellungService,
    CustomerAssetGroupService,
    CustomerFilterService,
    CustomerFilterResultService,
    CustomerGroupService,
    FirmaService,
    KundengruppeService,
    KundenService,
    WettbewerberPreisViewDtoService,
    KundenFilterDtoService,
    KundensegmentService,
    PersonService,
    FirmaPersonenDtoService,
    RegistrationService,
    RegistrierungenService,
    RegistrationFilterDtoService,
    BeigabenArtikelService,
    BeigabenAusloeserService,
    BeigabenKundeService,
    BeigabenService,
    HerstellerService,
    MarkenService,
    MarkenFilterDtoService,
    ArtikelPreisService,
    ArtikelPreisVkService,
    KalkulationsGruppeService,
    ProduktTypAttributeService,
    ProduktTypGroupService,
    ProduktTypService,
    KundensortimenteService,
    KundensortimentDtoService,
    SortimenteService,
    PropertyService,
    TypeService,
    BookingService,
    BookingViewDtoService,
    BookingObjectService,
    BookingTimesService,
    AttributeService,
    GroupAttributeService,
    GroupService,
    GroupTreeAssigmentService,
    GroupTreeAttributeService,
    GroupTreeService,
    MaingroupAttributeService,
    MainGroupService,
    StatusGroupTreeService,
    StatusService,
    StatusTypeService,
    WettbewerberService,
    WettbewerberPreisGroupService,
    WettbewerberPreisService,
    CustomerViewDtoService
  ]
})
export class OdataServicesModule { }