<p>Artikelnummern: </p>
<kendo-multiselect #artikelnrFilterMultiSelect
[data]="data"
[textField]="'ArtikelNrValue'"
[valueField]="'ArtikelNrValue'"
(valueChange)="onValueChange($event)"
[(ngModel)]="selectedItems"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Artikel wählen...'"
[disabled]="disabled"
>

<ng-template kendoComboBoxItemTemplate  let-dataItem>
    <div>
        <p>Id: <b>{{ dataItem.Artikelnr }}</b></p>
        <p>{{ dataItem.Firma }}, {{ dataItem.Ort }}</p>
    </div>
</ng-template>
<ng-template kendoDropDownListNoDataTemplate>
    <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>

</ng-template>
</kendo-multiselect>
