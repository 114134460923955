import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupTreeMetaData } from '../entities/assets/assetgrouptreemetadata.entity';
import { AssetGroupTree } from '../entities/assets/assetgrouptree.entity';
import { MetaData } from '../entities/assets/metadata.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class AssetGroupTreeMetaDataService extends ODataEntityService<AssetGroupTreeMetaData> {
  constructor(protected client: ODataClient) {
    super(client, 'AssetGroupTreeMetaData', 'entities.assets.AssetGroupTreeMetaData');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public assetGroupTree(entity: EntityKey<AssetGroupTreeMetaData>): ODataNavigationPropertyResource<AssetGroupTree> {
    return this.entity(entity).navigationProperty<AssetGroupTree>('assetGroupTree');
  }
  public metaData(entity: EntityKey<AssetGroupTreeMetaData>): ODataNavigationPropertyResource<MetaData> {
    return this.entity(entity).navigationProperty<MetaData>('metaData');
  }
  public status(entity: EntityKey<AssetGroupTreeMetaData>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<AssetGroupTreeMetaData>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
