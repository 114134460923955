// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//ALTIVO
/* export const environment = {
  production: false,
  auth_service_url: 'https://login.auth-me.de',
   rest_service_url: 'https://api.altivo.de/v1',
   asset_service_url: 'https://asset.altivo.de'

}; */
//VAWAGO
/* export const environment = {
  production: false,
  auth_service_url: 'https://login.auth-me.de',
   rest_service_url: 'https://api.vawago.de/v1',
   asset_service_url: 'https://asset.vawago.de'

}; */
//TT_COACH
export const environment = {
  production: false,
  auth_service_url: 'https://login.auth-me.de',
   rest_service_url: 'https://api.tt-coach.de/v1',
   asset_service_url: 'https://asset.tt-coach.de'

};
//LOCALHOST
/* export const environment = {
  production: false,
  rest_service_url: 'https://localhost:44370/v1',
  auth_service_url: 'https://login.auth-me.de',
 asset_service_url: 'https://localhost:44396'
};
 */
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
