import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Firma } from './firma.entity';
//#endregion

export const FirmaConfig = {
  name: "Firma",
  annotations: [],
  fields: {
    firmaName: {type: 'Edm.String'},
    interneNr: {type: 'Edm.String'},
    firmierungId: {type: 'Edm.Int32'},
    isAndereFirmierung: {type: 'Edm.Boolean', nullable: false},
    andereFirmierung: {type: 'Edm.String'},
    umsatzSteuerNummer: {type: 'Edm.String'},
    lieferAdresseGleichRechnungsAdresse: {type: 'Edm.Boolean', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firmaAdresse: {type: 'services.FirmaAdresse', collection: true, navigation: true}
  }
} as EntityConfig<Firma>;