import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { KundenNrComponent } from './kunden-nr.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';

@NgModule({
  declarations: [KundenNrComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,InputsModule,
    DropDownsModule
  ],exports: [KundenNrComponent]
})
export class KundenNrModule { }
