import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MarkenService, ArtikelService, WarengruppenService, ArtikelGroupFilterSettingsService, ArtikelGroupFilterSettings, MarkeDto, Artikel, WarengruppeDto, DataArrayConvert, Hersteller, ProduktTyp, ProduktTypService, HerstellerService } from 'mza-api-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-artikel-gruppen-filter-settings',
  templateUrl: './artikel-gruppen-filter-settings.component.html',
  styleUrls: ['./artikel-gruppen-filter-settings.component.scss']
})
export class ArtikelGruppenFilterSettingsComponent implements OnInit {
  
  public dataGroupFilter: ArtikelGroupFilterSettings;
  public dataMarken: MarkeDto[];
  public dataArtikel: Artikel[];
  public dataHersteller: Hersteller[];
  public dataProduktTypen: ProduktTyp[];
  @Input() groupId : number;
  @Output("settingsChange") settingsChange = new EventEmitter<boolean>(); 

  public warengruppennummern: string[] =[];
  public filterSettingForm: FormGroup;

  constructor( 
    public markenService: MarkenService, 
    public artikelService: ArtikelService, 
    public produktTypService: ProduktTypService, 
    public herstellerService: HerstellerService, 
    public artikelGroupFilterService: ArtikelGroupFilterSettingsService,
    private formBuilder: FormBuilder) 
    {
     this.createFilterForm(null);
   }

   public createFilterForm(args: ArtikelGroupFilterSettings): FormGroup  {
    let data  = <ArtikelGroupFilterSettings>{ id: 0,                  
                       //  status: 0,
                         filterArtikel: "",
                         filterArtikelRemoved: "",
                         filterMarken: "",
                         filterHersteller: "",
                         filterProduktTypen: "",
                         createdBy:"",
                         groupId: this.groupId};
    const item : ArtikelGroupFilterSettings = isNullOrUndefined(args)  ? data : args;
  
    this.filterSettingForm = this.formBuilder.group({
      id: item.id ,
    //  status: [item.status],           
      groupId: [item.groupId] ,
      createdBy:[item.createdBy],
      filterArtikel: [DataArrayConvert.ConvertStringToIntList(item.filterArtikel)],
      filterArtikelRemoved: [DataArrayConvert.ConvertStringToIntList(item.filterArtikelRemoved)],
      filterMarken: [DataArrayConvert.ConvertStringToIntList(item.filterMarken)],
      filterHersteller: [DataArrayConvert.ConvertStringToIntList(item.filterHersteller)],
      filterProduktTypen: [DataArrayConvert.ConvertStringToIntList(item.filterProduktTypen)]

  });
  return this.filterSettingForm;
  }

  ngOnInit() {
    if(this.groupId  !== 0){

      let data = this.markenService.entities();
      data.query.select(["id","name"]);
      data.get().subscribe(({entities, meta}) =>{
        this.dataMarken = entities;

        
    });

 let dataA = this.artikelService.entities();
 //dataA.filter({imShopSichtbar: true});
      dataA.query.select(["id","artikelNr"]);
      dataA.query.filter({imShopSichtbar: true});
      dataA.get().subscribe(({entities, meta}) =>{
        this.dataArtikel = entities;
    });

    let odataH = this.herstellerService.entities();
    
    odataH.query.select(["bezeichnung","herstellerID"]);
    odataH.get().subscribe(({entities, meta}) =>{
           this.dataHersteller = entities;
       });

       let odataProduktTypen = this.produktTypService.entities();
    
       odataProduktTypen.select(["name","id"]);
       odataProduktTypen.get().subscribe(({entities, meta}) =>{
              this.dataProduktTypen =entities;
          });


  }
}

ngOnChanges(changes: SimpleChanges): void {
  this.loadData();
}

private loadData() {
  if (!isNullOrUndefined(this.groupId) ){
  //FilterSettings:

    let dataFilter = this.artikelGroupFilterService.entities();
    dataFilter.query.filter({GroupId: this.groupId});
    dataFilter.query.top(1);
    dataFilter.query.select(["id","createdBy","filterMarken","filterArtikel","filterArtikelRemoved","filterHersteller"]);
    dataFilter.get().subscribe(({entities, meta}) =>{
             this.dataGroupFilter = entities[0];
             this.createFilterForm( this.dataGroupFilter);
        });
  }
}

public onWgNrChange(wgNr: string[]){
  this.filterSettingForm.patchValue({filterWarengruppen: wgNr});
}

public onGeneratorClick(e){
  //const result: ArtikelGroupFilterSettings = Object.assign({}, this.filterSettingForm.value);
  let formData = Object.assign({});
 formData = Object.assign(formData, this.filterSettingForm.value);
 
  let result: ArtikelGroupFilterSettings = this.filterSettingForm.value;
 
    result.filterMarken = DataArrayConvert.ConvertArrayToString(formData.filterMarken);
    result.filterHersteller = DataArrayConvert.ConvertArrayToString(formData.filterHersteller);
    result.filterProduktTypen = DataArrayConvert.ConvertArrayToString(formData.filterProduktTypen);
    result.filterArtikel = DataArrayConvert.ConvertArrayToString(formData.filterArtikel);
    result.filterArtikelRemoved = DataArrayConvert.ConvertArrayToString(formData.filterArtikelRemoved);
   
    result.groupId = this.groupId;
  //result.filterArtikel = result.filterArtikel.join();
  if(result.id === 0){
   // delete result.id;
    this.artikelGroupFilterService.create(result).subscribe(
      r => {
        this.dataGroupFilter= r[0];
        this.filterSettingForm.controls['id'].patchValue(r[0].id);
        alert("saved");
        this.loadData();
        this.settingsChange.emit(true);
      }
    )
  }else{
    this.artikelGroupFilterService.assign({id: result.id},result).subscribe(
      (r) => {
        console.log("update settings");
        this.settingsChange.emit(true);
      }//alert("updated")
     
    )
  }
  
}

}
