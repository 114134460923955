import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaumListeComponent } from './baum-liste/baum-liste.component';
import { GruppenListeComponent } from './gruppen-liste/gruppen-liste.component';
import { GruppenattributeListeComponent } from './gruppen-liste/gruppenattribute-liste/gruppenattribute-liste.component';
import { GruppenartikelListeComponent } from './gruppen-liste/gruppenartikel-liste/gruppenartikel-liste.component';
import { GruppeOverviewComponent } from './gruppe-overview/gruppe-overview.component';
import { GruppenComponent } from './gruppen.component';
import { AuthGuard } from 'mza-auth-service';

const routes: Routes = [
  {
    path: 'gruppen', component: GruppenComponent,
    children: [{
      path: 'hauptgruppe/:id',
      component: GruppeOverviewComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'baum-ansicht',
          component: BaumListeComponent, canActivate: [AuthGuard]

        }, {
          path: 'listen-ansicht',
          component: GruppenListeComponent, canActivate: [AuthGuard]

        },
        {
          path: 'listen-ansicht/:id',
          component: GruppenListeComponent, canActivate: [AuthGuard],
          children: [
            {
              path: 'attributes',
              component: GruppenattributeListeComponent, canActivate: [AuthGuard]
            }, {
              path: 'artikelliste',
              component: GruppenartikelListeComponent, canActivate: [AuthGuard]
            }
          ]
        }
      ]
    }
    ]
  
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GruppenRoutingModule { }
