import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelFieldComponent } from './label-field/label-field.component';

@NgModule({
  declarations: [LabelFieldComponent],
  imports: [
    CommonModule
  ],exports: [LabelFieldComponent]
})
export class LabelsModule { }
