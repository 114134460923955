import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthOidcService } from '../../../auth-oidc/auth/auth-oidc.service';

@Component({
  selector: 'lib-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  constructor(private location: Location, private authService: AuthOidcService) { }

  ngOnInit() {
  }
  login() {
    this.authService.login();
   
}
logout() {
  this.authService.logout();
}
goback() {
    this.location.back();
}

public isUserisAuthorized(): boolean{
  return this.authService.isAuthorized;
}
}
