import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainGroupService , Maingroup} from 'mza-api-service';
import { DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'gpv-gruppen',
  templateUrl: './gruppen.component.html',
  styleUrls: ['./gruppen.component.css']
})
export class GruppenComponent implements OnInit {
  public selectedGroupName = '';
  public maingroupId: number;
  public expanded = true;
  //public items: Array<any> = []; 
  public items: Array<any> = [
    { separator: true }
];
  constructor(private route: ActivatedRoute,  private router: Router,
    private _mainGroupService: MainGroupService) { 
      
  }

  ngOnInit() {
   let reqMaingroup = this._mainGroupService.entities();
   reqMaingroup.query.filter({isSystemRelevant: false});
   reqMaingroup.get().subscribe(
      ({entities,meta})=> {
        entities.forEach(result => {
          this.items.push({
              text: result.name,
            //  icon: result.icon,
              path: result.id ? '/gruppen/hauptgruppe/' + result.id : ''
          });
        });
      }
    );

/*     this.route.params.subscribe(params => {
      this.maingroupId = +params['id']; // (+) converts string 'id' to a number
   }); */
  }
  public onSelect(ev: DrawerSelectEvent): void {
    
    this.selectedGroupName = ev.item.text;
    this.maingroupId = ev.item.path;

   // this.router.navigate([ 'hauptgruppe/'+this.maingroupId]);
}

public switchExpanded(): void {
  this.expanded = !this.expanded;
}
}
