import { Directive, OnInit, OnDestroy, Injectable, Inject, forwardRef, Input } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import {   KalkulationsGruppeDataService } from '../data-services';
import { ODataBindingDirective } from './core/o-data-binding-directive';
import { ODataEntitySetResource } from 'angular-odata';

@Injectable()
@Directive({
  selector: '[kalkulationsGruppeDataBinding]'
})
export class KalkulationsGruppeDataBindingDirective extends ODataBindingDirective  {

  @Input('kalkulationsGruppeDataBinding') query:  ODataEntitySetResource<any>;
    
    constructor(@Inject(forwardRef(() =>KalkulationsGruppeDataService)) dataService: KalkulationsGruppeDataService, @Inject(forwardRef(() => GridComponent))  grid: GridComponent) {
        super(dataService,grid);
    }
    public ngOnChanges( c: any ) : void {
 
        console.log( "kalkulationsGruppe-Query:", this.query);
        this.rebind();
    }

}
