import { Component, OnInit } from '@angular/core';
import { Artikel, ArtikelService } from 'mza-api-service';

@Component({
  selector: 'artikel-nr',
  templateUrl: './artikel-nr.component.html',
  styleUrls: ['./artikel-nr.component.css']
})
export class ArtikelNrComponent implements OnInit {

  constructor( public artikelService: ArtikelService) { }

  public dataArtikelListe: Artikel[]= [];
  ngOnInit(): void {
    this.loadData();
  }

  private loadData(){
    let dataA = this.artikelService.entities();
    //dataA.filter({imShopSichtbar: true});
         dataA.query.select(["id","artikelNr"]);
         dataA.query.filter({imShopSichtbar: true});
         dataA.get().subscribe(({entities, meta}) =>{
           this.dataArtikelListe = entities;
       });
   
  }

}
