import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthOidcService } from '../../auth-oidc/auth/auth-oidc.service';
import { AuthOidcAccountRoutingModule } from './auth-oidc-account.routing-module';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [ UnauthorizedComponent, LoginComponent],
  imports: [
    CommonModule,
    AuthOidcAccountRoutingModule,
    AuthModule.forRoot()
  ],  
  providers: [
    AuthOidcService,
    OidcSecurityService,
  ],
  exports:[UnauthorizedComponent,LoginComponent]
})
export class AuthOidcAccountModule { }
