import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State, process } from '@progress/kendo-data-query';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupAttribute, GroupAttributeDataService, Attribute } from 'mza-api-service';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-gruppenattribute-liste',
  templateUrl: './gruppenattribute-liste.component.html',
  styleUrls: ['./gruppenattribute-liste.component.css']
})
export class GruppenattributeListeComponent implements OnInit {

  public groupAttributeForm: FormGroup;
  public gruppen: Observable<GridDataResult>;

  @Input()  groupId : number;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};
  constructor(  public _dataServiceGroups: GroupAttributeDataService,
    private formBuilder: FormBuilder,private router: Router, private route: ActivatedRoute) {
   // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.createGroupForm  = this.createGroupForm.bind(this);
   }

  ngOnInit() {
    //this.groupId = +this.route.parent.snapshot.paramMap.get( "id" );
    this.loadData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }
  private loadData() {
    if (!isNullOrUndefined(this.groupId) ){

      let reqData = this._dataServiceGroups.getQuery();
    reqData.query.expand({attribute: {select: ["id","name", "description"]}});
    reqData.query.filter({groupId: this.groupId});
    this._dataServiceGroups.query(this.state, reqData );
    this.gruppen = this._dataServiceGroups.pipe(map(result => process(result.data, this.state)));
    }
  }
  public createGroupForm(args: any): FormGroup  {
    let data  = <GroupAttribute>{ id: 0,
                         sortValue: 0,
                         status: null,
                         attributeId: 0,
                         attribute:  <Attribute>{ 
                           id: 0,
                           name: "",
                           isIdea: "true",
                           description: "",
                           label: "",
                           example: "",
                           status: null,
                           createdBy: ""
                         },
                         createdBy:"",
                         groupId: this.groupId};
    const item : GroupAttribute = args.isNew ? data : args.dataItem;

    this.groupAttributeForm = this.formBuilder.group({
      id: item.id ,
      attributeId: [item.attributeId],
      sortValue: [item.sortValue],   
      status: [item.status],            
      groupId: [item.groupId] ,
      createdBy:[item.createdBy],  
      attribute: this.formBuilder.group( {
        name: [item.attribute.name],
        description: [item.attribute.description],
        label: [item.attribute.label]
      }  )        
  });
  return this.groupAttributeForm;
}

public saveChanges(grid: any): void {
  grid.closeCell();
  grid.cancelCell();

  this._dataServiceGroups.saveChanges(this.state);
  //this.sortimentService.query(this.state, this.query);
}

public cancelChanges(grid: any): void {
  grid.cancelCell();
  this._dataServiceGroups.cancelChanges();
}
public onStateChange(state: State) {
  let query = this._dataServiceGroups.getQuery();
  this._dataServiceGroups.query(state, query );
}


}
