import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Kundensegment } from './kundensegment.entity';
//#endregion

export const KundensegmentConfig = {
  name: "Kundensegment",
  annotations: [],
  fields: {
    kundengruppeId: {type: 'Edm.Int32'},
    name: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kundengruppe: {type: 'entities.customers.Kundengruppe', navigation: true, field: 'kundengruppeId', ref: 'id'},
    personensegmente: {type: 'services.PersonenSegmente', collection: true, navigation: true}
  }
} as EntityConfig<Kundensegment>;