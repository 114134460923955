import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Videoformat } from './videoformat.entity';
//#endregion

export const VideoformatConfig = {
  name: "Videoformat",
  annotations: [],
  fields: {
    title: {type: 'Edm.String'},
    isCustomFormat: {type: 'Edm.String'},
    pixelX: {type: 'Edm.Int32', nullable: false},
    pixelY: {type: 'Edm.Int32', nullable: false},
    colorDepth: {type: 'Edm.Int32', nullable: false},
    hz: {type: 'Edm.Int32', nullable: false},
    megapixel: {type: 'Edm.Int32', nullable: false},
    sar: {type: 'Edm.String'},
    dar: {type: 'Edm.String'},
    par: {type: 'Edm.String'},
    pixel: {type: 'Edm.Int32', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assets: {type: 'entities.assets.Asset', collection: true, navigation: true}
  }
} as EntityConfig<Videoformat>;