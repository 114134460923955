import { Directive, OnInit, OnDestroy, Input, Injectable, Inject, Optional, forwardRef } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { ArtikelGroupService } from '../odata-services';
import { ArtikelGroupDataService } from '../data-services';
import { ODataBindingDirective } from './core/o-data-binding-directive';
import { ODataEntitySetResource } from 'angular-odata';

@Injectable()
@Directive({
    selector: '[artikelGroupBinding]'
})
export class ArtikelGroupBindingDirective extends ODataBindingDirective {

    @Input('artikelGroupBinding') query:  ODataEntitySetResource<any>;
    
    constructor(  @Inject(forwardRef(() => ArtikelGroupDataService)) dataService: ArtikelGroupDataService, @Inject(forwardRef(() => GridComponent))  grid: GridComponent) {
        super(dataService,grid);
    }
    public ngOnChanges( c: any ) : void {
 
        console.log( "Base-Query:", this.query);
        this.rebind();
    }

    
}