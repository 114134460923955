import { Injectable, Inject } from '@angular/core';
import { BeigabenService } from '../odata-services/services/beigaben.service';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class BeigabenDataService extends GridDataService {
    
  public odataService: BeigabenService;
  //public Sortimentsauswahl: string[] = [];
  
    constructor(@Inject(BeigabenService) service: BeigabenService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
  
   
}