//#region ODataApi Imports
export * from './services/phasetype.enum';
export * from './services/reftype.enum';
export * from './services/datatype.enum';
export * from './services/dataformat.enum';
export * from './services/vertriebstype.enum';
export * from './services/phasetype.enum.config';
export * from './services/reftype.enum.config';
export * from './services/datatype.enum.config';
export * from './services/dataformat.enum.config';
export * from './services/vertriebstype.enum.config';
export * from './entities/boerse/aktie.entity';
export * from './entities/boerse/aktientrends.entity';
export * from './entities/boerse/analyst.entity';
export * from './entities/boerse/groupaktie.entity';
export * from './entities/boerse/kursziel.entity';
export * from './entities/boerse/tageslinie.entity';
export * from './entities/boerse/tradegatekurs.entity';
export * from './entities/articles/artikelasset.entity';
export * from './entities/articles/artikelattribute.entity';
export * from './entities/articles/artikelgroupfiltersettings.entity';
export * from './entities/articles/artikelgroup.entity';
export * from './entities/articles/artikelbezeichnungaufbauview.entity';
export * from './entities/articles/artikelbezeichnungsgeneratorbausteindto.entity';
export * from './entities/articles/artikelbezeichnungsgeneratordto.entity';
export * from './Mza/Service/Models/chartitemdto.entity';
export * from './Mza/Service/Models/chartsdto.entity';
export * from './entities/assets/asset.entity';
export * from './entities/assets/assetgroupmetadata.entity';
export * from './entities/assets/assetgroup.entity';
export * from './entities/assets/assetgrouptreeassigment.entity';
export * from './entities/assets/assetgrouptreemetadata.entity';
export * from './entities/assets/assetgrouptree.entity';
export * from './entities/assets/assetmetadata.entity';
export * from './entities/assets/documentformat.entity';
export * from './entities/assets/imageformat.entity';
export * from './entities/assets/videoformat.entity';
export * from './entities/assets/metadata.entity';
export * from './entities/artikel.entity';
export * from './entities/warengruppedto.entity';
export * from './entities/kundedto.entity';
export * from './Mza/Entities/Dtos/bezeichnungdto.entity';
export * from './Mza/Entities/Dtos/kundenfilterdto.entity';
export * from './Mza/Entities/Dtos/registrationfilterdto.entity';
export * from './Mza/Entities/Dtos/markenfilterdto.entity';
export * from './Mza/Service/Models/extendsattributesdto.entity';
export * from './Mza/Service/Models/assetviewdto.entity';
export * from './kaufabwicklung/bestellung.entity';
export * from './entities/customers/customerassetgroup.entity';
export * from './entities/customers/kundenfilter.entity';
export * from './entities/customers/kundenfilterergebnis.entity';
export * from './entities/customers/customergroup.entity';
export * from './entities/customers/kundengruppe.entity';
export * from './entities/customers/kundensegment.entity';
export * from './entities/customers/person.entity';
export * from './entities/customers/registrationdto.entity';
export * from './entities/schema/firma.entity';
export * from './entities/schema/registrationinfo.entity';
export * from './entities/schema/booking.entity';
export * from './entities/schema/bookingobject.entity';
export * from './entities/schema/bookingtimes.entity';
export * from './entities/schema/attribute.entity';
export * from './entities/schema/groupattribute.entity';
export * from './entities/schema/group.entity';
export * from './entities/schema/grouptreeassigment.entity';
export * from './entities/schema/grouptreeattribute.entity';
export * from './entities/schema/grouptree.entity';
export * from './entities/schema/maingroupattribute.entity';
export * from './entities/schema/maingroup.entity';
export * from './entities/schema/wettbewerber.entity';
export * from './entities/schema/wettbewerberpreisgroup.entity';
export * from './Mza/Service/Controllers/V1/Kunden/Models/userstatedto.entity';
export * from './Mza/Service/Controllers/V1/Kunden/Models/wettbewerberpreisviewdto.entity';
export * from './Mza/Service/Controllers/V1/Kunden/Models/firmapersonendto.entity';
export * from './Mza/Service/Controllers/V1/Kunden/Models/bookingviewdto.entity';
export * from './entities/produkte/beigabenartikel.entity';
export * from './entities/produkte/beigabenausloeser.entity';
export * from './entities/produkte/beigabenkunde.entity';
export * from './entities/produkte/beigabe.entity';
export * from './entities/produkte/hersteller.entity';
export * from './mza/Service/Models/dbobjectdto.entity';
export * from './mza/Service/Models/dbobjectdto.entity.config';
export * from './mza/Service/Models/sortdto.entity';
export * from './mza/Service/Models/sortdto.entity.config';
export * from './mza/Service/Models/sortresultdto.entity';
export * from './mza/Service/Models/sortresultdto.entity.config';
export * from './mza/Service/Models/columndto.entity';
export * from './mza/Service/Models/columndto.entity.config';
export * from './entities/produkte/markedto.entity';
export * from './entities/produkte/artikelpreis.entity';
export * from './entities/produkte/artikelpreisvk.entity';
export * from './entities/produkte/kalkulationsgruppe.entity';
export * from './entities/produkte/produkttypattribute.entity';
export * from './entities/produkte/produkttypgroup.entity';
export * from './entities/produkte/produkttyp.entity';
export * from './entities/produkte/kundensortimentdto.entity';
export * from './entities/produkte/sortimentdto.entity';
export * from './entities/ref/property.entity';
export * from './entities/ref/type.entity';
export * from './entities/schema/attribute.entity';
export * from './entities/schema/groupattribute.entity';
export * from './entities/schema/group.entity';
export * from './entities/schema/grouptreeassigment.entity';
export * from './entities/schema/grouptreeattribute.entity';
export * from './entities/schema/grouptree.entity';
export * from './entities/schema/maingroupattribute.entity';
export * from './entities/schema/maingroup.entity';
export * from './services/statusgrouptree.entity';
export * from './services/statusgrouptree.entity.config';
export * from './entities/settings/datamapping.entity';
export * from './entities/settings/datamapping.entity.config';
export * from './entities/Reports/report.entity';
export * from './entities/Reports/report.entity.config';
//export * from './services/statusgroup.entity';
export * from './entities/settings/status.entity';
export * from './entities/settings/statustype.entity';
export * from './entities/wettbewerb/wettbewerberpreis.entity';
export * from './services/customerviewdto.entity';
export * from './services/beigabentyp.entity';
export * from './services/kunde.entity';
export * from './services/firmaadresse.entity';
export * from './services/personensegmente.entity';
export * from './services/teampersonen.entity';
export * from './services/team.entity';
export * from './services/abteilung.entity';
export * from './services/leistungsprofilansprechpartner.entity';
export * from './services/leistungsprofilhaendler.entity';
export * from './services/leistungsprofilhaendlerangabefelder.entity';
export * from './services/leistungsprofilhaendlerbereichsangabe.entity';
export * from './services/leistungsprofilinternetverdachthaendler.entity';
export * from './services/leistungsprofilwebshopadressen.entity';
export * from './services/registrationabgleich.entity';
export * from './services/registrationgroup.entity';
export * from './services/artikelbezeichnung.entity';
export * from './services/statusgroup.entity';
export * from './services/firmadto.entity';
export * from './services/persondto.entity';
export * from './services/firmaadressedto.entity';
export * from './entities/boerse/aktie.entity.config';
export * from './entities/boerse/aktientrends.entity.config';
export * from './entities/boerse/analyst.entity.config';
export * from './entities/boerse/groupaktie.entity.config';
export * from './entities/boerse/kursziel.entity.config';
export * from './entities/boerse/tageslinie.entity.config';
export * from './entities/boerse/tradegatekurs.entity.config';
export * from './entities/articles/artikelasset.entity.config';
export * from './entities/articles/artikelattribute.entity.config';
export * from './entities/articles/artikelgroupfiltersettings.entity.config';
export * from './entities/articles/artikelgroup.entity.config';
export * from './entities/articles/artikelbezeichnungaufbauview.entity.config';
export * from './entities/articles/artikelbezeichnungsgeneratorbausteindto.entity.config';
export * from './entities/articles/artikelbezeichnungsgeneratordto.entity.config';
export * from './entities/assets/asset.entity.config';
export * from './entities/assets/assetgroupmetadata.entity.config';
export * from './entities/assets/assetgroup.entity.config';
export * from './entities/assets/assetgrouptreeassigment.entity.config';
export * from './entities/assets/assetgrouptreemetadata.entity.config';
export * from './entities/assets/assetgrouptree.entity.config';
export * from './entities/assets/assetmetadata.entity.config';
export * from './entities/assets/documentformat.entity.config';
export * from './entities/assets/imageformat.entity.config';
export * from './entities/assets/videoformat.entity.config';
export * from './entities/assets/metadata.entity.config';
export * from './entities/artikel.entity.config';
export * from './entities/warengruppedto.entity.config';
export * from './entities/kundedto.entity.config';
export * from './Mza/Entities/Dtos/bezeichnungdto.entity.config';
export * from './Mza/Entities/Dtos/kundenfilterdto.entity.config';
export * from './Mza/Entities/Dtos/registrationfilterdto.entity.config';
export * from './Mza/Entities/Dtos/markenfilterdto.entity.config';
export * from './Mza/Service/Models/extendsattributesdto.entity.config';
export * from './Mza/Service/Models/assetviewdto.entity.config';
export * from './kaufabwicklung/bestellung.entity.config';
export * from './entities/customers/customerassetgroup.entity.config';
export * from './entities/customers/kundenfilter.entity.config';
export * from './entities/customers/kundenfilterergebnis.entity.config';
export * from './entities/customers/customergroup.entity.config';
export * from './entities/customers/kundengruppe.entity.config';
export * from './entities/customers/kundensegment.entity.config';
export * from './entities/customers/person.entity.config';
export * from './entities/customers/registrationdto.entity.config';
export * from './entities/schema/firma.entity.config';
export * from './entities/schema/registrationinfo.entity.config';
export * from './entities/schema/booking.entity.config';
export * from './entities/schema/bookingobject.entity.config';
export * from './entities/schema/bookingtimes.entity.config';
export * from './entities/schema/attribute.entity.config';
export * from './entities/schema/groupattribute.entity.config';
export * from './entities/schema/group.entity.config';
export * from './entities/schema/grouptreeassigment.entity.config';
export * from './entities/schema/grouptreeattribute.entity.config';
export * from './entities/schema/grouptree.entity.config';
export * from './entities/schema/maingroupattribute.entity.config';
export * from './entities/schema/maingroup.entity.config';
export * from './entities/schema/wettbewerber.entity.config';
export * from './entities/schema/wettbewerberpreisgroup.entity.config';
export * from './Mza/Service/Controllers/V1/Kunden/Models/userstatedto.entity.config';
export * from './Mza/Service/Controllers/V1/Kunden/Models/wettbewerberpreisviewdto.entity.config';
export * from './Mza/Service/Controllers/V1/Kunden/Models/firmapersonendto.entity.config';
export * from './Mza/Service/Controllers/V1/Kunden/Models/bookingviewdto.entity.config';
export * from './entities/produkte/beigabenartikel.entity.config';
export * from './entities/produkte/beigabenausloeser.entity.config';
export * from './entities/produkte/beigabenkunde.entity.config';
export * from './entities/produkte/beigabe.entity.config';
export * from './entities/produkte/hersteller.entity.config';
export * from './entities/produkte/markedto.entity.config';
export * from './entities/produkte/artikelpreis.entity.config';
export * from './entities/produkte/artikelpreisvk.entity.config';
export * from './entities/produkte/kalkulationsgruppe.entity.config';
export * from './entities/produkte/produkttypattribute.entity.config';
export * from './entities/produkte/produkttypgroup.entity.config';
export * from './entities/produkte/produkttyp.entity.config';
export * from './entities/produkte/kundensortimentdto.entity.config';
export * from './entities/produkte/sortimentdto.entity.config';
export * from './entities/ref/property.entity.config';
export * from './entities/ref/type.entity.config';
export * from './entities/schema/attribute.entity.config';
export * from './entities/schema/groupattribute.entity.config';
export * from './entities/schema/group.entity.config';
export * from './entities/schema/grouptreeassigment.entity.config';
export * from './entities/schema/grouptreeattribute.entity.config';
export * from './entities/schema/grouptree.entity.config';
export * from './entities/schema/maingroupattribute.entity.config';
export * from './entities/schema/maingroup.entity.config';
export * from './entities/schema/dashboardassigment.entity';
export * from './entities/schema/dashboard.entity';
export * from './services/statusgrouptree.entity.config';
export * from './entities/schema/dashboardassigment.entity.config';
export * from './entities/schema/dashboard.entity.config';
//export * from './services/statusgroup.entity.config';
export * from './entities/settings/status.entity.config';
export * from './entities/settings/statustype.entity.config';
export * from './entities/wettbewerb/wettbewerberpreis.entity.config';
export * from './services/customerviewdto.entity.config';
export * from './services/beigabentyp.entity.config';
export * from './services/kunde.entity.config';
export * from './services/firmaadresse.entity.config';
export * from './services/personensegmente.entity.config';
export * from './services/teampersonen.entity.config';
export * from './services/team.entity.config';
export * from './services/abteilung.entity.config';
export * from './services/leistungsprofilansprechpartner.entity.config';
export * from './services/leistungsprofilhaendler.entity.config';
export * from './services/leistungsprofilhaendlerangabefelder.entity.config';
export * from './services/leistungsprofilhaendlerbereichsangabe.entity.config';
export * from './services/leistungsprofilinternetverdachthaendler.entity.config';
export * from './services/leistungsprofilwebshopadressen.entity.config';
export * from './services/registrationabgleich.entity.config';
export * from './services/registrationgroup.entity.config';
export * from './services/artikelbezeichnung.entity.config';
export * from './services/statusgroup.entity.config';
export * from './services/firmadto.entity.config';
export * from './services/persondto.entity.config';
export * from './services/firmaadressedto.entity.config';
export * from './services/container.service';
export * from './services/aktie.service';
export * from './services/aktientrends.service';
export * from './services/analyst.service';
export * from './services/groupaktie.service';
export * from './services/kursziel.service';
export * from './services/tageslinie.service';
export * from './services/tradegatekurs.service';
export * from './services/artikelasset.service';
export * from './services/artikelattribute.service';
export * from './services/artikelgroupfiltersettings.service';
export * from './services/artikelgroup.service';
export * from './services/asset.service';
export * from './services/artikel.service';
export * from './services/artikelbezeichnungsaufbau.service';
export * from './services/bezeichnungdto.service';
export * from './services/artikelbezeichnungsgeneratorbausteine.service';
export * from './services/artikelbezeichnungsgenerator.service';
export * from './services/warengruppen.service';
export * from './services/assetgroupmetadata.service';
export * from './services/assetgroup.service';
export * from './services/assetgrouptreeassigment.service';
export * from './services/assetgrouptreemetadata.service';
export * from './services/assetgrouptree.service';
export * from './services/assetmetadata.service';
export * from './services/assetviewdto.service';
export * from './services/documentformat.service';
export * from './services/imageformat.service';
export * from './services/videoformat.service';
export * from './services/metadata.service';
export * from './services/bestellung.service';
export * from './services/customerassetgroup.service';
export * from './services/customerfilter.service';
export * from './services/customerfilterresult.service';
export * from './services/customergroup.service';
export * from './services/firma.service';
export * from './services/kundengruppe.service';
export * from './services/kunden.service';
export * from './services/wettbewerberpreisviewdto.service';
export * from './services/kundenfilterdto.service';
export * from './services/kundensegment.service';
export * from './services/person.service';
export * from './services/firmapersonendto.service';
export * from './services/registration.service';
export * from './services/registrierungen.service';
export * from './services/registrationfilterdto.service';
export * from './services/beigabenartikel.service';
export * from './services/beigabenausloeser.service';
export * from './services/beigabenkunde.service';
export * from './services/beigaben.service';
export * from './services/hersteller.service';
export * from './services/marken.service';
export * from './services/markenfilterdto.service';
export * from './services/artikelpreis.service';
export * from './services/artikelpreisvk.service';
export * from './services/kalkulationsgruppe.service';
export * from './services/produkttypattribute.service';
export * from './services/produkttypgroup.service';
export * from './services/produkttyp.service';
export * from './services/kundensortimente.service';
export * from './services/kundensortimentdto.service';
export * from './services/sortimente.service';
export * from './services/property.service';
export * from './services/type.service';
export * from './services/booking.service';
export * from './services/bookingviewdto.service';
export * from './services/bookingobject.service';
export * from './services/bookingtimes.service';
export * from './services/attribute.service';
export * from './services/groupattribute.service';
export * from './services/group.service';
export * from './services/grouptreeassigment.service';
export * from './services/grouptreeattribute.service';
export * from './services/grouptree.service';
export * from './services/maingroupattribute.service';
export * from './services/maingroup.service';
export * from './services/statusgrouptree.service';
export * from './services/status.service';
export * from './services/statustype.service';
export * from './services/wettbewerber.service';
export * from './services/wettbewerberpreisgroup.service';
export * from './services/wettbewerberpreis.service';
export * from './services/customerviewdto.service';
export * from './services/aktie.service.config';
export * from './services/aktientrends.service.config';
export * from './services/analyst.service.config';
export * from './services/groupaktie.service.config';
export * from './services/kursziel.service.config';
export * from './services/tageslinie.service.config';
export * from './services/tradegatekurs.service.config';
export * from './services/artikelasset.service.config';
export * from './services/artikelattribute.service.config';
export * from './services/artikelgroupfiltersettings.service.config';
export * from './services/artikelgroup.service.config';
export * from './services/asset.service.config';
export * from './services/artikel.service.config';
export * from './services/artikelbezeichnungsaufbau.service.config';
export * from './services/bezeichnungdto.service.config';
export * from './services/artikelbezeichnungsgeneratorbausteine.service.config';
export * from './services/artikelbezeichnungsgenerator.service.config';
export * from './services/warengruppen.service.config';
export * from './services/assetgroupmetadata.service.config';
export * from './services/assetgroup.service.config';
export * from './services/assetgrouptreeassigment.service.config';
export * from './services/assetgrouptreemetadata.service.config';
export * from './services/assetgrouptree.service.config';
export * from './services/assetmetadata.service.config';
export * from './services/assetviewdto.service.config';
export * from './services/documentformat.service.config';
export * from './services/imageformat.service.config';
export * from './services/videoformat.service.config';
export * from './services/metadata.service.config';
export * from './services/report.service';
export * from './services/report.service.config';
export * from './services/dashboard.service';
export * from './services/dashboard.service.config';
export * from './services/dashboardassigment.service';
export * from './services/dashboardassigment.service.config';
export * from './services/datamapping.service';
export * from './services/datamapping.service.config';
export * from './services/bestellung.service.config';
export * from './services/customerassetgroup.service.config';
export * from './services/customerfilter.service.config';
export * from './services/customerfilterresult.service.config';
export * from './services/customergroup.service.config';
export * from './services/firma.service.config';
export * from './services/kundengruppe.service.config';
export * from './services/kunden.service.config';
export * from './services/wettbewerberpreisviewdto.service.config';
export * from './services/kundenfilterdto.service.config';
export * from './services/kundensegment.service.config';
export * from './services/person.service.config';
export * from './services/firmapersonendto.service.config';
export * from './services/registration.service.config';
export * from './services/registrierungen.service.config';
export * from './services/registrationfilterdto.service.config';
export * from './services/beigabenartikel.service.config';
export * from './services/beigabenausloeser.service.config';
export * from './services/beigabenkunde.service.config';
export * from './services/beigaben.service.config';
export * from './services/hersteller.service.config';
export * from './services/marken.service.config';
export * from './services/markenfilterdto.service.config';
export * from './services/artikelpreis.service.config';
export * from './services/artikelpreisvk.service.config';
export * from './services/kalkulationsgruppe.service.config';
export * from './services/produkttypattribute.service.config';
export * from './services/produkttypgroup.service.config';
export * from './services/produkttyp.service.config';
export * from './services/kundensortimente.service.config';
export * from './services/kundensortimentdto.service.config';
export * from './services/sortimente.service.config';
export * from './services/property.service.config';
export * from './services/type.service.config';
export * from './services/booking.service.config';
export * from './services/bookingviewdto.service.config';
export * from './services/bookingobject.service.config';
export * from './services/bookingtimes.service.config';
export * from './services/attribute.service.config';
export * from './services/groupattribute.service.config';
export * from './services/group.service.config';
export * from './services/grouptreeassigment.service.config';
export * from './services/grouptreeattribute.service.config';
export * from './services/grouptree.service.config';
export * from './services/maingroupattribute.service.config';
export * from './services/maingroup.service.config';
export * from './services/statusgrouptree.service.config';
export * from './services/status.service.config';
export * from './services/statustype.service.config';
export * from './services/wettbewerber.service.config';
export * from './services/wettbewerberpreisgroup.service.config';
export * from './services/wettbewerberpreis.service.config';
export * from './services/customerviewdto.service.config';
export * from './odataservices.config';
export * from './odataservices.module';
//#endregion
