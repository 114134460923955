import { Injectable, Inject, Optional } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelGroupService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable({
  providedIn: 'root'
})
export class ArtikelGroupDataService  extends GridDataService {
    
  public odataService: ArtikelGroupService;
  /* public notificationService2: NotificationService; */
  
    constructor( @Inject(ArtikelGroupService) service: ArtikelGroupService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
      /* this.notificationService2 =  notificationService */
     // this._notificationService = notificationService;
    }

/*     public showDSSave(): void {
      console.log("DS:", this.notificationService2);
      this.notificationService2.show({
          content: 'Your data has been saved. Time for tea!',
          cssClass: 'button-notification',
          animation: { type: 'slide', duration: 400 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          closable: true
      });
  } */
}
