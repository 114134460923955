import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RegistrierungNrComponent } from './registrierung-nr.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [RegistrierungNrComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DropDownsModule
  ],exports: [RegistrierungNrComponent]
})
export class RegistrierungNrModule { }
