import { Injectable, Inject } from '@angular/core';
import { GroupService } from '../odata-services/services/group.service';

//import { SortimentKunde } from '../models/sortimente/sortiment-kunde.model';

import { isUndefined, isNull } from 'util';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class KundensortimenteDataService extends GridDataService {
    
 // public odataService: KundensortimenteService;
  public Sortimentsauswahl: string[] = [];
  
    constructor( @Inject(GroupService)  service: GroupService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,'isFreigeschaltet',notificationService); 
 
      
    }
  
   // public SaveIsFreigeschaltet(item : SortimentKunde, state: State) : void
/*     public SaveIsFreigeschaltet(item : any, state: State) : void
    { 
      
        this.update(item);
        this.saveChanges(state);
    }
    public queryRegistrierungszuordnung(regId: number, state: State) : void {
      this.reset();
      if(isUndefined(regId) || isNull(regId))
        regId=0;
      let query; //= this.odataService.getODataQueryRegistrierungszuordnung(regId);
      return this.query(state,query);
    }

    public queryKundenzuordnung(kundenNr: number, state: State) : void {
      this.reset();
      if(isUndefined(kundenNr) || isNull(kundenNr))
         kundenNr=0;
      let query ; // =this.odataService.getODataQueryKundenzuordnung(kundenNr);
      return this.query(state,query);
    } */
}
