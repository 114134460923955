import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MarkenNameMultiselectComponent } from './marken-name-multiselect.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [MarkenNameMultiselectComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DropDownsModule
  ],exports: [MarkenNameMultiselectComponent]
})
export class MarkenNameMultiselectModule { }
