import { Directive } from '@angular/core';

@Directive({
  selector: '[libProduktTypAttributeData]'
})
export class ProduktTypAttributeDataDirective {

  constructor() { }

}
