import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Hersteller } from './hersteller.entity';
//#endregion

export const HerstellerConfig = {
  name: "Hersteller",
  annotations: [],
  fields: {
    herstellerID: {type: 'Edm.Int32', key: true, ref: 'herstellerID', nullable: false},
    bezeichnung: {type: 'Edm.String'},
    articles: {type: 'entities.Artikel', collection: true, navigation: true}
  }
} as EntityConfig<Hersteller>;