import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { CustomerAssetGroupConfig } from './customerassetgroup.entity.config';
import { KundenFilterConfig } from './kundenfilter.entity.config';
import { KundenFilterErgebnisConfig } from './kundenfilterergebnis.entity.config';
import { CustomerGroupConfig } from './customergroup.entity.config';
import { KundengruppeConfig } from './kundengruppe.entity.config';
import { KundensegmentConfig } from './kundensegment.entity.config';
import { PersonConfig } from './person.entity.config';
import { RegistrationDtoConfig } from './registrationdto.entity.config';
//#endregion

export const CustomersSchema = {
  namespace: "entities.customers",
  enums: [],
  entities: [CustomerAssetGroupConfig,
    KundenFilterConfig,
    KundenFilterErgebnisConfig,
    CustomerGroupConfig,
    KundengruppeConfig,
    KundensegmentConfig,
    PersonConfig,
    RegistrationDtoConfig],
  callables: [{
    name: 'InputFilter',
    bound: true,
    composable: false,
    parameters: { value: {type: 'Edm.String'} },
    return: "Mza.Entities.Dtos.KundenFilterDto"
  }],
  containers: []
} as SchemaConfig;