import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { RegistrationDto } from './registrationdto.entity';
//#endregion

export const RegistrationDtoConfig = {
  name: "RegistrationDto",
  annotations: [],
  fields: {
    kundenNummer: {type: 'Edm.Int32'},
    isConfirmedByVertrieb: {type: 'Edm.Boolean', nullable: false},
    confirmationVertriebBy: {type: 'Edm.String'},
    confirmationVertriebAt: {type: 'Edm.DateTimeOffset'},
    confirmationVertriebBemerkung: {type: 'Edm.String'},
    confirmationVertriebBemerkungChangedAt: {type: 'Edm.DateTimeOffset'},
    confirmationVertriebBemerkungChangedBy: {type: 'Edm.String'},
    isConfirmedByAussendienst: {type: 'Edm.Boolean', nullable: false},
    confirmationAussendienstBy: {type: 'Edm.String'},
    confirmationAussendienstAt: {type: 'Edm.DateTimeOffset'},
    confirmationAussendienstBemerkung: {type: 'Edm.String'},
    confirmationAussendienstBemerkungChangedAt: {type: 'Edm.DateTimeOffset'},
    confirmationAussendienstBemerkungChangedBy: {type: 'Edm.String'},
    isEmailConfirmed: {type: 'Edm.Boolean', nullable: false},
    emailConfirmationToken: {type: 'Edm.Guid', nullable: false},
    emailConfirmationReceivedAt: {type: 'Edm.DateTimeOffset'},
    firmaId: {type: 'Edm.Int32', nullable: false},
    email: {type: 'Edm.String'},
    telefon: {type: 'Edm.String'},
    fax: {type: 'Edm.String'},
    mobil: {type: 'Edm.String'},
    websiteUrl: {type: 'Edm.String'},
    gewerbeArtId: {type: 'Edm.Int32', nullable: false},
    zahlungsArtId: {type: 'Edm.Int32', nullable: false},
    leistungsProfilHaendlerId: {type: 'Edm.Int32'},
    newsletterSubscription: {type: 'Edm.Boolean', nullable: false},
    isConfirmedAGB: {type: 'Edm.Boolean', nullable: false},
    scoreInteger: {type: 'Edm.Int32'},
    scoreAmpel: {type: 'Edm.String'},
    scoreGeneratedBy: {type: 'Edm.String'},
    scoreGeneratedAt: {type: 'Edm.DateTimeOffset'},
    isKundeCalled: {type: 'Edm.Boolean'},
    isKundeVisited: {type: 'Edm.Boolean'},
    isInternetSuspicion: {type: 'Edm.Boolean'},
    isKundeDenied: {type: 'Edm.Boolean'},
    isEmailKundeDenied: {type: 'Edm.Boolean', nullable: false},
    emailKundeDeniedReceivedAt: {type: 'Edm.DateTimeOffset'},
    emailKundeDeniedReceivedBy: {type: 'Edm.String'},
    besuchKw: {type: 'Edm.Int32'},
    isExportCsvCreated: {type: 'Edm.Boolean'},
    isRegistDoppelt: {type: 'Edm.Boolean'},
    isEmailAussendienst: {type: 'Edm.Boolean', nullable: false},
    emailAussendienstReceivedAt: {type: 'Edm.DateTimeOffset'},
    emailAussendienstReceivedBy: {type: 'Edm.String'},
    konditionenInfoAccept: {type: 'Edm.Boolean', nullable: false},
    zugeteilterAD: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'services.FirmaDto', navigation: true}
  }
} as EntityConfig<RegistrationDto>;