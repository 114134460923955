import { Component, OnInit } from '@angular/core';
import { MainGroupDataService , Maingroup,Report,ReportService, DataResult,SortDto, DataArrayConvert, StatusType, StatusTypeService, Dashboard, DashboardService} from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {  ODataOptions }from 'angular-odata';

@Component({
  selector: 'gpv-hauptgruppen-main',
  templateUrl: './hauptgruppen-main.component.html',
  styleUrls: ['./hauptgruppen-main.component.css']
})
export class HauptgruppenMainComponent implements OnInit {

  public groupForm: FormGroup;
  public maingroupItems: Array<Maingroup> = [];
  public reportItems: Array<Report> = [];
  public sortItems: Array<SortDto> = [];
  public dashboardItems: Array<Dashboard> = [];
  public statusTypeItems: Array<StatusType> = [];
  //private query: ODataOptions;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};
  constructor(public _dataServiceMainGroups: MainGroupDataService,private _statusTypeService: StatusTypeService,
             private _reportService: ReportService, private _dashboardService: DashboardService,
             private formBuilder: FormBuilder) { 
              this.createGroupForm  = this.createGroupForm.bind(this);
             
             }

  ngOnInit() {
   let query = this._dataServiceMainGroups.odataService.entities();
   query.query.select("id,name");
  
   query.get().subscribe(
     ({entities, meta}) => this.maingroupItems = entities
   );

   this.loadReports();
    this.loadDashboards();
    this.loadSortReports();
    //let query = this._dataServiceMainGroups.getQuery();
    /* this._dataServiceMainGroups.query(this.state, query );
    this.gruppen = this._dataServiceMainGroups.subscribe; */

    this.loadStatusTypeItems();
  }

  private loadReports() {
    let requestReports = this._reportService.entities();
    requestReports.query.select("id,name");

    requestReports.get().subscribe(
      ({ entities, meta }) => this.reportItems = entities
    );
  }
  private loadSortReports() {
    let requestReports = this._dataServiceMainGroups.odataService.getSorts();
  
    requestReports.call(null,"entities").subscribe(
      (entities) => this.sortItems = entities
    );
  }
  
  private loadDashboards() {
    let requestDashboards = this._dashboardService.entities();
    requestDashboards.query.select("id,name");

    requestDashboards.get().subscribe(
      ({ entities, meta }) => this.dashboardItems = entities
    );
  }

  private loadStatusTypeItems(){
    let query = this._statusTypeService.entities();
    query.select("id,name");
   
    query.get().subscribe(
      ({entities, meta}) => this.statusTypeItems = entities
    );
  }

  public createGroupForm(args: any): FormGroup  {
      let data  = <Maingroup>{ id: 0,
                           description: "",
                           name: "",
                           value: "",
                           sortValue: 0,
                           status: null,
                           allowGroupTreeAssignment: false,
                           allowTreeStructur : false,
                           allowGroupsInDifferentNodes: false,
                           showValue: false,
                           showDescription: false,
                           useArtikel: false,
                           useAttribute: false,
                           useAssets: false,
                           useKunden: false,
                           useWettbewerber: false,
                           useWettbewerberPreis: false,
                           useArtikelPreis: false,
                           useArtikelPreisVk: false,
                           useRegistration: false,
                           useAktie: false,
                           usedMaingroups: "",
                           usedReports: "",
                           usedDashboards: "",
                           usedSortReports: "",
                           usedStatusTypeId: 0
                           };
      const item : Maingroup = args.isNew ? data : args.dataItem;

      this.groupForm = this.formBuilder.group({
        id: item.id ,
        name: [item.name, [Validators.maxLength(40), Validators.required] ],
        description: [item.description, [Validators.maxLength(250)] ],
        value: [item.value, [Validators.maxLength(40)] ],
        sortValue: [item.sortValue],   
        status: [item.status],             
        allowGroupTreeAssignment:  [item.allowGroupTreeAssignment], 
        allowTreeStructur: [item.allowTreeStructur],     
        allowGroupsInDifferentNodes: [item.allowGroupsInDifferentNodes],
        showValue:  [item.showValue],
        showDescription:  [item.showDescription],
        useArtikel:  [item.useArtikel],
        useAttribute:  [item.useAttribute],
        useAssets:  [item.useAssets],
        useKunden:  [item.useKunden],
        useWettbewerber:  [item.useWettbewerber],
        useWettbewerberPreis:  [item.useWettbewerberPreis],
        useArtikelPreis:  [item.useArtikelPreis],
        useArtikelPreisVk:  [item.useArtikelPreisVk],
        useRegistration:  [item.useRegistration],
        useAktie:  [item.useAktie],        
        usedMaingroups: [DataArrayConvert.ConvertStringToIntList(item.usedMaingroups)]  ,
        usedReports: [DataArrayConvert.ConvertStringToIntList(item.usedReports)]  ,
        usedDashboards: [DataArrayConvert.ConvertStringToIntList(item.usedDashboards)]  ,
        usedSortReports: [DataArrayConvert.ConvertStringToIntList(item.usedSortReports)]  ,
        usedStatusTypeId: [item.usedStatusTypeId]           
    });
    return this.groupForm;
  }

  public saveChanges(grid: any): void {
/*     grid.closeCell();
    grid.cancelCell(); */

    this._dataServiceMainGroups.saveChanges(this.state);
    //this.sortimentService.query(this.state, this.query);
  }
  
  public cancelChanges(grid: any): void {
   /*  grid.cancelCell(); */
    this._dataServiceMainGroups.cancelChanges();
  }
/*   public onStateChange(state: State) {
    let query = this._dataServiceMainGroups.getQuery();
    this._dataServiceMainGroups.query(this.state, query );
} */

public onUsedMaingroupsChange(value) {
  this.groupForm.setValue({usedMaingroups: value});
}

public onUsedStatusChange(value) {
  console.log("value",value);
  this.groupForm.setValue({usedStatusTypeId: value});
}

public cellCloseHandler(args: any) {
  const { formGroup, dataItem } = args;

  if (!formGroup.valid) {
       // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
  } else if (formGroup.dirty) {
    console.log("FG:",formGroup.value);
    formGroup.value.usedMaingroups = DataArrayConvert.ConvertArrayToString(formGroup.value.usedMaingroups);
    formGroup.value.usedReports = DataArrayConvert.ConvertArrayToString(formGroup.value.usedReports);
    formGroup.value.usedDashboards = DataArrayConvert.ConvertArrayToString(formGroup.value.usedDashboards);
    formGroup.value.usedSortReports = DataArrayConvert.ConvertArrayToString(formGroup.value.usedSortReports);
    
    console.log("FG2:",formGroup.value);
      this._dataServiceMainGroups.assignValues(dataItem, DataArrayConvert.ConvertArrayToString(formGroup.value));
      this._dataServiceMainGroups.update(dataItem);
  }
}

public getSelectedValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}

public getSelectedReportValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}
public onUsedReportsChange(value) {
  this.groupForm.setValue({usedReports: value});
}
public getSelectedDashboardValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}
public onUsedDashboardsChange(value) {
  this.groupForm.setValue({usedDashboards: value});
}
public getSelectedSortReportsValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}
public onUsedSortReportsChange(value) {
  this.groupForm.setValue({usedSortReports: value});
}

}
