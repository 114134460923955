import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ColumnDto } from './columndto.entity';
//#endregion

export const ColumnDtoConfig = {
  name: "ColumnDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    column: {type: 'Edm.String'},
    type: {type: 'Edm.String'}
  }
} as EntityConfig<ColumnDto>;