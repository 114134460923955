import { Injectable, Inject } from '@angular/core';
import { WettbewerberService } from '../odata-services/services/wettbewerber.service';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class WettbewerberDataService extends GridDataService {
    
  public odataService: WettbewerberService;
  
  
    constructor(  @Inject(WettbewerberService)  service: WettbewerberService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
