import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ProduktTypAttribute } from './produkttypattribute.entity';
//#endregion

export const ProduktTypAttributeConfig = {
  name: "ProduktTypAttribute",
  annotations: [],
  fields: {
    produktTypId: {type: 'Edm.Int32'},
    attributeId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    produktTyp: {type: 'entities.produkte.ProduktTyp', navigation: true, field: 'produktTypId', ref: 'id'},
    attribute: {type: 'entities.schema.Attribute', navigation: true, field: 'attributeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<ProduktTypAttribute>;