import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { RegistrierungenService } from '../odata-services/services/registrierungen.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class RegistrierungenDataService extends GridDataService {
    
  public odataService: RegistrierungenService;
  
  
    constructor(  @Inject(RegistrierungenService)  service: RegistrierungenService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
