import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class ArtikelDataService extends GridDataService {
    
  public odataService: ArtikelService;
  
  
    constructor( @Inject(ArtikelService)  service: ArtikelService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
