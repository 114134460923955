import { Component, OnInit } from '@angular/core';
import { WettbewerberpreisDataService , WettbewerberPreis, DataResult} from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {  ODataOptions }from 'angular-odata';

@Component({
  selector: 'gpv-wettbewerbpreise',
  templateUrl: './wettbewerbpreise.component.html',
  styleUrls: ['./wettbewerbpreise.component.scss']
})
export class WettbewerbpreiseComponent implements OnInit {

  public wettbewerbpreisForm: FormGroup;
  public WettbewerbpreisListe: Observable<DataResult>;
  //private query: ODataOptions;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};
  
constructor(public _dataServiceWettbewerberPreis: WettbewerberpreisDataService,
  private formBuilder: FormBuilder) { 
   this.createWettbewerberPreisForm  = this.createWettbewerberPreisForm.bind(this);
  
  }

  ngOnInit() {
  }

  public createWettbewerberPreisForm(args: any): FormGroup  {
    let data  = <WettbewerberPreis>{ id: 0,
                        artikelId: 0,
                        kundenNr: 0,
                        preis: 0
    /*                      value: "",
                         sortValue: 0,
                         status: null */
              
                         };
    const item : WettbewerberPreis = args.isNew ? data : args.dataItem;

    this.wettbewerbpreisForm = this.formBuilder.group({
      id: item.id ,
      kundenNr: [item.kundenNr, [ Validators.required] ],
      artikelId: [item.artikelId, [ Validators.required] ],
      preis: [item.preis]
/*       description: [item.description, [Validators.maxLength(250)] ],
      value: [item.value, [Validators.maxLength(40)] ],
      sortValue: [item.sortValue],   
      status: [item.status],  */           
   
  });
  return this.wettbewerbpreisForm;
}

public saveChanges(grid: any): void {
  this._dataServiceWettbewerberPreis.saveChanges(this.state);
}

public cancelChanges(grid: any): void {
  this._dataServiceWettbewerberPreis.cancelChanges();
}


}
