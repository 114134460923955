import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtikelBildComponent } from './artikel-bild.component';

@NgModule({
  declarations: [ArtikelBildComponent],
  imports: [
    CommonModule 
  ],exports: [ArtikelBildComponent]
})
export class ArtikelBildModule { }
