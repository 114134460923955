import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { HauptgruppenMainComponent } from './hauptgruppen/hauptgruppen-main/hauptgruppen-main.component';
import { ProduktTypenComponent } from './produkte/produkt-typen/produkt-typen.component';
import { AssetOverviewComponent } from './assets/asset-overview/asset-overview.component';
import { ArtikelOverviewComponent } from './artikel/artikel-overview/artikel-overview.component';
import { GruppenComponent } from './gruppen/gruppen.component';
import { HerstellerComponent } from './produkte/hersteller/hersteller.component';
import { LoginComponent, AuthGuard } from 'mza-auth-service';
import { GruppeOverviewComponent } from './gruppen/gruppe-overview/gruppe-overview.component';
import { BaumListeComponent } from './gruppen/baum-liste/baum-liste.component';
import { GruppenListeComponent } from './gruppen/gruppen-liste/gruppen-liste.component';
import { GruppenattributeListeComponent } from './gruppen/gruppen-liste/gruppenattribute-liste/gruppenattribute-liste.component';
import { GruppenartikelListeComponent } from './gruppen/gruppen-liste/gruppenartikel-liste/gruppenartikel-liste.component';
import { SettingsComponent } from './settings/settings.component';
import { ReportsOverviewComponent } from './reports/reports-overview/reports-overview.component';
import { ReportComponent } from './reports/report/report.component';
import { ReportSettingComponent } from './reports/report-setting/report-setting.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { DashboardSettingComponent } from './dashboards/dashboard-setting/dashboard-setting.component';
import { DashboardsOverviewComponent } from './dashboards/dashboards-overview/dashboards-overview.component';


const routes: any = [
   
    { path: 'produkte', 
     component: ArtikelOverviewComponent, 
     text: 'Artikel' , 
     icon: 'k-i-edit-tools',
     expanded: false, 
     hasChildren: true,
    canActivate: [AuthGuard] ,
        children:[
          { path: 'Hersteller', component: HerstellerComponent, text: 'Hersteller' , icon: 'k-i-edit-tools', canActivate: [AuthGuard] }
        ]
    },
    { path: 'gruppen', component: GruppenComponent, text: 'Gruppen' , icon: 'k-i-connector', canActivate: [AuthGuard],
    children: [{
      path: 'hauptgruppe/:id',
      component: GruppeOverviewComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: 'baum-ansicht',
          component: BaumListeComponent, canActivate: [AuthGuard]

        }, {
          path: 'listen-ansicht',
          component: GruppenListeComponent, canActivate: [AuthGuard]

        },
        {
          path: 'listen-ansicht/:id',
          component: GruppenListeComponent, canActivate: [AuthGuard],
          children: [
            {
              path: 'attributes',
              component: GruppenattributeListeComponent, canActivate: [AuthGuard]
            }, {
              path: 'artikelliste',
              component: GruppenartikelListeComponent, canActivate: [AuthGuard]
            }
          ]
        }
      ]
    }
    ]
   },
    { path: 'assets', component: AssetOverviewComponent, text: 'Assets' , icon: 'k-i-validation-data', canActivate: [AuthGuard]},
    { path: 'settings', component: SettingsComponent, text: 'Einstellungen', icon: 'k-i-gear' , canActivate: [AuthGuard] ,
    children: [
      {
        path: 'maingroups', text: 'Hauptgruppen',
        component: HauptgruppenMainComponent, canActivate: [AuthGuard]
      }, {
        path: 'reports', text: 'Reports',
        component: ReportsOverviewComponent, canActivate: [AuthGuard]
      },
      { path: 'report-edit/:id', component: ReportSettingComponent, canActivate: [AuthGuard] }
      , {
        path: 'dashboards', text: 'Dashboards',
        component: DashboardsOverviewComponent, canActivate: [AuthGuard]
      },
      { path: 'dashboard-edit/:id', component: DashboardSettingComponent, canActivate: [AuthGuard] },
    ]
  },{ path: 'dashboard/:id', component: DashboardComponent, canActivate: [AuthGuard] } ,{ path: 'report/:id', component: ReportComponent, canActivate: [AuthGuard] },
     {
      path: 'login',
      component: LoginComponent
    },
     { path: '**', redirectTo: '' , canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
