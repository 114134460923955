import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { DbObjectDto } from './dbobjectdto.entity';
//#endregion

export const DbObjectDtoConfig = {
  name: "DbObjectDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    dbObject: {type: 'Edm.String'},
    type: {type: 'Edm.String'}
  }
} as EntityConfig<DbObjectDto>;