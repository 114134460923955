import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { KundenFilterDto } from '../Mza/Entities/Dtos/kundenfilterdto.entity';
//#endregion

@Injectable()
export class KundenFilterDtoService extends ODataEntityService<KundenFilterDto> {
  constructor(protected client: ODataClient) {
    super(client, 'KundenFilterDto', 'Mza.Entities.Dtos.KundenFilterDto');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  //#endregion
}
