import { Directive, OnInit, OnDestroy, Input, Injectable, Inject } from '@angular/core';
import { DataBindingDirective, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import {  GroupTreeAttributeDataService } from '../data-services';
import { ODataBindingDirective } from './core/o-data-binding-directive';
import { ODataEntitySetResource } from 'angular-odata';

@Directive({
    selector: '[groupTreeAttributeDataBinding]'
})
export class GroupTreeAttributeDataBindingDirective extends ODataBindingDirective {

    @Input('groupTreeAttributeDataBinding') query:  ODataEntitySetResource<any>;
    
    constructor( @Inject(GroupTreeAttributeDataService)  protected dataService: GroupTreeAttributeDataService, grid: GridComponent) {
        super(dataService,grid);
    }
    public ngOnChanges( c: any ) : void {
        console.log( "Base-Query:", this.query);
        this.rebind();
    }

    
}