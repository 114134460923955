import { Component, OnInit, Input } from '@angular/core';
import { AssetService, Asset, AssetViewDto } from 'mza-api-service';
import { isNullOrUndefined } from 'util';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthOidcService, ContentType } from 'mza-auth-service';
import { Http } from '@angular/http';

@Component({
  selector: 'gpv-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.css']
})
export class AssetDetailsComponent implements OnInit {

  @Input('filename') fileName: string ="";  
  @Input('assetId') assetId: number =0;  
  @Input('assetItems') assetItems: AssetViewDto[];  
  public width = '100%';
  public height = '100%';
  public asset: Asset;
  public activeIndex: number = 1;

  public assetDownloadFile: any;
  constructor(  public _assetService: AssetService,   private sanitizer: DomSanitizer,
    private authService: AuthOidcService,private  httpClient:HttpClient ) { }

  ngOnInit() {
    this.dataBound();
  }

  private dataBound(){
    console.log(this.assetId,this.fileName);
    if(isNullOrUndefined(this.assetId) || this.assetId === 0)
      return;

      this.activeIndex = this.assetItems.findIndex( x => x.id === this.assetId);
    let reqAsset = this._assetService.entity(this.assetId);
    reqAsset.query.select("id,name,Datatype,dataformat,tags,fileName,fileSize");//ERROR Prüfen: ,"createdBy","createdAt"
    reqAsset.query.expand("Imageformat");
    reqAsset.get().subscribe(({entity,meta}) =>{
      this.asset = entity;
  });
  }





    public assetChanged(  event: any){

      console.log(event.item);
      this.assetId = event.item.id;
      this.fileName = event.item.fileName;
      this.dataBound();
    }

    public deleteFile(){
        //Löschen umstellen auf den MZA Service und über den die Datei löschen
        const token = this.authService.getToken();
        const tokenValue = 'Bearer ' + token;
          const  headers = new  HttpHeaders().set('Authorization', tokenValue);
          
        this.httpClient.request("delete", "https://edvsrv:9989/Streaming/Delete/"+ this.fileName, {
          headers: headers,
          withCredentials: false
        }).subscribe(
          data => {
            this._assetService.destroy(this.asset).subscribe();  
          }  
        );

    /*       this.httpClient.delete("https://edvsrv:9989/Streaming/Delete/"+ this.fileName , {  headers } )  .subscribe(  
            data => {
              this._assetService.destroy(this.asset).subscribe();  
           //   let result = 'data:image/png;base64,' + data;  
            //  this.assetDownloadFile = this.sanitizer.bypassSecurityTrustUrl(data);  
            }  
          ); */

      /*       this.authService.delete("https://edvsrv:9989/Streaming/Delete/cmx5ugx4-x4q") // + this.fileName 

              .subscribe(  
                data => {  
              
                }  
              ); */
     

    }

    
}
