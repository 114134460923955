import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PlotBand } from '@progress/kendo-angular-charts';

@Component({
  selector: 'report-bullet',
  templateUrl: './report-bullet.component.html',
  styleUrls: ['./report-bullet.component.css']
})
export class ReportBulletComponent implements OnInit, OnChanges {

  @Input("data") bulletData: any = null ;

  public plotBands: PlotBand[] = [];
  public minValue: number | Date= 0;
  public maxValue: number | Date  = 100;

public bullet: any[] = [[0]];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.boundChart();
  }

  ngOnInit(): void {
    this.boundChart();
  }

  private getMinValue(): number | Date{
  let plot =  this.plotBands.reduce(function(prev, curr) {
      return prev.from < curr.from ? prev : curr;
  });
  return plot.from;
  }

  public getTitle(){
    console.log(`${this.bulletData.title}`);
    return `${this.bulletData.title}`;
  }

  private getMaxValue(): number | Date{
    let plot =  this.plotBands.reduce(function(prev, curr) {
        return prev.to > curr.to ? prev : curr;
    });
    return plot.to;
    }

    private boundChart(){
      this.plotBands = [];
      this.plotBands = this.bulletData.plotBands;  
  
    this.minValue =  this.getMinValue();
    this.maxValue =  this.getMaxValue();
    }

}
