<div class="row justify-content-center">
    <div class="col-md-8 text-center">
        <h1 class="mt-5">{{title}}</h1>
        <p class="lead">This step uses the <a href="http://docs.identityserver.io/en/latest/topics/grant_types.html#implicit" target="_blank">implicit flow type</a> and redirects to the IdentityServer instance to perform the authentication step.   Before you can login here, you must <a routerLink="/register">register a new account</a>.</p>
        <img src="assets/images/open-identity.png" width="175" class="mb-4" />
        <p>
            <button class="btn btn-lg btn-primary" (click)="login()">Login with IdentityServer</button>
            <button class="btn btn-lg btn-primary" (click)="logout()">Logout</button>
            
        </p>     
        {{authService.authorizationHeaderValue}}

        <p>storage: {{ auth}}</p>
        <p>Name: {{authService.name}}</p> {{authService}}
      </div>
      <h3>Super-secret user claims sent from the server!</h3>
      <table class="table small">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Type</th>
              <th scope="col">Value</th>                
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let claim of claims; let i = index" [attr.data-index]="i">
              <th scope="row">{{i}}</th>
              <td>{{claim.type}}</td>
              <td>{{claim.value}}</td>                   
            </tr>                 
          </tbody>
        </table>     
  </div>
