import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { BeigabenBestellartComponent } from './beigaben-bestellart/beigaben-bestellart.component';
import { BeigabenLagerortComponent } from './beigaben-lagerort/beigaben-lagerort.component';
import { BeigabenInfoStatusComponent } from './beigaben-info-status/beigaben-info-status.component';
import { BeigabenInfoSettingsComponent } from './beigaben-info-settings/beigaben-info-settings.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ArtikelBildModule } from '../artikel/artikel-bild/artikel-bild.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
@NgModule({
  declarations: [BeigabenBestellartComponent, BeigabenLagerortComponent, BeigabenInfoSettingsComponent, BeigabenInfoStatusComponent],
  imports: [
    CommonModule,FormsModule,BrowserModule, BrowserAnimationsModule,FlexLayoutModule, ArtikelBildModule,DropDownListModule,InputsModule,   ReactiveFormsModule,
  ],exports: [BeigabenBestellartComponent,BeigabenLagerortComponent,BeigabenInfoSettingsComponent]
})
export class BeigabenModule { }
