import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { FirmaPersonenDto } from './firmapersonendto.entity';
//#endregion

export const FirmaPersonenDtoConfig = {
  name: "FirmaPersonenDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    person: {type: 'entities.customers.Person', navigation: true},
    kundensegmente: {type: 'entities.customers.Kundensegment', collection: true, navigation: true}
  }
} as EntityConfig<FirmaPersonenDto>;