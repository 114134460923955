import { Component, OnInit, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { ODataEntitySetResource } from 'angular-odata';
import { State } from '@progress/kendo-data-query';
import { ArtikelDataService, ArtikelGroup, Artikel, DataArrayConvert, ArtikelGroupFilterSettings, ArtikelGroupFilterSettingsService, ArtikelService, Hersteller, HerstellerService, ProduktTypService, ProduktTyp } from 'mza-api-service';
import { flatten } from '@progress/kendo-angular-grid/dist/es2015/filtering/base-filter-cell.component';
import { isNullOrUndefined } from 'util';
import { isNumber } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { column } from '../../shared/models/column.model';

@Component({
  selector: 'gpv-artikel-overview',
  templateUrl: './artikel-overview.component.html',
  styleUrls: ['./artikel-overview.component.css']
})
export class ArtikelOverviewComponent implements OnInit {
  public  scrHeight:any;
  public scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
  }
  
public showHersteller : boolean = false;
public showProdukttyp : boolean = false;
public showArtikel: boolean = true;
public selectedAnsicht: string = "Artikel"
public listItemsAnsicht: Array<string> = ["Artikel", "Hersteller", "Produkttyp"];

public herstellerItems: Array<Hersteller> = [];
public produkttypItems: Array<ProduktTyp> = [];

  //WINDOW ARTIKEL
public opened = false;
public dataSaved = false;

public close() {
  this.opened = false;
}

public open() {
  this.opened = true;
}
//ENDE

  public selectedArtikelId: number[] = [];
  public artikelForm: FormGroup;
  public isArtikelAllValueView: boolean = false;
  public isArtikelListKachelView: boolean = false;
  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'single'
};
  public query : ODataEntitySetResource<any>;
  public state: State = {
    skip: 0,
    take: 100,
    sort: [{
      field: 'artikelNr',
      dir: 'asc'
    }],
    filter: {
      logic: 'and',
      filters: [{
        field: 'statusId',
        operator: 'gt',
        value: 0
    }]
    }
};

  constructor(public _dataServiceArtikel: ArtikelDataService,
    public artikelService: ArtikelService, 
    public herstellerService: HerstellerService, 
    public produkttypService: ProduktTypService,
    private formBuilder: FormBuilder) { 
      this.createArtikelForm  = this.createArtikelForm.bind(this);
      this.getScreenSize();
     this.loadArtikelGrid();

    }

    public createArtikelForm(args: any): FormGroup  {
      console.log("ITEM INTI:");
      let data  = <Artikel>{ id: 0,
                          artikelNr: "",
                          name: "",
                          herstellerId: null,
                          produktTypId: null
                        };
      const item : Artikel = args.isNew ? data  : args.dataItem;
    console.log("ITEM:");
      this.artikelForm = this.formBuilder.group({
        id: item.id ,
        artikelNr: [item.artikelNr,[Validators.maxLength(25), Validators.required]],
        name: [item.name,[Validators.maxLength(50), Validators.required]],
        herstellerId: [item.herstellerId],
        produktTypId: [item.produktTypId]
    });

    return this.artikelForm;
    }


  ngOnInit() {

    //loadHersteller
    let reqHersteller = this.herstellerService.entities();
    reqHersteller.query.select("herstellerID,bezeichnung");
  
    reqHersteller.get().subscribe(
      ({entities,meta}) => this.herstellerItems = entities
    );

    //loadProdukttyp
    let queryPT = this.produkttypService.entities();
    queryPT.query.select("id,name");
  
    queryPT.get().subscribe(
      ({entities,meta}) => this.produkttypItems = entities
    );
  }
  public onAssetChanges(value : number, dataItem: Artikel){
    //dataItem.artikelNr = value.toString();
    this.artikelService.assign({id: dataItem.id},{artikelNr: value.toString()}).subscribe(
      r => {
        this._dataServiceArtikel.getData();
      }
    )
   // this.assetId = value;
  }

  public onSwitchListKachelView(e, value) {
    this.isArtikelListKachelView =value;
    if(value)
      this.columnsArtikel = [];
  }
  
  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this._dataServiceArtikel.cancelChanges();
  }

  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
  
   /*  let dataA : Artikel[] = this._dataServiceArtikel.getDeletedItems();
    let data = dataA.map(( {id}) => id);
    let value = DataArrayConvert.ConvertArrayToString(data); */

    this._dataServiceArtikel.saveChanges(this.state);
   /*    if(this.dataGroupFilter.filterArtikelRemoved.length > 0){
      value = this.dataGroupFilter.filterArtikelRemoved + "," + value;
    }
    this.artikelGroupFilterService.assign({id: this.dataGroupFilter.id, filterArtikelRemoved: value}).subscribe(
      r => {
        alert("updated");
        this._dataServiceArtikelGroups.resetAllDataToSavedChanges();
      this.loadData();
      }
    ); */
  }
 public handleAnsichtChange(value) {
   console.log(value);
    if(value === "Hersteller"){
      this.showHersteller = true;
      this.showArtikel = false;
      this.showProdukttyp = false;
    }
    else  if(value === "Produkttyp"){
      this.showHersteller = false;
      this.showArtikel = false;
      this.showProdukttyp = true;
    }
    else  if(value === "Artikel"){
      this.showHersteller = false;
      this.showArtikel = true;
      this.showProdukttyp = false;
    }
  }


    public herstellerFilterChange(value: number): void {
      const [filter] = flatten( this.state.filter).filter(x => x.field === 'herstellerId');
      
        if (!filter) {
          this.state.filter.filters.push({
            field: 'herstellerId',
            operator: 'eq',
            value: value
        });
      }else{
        filter.value = value;
      }
      console.log(value);
      if(isNullOrUndefined(value) || !isNumber(value)){
        console.log("pop filter");

        this.state.filter.filters.splice(this.state.filter.filters.indexOf(filter), 1);
      }
      /* if(value > 0){
          filter.operator = "eq";
          filter.value = value;
      }
       else{
        filter.operator = "eq";
        filter.value = null;
       }  */
    this.loadData();
  }

  public produkttypFilterChange(value: number): void {
    const [filter] = flatten( this.state.filter).filter(x => x.field === 'produktTypId');
    
      if (!filter) {
        this.state.filter.filters.push({
          field: 'produktTypId',
          operator: 'eq',
          value: value
      });
    }else{
      filter.value = value;
    }
    console.log(value);
    if(isNullOrUndefined(value) || !isNumber(value)){
      console.log("pop filter");

      this.state.filter.filters.splice(this.state.filter.filters.indexOf(filter), 1);
    }
    /* if(value > 0){
        filter.operator = "eq";
        filter.value = value;
    }
     else{
      filter.operator = "eq";
      filter.value = null;
     }  */
  this.loadData();
}
  
  private loadData() {

 this._dataServiceArtikel.refresh(this.state,this.query)

  }
  public cellCloseHandler(args: any) {
    const { formGroup, dataItem } = args;
  //  this._dataServiceArtikel.assignValues(dataItem, formGroup.value);
    this._dataServiceArtikel.update(dataItem);
   
}

public getHerstellerName(id: number){
  let name = "";
  if(id === 0) return name;
  
 
  let data = this.herstellerItems.find((f) => f.herstellerID === id);
  if(!isNullOrUndefined(data))
    name = data.bezeichnung;
  return name;
}

public getProdukttypName(id: number){
  let name = "";
  if(id === 0) return name;
  
 
  let data = this.produkttypItems.find((f) => f.id === id);
  if(!isNullOrUndefined(data))
    name = data.name;
  return name;
}

  public columnsArtikel : column[] = [];

public artikelColumnsChange( event : column[]){

  this.columnsArtikel = event;
  this.loadArtikelGrid();
}

private loadArtikelGrid(){


  this.query = this._dataServiceArtikel.getQuery();
 
  let selectedFields = this.columnsArtikel.map(m => m.name);
  selectedFields.push("id");
  selectedFields.push("artikelNr");
  selectedFields.push("name");
  selectedFields.push("herstellerId");
  selectedFields.push("produktTypId");
  
/*   selectedFields.push("imShopSichtbar");
  selectedFields.push("alphaNr");
  selectedFields.push("warengruppenNr");
  selectedFields.push("internetmarke");
  selectedFields.push("lieferantenNr"); */
  this.query.query.select(selectedFields);


}

}
