import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KundensortimentDto } from './kundensortimentdto.entity';
//#endregion

export const KundensortimentDtoConfig = {
  name: "KundensortimentDto",
  annotations: [],
  fields: {
    valueId: {type: 'Edm.Guid'},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    registrierungsId: {type: 'Edm.Int32', nullable: false},
    sortimentId: {type: 'Edm.Int32', nullable: false},
    sortimentsbezeichnung: {type: 'Edm.String'},
    isFreigeschaltet: {type: 'Edm.Boolean', nullable: false},
    hasFreischaltungBeantragt: {type: 'Edm.Boolean', nullable: false},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<KundensortimentDto>;