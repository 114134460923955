import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { UserStateDto } from './userstatedto.entity';
//#endregion

export const UserStateDtoConfig = {
  name: "UserStateDto",
  annotations: [],
  fields: {
    email: {type: 'Edm.String'},
    alias: {type: 'Edm.String'},
    vorname: {type: 'Edm.String'},
    nachname: {type: 'Edm.String'},
    isRegistriert: {type: 'Edm.Boolean', nullable: false},
    registrationId: {type: 'Edm.Int32', nullable: false},
    id: {type: 'Edm.Int32', nullable: false},
    isAboActive: {type: 'Edm.Boolean', nullable: false},
    firma: {type: 'Edm.String'},
    preferred_username: {type: 'Edm.String'},
    wappenUrl: {type: 'Edm.String'},
    interneFirmenNr: {type: 'Edm.String'},
    firmaId: {type: 'Edm.Int32'},
    kundenNr: {type: 'Edm.Int32', nullable: false},
    kundengruppe: {type: 'Edm.String'},
    kundensegment: {type: 'Edm.String'},
    roles: {type: 'Edm.String', collection: true}
  }
} as EntityConfig<UserStateDto>;