import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Kundengruppe } from './kundengruppe.entity';
//#endregion

export const KundengruppeConfig = {
  name: "Kundengruppe",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kundensegmente: {type: 'entities.customers.Kundensegment', navigation: true}
  }
} as EntityConfig<Kundengruppe>;