import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BeigabenArtikel } from './beigabenartikel.entity';
//#endregion

export const BeigabenArtikelConfig = {
  name: "BeigabenArtikel",
  annotations: [],
  fields: {
    beigabenId: {type: 'Edm.Int32'},
    artikelId: {type: 'Edm.Int32'},
    menge: {type: 'Edm.Int32', nullable: false},
    isEinmalig: {type: 'Edm.Boolean', nullable: false},
    mindestWarenwert: {type: 'Edm.Int32', nullable: false},
    maxAbgabeMenge: {type: 'Edm.Int32'},
    maxAbgabeMengeGesamt: {type: 'Edm.Int32'},
    landGruppenId: {type: 'Edm.Int32'},
    isVerhaeltnis: {type: 'Edm.Boolean', nullable: false},
    isAktiv: {type: 'Edm.Boolean', nullable: false},
    lagerortId: {type: 'Edm.Int32', nullable: false},
    useAusloeser: {type: 'Edm.Boolean', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'},
    beigabe: {type: 'entities.produkte.Beigabe', navigation: true},
    ausloeser: {type: 'entities.produkte.BeigabenAusloeser', collection: true, navigation: true}
  }
} as EntityConfig<BeigabenArtikel>;