import { Component, OnInit, Input, SimpleChanges, Inject, Output, EventEmitter, HostListener } from '@angular/core';
import { AssetViewDto, ArtikelGroupService, Property, PropertyService, Asset,AssetService } from 'mza-api-service';
import { State, GroupDescriptor } from '@progress/kendo-data-query';
import { ODataEntitySetResource, Filter } from 'angular-odata';
import { isNullOrUndefined } from 'util';
import { HttpParams } from '@angular/common/http';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { FormatHelper } from '../../shared/helpers/format-helper';
import { TreeSettings } from '../../shared/models/treeviewgroup.model';

@Component({
  selector: 'gpv-asset-overview',
  templateUrl: './asset-overview.component.html',
  styleUrls: ['./asset-overview.component.css']
})
export class AssetOverviewComponent implements OnInit {
  public scrHeight:any;
  public scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
  }

   uploadSaveUrl = this.assetUrl +'/Streaming/UploadPhysical'//'https://localhost:44396/Streaming/UploadPhysical'; // should represent an actual API endpoint
  //  uploadSaveUrl = 'https://localhost:44396/Streaming/UploadPhysical'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
@Input("showTree") showTree :boolean = true;
@Input("groupId") groupId : number = 0;
@Input("isSelectedMode") isSelectedMode : boolean = false;
@Output("assetId") assetId : EventEmitter<number> = new EventEmitter<number>(); 

public SettingTree: TreeSettings ={
  allowDragAndDrop : false,
  allowTreeStructur : false,
  allowMultiSelection : false,
  allowGroupTreeAssignmentForParent: false,
  allowGroupTreeAssignment: false,

  allowGroupsInDifferentNodes: false,
  allowAddRemove: false,
  allowChecked: false,
  showValue: false,
  showDescription: false,
  showBar: false,
  usedSorts: ""
 
};


public metaDataAnsichtItems: Property[] 
public metaDataFormatItems: Property[] 
public metaDataImageSizeItems : Property[] 
public Assets: AssetViewDto[];
public query : ODataEntitySetResource<any>;
public imagePreviewSize: number = 1;
public state: State = {
  skip: 0,
  take: 20,
  sort: [{
    field: 'artikelNr',
    dir: 'asc'
  }]
};
public isArtikelListKachelView: boolean = true;


//ASSET GRID SELECT ASSET AND OPEN WINDOW FUNCTION
public selectedAsset : AssetViewDto;
public selectedFileName : string;
public selectedAssetId: string[] = [];

public selectableSettings: SelectableSettings = {
  checkboxOnly: false,
  mode: 'single'
};
public onSelectedKeysChange(e) {
  const len = this.selectedAssetId.length;

 if (len !== 1) {
  this.openedAsset = false;
  return;
  }

  [this.selectedAsset]  = this.Assets.filter(x => x.fileName === this.selectedAssetId[0]);

  if(!this.isSelectedMode){
      this.openAssetDetailWindow();
  }
  else{
      this.assetId.emit(this.selectedAsset.id);
  }
}

private openAssetDetailWindow(): void
{

  this.openedAsset = true;
  
 
   this.selectedFileName =this.selectedAssetId[0];
  
 /*   let dataA = this.artikelService.entity(this.selectedAssetId[0]);

     dataA.select(["id","artikelNr", "WarengruppenNr", "SimsonArtikelNr", 
                   "MZArtikelNr","IWLArtikelNr", "EMWArtikelNr", "AlphaNr", 
                   "Originalteil", "Gesperrt","StVoZulassung", "ISBN", "GTIN",
                   "Qualitaetszeichen", "Internetmarke", "ImShopSichtbar",
                   "ServicepointArtikel","SortimentSimson","WeitereSimsonNr","SortimentMZ"
                   ,"WeitereMZNr" ,"SortimentIWL","WeitereIWLNr","SortimentEMW","WeitereEMWNr"
                   ,"SortimentZubehoer","SortimentWindhoff","SortimentUnitrike","WindhoffArtikelNr","UnitrikeArtikelNr","WeitereWindhoffNr","WeitereUnitrikeNr",
                   "ImportDatum"] );
     dataA.get().subscribe(([res,]) =>{
       this.selectedAsset = res[0];
   }); */
 
}

// ASSET SELECTED-SECTION
//WINDOW ARTIKEL
public openedAsset = false;
public dataSaved = false;

public closeAsset() {
  this.openedAsset = false;
}

public openAsset() {
  this.openedAsset = true;
}
//ENDE

public onSwitchListKachelView(e, value) {
  this.isArtikelListKachelView =value;
}

public isArtikelAllValueView: boolean = false;
  constructor(private _serviceArtikelGroup: ArtikelGroupService, 
    private _serviceAsset: AssetService, 
    private _serviceProperty: PropertyService,
    @Inject('ASSET_URL') private assetUrl: string )
     {
      this.getScreenSize();
     }

  ngOnInit() {
   //var query = this._serviceArtikelGroup.entities();
  /*  query.ge({artikelAssets: { expand:{ArtikelGroup:{}}}});
   //query.filter().push({ArtikelAsset: {ArtikelGroup: {GroupId: 21}}});
   query.get({withCount: true}).subscribe(
     
   ); */
    this.loadDataOverview();
    this.loadFilter();
  }
  
  private loadData(){
    this.loadDataOverview();
    this.loadDataForGroups();
  }
  private loadFilter(){

    let queryAnsicht = this._serviceProperty.entities();
    queryAnsicht.filter({typeId: {eq: 2}});
    queryAnsicht.get().subscribe(
      ({entities, meta}) => {
        this.metaDataAnsichtItems = entities;
      }
    );

    let queryFormat = this._serviceProperty.entities();
    queryFormat.filter({typeId: {eq: 1}});
    queryFormat.get().subscribe(
      ({entities, meta}) => {
        this.metaDataFormatItems = entities;
      }
    );

    let queryImageSize = this._serviceProperty.entities();
    queryImageSize.filter({typeId: {eq: 3}});
    queryImageSize.get().subscribe(
      ({entities, meta}) => {
        this.metaDataImageSizeItems = entities;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  
  }
  public FilterArtikelValues: number[] = [];
  public FilterAsichtValues: number[] = [];
  public FilterImageSizeValues: number[] = [];
  public FilterFormatValues: number[] = [];

  private getFilterParams() : HttpParams{
    let filters : Filter = [{propertyId: {eq: this.FilterAsichtValues[0]}}]; //? Zeile drunter
    if(this.FilterAsichtValues.length > 0)
      filters.push({propertyId: {eq: this.FilterAsichtValues[0]}});
    
      let filterPropertyInValues = [];
      let filterArtikelInValues = [];
      let filterCountSize: string ="";

      let filterPropertyCount: number = 0;
      let filterArtikelCount: number = 0;
      
     if(this.FilterAsichtValues.length > 0){
       filterPropertyCount++;
       filterPropertyInValues.push(this.FilterAsichtValues);
     }

     if(this.FilterImageSizeValues.length > 0){
      filterPropertyCount++;
       filterPropertyInValues.push(this.FilterImageSizeValues);
     }

     if(this.FilterFormatValues.length > 0){
        filterPropertyCount++;
        filterPropertyInValues.push(this.FilterFormatValues);
     }
     
     if(this.FilterArtikelValues.length > 0){
      filterArtikelCount++;
      filterArtikelInValues.push(this.FilterArtikelValues);
     }
     
    let filter = "";
    
    if(filterPropertyCount > 0 || filterArtikelCount > 0 )
      filter = "filter("

    if(filterPropertyCount > 0){
      filter += "propertyId in ("+filterPropertyInValues+")"; 
    }
    if(filterPropertyCount > 0 && filterArtikelCount > 0 )
    filter += " AND "

    if(filterArtikelCount > 0)
      filter += "artikelId in("+filterArtikelInValues+")"; 
      
    if(filterPropertyCount > 0 || filterArtikelCount > 0 )
    filter += ")/"

    if(filterPropertyCount > 1 || filterArtikelCount > 1 ){
       
        filterCountSize = ",aggregate($count as count))/filter(count gt "+ (filterPropertyCount-1)
     }

    let  params = new HttpParams()
      .set('$apply', filter+'groupby((id,artikelId,fileSize,name,fileName)'+filterCountSize+')');
      return params;
  }

  private loadDataOverview() {
    if (!isNullOrUndefined(this.groupId) && this.groupId > 0)
      return;

      
      let getAssetHist = this._serviceAsset.getOverview();
      getAssetHist.call(null,"entities",{params: this.getFilterParams()}).subscribe( (entities) => {
        console.log("Asset load:",entities);
       this.Assets = entities;   
        } ); 

     /*   let query = this._serviceAsset.getOverview({params: this.getFilterParams()})
      .subscribe( ([data, entity]) => {
  
      this.Assets = data;
     
       } );  */
  }


  private loadDataForGroups() {
    if (isNullOrUndefined(this.groupId) )
      return;
    //let filters : Filter = []; //= [{value: {contains: value}}];
    let filters : Filter = [{propertyId: {eq: this.FilterAsichtValues[0]}}];
    if(this.FilterAsichtValues.length > 0)
      filters.push({propertyId: {eq: this.FilterAsichtValues[0]}});
    
    
    // let params = {"$filter": "propertyId in ("+this.FilterAsichtValues+","+this.FilterImageSizeValues+","+this.FilterFormatValues+")"};
     let filterInValues = [];
    
      let filterCountSize: string ="";

      let filterCount: number = 0
     if(this.FilterAsichtValues.length > 0){
       filterCount++;
       filterInValues.push(this.FilterAsichtValues);
     }
        
        //filterAsicht = " and propertyId in ("+this.FilterAsichtValues+")";
    
     if(this.FilterImageSizeValues.length > 0){
      filterCount++;
       filterInValues.push(this.FilterImageSizeValues);
     }
        
      // filterImageSize = " and propertyId in ("+this.FilterImageSizeValues+")";

     if(this.FilterFormatValues.length > 0){
      filterCount++;
          filterInValues.push(this.FilterFormatValues);
     }
     
     // filterFormat= " and propertyId in ("+this.FilterFormatValues+")";
     
    let filter = "";
    
    if(filterCount > 0){
      filter = "filter(propertyId in ("+filterInValues+"))/"; 
    }
    if(filterCount > 1){
       
        filterCountSize = ",aggregate($count as count))/filter(count gt "+ (filterCount-1)
     }
   
 

    let  params = new HttpParams()
      .set('$apply', filter+'groupby((id,artikelId,fileSize,name,fileName)'+filterCountSize+')');
      
    //.set('page', '3')
    //.set('sort', 'name');
    // let params = {"$select": "id,artikelId,fileSize,name,fileName"+filter+"&$groupby(id,artikelId,fileSize,name,fileName)"};

     let query = this._serviceArtikelGroup.getAssets()
     query.call({groupId:this.groupId},"entities",{params: this.getFilterParams()})
    .subscribe( (data) => {

    this.Assets = data;
   
     } ); 
  }
  public getFileSize(bytes,decimals){
    return FormatHelper.formatBytes(bytes,decimals);
  }

public onValueChange(value){
  this.loadData();
}

public nodeSelected({ index, dataItem }: any): void {
  //this.selectedKeys = [index];
  console.log("node selected Baum-Liste",dataItem,index );

/*   let reqGroupTreeAssigment = this._groupTreeAssigmentService.entities();
  reqGroupTreeAssigment.query.select("GroupId");
  reqGroupTreeAssigment.query.filter({groupTreeId: this.selectedDataItem.id});
  reqGroupTreeAssigment.get({withCount: false}).subscribe(({entities,meta}) => {
    console.log("anzahl ergebnise", meta.count )
    if(entities.length > 0){
       this.groupId = entities[0].groupId;
       this.groupIds = entities.map(m => m.groupId);
    }else{
      this.groupIds = undefined;
       this.groupId = null;
    }
       
  }
    ); */
  
};

public ShowOptions: Array<Item> = [
  { text: "Aus", value: "none" },
/*   { text: "Auswahl", value: "click" }, */
  { text: "Maus-Over", value: "hover" }
 
];
public showOption: Item = this.ShowOptions[1];
}
interface Item {
  text: string,
  value: string
}