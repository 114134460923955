import { Component, EventEmitter,  OnInit, Output } from '@angular/core';

import { EntityConfig, Field } from 'angular-odata';
import { ArtikelConfig } from 'mza-api-service'
import { column } from '../../models/column.model';


@Component({
  selector: 'odata-column-select',
  templateUrl: './odata-column-select.component.html',
  styleUrls: ['./odata-column-select.component.css']
})

export class OdataColumnSelectComponent implements OnInit {

 @Output() columnChanged : EventEmitter<string[]> = new EventEmitter();

  constructor() { 
    this.columnConfig = ArtikelConfig;
    let columnsTemp : column[] = [];
     for (var columnName in  this.columnConfig.fields) {
       var field =  this.columnConfig.fields[columnName];
       console.log("columnConfig:",field.type.substring(0,2));
       if(field.type.substring(0,3) ==="Edm")
       columnsTemp.push({name: columnName, isCheck: false, value: columnName, filterType: this.getColumnTypForFilter(field.type) });
         // this.columns.push({name: columnName, isCheck: false});
       //Auflösung des Field Typ in die einzelnen Properties. Wird aktuell nicht gebraucht   
       /* for (var fieldValueId in field) {
           var fieldValue = field[fieldValueId];
           console.log("columnConfig",columnName,field.type, fieldValue);
       } */
   }​
   this.columns = columnsTemp.sort(function(a, b){
     if(a.name < b.name) { return -1; }
     if(a.name > b.name) { return 1; }
     return 0;
     
 });
  }

  public columnConfig : EntityConfig<any>;
  public columns : column[] = [];
  // public selectedColumns : column[] = [];
  
  public selectedColumns : string[] = [];


  ngOnInit(): void {

    console.log("columnConfig:",this.columns);
  /*   this.columns = this.columnConfig.fields[0].
    .forEach(
      ([key, value]) => console.log(key, value);
    ); // map(element = > pick(element, fields)
     */
  }

  private count(obj) { return Object.keys(obj).length; }

  public onValueChange(event: any) {
    this.columnChanged.emit(this.selectedColumns);
   
}
private getColumnTypForFilter(schemaType: string): string{
 console.log("getColumnTypForFilter",schemaType.substr(4,schemaType.length-4).toLowerCase()) ;
 let filterTyp = schemaType.substr(4,schemaType.length-4).toLowerCase();
 if(filterTyp === "int16" ||filterTyp === "int32" || filterTyp === "decimal" || filterTyp === "double"  )
  return "numeric";

if(filterTyp === "datetimeoffset" || filterTyp === "decimal" )
  return "date";

  if( filterTyp === "boolean" )
  return filterTyp;

return "";
}
  public isStatusSet(isCheck: boolean):boolean{
    return isCheck;
   // return this.dataStatusGroupTree.findIndex(x => x.statusId === id) >= 0 ? true : false;
  }

  public setStatus(isCheck: boolean){
    console.log("setStatus", isCheck);
    return isCheck;
  /*   let idx = this.dataStatusGroupTree.findIndex(x => x.statusId === id) ;
   if( idx >= 0){
    this.statusGroupTreeService.destroy({id: this.dataStatusGroupTree[idx].id}).subscribe((result) => this.dataStatusGroupTree.splice(idx,1));
   }else{
      this.statusGroupTreeService.create({groupTreeId: this.groupTreeId,statusId: id})
      .subscribe((entity) => {
        //odata-meta noch als property enthalten
        this.dataStatusGroupTree.push(entity);
      });
   } */
 
  }

}
