<p>Registrierungs-Id: </p>
<kendo-combobox #registrierungsNrFilter
[data]="data"
[textField]="'valueId'"
[valueField]="'valueId'"
(valueChange)="onValueChange($event)"
[(ngModel)]="selectedItem"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Registrierung suchen...'"
[disabled]="disabled"
>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div>
            <p>Id: <b>{{ dataItem.id }}</b></p>
            <p>{{ dataItem.firma }}, {{ dataItem.postleitzahl }} {{ dataItem.ort }}</p>
        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox>