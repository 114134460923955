import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { MetaData } from '../entities/assets/metadata.entity';
import { Property } from '../entities/ref/property.entity';
import { Type } from '../entities/ref/type.entity';
//#endregion

@Injectable()
export class TypeService extends ODataEntityService<Type> {
  constructor(protected client: ODataClient) {
    super(client, 'Type', 'entities.ref.Type');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public metaDataList(entity: EntityKey<Type>): ODataNavigationPropertyResource<MetaData> {
    return this.entity(entity).navigationProperty<MetaData>('metaDataList');
  }
  public properties(entity: EntityKey<Type>): ODataNavigationPropertyResource<Property> {
    return this.entity(entity).navigationProperty<Property>('properties');
  }
  //#endregion
}
