import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { ArtikelAttribute } from '../entities/articles/artikelattribute.entity';
import { Attribute } from '../entities/schema/attribute.entity';
import { GroupAttribute } from '../entities/schema/groupattribute.entity';
import { GroupTreeAttribute } from '../entities/schema/grouptreeattribute.entity';
import { MaingroupAttribute } from '../entities/schema/maingroupattribute.entity';
import { ProduktTypAttribute } from '../entities/produkte/produkttypattribute.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class AttributeService extends ODataEntityService<Attribute> {
  constructor(protected client: ODataClient) {
    super(client, 'Attribute', 'entities.schema.Attribute');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public artikelAttributes(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<ArtikelAttribute> {
    return this.entity(entity).navigationProperty<ArtikelAttribute>('artikelAttributes');
  }
  public groupAttributes(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<GroupAttribute> {
    return this.entity(entity).navigationProperty<GroupAttribute>('groupAttributes');
  }
  public groupTreeAttributes(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<GroupTreeAttribute> {
    return this.entity(entity).navigationProperty<GroupTreeAttribute>('groupTreeAttributes');
  }
  public maingroupAttributes(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<MaingroupAttribute> {
    return this.entity(entity).navigationProperty<MaingroupAttribute>('maingroupAttributes');
  }
  public produktTypAttributes(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<ProduktTypAttribute> {
    return this.entity(entity).navigationProperty<ProduktTypAttribute>('produktTypAttributes');
  }
  public status(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Attribute>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
