import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Artikel } from '../entities/artikel.entity';
import { BeigabenArtikel } from '../entities/produkte/beigabenartikel.entity';
import { BeigabenAusloeser } from '../entities/produkte/beigabenausloeser.entity';
import { Beigabe } from '../entities/produkte/beigabe.entity';
//#endregion

@Injectable()
export class BeigabenArtikelService extends ODataEntityService<BeigabenArtikel> {
  constructor(protected client: ODataClient) {
    super(client, 'BeigabenArtikel', 'entities.produkte.BeigabenArtikel');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public artikel(entity: EntityKey<BeigabenArtikel>): ODataNavigationPropertyResource<Artikel> {
    return this.entity(entity).navigationProperty<Artikel>('artikel');
  }
  public ausloeser(entity: EntityKey<BeigabenArtikel>): ODataNavigationPropertyResource<BeigabenAusloeser> {
    return this.entity(entity).navigationProperty<BeigabenAusloeser>('ausloeser');
  }
  public beigabe(entity: EntityKey<BeigabenArtikel>): ODataNavigationPropertyResource<Beigabe> {
    return this.entity(entity).navigationProperty<Beigabe>('beigabe');
  }
  //#endregion
}
