import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { BeigabenAusloeser } from './beigabenausloeser.entity';
//#endregion

export const BeigabenAusloeserConfig = {
  name: "BeigabenAusloeser",
  annotations: [],
  fields: {
    artikelId: {type: 'Edm.Int32'},
    beigabenArtikelId: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'},
    beigabenArtikel: {type: 'entities.produkte.BeigabenArtikel', navigation: true, field: 'beigabenArtikelId', ref: 'id'}
  }
} as EntityConfig<BeigabenAusloeser>;