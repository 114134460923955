import { Injectable } from '@angular/core';
 
@Injectable()
export class ConfigService {    
    constructor() {
      
    }
    /* constructor(authServer : string, restServer : string) {
        this._authServer = authServer;
        this._restServer = restServer;
    } */

    private _authServer : string ="http://localhost:5000";
    private _restServer : string = "http://localhost:44370/v1";

    get authApiURI() {
        return this._authServer;
    }    
     
    get resourceApiURI() {
        return this._restServer;
    }  
}