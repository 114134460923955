import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Beigabe } from './beigabe.entity';
//#endregion

export const BeigabeConfig = {
  name: "Beigabe",
  annotations: [],
  fields: {
    bezeichnung: {type: 'Edm.String'},
    kundenFilterId: {type: 'Edm.Int32'},
    gueltigVon: {type: 'Edm.DateTimeOffset'},
    gueltigBis: {type: 'Edm.DateTimeOffset'},
    showInWarenkorb: {type: 'Edm.Boolean', nullable: false},
    showInHinweistext: {type: 'Edm.Boolean', nullable: false},
    isAktiv: {type: 'Edm.Boolean', nullable: false},
    reportTypId: {type: 'Edm.Int32'},
    isDirektversand: {type: 'Edm.Boolean', nullable: false},
    isArchiv: {type: 'Edm.Boolean', nullable: false},
    typId: {type: 'Edm.Int32', nullable: false},
    bestellArt: {type: 'Edm.Int32', nullable: false},
    beigabenArtId: {type: 'Edm.Int32', nullable: false},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    beigabenTyp: {type: 'services.BeigabenTyp', navigation: true},
    beigabenArt: {type: 'entities.ref.Property', navigation: true},
    kundenFilter: {type: 'entities.customers.KundenFilter', navigation: true, field: 'kundenFilterId', ref: 'id'},
    beigabenArtikel: {type: 'entities.produkte.BeigabenArtikel', collection: true, navigation: true},
    beigabenKunden: {type: 'entities.produkte.BeigabenKunde', collection: true, navigation: true}
  }
} as EntityConfig<Beigabe>;