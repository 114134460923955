import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { RefType } from './reftype.enum';
import { GroupTreeAssigment } from '../entities/schema/grouptreeassigment.entity';
import { GroupTreeAttribute } from '../entities/schema/grouptreeattribute.entity';
import { GroupTree } from '../entities/schema/grouptree.entity';
import { Maingroup } from '../entities/schema/maingroup.entity';
import { StatusGroupTree } from '../entities/settings/statusgrouptree.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
import { SortResultDto } from '../mza/Service/Models/sortresultdto.entity';
import { SortDto } from '../mza/Service/Models/sortdto.entity';
//#endregion

@Injectable()
export class GroupTreeService extends ODataEntityService<GroupTree> {
  constructor(protected client: ODataClient) {
    super(client, 'GroupTree', 'entities.schema.GroupTree');
  }

  
  
  //#region ODataApi Actions
  public mergeGroupTreesInOne(entity: EntityKey<GroupTree>): ODataActionResource<{groupTreeIds: number[]}, any> {
    const resource = this.entity(entity).action<{groupTreeIds: number[]}, any>('services.MergeGroupTreesInOne');
    return resource;
  }
  public convertFolderInGroup(entity: EntityKey<GroupTree>): ODataActionResource<null, any> {
    const resource = this.entity(entity).action<null, any>('services.ConvertFolderInGroup');
    return resource;
  }
  public removeGroupTreeWithChildren(entity: EntityKey<GroupTree>): ODataActionResource<null, any> {
    const resource = this.entity(entity).action<null, any>('services.RemoveGroupTreeWithChildren');
    return resource;
  }
  public removeGroupTreeWithChildrenAndGroups(entity: EntityKey<GroupTree>): ODataActionResource<null, any> {
    const resource = this.entity(entity).action<null, any>('services.RemoveGroupTreeWithChildrenAndGroups');
    return resource;
  }
  public removeGroupTreeRefItem(entity: EntityKey<GroupTree>): ODataActionResource<{ maingroupParentId: number}, any> {
    const resource = this.entity(entity).action<{maingroupParentId: number}, any>('services.RemoveGroupTreeRefItem');
    return resource;
  }
  public getSortResult(): ODataFunctionResource<{sortId: number, maingroupId: number}, SortResultDto> {
    const resource = this.entities().function<{sortId: number, maingroupId: number}, SortResultDto>('services.GetSortResult');
    return resource;
  }
  public getSorts(): ODataFunctionResource<{maingroupId: number}, SortDto> {
    const resource = this.entities().function<{maingroupId: number}, SortDto>('services.GetSorts');
    return resource;
  }
  //#endregion
  //#region ODataApi Functions
  public getGroupTreeRefIds(): ODataFunctionResource<{childId: string}, number> {
    const resource = this.entities().function<{childId: string}, number>('services.GetGroupTreeRefIds');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public attributes(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<GroupTreeAttribute> {
    return this.entity(entity).navigationProperty<GroupTreeAttribute>('attributes');
  }
  public groupTreeAssigments(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<GroupTreeAssigment> {
    return this.entity(entity).navigationProperty<GroupTreeAssigment>('groupTreeAssigments');
  }
  public maingroup(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<Maingroup> {
    return this.entity(entity).navigationProperty<Maingroup>('maingroup');
  }
  public status(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusGroupTree(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<StatusGroupTree> {
    return this.entity(entity).navigationProperty<StatusGroupTree>('statusGroupTree');
  }
  public statusType(entity: EntityKey<GroupTree>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
