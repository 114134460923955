import { NgModule } from '@angular/core';
import { AfterValueChangedDirective } from './after-value-changed.directive';




@NgModule({
  imports: [],
  declarations: [AfterValueChangedDirective],
  exports:[AfterValueChangedDirective]
})
export class DirectiveModule { }
