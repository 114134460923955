import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelbezeichnungsgeneratorBausteinDto } from './artikelbezeichnungsgeneratorbausteindto.entity';
//#endregion

export const ArtikelbezeichnungsgeneratorBausteinDtoConfig = {
  name: "ArtikelbezeichnungsgeneratorBausteinDto",
  annotations: [],
  fields: {
    tbTypWarengruppeId: {type: 'Edm.Int32', nullable: false},
    tbTypZusammensetztungId: {type: 'Edm.Int32', nullable: false},
    artikelId: {type: 'Edm.Int32'},
    warengruppenNr: {type: 'Edm.String'},
    typArtikel: {type: 'Edm.String'},
    typBaustein: {type: 'Edm.String'},
    textBaustein: {type: 'Edm.String'},
    bezeichnung: {type: 'Edm.String'},
    typBeschr: {type: 'Edm.String'},
    textBeschr: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32', nullable: false},
    valueId: {type: 'Edm.Int32'},
    value: {type: 'Edm.String'},
    useValue: {type: 'Edm.Boolean'},
    defaultLabel: {type: 'Edm.String'},
    useKomma: {type: 'Edm.Boolean'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<ArtikelbezeichnungsgeneratorBausteinDto>;