import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KalkulationsGruppe } from './kalkulationsgruppe.entity';
//#endregion

export const KalkulationsGruppeConfig = {
  name: "KalkulationsGruppe",
  annotations: [],
  fields: {
    lieferantNr: {type: 'Edm.String'},
    bezeichnungSchema: {type: 'Edm.String'},
    bezeichnung: {type: 'Edm.String'},
    bezeichnungsId: {type: 'Edm.String'},
    value: {type: 'Edm.Decimal', nullable: false},
    useSchemaForBezeichungsId: {type: 'Edm.Boolean', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikelPreise: {type: 'entities.produkte.ArtikelPreis', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<KalkulationsGruppe>;