import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { FirmaAdresseDto } from './firmaadressedto.entity';
//#endregion

export const FirmaAdresseDtoConfig = {
  name: "FirmaAdresseDto",
  annotations: [],
  fields: {
    firmaId: {type: 'Edm.Int32', nullable: false},
    isRechnungsAdresse: {type: 'Edm.Boolean', nullable: false},
    strasse: {type: 'Edm.String'},
    hausnummer: {type: 'Edm.String'},
    postleitzahl: {type: 'Edm.String'},
    ort: {type: 'Edm.String'},
    ortsteil: {type: 'Edm.String'},
    landId: {type: 'Edm.Int32', nullable: false},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<FirmaAdresseDto>;