import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardSettingComponent } from './dashboard-setting/dashboard-setting.component';
import { DashboardsOverviewComponent } from './dashboards-overview/dashboards-overview.component';

import { GridModule } from '@progress/kendo-angular-grid';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LabelsModule } from 'mza-components';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MatButton } from '@angular/material/button';

import { RouterModule } from '@angular/router';
import { InputsModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';

import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { IconsModule } from '@progress/kendo-angular-icons';
import { DirectiveModule } from '../shared/directive/directive.module';
import { ReportsModule } from '../reports/reports.module';

import { OrderModule } from 'ngx-order-pipe';

@NgModule({
  declarations: [DashboardComponent, DashboardSettingComponent, DashboardsOverviewComponent],
  imports: [  CommonModule,GridModule, NotificationModule ,FormsModule, RouterModule,  ButtonsModule, ReactiveFormsModule, 
    DropDownsModule, TextBoxModule ,NumericTextBoxModule ,InputsModule,DialogModule,
    LabelsModule,IconsModule,
    ButtonsModule, MatCheckboxModule,MatInputModule,WindowModule,ChartsModule,DirectiveModule,ReportsModule,OrderModule,
    FlexLayoutModule,
    LabelsModule,
  ],exports:[DashboardComponent]
})
export class DashboardsModule { }
