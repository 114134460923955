import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { SortimentDto } from './sortimentdto.entity';
//#endregion

export const SortimentDtoConfig = {
  name: "SortimentDto",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    beschreibung: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<SortimentDto>;