import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { KundeDto } from './kundedto.entity';
//#endregion

export const KundeDtoConfig = {
  name: "KundeDto",
  annotations: [],
  fields: {
    kundenNr: {type: 'Edm.Int32', nullable: false},
    firma: {type: 'Edm.String'},
    email: {type: 'Edm.String'},
    websiteUrl: {type: 'Edm.String'},
    isNewsletter: {type: 'Edm.Boolean', nullable: false},
    isHaendlersucheAktiv: {type: 'Edm.Boolean', nullable: false},
    anrede: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    ansprechpartner: {type: 'Edm.String'},
    strasse: {type: 'Edm.String'},
    plz: {type: 'Edm.String'},
    ort: {type: 'Edm.String'},
    land: {type: 'Edm.String'},
    telefon: {type: 'Edm.String'},
    fax: {type: 'Edm.String'},
    g8: {type: 'Edm.String'},
    gesamtumsatz: {type: 'Edm.Int32', nullable: false},
    gesamtumsatzLetzesJahr: {type: 'Edm.Int32', nullable: false},
    gesamtumsatzZukunft: {type: 'Edm.Int32'},
    gesamtMindestumsatzUnterschrittenAm: {type: 'Edm.DateTimeOffset'},
    abc: {type: 'Edm.String'},
    vertreterNr: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<KundeDto>;