import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelConfig } from './artikel.entity.config';
import { WarengruppeDtoConfig } from './warengruppedto.entity.config';
import { KundeDtoConfig } from './kundedto.entity.config';
//#endregion

export const EntitiesSchema = {
  namespace: "entities",
  enums: [],
  entities: [ArtikelConfig,
    WarengruppeDtoConfig,
    KundeDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;