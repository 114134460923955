//#region ODataApi Imports
//#endregion

export const ISFLAGS_PHASETYPE = false;
export enum PhaseType {
  //#region ODataApi Members
  DELETE = 1,
  ARCHIV = 20,
  CONSTRUCT = 25,
  DRAFT = 30,
  PENDING = 40,
  PUBLISH_INTERN = 50,
  PUBLISH_EXTERN = 75,
  PUBLISH_ALL = 100,
  //#endregion
}
