import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HauptgruppenMainComponent } from './hauptgruppen-main.component';

import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { GridModule } from '@progress/kendo-angular-grid';
import { MainGroupBindingDirective } from 'mza-api-service';
import { MultiSelectModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [HauptgruppenMainComponent,MainGroupBindingDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeViewModule,
    InputsModule,
    FormsModule, MultiSelectModule,
    ButtonsModule, MatButtonModule, MatCheckboxModule,MatInputModule,MatBadgeModule,
    GridModule,DropDownListModule
  ],exports: [HauptgruppenMainComponent]
})
export class HauptgruppenMainModule { }
