import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import {zip } from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat';
import { ODataEntityService, ODataEntitySetResource ,PlainObject } from 'angular-odata';
import { isUndefined, isNullOrUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { State, FilterDescriptor, isCompositeFilterDescriptor} from '@progress/kendo-data-query';
import { NotificationService } from '@progress/kendo-angular-notification';



const itemIndex = (item: any, data: any[]): number => {
  if(isNullOrUndefined(data))
  return -1;

  for (let idx = 0; idx < data.length; idx++) {
    if(data[idx].id > 0){
        if (data[idx].id === item.id) {
          return idx;
      }
    }else{
      if(!isUndefined(data[idx].valueId )){
        if (data[idx].valueId === item.valueId) {
          return idx;
        }
      }
    }
    
  }

  return -1;
};
//const cloneData = (griddata: GridDataResult) => griddata.data.map(item => Object.assign({}, item));
const cloneData = (data: any[]) => data.map(item => Object.assign({}, item));

export abstract class GridDataService extends BehaviorSubject<GridDataResult> {

 // public _notificationService: NotificationService;
  public loading: boolean;
  public selectedItems: string[] = [];

  private data  = new DataResult();
  private originalData  = new DataResult();
  private createdItems: any[] = [];
  private updatedItems: any[] = [];
  private deletedItems: any[] = [];
  private savedItems: any[] = [];
  private total : number = 0;

  protected reset() {
    this.data = new DataResult();
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
}
protected resetChangedDataOnly() {
  this.deletedItems = [];
  this.updatedItems = [];
  this.createdItems = [];
}
  constructor(protected service: ODataEntityService<any>, private selectedItemKey?: string, protected notificationService?: NotificationService) {
    super(null);
    //this._notificationService = notificationService;
   }
   public showSave(): void {
    if(isNullOrUndefined( this.notificationService))
      return;
    this.notificationService.show({
        content: 'Daten gespeichert',
        cssClass: 'button-notification',
        animation: { type: 'fade', duration: 400 },
        position: { horizontal: 'right', vertical: 'top' },
        type: { style: 'success', icon: true }
    });
}

private saveMessageError(){
  if(isNullOrUndefined( this.notificationService))
  return;
  this.notificationService.show({
    content: 'Fehler beim speichern',
    cssClass: 'save-error',
    animation: { type: 'fade', duration: 400 },
    position: { horizontal: 'right', vertical: 'top' },
    type: { style: 'error', icon: true },
   // hideAfter: this.hideAfter,
   closable: true
});
}
private setQueryState(state: State,query?: ODataEntitySetResource<any>){
  if(isNullOrUndefined(query))
   query = this.getQuery();

  if(state && query){
    if(state.skip){
      console.log("griddata-skip-state: ",state.skip);
      query.query.skip(state.skip)
    }else{
     
        query.query.skip(0)
      
    }
     
    if(state.take)
      query.query.top(state.take)
    if(state.sort && state.sort.length > 0){
      console.log("griddata-sort-state: ",state.sort);
       query.query.orderBy([state.sort[0].field+" "+state.sort[0].dir]);
    }
  
    if(state.filter)
    {

      let filterValues = state.filter.filters.map(f => isCompositeFilterDescriptor(f) ? f.filters :[f] ).reduce((p,n) => p.concat(n), []);
      query.query.filter().clear();
      
      filterValues.forEach(function (filter : FilterDescriptor) {
        console.log(filter.operator);
        console.log(filter.field);
        let field  = filter.field;
        console.log(filter.value);
        var odataFilter = {};
        
        if(filter.operator === "eq"){
          odataFilter[field.toString()] = filter.value ;       
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "neq"){
          odataFilter[field.toString()]= {"ne":  filter.value }; 
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "isnull"){
          odataFilter[field.toString()] = null ;       
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "isnotnull"){
          odataFilter[field.toString()] != null ;       
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "lt"){
          odataFilter[field.toString()] = {"lt": filter.value};       
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "lte"){
          odataFilter[field.toString()] = {"le": filter.value};           
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "gt"){
          odataFilter[field.toString()] = {"gt": filter.value};        
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "gte"){
          odataFilter[field.toString()] = {"ge": filter.value};       
          query.query.filter().push(odataFilter) ; 
        }

        if(filter.operator === "startswith"){
          odataFilter[field.toString()] = {"startswith": filter.value};
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "endswith"){
          odataFilter[field.toString()] = {"endswith": filter.value};      
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "contains"){
          odataFilter[field.toString()]= {"contains": filter.value};   
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "doesnotcontain"){
          odataFilter[field.toString()] = {"not contains": filter.value};       
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "isempty"){
          odataFilter[field.toString()]= {"eq": ''};   
          query.query.filter().push(odataFilter) ; 
        }
        if(filter.operator === "isnotempty"){
          odataFilter[field.toString()] = {"ne": ''};        
          query.query.filter().push(odataFilter) ; 
        }
    });
     
    }   
  }
 
  query.count()
  let count :PlainObject = {$count: true};
  query.query.custom(count);
  return query;
 }

   public refresh(state: State, query?: ODataEntitySetResource<any>){
    this.loading = true;
    query = this.setQueryState(state,query);   
    query.get().subscribe(
      ({entities, meta}) => {
          this.loading = false;
       
          
          this.data = new DataResult( cloneData( entities ), meta.count ); 
          this.total = meta.count;
          this.originalData = new DataResult( cloneData( entities ), meta.count);
   
      return super.next(this.data);
    },error => { 
        this.loading = false;
        this.reset();
        return super.next(this.data);
      }
     );
   
   }


  private getSelectedItems(res: any[]) {
    let key = this.selectedItemKey;
    if(isUndefined(key) || isNullOrEmptyString(key)){
       return;
    }      
    else{
      this.selectedItems = res.filter(function (v, i) {
      if(isUndefined(v))
            return false;
     
      return (v[key] === true); //v["IsFreigeschaltet"]
    }).map((item) => item.valueId);
    }
  
  }

   public query(state: any, query?: ODataEntitySetResource<any>) {
    this.loading = true;
    //Verbesserung: aktuellen state speichern und mit den aktuellen vergleich, wenn gleich, kann die Abfrage ignoriert werden
 /*    if (this.data.length) {
      this.loading = false;
      return super.next(this.data);
     }  */
  
     query = this.setQueryState(state,query);   
     query.get().subscribe(
      ({entities, meta}) => {

        this.getSelectedItems(entities);
        console.log('dataResult count: ' + meta.count)
        //Wenn der Datensatz geskipt ist und man nachträglich filtert, werden keine Datensätze angezeigt, wenn das Ergebnis kleiner als der Skip-Value ist
        if(meta.count > 0 && entities.length === 0){
            this.reset();
            state.skip = 0;
            this.refresh(state,query);
            return;
        }
        this.data = new DataResult( cloneData( entities), meta.count ); 
        this.total = meta.count;
        this.originalData = new DataResult( entities, meta.count );//cloneData( res[0]);
        this.loading = false;
      return super.next(this.data);
    }
        ,error => { 
          this.loading = false;
          this.reset();
          return super.next(this.data);
        }
        );
   
  }

  public hasChanges(): boolean {    
    return Boolean(this.deletedItems.length || this.updatedItems.length || this.createdItems.length);
}
public create(item: any): void {
  this.createdItems.push(item);
  this.data.unshift(item);

  super.next(this.data);
}

public update(item: any): void {
  if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
          this.updatedItems.splice(index, 1, item);
      } else {
          this.updatedItems.push(item);
      }
  } else {
      const index = itemIndex(item, this.createdItems);
      if (index !== -1) {
        this.createdItems.splice(index, 1, item);
    } else {
        this.createdItems.push(item);
    }
    
  }
}

public remove(item: any): void {
  let index = itemIndex(item, this.data.value);
  this.data.splice(index, 1);

  index = itemIndex(item, this.createdItems);
  if (index >= 0) {
      this.createdItems.splice(index, 1);
  } else {
      this.deletedItems.push(item);
  }

  index = itemIndex(item, this.updatedItems);
  if (index >= 0) {
      this.updatedItems.splice(index, 1);
  }

  super.next(this.data);
}

public isNew(item: any): boolean {
  return isUndefined(item.id) || item.id === 0;
}
  public assignValues(target: any, source: any): void {
    Object.assign(target, source);
}
public saveChanges(state: any, query?: ODataEntitySetResource<any>): void {
  if (!this.hasChanges()) {
      return;
  }

  const completed = [];

  if (this.deletedItems.length) {
    this.deletedItems.forEach(element => {
     // let keyQuery = this.service.destroy(element.id);
     // this.service.delete(keyQuery).subscribe();
      completed.push( this.service.destroy({id:element.id}));
    });
  }

  if (this.updatedItems.length) {
    this.updatedItems.forEach(element => {
     // let keyQuery = this.service.entityQuery(element.id);
     // this.service.entity(element.id);
     //completed.push( this.service.update(element));
     //https://github.com/diegomvh/TripPinEntity/blob/afdb55fc0c23667fa06daae81e4f8adcc1270de6/src/app/app.component.ts CREATEPERSON WITH ETAG
      completed.push( this.service.update({id:element.id}, element));
/*       this.service.patch(keyQuery,element).subscribe(
        res => {
        
          this.refresh(state);
        }
        /* res => {
          alert(res.toComplexValue<any>().ChangedAt);
          element = res.toComplexValue<any>();
        } //
        ); */
    });
  }

  if (this.createdItems.length) {
      this.createdItems.forEach(element => {
        //FEHLER IN DER ODATA-IMPLEMENTIERUNG. POST MIT DER ID IST SPORALISCH INVALID
        delete element.id;
        completed.push(  this.service.create(element));
  /*       this.service.create(element).subscribe(
          res => {
        
            this.refresh(state);
          }
        ); */
      });
     
  }
  
  this.reset();
  if(query){
    zip(...completed).subscribe(() => {
      this.query(state,query);
      this.showSave();
    }
    ,  (error) => {  
      this.saveMessageError();
    });
    
  }else{
    zip(...completed).subscribe(() => {
      this.query(state);
      this.showSave();
    },
    (error) => {  
      this.saveMessageError();
    });
  }
 
}
public unloadData(): void{
  this.reset();
  this.originalData = new DataResult();
  super.next(this.data);
}
public cancelChanges(): void {
  this.reset();

  this.data = this.originalData;
  super.next(this.data);
}

public resetAllDataToSavedChanges() : void{
  this.resetChangedDataOnly();
}
//Evlt hier query zurück geben und in den anderen Abfragen den zugriff auf Query entfernen
public getQuery() : ODataEntitySetResource<any>{
  return this.service.entities();
}

public getData(): any{
  return this.data;
}

public getDeletedItems(): any{
  return this.deletedItems;
}

public getTotal(): any{
  return this.total;
}
}

// Implementing Adapter Pattern: to handle any[] or BehaviorSubject<GridDataResult>
export class DataResult implements GridDataResult {
  data = [];
  total = 0;

  constructor ( data?: any[], total?: number ) {
    this.data = data || [];
    this.total = total || 0;
  }
  unshift = ( item ) => {
    this.data.unshift( item ); this.total++;
  }
  splice = ( index, item ) => {
    this.data.splice( index, item ); this.total--;
  }
  get length() 
  {
     if(isNullOrUndefined(this.data)){
      return 0;
    }
      
    return this.data.length; 
  }
  map = ( x ) => {
    if(!isNullOrUndefined(this.data)){
      this.data.map( x )
    }
  };
  get value () { return this.data; }
}