import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Wettbewerber } from './wettbewerber.entity';
//#endregion

export const WettbewerberConfig = {
  name: "Wettbewerber",
  annotations: [],
  fields: {
    firmenname: {type: 'Edm.String'},
    preisaufschlag: {type: 'Edm.Decimal', nullable: false},
    datum: {type: 'Edm.DateTimeOffset', nullable: false},
    typ: {type: 'services.VertriebsType', nullable: false},
    shopLink: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    wettbewerberpreise: {type: 'entities.wettbewerb.WettbewerberPreis', collection: true, navigation: true}
  }
} as EntityConfig<Wettbewerber>;