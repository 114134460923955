import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Person } from '../entities/customers/person.entity';
import { Firma } from '../entities/schema/firma.entity';
import { UserStateDto } from '../Mza/Service/Controllers/V1/Kunden/Models/userstatedto.entity';
import { FirmaPersonenDto } from '../Mza/Service/Controllers/V1/Kunden/Models/firmapersonendto.entity';
//#endregion

@Injectable()
export class PersonService extends ODataEntityService<Person> {
  constructor(protected client: ODataClient) {
    super(client, 'Person', 'entities.customers.Person');
  }

  
  
  //#region ODataApi Actions
  public sendEinladung(entity: EntityKey<Person>): ODataActionResource<{firmaId: number}, any> {
    const resource = this.entity(entity).action<{firmaId: number}, any>('services.SendEinladung');
    return resource;
  }
  //#endregion
  //#region ODataApi Functions
  public acceptEinladung(): ODataFunctionResource<{token: string}, string> {
    const resource = this.entities().function<{token: string}, string>('services.AcceptEinladung');
    return resource;
  }
  public getUserStateForApp(): ODataFunctionResource<null, UserStateDto> {
    const resource = this.entities().function<null, UserStateDto>('services.GetUserStateForApp');
    return resource;
  }
  public getFirmaPersonen(): ODataFunctionResource<null, FirmaPersonenDto> {
    const resource = this.entities().function<null, FirmaPersonenDto>('services.GetFirmaPersonen');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public firma(entity: EntityKey<Person>): ODataNavigationPropertyResource<Firma> {
    return this.entity(entity).navigationProperty<Firma>('firma');
  }
  //#endregion
}
