import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtikelGroupBindingDirective, ArtikelBindingDirective } from 'mza-api-service';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ArtikelGruppenGridComponent } from './artikel-gruppen-grid/artikel-gruppen-grid.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ArtikelGruppenFilterSettingsComponent } from './artikel-gruppen-filter-settings/artikel-gruppen-filter-settings.component';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ArtikelOverviewComponent } from './artikel-overview/artikel-overview.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ProduktTypenComponent } from '../produkte/produkt-typen/produkt-typen.component';
import { HerstellerComponent } from '../produkte/hersteller/hersteller.component';
import { ProdukteModule } from '../produkte/produkte.module';
//import { AssetImageComponent } from '../shared/images/asset-image/asset-image.component';
import { AssetsModule } from '../assets/assets.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ArtikelnrColumnComponent } from '../shared/components/columns/artikelnr-column/artikelnr-column.component';
import { WarengruppenNrModule } from '../shared/components/warengruppen-nr/warengruppen-nr.module';
import { ArtikelNrModule } from '../shared/components/artikel-nr/artikel-nr.module';
import { OdataColumnSelectModule } from '../shared/components/odata-column-select/odata-column-select.module';








@NgModule({
  declarations: [ArtikelGruppenGridComponent, 
    ArtikelGroupBindingDirective,
    ArtikelBindingDirective,  
    ArtikelGruppenFilterSettingsComponent, 
    ArtikelOverviewComponent,ArtikelnrColumnComponent
    ],
  imports: [
    CommonModule,GridModule,ButtonsModule,LayoutModule,
    DropDownsModule, FormsModule,  BrowserAnimationsModule, 
    ReactiveFormsModule,
    DialogsModule,
    ProdukteModule,
    AssetsModule,
    FlexLayoutModule,ExcelModule ,WarengruppenNrModule,ArtikelNrModule,OdataColumnSelectModule
  ],
    
    exports:[ArtikelGruppenGridComponent,ArtikelGruppenFilterSettingsComponent,ArtikelOverviewComponent]
})
export class ArtikelModule { }
