import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { MetaData } from './metadata.entity';
//#endregion

export const MetaDataConfig = {
  name: "MetaData",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    label: {type: 'Edm.String'},
    typeId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    type: {type: 'entities.ref.Type', navigation: true, field: 'typeId', ref: 'id'},
    assetGroupMetaDataList: {type: 'entities.assets.AssetGroupMetaData', collection: true, navigation: true},
    assetGroupTreeMetaDataList: {type: 'entities.assets.AssetGroupTreeMetaData', collection: true, navigation: true},
    assetMetaDataList: {type: 'entities.assets.AssetMetaData', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<MetaData>;