import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { KundenFilter } from '../entities/customers/kundenfilter.entity';
import { KundenFilterErgebnis } from '../entities/customers/kundenfilterergebnis.entity';
import { BeigabenKunde } from '../entities/produkte/beigabenkunde.entity';
import { Beigabe } from '../entities/produkte/beigabe.entity';
//#endregion

@Injectable()
export class CustomerFilterService extends ODataEntityService<KundenFilter> {
  constructor(protected client: ODataClient) {
    super(client, 'CustomerFilter', 'entities.customers.KundenFilter');
  }

  
  
  //#region ODataApi Actions
  public createCustomerList(entity: EntityKey<KundenFilter>): ODataActionResource<{kundenListe: string[]}, any> {
    const resource = this.entity(entity).action<{kundenListe: string[]}, any>('services.CreateCustomerList');
    return resource;
  }
  public createCustomerListFromFilter(entity: EntityKey<KundenFilter>): ODataActionResource<null, any> {
    const resource = this.entity(entity).action<null, any>('services.CreateCustomerListFromFilter');
    return resource;
  }
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public beigaben(entity: EntityKey<KundenFilter>): ODataNavigationPropertyResource<Beigabe> {
    return this.entity(entity).navigationProperty<Beigabe>('beigaben');
  }
  public beigabenKunden(entity: EntityKey<KundenFilter>): ODataNavigationPropertyResource<BeigabenKunde> {
    return this.entity(entity).navigationProperty<BeigabenKunde>('beigabenKunden');
  }
  public kundenFilterErgebnise(entity: EntityKey<KundenFilter>): ODataNavigationPropertyResource<KundenFilterErgebnis> {
    return this.entity(entity).navigationProperty<KundenFilterErgebnis>('kundenFilterErgebnise');
  }
  //#endregion
}
