import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { GroupTreeAttributeService, ExtendsAttributesDto } from 'mza-api-service';
import { PanelBarItemModel, PanelBarExpandMode } from '@progress/kendo-angular-layout';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'gpv-attribute-liste-group-tree',
  templateUrl: './attribute-liste-group-tree.component.html',
  styleUrls: ['./attribute-liste-group-tree.component.scss']
})
export class AttributeListeGroupTreeComponent implements OnInit, OnChanges {
  
  @Input() groupTreeId:  number; 
  public ExtendsAttributes : ExtendsAttributesDto[] ;
  public items: Array<any>;
  public ExtendsAttributesItems: Array<PanelBarItemModel>;
  public parsedExtendsAttributesItems: any[] = this.ExtendsAttributesItems;
  public kendoPanelBarExpandMode = PanelBarExpandMode.Multiple;
  public searchTerm = '';

  constructor(public _serviceGroups: GroupTreeAttributeService) { }

  ngOnInit() {
    this.loadData()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }
  private loadData() {
    if (!isNullOrUndefined(this.groupTreeId) ){
      
        this._serviceGroups.getExtendsAttributes().call({id:this.groupTreeId},"entity").subscribe( s => {
          this.ExtendsAttributes = s[0];
    
          const uniqueGroupTrees = [...new Set(this.ExtendsAttributes.map(item => item.groupTreeName))];
          this.ExtendsAttributesItems = uniqueGroupTrees.map(groupTree => {
            const data = <PanelBarItemModel>{
              title: groupTree,
              children: [],
              expanded: true
          };  
            data.children =  this.ExtendsAttributes.filter(gf => gf.groupTreeName === groupTree).map(attribut => {
            return <PanelBarItemModel>{ title: attribut.attributeName , id: ""+attribut.attributeId};             
           });
           return data;
           });
           this.parsedExtendsAttributesItems = this.ExtendsAttributesItems;

    });
  }
}

public onkeyup(value: string): void {
  this.parsedExtendsAttributesItems = this.search(this.ExtendsAttributesItems, value);
}

public search(items: any[], term: string): any[] {
  return items.reduce((acc, item) => {
        if (this.contains(item.title, term)) {
          acc.push(item);
        } else if (item.children && item.children.length > 0) {
          const newItems = this.search(item.children, term);

          if (newItems.length > 0) {
                acc.push({ title: item.title, children: newItems });
          }
      }

        return acc;
    },                []);
}
public children = (dataitem: any): Observable<any[]> => of(dataitem.children);
public hasChildren = (dataitem: any): boolean => !!dataitem.children;

public contains(text: string, term: string): boolean {
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
}
}
