import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Analyst } from './analyst.entity';
//#endregion

export const AnalystConfig = {
  name: "Analyst",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kursziele: {type: 'entities.boerse.Kursziel', collection: true, navigation: true}
  }
} as EntityConfig<Analyst>;