import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { SortDto } from '../Mza/Service/Models/sortdto.entity';
import { Group } from '../entities/schema/group.entity';
import { MaingroupAttribute } from '../entities/schema/maingroupattribute.entity';
import { Maingroup } from '../entities/schema/maingroup.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class MainGroupService extends ODataEntityService<Maingroup> {
  constructor(protected client: ODataClient) {
    super(client, 'MainGroup', 'entities.schema.Maingroup');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getSorts(): ODataFunctionResource<null, SortDto> {
    const resource = this.entities().function<null, SortDto>('services.GetSorts');
    return resource;
  }

  //#endregion
  //#region ODataApi Navigations
  public attributes(entity: EntityKey<Maingroup>): ODataNavigationPropertyResource<MaingroupAttribute> {
    return this.entity(entity).navigationProperty<MaingroupAttribute>('attributes');
  }
  public groups(entity: EntityKey<Maingroup>): ODataNavigationPropertyResource<Group> {
    return this.entity(entity).navigationProperty<Group>('groups');
  }
  public maingroups(entity: EntityKey<Maingroup>): ODataNavigationPropertyResource<Maingroup> {
    return this.entity(entity).navigationProperty<Maingroup>('maingroups');
  }
  public status(entity: EntityKey<Maingroup>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Maingroup>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
