import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SelectableSettings, GridComponent } from '@progress/kendo-angular-grid';
import { ODataEntitySetResource, Select } from 'angular-odata';
import { State } from '@progress/kendo-data-query';
import { ArtikelGroupDataService, ArtikelGroup, Artikel, DataArrayConvert, ArtikelGroupFilterSettings, ArtikelGroupFilterSettingsService, ArtikelService } from 'mza-api-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable } from 'rxjs';
import { column } from '../../shared/models/column.model';

@Component({
  selector: 'gpv-artikel-gruppen-grid',
  templateUrl: './artikel-gruppen-grid.component.html',
  styleUrls: ['./artikel-gruppen-grid.component.css']
})
export class ArtikelGruppenGridComponent implements OnInit {

  @Input() groupId : number;
  @Input() groupIds : number[];
  public dataGroupFilter: ArtikelGroupFilterSettings[];

  private hideNotificationAfter: number = 3000;
  public selectedGroupId: number[] = [];
  public artikelGroupForm: FormGroup;
  public isArtikelListKachelView: boolean = true;
  public isEditMode: boolean = false;
  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'single'
};
  public query : ODataEntitySetResource<any>;
  public state: State = {
    skip: 0,
    take: 100,
    sort: [{
      field: 'artikelId',
      dir: 'asc'
    }]
};


public isArtikelAllValueView: boolean = false;
  
//WINDOW ARTIKEL
public opened = false;
public dataSaved = false;

public close() {
  this.opened = false;
}

public open() {
  this.opened = true;
}
//ENDE



constructor( 
  public _dataServiceArtikelGroups: ArtikelGroupDataService, public artikelGroupFilterService: ArtikelGroupFilterSettingsService,
  public artikelService: ArtikelService, public notificationService: NotificationService,
  private formBuilder: FormBuilder) {
//  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
 /*  this.formMarke = this.formBuilder.group({
    marke: [{id: 0, bezeichnung: ''}, Validators.required  ]
  }); */
  this.createGroupForm  = this.createGroupForm.bind(this);
  //this._dataServiceArtikelGroups._notificationService = this.notificationService;
 // this.loadData(); 

 }


 public createGroupForm(args: any): FormGroup  {
  console.log("ITEM INTI:");
  let data  = <ArtikelGroup>{ id: 0,
                       sortValue: 0,
                       status: null,
                       artikelId: 0,
                       artikel:  <Artikel>{ 
                         id: 0,
                         artikelNr: ""     
                       },
                       groupId: this.groupId};
  const item : ArtikelGroup = args.isNew ? data  : args.dataItem;
console.log("ITEM:");
  this.artikelGroupForm = this.formBuilder.group({
    id: item.id ,
    artikelId: [item.artikelId],
    sortValue: [item.sortValue],   
    status: [item.status],            
    groupId: [item.groupId] ,
    artikel: this.formBuilder.group( {
      artikelNr: [item.artikel.artikelNr],
      id: [item.artikel.id]    
    }  
    )        
});
return this.artikelGroupForm;
}

  ngOnInit() {
    //this.loadData(); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }


  private loadData() {
    this.loadArtikelGrid();
  
    //WG BESTIMMEN
    let dataFilter = this.artikelGroupFilterService.entities();
    dataFilter.query.filter(this.getGroupFilterQuery());
   
    dataFilter.query.select(["id","filterArtikelRemoved"]);
    dataFilter.get().subscribe(({entities, meta}) =>{
             this.dataGroupFilter = entities; 

        });
  }

  private loadArtikelGrid(){
    if (isNullOrUndefined(this.groupId) && isNullOrUndefined(this.groupIds)){
      this._dataServiceArtikelGroups.unloadData();
      this.query = undefined;
      return;
    }
       
        console.log("loaf´d OK!:",this.groupId,this.groupIds);
    this.query = this._dataServiceArtikelGroups.getQuery();
   
    let selectedFields = this.columnsArtikel.map(m => m.name);
    selectedFields.push("id");
    selectedFields.push("artikelNr");
/*     selectedFields.push("lagerbestandM1");
    selectedFields.push("warengruppenNr");
    selectedFields.push("imShopSichtbar");
    selectedFields.push("internetmarke");
    selectedFields.push("lieferantenNr"); */
    
    selectedFields.push("name");
    console.log("loadArtikelGrid: ", selectedFields);
    this.query.query.select( ["id","artikelId","artikelGroupFilterSettingsId","groupId"]);
    this.query.query.expand({artikel: {select: selectedFields}});


      this.query.query.filter(this.getGroupFilterQuery()); 
  }

  private getGroupFilterQuery(): any{
    if (!isNullOrUndefined(this.groupId) )
      return {groupId: this.groupId}; 

  if (!isNullOrUndefined(this.groupIds)){
    if(this.groupIds.length === 1)
      return {groupId: this.groupIds[0]} ; 
    else
     // this.query.query.filter({groupId: {"in": this.groupIds}}); 
     {
  //    this.query.query.filter({groupId: this.groupIds[0]});     
    let filterValue: string = "";
      this.groupIds.forEach(i => {
        filterValue += "groupId eq " + i + " or "
      });
      return filterValue.substring(0,filterValue.length -4);

     }
  }{
    return {groupId: 0} ; 
  }
  }

  public onSwitchListKachelView(e, value) {
    this.isArtikelListKachelView =value;
    if(value)
      this.columnsArtikel = [];
  }
  
  public onSwitchEditMode(e) {
    console.log("onSwitchEditMode: ",e);
    this.isEditMode =e;
  }

  public cancelChanges(grid: any): void {
    grid.cancelCell();
    this._dataServiceArtikelGroups.cancelChanges();
  }
 
  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();
  
    let dataAG : ArtikelGroup[] = this._dataServiceArtikelGroups.getDeletedItems();
    let data = dataAG.map(( {artikelId}) => artikelId);
    let value = DataArrayConvert.ConvertArrayToString(data);

    this._dataServiceArtikelGroups.saveChanges(this.state);

 /*    if(this.dataGroupFilter.filterArtikelRemoved.length > 0){
      value = this.dataGroupFilter.filterArtikelRemoved + "," + value;
    }
    this.artikelGroupFilterService.assign({id: this.dataGroupFilter.id, filterArtikelRemoved: value}).subscribe(
      r => {
        alert("updated");
        this._dataServiceArtikelGroups.resetAllDataToSavedChanges();
      this.loadData();
      }
    ); */
    //this.sortimentService.query(this.state, this.query);
  }

 
  public showGrid(): boolean {
    console.log("showGrid:",!isNullOrUndefined(this.groupId) || !isNullOrUndefined(this.groupIds),this.groupId,this.groupIds);
    return !isNullOrUndefined(this.groupId) || !isNullOrUndefined(this.groupIds);
 //  return true;
  }

  public selectedArtikel : Artikel;
public onSelectedKeysChange(e) {
   const len = this.selectedGroupId.length;

  if (len === 1) {
    this.opened = true;
    let dataA = this.artikelService.entity(this.selectedGroupId[0]);

      dataA.query.select("id,artikelNr,WarengruppenNr,SimsonArtikelNr,name,"+
                   "MZArtikelNr,IWLArtikelNr,EMWArtikelNr,AlphaNr,"+
                    "Originalteil,Gesperrt,StVoZulassung,ISBN,GTIN,"+
                    "Qualitaetszeichen,Internetmarke,ImShopSichtbar,"+
                    "ServicepointArtikel,SortimentSimson,WeitereSimsonNr,SortimentMZ,"+
                    "WeitereMZNr,SortimentIWL,WeitereIWLNr,SortimentEMW,WeitereEMWNr,lagerbestandM1,"+
                    "SortimentZubehoer,SortimentWindhoff,SortimentUnitrike,WindhoffArtikelNr,UnitrikeArtikelNr,WeitereWindhoffNr,WeitereUnitrikeNr,"+
                    "ImportDatum,stuecklisteImShopSichtbar,isStueckliste");
      dataA.get().subscribe(({entity,meta}) =>{
        this.selectedArtikel = entity;
    });
  } else {
    this.opened = false;
  } 
}
public onRemoveArtikelFromGroup(item: any){
    let value =  ""+item.artikelId;
    let artikelFilterSetting = this.dataGroupFilter[0];
      if(artikelFilterSetting.filterArtikelRemoved.length > 0){
          value =value+ ","+ artikelFilterSetting.filterArtikelRemoved;
    }
    console.log("onRemoveArtikelFromGroup:",artikelFilterSetting,item );
    this.artikelGroupFilterService.assign({id: artikelFilterSetting.id},{ filterArtikelRemoved: value}).subscribe(
      r => {
        this.saveOk();
        this._dataServiceArtikelGroups.resetAllDataToSavedChanges();
        this.loadData();
      },
      (error) => {
        this.saveError()
      }
    ); 
  
}

public onSwitchArtikelAllValueView(e, value) {
  this.isArtikelAllValueView =value;
}

public rebindArtkelGrid(settingChange: boolean){
//  if(settingChange)
console.log("rebindArtkelGrid: ", settingChange);
    this.loadData();
}

public columnsArtikel : column[] = [];

public artikelColumnsChange( event : column[]){

  this.columnsArtikel = event;
  this.loadArtikelGrid();
}

private saveOk(){

  this.notificationService.show({
    content: 'Report erstellt',
    cssClass: 'button-notification',
    animation: { type: 'fade', duration: 400 },
    position: { horizontal: 'right', vertical: 'top' },
    type: { style: 'success', icon: true },
    hideAfter: this.hideNotificationAfter,
    //closable: true
  });
}

private saveError(){
 
  this.notificationService.show({
    content: 'Fehler beim Speichern',
    cssClass: 'save-error',
    animation: { type: 'fade', duration: 400 },
    position: { horizontal: 'right', vertical: 'top' },
    type: { style: 'error', icon: true },
    // hideAfter: this.hideAfter,
    closable: true
  });
}

}
