import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mza-artikel-bild',
  templateUrl: './artikel-bild.component.html',
  styleUrls: ['./artikel-bild.component.css']
})
export class ArtikelBildComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.imageUrl = 'https://www.mza-vertrieb.de/images/Artikel/klein/'+this.artikelNr+'.jpg' ;
  }
  @Input() artikelNr: string;


  public imageUrl = "";
  constructor() { }
 
  ngOnInit() {
    this.imageUrl = 'https://www.mza-vertrieb.de/images/Artikel/klein/'+this.artikelNr+'.jpg' ;
  }

}
