import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { RegistrationAbgleich } from './registrationabgleich.entity';
//#endregion

export const RegistrationAbgleichConfig = {
  name: "RegistrationAbgleich",
  annotations: [],
  fields: {
    registrationInfoId: {type: 'Edm.Int32'},
    vorhandeneRegistiertId: {type: 'Edm.Int32'},
    vorhandeneKundenNr: {type: 'Edm.Int32'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    registrationInfo: {type: 'entities.schema.RegistrationInfo', navigation: true, field: 'registrationInfoId', ref: 'id'}
  }
} as EntityConfig<RegistrationAbgleich>;