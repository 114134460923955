import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsOverviewComponent } from './reports-overview/reports-overview.component';
import { ReportComponent } from './report/report.component';
import { ReportSettingComponent } from './report-setting/report-setting.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LabelsModule } from 'mza-components';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MatButton } from '@angular/material/button';
import 'hammerjs';
import { RouterModule } from '@angular/router';
import { InputsModule, NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { AfterValueChangedDirective } from '../shared/directive/after-value-changed.directive';
import { ReportGridComponent } from './report/report-grid/report-grid.component';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ReportBulletComponent } from './report/report-bullet/report-bullet.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IconsModule } from '@progress/kendo-angular-icons';
import { DirectiveModule } from '../shared/directive/directive.module';


@NgModule({
  declarations: [ReportsOverviewComponent, ReportComponent, ReportSettingComponent, ReportGridComponent, ReportBulletComponent],
  imports: [
    CommonModule,GridModule, NotificationModule ,FormsModule, RouterModule,    ButtonsModule, ReactiveFormsModule, DirectiveModule,
    DropDownsModule, TextBoxModule ,NumericTextBoxModule ,InputsModule,DialogModule,
    LabelsModule,IconsModule,
    ButtonsModule, MatCheckboxModule,MatInputModule,WindowModule,ChartsModule,
    FlexLayoutModule,
    LabelsModule,
  ],exports:[ReportComponent]
})
export class ReportsModule { }
