import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthOidcService } from 'mza-auth-service';
import { Router, ActivatedRoute, UrlSegment, NavigationEnd, UrlSegmentGroup, PRIMARY_OUTLET } from '@angular/router';
import { DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { of } from 'rxjs';
import { map, mapTo, filter, switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'gpv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'PIM-Verwaltung';
  public expanded = false;
  public items: Array<any> = [];
  constructor(private authService: AuthOidcService
    ,private router: Router
    ,private route: ActivatedRoute) {
      
      const routes: any[] = router.config;

    routes.forEach(route => {
        this.items.push({
            text: route.text,
            icon: route.icon,
            path: route.path ? route.path : '',
            children: route.hasChildren
        });
    });
  //  this.items[0].selected = true;
/*   this.route.snapshot.subscribe(params => {
    this.maingroupId = +params['id']; // (+) converts string 'id' to a number
    this.router.navigate([ '/gruppen/hauptgruppe/' + this.maingroupId+'/baum-ansicht'  ]);
  }); */
  router.events.subscribe(e => {
    if (e instanceof NavigationEnd) {
      
     let paths = e.url.split('/');
     if(isNullOrUndefined( paths)) return;
     if( paths.length <= 1) return;
     
    
     let idx = this.items.findIndex(x => x.path == paths[1]);
      //WIRD GESETZT ABER IM SUBCRIBE WIRD ES NICHT GESETZT?
     //manuell setzten im HTML 
      this.items[idx].selected = true;
 
    }
  });

  }

  public itemIndex;
  public onSelect(ev: DrawerSelectEvent): void {
  /*   this.selected = ev.item.text;*/

    const item = this.items.find((e, index) => {
      this.itemIndex = index;
      return e.text === ev.item.text;
    }); 
    item.expanded ? (item.expanded = false) : (item.expanded = true);

    /* if (ev.item.text === 'Getting Started') {
      item.expanded
        ? this.addChildren(this.gettingStarted)
        : this.removeChildren(this.gettingStarted);
    }
    if (ev.item.text === 'Overview') {
      let childItems: DrawerItemsCollection = {
        parents: [],
        children: []
      };

      switch (this.framework) {
        case 'Angular':
          childItems = angularItems;
          break;

        case 'React':
          childItems = reactItems;
          break;

        case 'Vue':
          childItems = vueItems;
      }

      item.expanded
        ? this.addChildren(childItems.children)
        : this.removeChildren(childItems.children);
    } */
  }


  getRoutes() { const segments: UrlSegment[] = this.route.snapshot.url; }
   ngOnInit(): void {
    this.authService.initAuth();
  } 
 

  ngOnDestroy() {
    this.authService.ngOnDestroy();
  }
}
