import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ChartItemDto } from './chartitemdto.entity';
//#endregion

export const ChartItemDtoConfig = {
  name: "ChartItemDto",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int32', nullable: false},
    wert: {type: 'Edm.Decimal', nullable: false},
    datum: {type: 'Edm.DateTimeOffset', nullable: false}
  }
} as EntityConfig<ChartItemDto>;