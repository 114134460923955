import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KundenZuweisungComponent } from './kunden-zuweisung/kunden-zuweisung.component';
import { KundenFilterGruppenZuweisungComponent } from './kunden-zuweisung/kunden-filter-gruppen-zuweisung/kunden-filter-gruppen-zuweisung.component';
import { KundengruppeGruppenZuweisungComponent } from './kunden-zuweisung/kundengruppe-gruppen-zuweisung/kundengruppe-gruppen-zuweisung.component';
import { KundeGruppenZuweisungComponent } from './kunden-zuweisung/kunde-gruppen-zuweisung/kunde-gruppen-zuweisung.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CustomerGroupBindingDirective } from 'mza-api-service';
import { KundenNrModule } from 'mza-components';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { WettbewerbModule } from './wettbewerb/wettbewerb.module';


@NgModule({
  declarations: [KundenZuweisungComponent, CustomerGroupBindingDirective,
                KundenFilterGruppenZuweisungComponent, KundengruppeGruppenZuweisungComponent, KundeGruppenZuweisungComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,KundenNrModule,
    InputsModule,
    FormsModule,
    DropDownsModule,
    NotificationModule,
    ButtonsModule, //, MatButtonModule, MatCheckboxModule,MatInputModule,MatBadgeModule,
    GridModule,WettbewerbModule
  ],
  exports:[KundeGruppenZuweisungComponent,KundenFilterGruppenZuweisungComponent,KundengruppeGruppenZuweisungComponent]
})
export class KundenModule { }
