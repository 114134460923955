import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { TeamPersonen } from './teampersonen.entity';
//#endregion

export const TeamPersonenConfig = {
  name: "TeamPersonen",
  annotations: [],
  fields: {
    title: {type: 'Edm.String'},
    firmaId: {type: 'Edm.Int32'},
    teamId: {type: 'Edm.Int32'},
    personId: {type: 'Edm.Int32'},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    team: {type: 'services.Team', navigation: true, field: 'teamId', ref: 'id'},
    person: {type: 'entities.customers.Person', navigation: true, field: 'personId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<TeamPersonen>;