import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Abteilung } from './abteilung.entity';
//#endregion

export const AbteilungConfig = {
  name: "Abteilung",
  annotations: [],
  fields: {
    title: {type: 'Edm.String'},
    email: {type: 'Edm.String'},
    telefonNr: {type: 'Edm.String'},
    faxNr: {type: 'Edm.String'},
    mobilNr: {type: 'Edm.String'},
    firmaId: {type: 'Edm.Int32'},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    teams: {type: 'services.Team', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Abteilung>;