import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { GroupTreeService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class GroupTreeDataService extends GridDataService {
    
  public odataService: GroupTreeService;
  
  
    constructor(  @Inject(GroupTreeService)  service: GroupTreeService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
