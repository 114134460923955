import { Injectable, Inject } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { DOCUMENT } from "@angular/common";


//https://stackblitz.com/edit/angular-ez9xsw?file=src%2Fapp%2Fhotkeys-dialog%2Fhotkeys-dialog.component.html
type Options = {
  element: any;
  description: string | undefined;
  keys: string;
};

@Injectable({
  providedIn: "root"
})
export class Hotkeys {
  hotkeys = new Map();
  defaults: Partial<Options> = {
    element: this.document
  };
  public isNotCRTL: boolean = null;
  constructor(
    private eventManager: EventManager,
  
    @Inject(DOCUMENT) private document: Document
  ) {
    this.addShortcut({ keys: "shift.?" }).subscribe(() => {
      this.isNotCRTL = this.openHelpModal();
    });
    this.addShortcut({ keys: "control" }).subscribe(() => {
      this.isNotCRTL = false;
    });
    this.deleteShortcut({ keys: "control" }).subscribe(() => {
      this.isNotCRTL = true;
    });
  }

  addShortcut(options: Partial<Options>) {
    const merged = { ...this.defaults, ...options };
    const event = `keyup.${merged.keys}`;

    merged.description && this.hotkeys.set(merged.keys, merged.description);

    return new Observable(observer => {
      const handler = e => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(
        merged.element,
        event,
        handler
      );

      return () => {
        dispose();

        this.hotkeys.delete(merged.keys);
      };
    });
  }
  deleteShortcut(options: Partial<Options>) {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    merged.description && this.hotkeys.set(merged.keys, merged.description);

    return new Observable(observer => {
      const handler = e => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(
        merged.element,
        event,
        handler
      );

      return () => {
        dispose();

        this.hotkeys.delete(merged.keys);
      };
    });
  }

  openHelpModal(): boolean {
    return true;
  }
}
