import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { MaingroupAttributeService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class MainGroupAttributeDataService extends GridDataService {
    
  public odataService: MaingroupAttributeService;
  
  
    constructor( @Inject(MaingroupAttributeService) service: MaingroupAttributeService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
