import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetConfig } from './asset.entity.config';
import { AssetGroupMetaDataConfig } from './assetgroupmetadata.entity.config';
import { AssetGroupConfig } from './assetgroup.entity.config';
import { AssetGroupTreeAssigmentConfig } from './assetgrouptreeassigment.entity.config';
import { AssetGroupTreeMetaDataConfig } from './assetgrouptreemetadata.entity.config';
import { AssetGroupTreeConfig } from './assetgrouptree.entity.config';
import { AssetMetaDataConfig } from './assetmetadata.entity.config';
import { DocumentformatConfig } from './documentformat.entity.config';
import { ImageformatConfig } from './imageformat.entity.config';
import { VideoformatConfig } from './videoformat.entity.config';
import { MetaDataConfig } from './metadata.entity.config';
//#endregion

export const AssetsSchema = {
  namespace: "entities.assets",
  enums: [],
  entities: [AssetConfig,
    AssetGroupMetaDataConfig,
    AssetGroupConfig,
    AssetGroupTreeAssigmentConfig,
    AssetGroupTreeMetaDataConfig,
    AssetGroupTreeConfig,
    AssetMetaDataConfig,
    DocumentformatConfig,
    ImageformatConfig,
    VideoformatConfig,
    MetaDataConfig],
  callables: [],
  containers: []
} as SchemaConfig;