import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { AssetMetaData } from './assetmetadata.entity';
//#endregion

export const AssetMetaDataConfig = {
  name: "AssetMetaData",
  annotations: [],
  fields: {
    assetId: {type: 'Edm.Int32'},
    metaDataId: {type: 'Edm.Int32'},
    propertyId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    value: {type: 'Edm.String'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    asset: {type: 'entities.assets.Asset', navigation: true, field: 'assetId', ref: 'id'},
    metaData: {type: 'entities.assets.MetaData', navigation: true, field: 'metaDataId', ref: 'id'},
    property: {type: 'entities.ref.Property', navigation: true, field: 'propertyId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<AssetMetaData>;