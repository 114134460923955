import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelAttributeService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable({
  providedIn: 'root'
})
export class ArtikelAttributeDataService extends GridDataService {
    
  public odataService: ArtikelAttributeService;
  
  
    constructor( @Inject(ArtikelAttributeService)  service: ArtikelAttributeService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
