import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { WettbewerberPreisConfig } from './wettbewerberpreis.entity.config';
//#endregion

export const WettbewerbSchema = {
  namespace: "entities.wettbewerb",
  enums: [],
  entities: [WettbewerberPreisConfig],
  callables: [],
  containers: []
} as SchemaConfig;