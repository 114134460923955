//#region ODataApi Imports
//#endregion

export const ISFLAGS_VERTRIEBSTYPE = false;
export enum VertriebsType {
  //#region ODataApi Members
  B2B = 1,
  B2C = 2,
  //#endregion
}
