import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Maingroup } from './maingroup.entity';
//#endregion

export const MaingroupConfig = {
  name: "Maingroup",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    allowGroupTreeAssignment: {type: 'Edm.Boolean', nullable: false},
    allowTreeStructur: {type: 'Edm.Boolean', nullable: false},
    allowGroupsInDifferentNodes: {type: 'Edm.Boolean', nullable: false},
    showValue: {type: 'Edm.Boolean', nullable: false},
    showDescription: {type: 'Edm.Boolean', nullable: false},
    usedStatusTypeId: {type: 'Edm.Int32', nullable: false},
    usedSortReports: {type: 'Edm.String'},
    useArtikel: {type: 'Edm.Boolean', nullable: false},
    useAttribute: {type: 'Edm.Boolean', nullable: false},
    useAssets: {type: 'Edm.Boolean', nullable: false},
    useKunden: {type: 'Edm.Boolean', nullable: false},
    useWettbewerber: {type: 'Edm.Boolean', nullable: false},
    useWettbewerberPreis: {type: 'Edm.Boolean', nullable: false},
    useArtikelPreis: {type: 'Edm.Boolean', nullable: false},
    useArtikelPreisVk: {type: 'Edm.Boolean', nullable: false},
    useRegistration: {type: 'Edm.Boolean', nullable: false},
    usedReports: {type: 'Edm.String'},
    usedDashboards: {type: 'Edm.String'},
    usedMaingroups: {type: 'Edm.String'},
    useAktie: {type: 'Edm.Boolean', nullable: false},
    isSystemRelevant: {type: 'Edm.Boolean', nullable: false},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    maingroups: {type: 'entities.schema.Maingroup', collection: true, navigation: true},
    groups: {type: 'entities.schema.Group', collection: true, navigation: true},
    attributes: {type: 'entities.schema.MaingroupAttribute', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Maingroup>;