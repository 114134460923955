import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ArtikelNrMultiselectComponent } from './artikel-nr-multiselect.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ArtikelService } from 'mza-api-service';


@NgModule({
  declarations: [ArtikelNrMultiselectComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DropDownsModule
  ],providers:[ArtikelService],exports: [ArtikelNrMultiselectComponent]
})
export class ArtikelNrMultiselectModule { }
