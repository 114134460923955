import { Injectable, Inject } from '@angular/core';
import { MainGroupService } from '../odata-services';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';

//import { Sortimentsauswahl } from '../models/sortimente/sortimentsauswahl.model';

@Injectable({
  providedIn: 'root'
})
export class MainGroupDataService extends GridDataService {
    
  public odataService: MainGroupService;
  
  
    constructor(  @Inject(MainGroupService)  service: MainGroupService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }



}
