import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { CustomerGroup } from '../entities/customers/customergroup.entity';
import { Group } from '../entities/schema/group.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class CustomerGroupService extends ODataEntityService<CustomerGroup> {
  constructor(protected client: ODataClient) {
    super(client, 'CustomerGroup', 'entities.customers.CustomerGroup');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getCustomers(): ODataFunctionResource<{groupId: number}, CustomerGroup> {
    const resource = this.entities().function<{groupId: number}, CustomerGroup>('services.GetCustomers');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public group(entity: EntityKey<CustomerGroup>): ODataNavigationPropertyResource<Group> {
    return this.entity(entity).navigationProperty<Group>('group');
  }
  public status(entity: EntityKey<CustomerGroup>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<CustomerGroup>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
