import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelPreisService } from '../odata-services/services/artikelpreis.service';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class ArtikelpreisDataService  extends GridDataService {
    
  public odataService: ArtikelPreisService;
  
  
    constructor(  @Inject(ArtikelPreisService)  service: ArtikelPreisService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
