import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
//import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';


import { AccountRoutingModule } from './account.routing-module';
import { AuthService } from '../../authentication';
import { ConfigService } from '../../configs/config.service';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';


@NgModule({
  declarations: [
    //LoginComponent, 
    RegisterComponent,AuthCallbackComponent],
  providers: [AuthService, ConfigService],
  imports: [
    CommonModule,
    FormsModule,
    AccountRoutingModule
  ],
  exports:
  [
    //LoginComponent,
     RegisterComponent,AuthCallbackComponent]
})
export class AccountModule { }
