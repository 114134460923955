import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { StatusGroupTreeConfig } from './statusgrouptree.entity.config';
import { StatusConfig } from './status.entity.config';
import { StatusTypeConfig } from './statustype.entity.config';
//#endregion

export const SettingsSchema = {
  namespace: "entities.settings",
  enums: [],
  entities: [StatusGroupTreeConfig,
    StatusConfig,
    StatusTypeConfig],
  callables: [],
  containers: []
} as SchemaConfig;