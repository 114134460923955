import { ApiConfig, EDM_PARSERS } from 'angular-odata';


//#region ODataApi Imports
import { BoerseSchema } from './entities/boerse/boerse.schema';
import { ArticlesSchema } from './entities/articles/articles.schema';
import { AssetsSchema } from './entities/assets/assets.schema';
import { EntitiesSchema } from './entities/entities.schema';
import { DtosSchema } from './Mza/Entities/Dtos/dtos.schema';
import { ModelsSchema } from './Mza/Service/Models/models.schema';
import { KaufabwicklungSchema } from './kaufabwicklung/kaufabwicklung.schema';
import { CustomersSchema } from './entities/customers/customers.schema';
import { SchemaSchema } from './entities/schema/schema.schema';
import { ModelsKundenSchema } from './Mza/Service/Controllers/V1/Kunden/Models/models.schema';
import { ProdukteSchema } from './entities/produkte/produkte.schema';
import { RefSchema } from './entities/ref/ref.schema';
import { SettingsSchema } from './entities/settings/settings.schema';
import { WettbewerbSchema } from './entities/wettbewerb/wettbewerb.schema';
import { ServicesSchema } from './services/services.schema';
//#endregion

export const mzaApiServiceConfig = {
  name: 'mza-api-service',
  serviceRootUrl: 'https://localhost:44370/v1/',
  version: '4.0',
  creation: new Date('2020-09-22T08:21:38.1589335+02:00'),
  schemas: [
      BoerseSchema,
    ArticlesSchema,
    AssetsSchema,
    EntitiesSchema,
    DtosSchema,
    ModelsSchema,
    KaufabwicklungSchema,
    CustomersSchema,
    SchemaSchema,
    ModelsKundenSchema,
    ProdukteSchema,
    RefSchema,
    SettingsSchema,
    WettbewerbSchema,
    ServicesSchema
  ],
  parsers: EDM_PARSERS
} as ApiConfig;