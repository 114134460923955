import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}


//LOCALHOST
/*  const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'AUTH_URL', useValue: 'https://login.auth-me.de' },
  { provide: 'CLIENT_ID', useValue: 'cuntino-dev' },
  { provide: 'SCOPE', useValue: 'openid profile email tt_coach_api_service vawago_api_service altivo_api_service cuntino_assert_service offline_access' }, 
  { provide: 'API_URL', useValue: 'https://localhost:44370/v1' },
  { provide: 'ASSET_URL', useValue: 'https://localhost:44396' } 
 
];   */

//LIVE
/* const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'AUTH_URL', useValue: 'https://auth.mza-vertrieb.de' },
  { provide: 'CLIENT_ID', useValue: 'MzaAngularClient.local' },
  { provide: 'SCOPE', useValue: 'openid profile email mzaodataservice MzaAssetService offline_access' }, 
  { provide: 'API_URL', useValue: 'https://data-service.mza-vertrieb.de/v1' },
  { provide: 'ASSET_URL', useValue: 'https://edvsrv:9989' } 
 
]; */

//ALTIVO
/* const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'CLIENT_ID', useValue: 'Altivo-Web-App' },
  { provide: 'SCOPE', useValue: 'openid profile email altivo_api_service cuntino_assert_service offline_access' },
  { provide: 'AUTH_URL', useValue: 'https://login.auth-me.de' },
  { provide: 'API_URL', useValue: 'https://api.altivo.de' },
  { provide: 'ASSET_URL', useValue: 'https://asset.altivo.de' } 

]; */
//TT-COACH
const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'CLIENT_ID', useValue: 'TT-CoachAdminClient' },
  { provide: 'SCOPE', useValue: 'openid profile email tt_coach_api_service vawago_api_service altivo_api_service cuntino_assert_service offline_access' },
  { provide: 'AUTH_URL', useValue: 'https://login.auth-me.de' },
  { provide: 'API_URL', useValue: 'https://api.tt-coach.de' },
  { provide: 'ASSET_URL', useValue: 'https://asset.tt-coach.de' } 
];

//VAWAGO
/* const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'CLIENT_ID', useValue: 'TT-CoachAdminClient' },
  { provide: 'SCOPE', useValue: 'openid profile email tt_coach_api_service vawago_api_service altivo_api_service cuntino_assert_service offline_access' },
  { provide: 'AUTH_URL', useValue: 'https://login.auth-me.de' },
  { provide: 'API_URL', useValue: 'https://api.vawago.de' },
  { provide: 'ASSET_URL', useValue: 'https://asset.vawago.de' } 
];
 */
platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
