import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { CustomerAssetGroup } from './customerassetgroup.entity';
//#endregion

export const CustomerAssetGroupConfig = {
  name: "CustomerAssetGroup",
  annotations: [],
  fields: {
    kundenNr: {type: 'Edm.Int32', nullable: false},
    assetGroupId: {type: 'Edm.Int32'},
    kundeId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kunde: {type: 'services.Kunde', navigation: true, field: 'kundeId', ref: 'id'},
    assetGroup: {type: 'entities.assets.AssetGroup', navigation: true, field: 'assetGroupId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<CustomerAssetGroup>;