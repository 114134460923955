import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { PhaseTypeConfig } from './phasetype.enum.config';
import { RefTypeConfig } from './reftype.enum.config';
import { DataTypeConfig } from './datatype.enum.config';
import { DataFormatConfig } from './dataformat.enum.config';
import { VertriebsTypeConfig } from './vertriebstype.enum.config';
import { CustomerViewDtoConfig } from './customerviewdto.entity.config';
import { BeigabenTypConfig } from './beigabentyp.entity.config';
import { KundeConfig } from './kunde.entity.config';
import { FirmaAdresseConfig } from './firmaadresse.entity.config';
import { PersonenSegmenteConfig } from './personensegmente.entity.config';
import { TeamPersonenConfig } from './teampersonen.entity.config';
import { TeamConfig } from './team.entity.config';
import { AbteilungConfig } from './abteilung.entity.config';
import { LeistungsprofilAnsprechpartnerConfig } from './leistungsprofilansprechpartner.entity.config';
import { LeistungsprofilHaendlerConfig } from './leistungsprofilhaendler.entity.config';
import { LeistungsprofilHaendlerAngabefelderConfig } from './leistungsprofilhaendlerangabefelder.entity.config';
import { LeistungsprofilHaendlerBereichsangabeConfig } from './leistungsprofilhaendlerbereichsangabe.entity.config';
import { LeistungsprofilInternetverdachtHaendlerConfig } from './leistungsprofilinternetverdachthaendler.entity.config';
import { LeistungsprofilWebshopadressenConfig } from './leistungsprofilwebshopadressen.entity.config';
import { RegistrationAbgleichConfig } from './registrationabgleich.entity.config';
import { RegistrationGroupConfig } from './registrationgroup.entity.config';
import { ArtikelbezeichnungConfig } from './artikelbezeichnung.entity.config';
import { StatusGroupConfig } from './statusgroup.entity.config';
import { FirmaDtoConfig } from './firmadto.entity.config';
import { PersonDtoConfig } from './persondto.entity.config';
import { FirmaAdresseDtoConfig } from './firmaadressedto.entity.config';
import { ContainerContainer } from './container.container';
//#endregion

export const ServicesSchema = {
  namespace: "services",
  enums: [PhaseTypeConfig,
    RefTypeConfig,
    DataTypeConfig,
    DataFormatConfig,
    VertriebsTypeConfig],
  entities: [CustomerViewDtoConfig,
    BeigabenTypConfig,
    KundeConfig,
    FirmaAdresseConfig,
    PersonenSegmenteConfig,
    TeamPersonenConfig,
    TeamConfig,
    AbteilungConfig,
    LeistungsprofilAnsprechpartnerConfig,
    LeistungsprofilHaendlerConfig,
    LeistungsprofilHaendlerAngabefelderConfig,
    LeistungsprofilHaendlerBereichsangabeConfig,
    LeistungsprofilInternetverdachtHaendlerConfig,
    LeistungsprofilWebshopadressenConfig,
    RegistrationAbgleichConfig,
    RegistrationGroupConfig,
    ArtikelbezeichnungConfig,
    StatusGroupConfig,
    FirmaDtoConfig,
    PersonDtoConfig,
    FirmaAdresseDtoConfig],
  callables: [{
    name: 'GetAssets',
    bound: true,
    composable: false,
    parameters: { groupId: {type: 'Edm.Int32', nullable: false} },
    return: "entities.assets.Asset"
  }, {
    name: 'GetHauptwort',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetSpezifikation',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetColor',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetMaterial',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetOberflaeche',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetDin',
    bound: true,
    composable: false,
    return: "Mza.Entities.Dtos.BezeichnungDto"
  }, {
    name: 'GetAssetHist',
    bound: true,
    composable: false,
    parameters: { assetId: {type: 'Edm.Int32', nullable: false} },
    return: "entities.assets.Asset"
  }, {
    name: 'GetOverview',
    bound: true,
    composable: false,
    return: "Mza.Service.Models.AssetViewDto"
  }, {
    name: 'HasVersandPremium',
    bound: true,
    composable: false,
    parameters: { id: {type: 'Edm.Int32', nullable: false} },
    return: "Edm.Boolean"
  }, {
    name: 'GetCustomers',
    bound: true,
    composable: false,
    parameters: { groupId: {type: 'Edm.Int32', nullable: false} },
    return: "entities.customers.CustomerGroup"
  }, {
    name: 'GetWettbewerberPreise',
    bound: true,
    composable: false,
    parameters: { groupId: {type: 'Edm.Int32', nullable: false} },
    return: "Mza.Service.Controllers.V1.Kunden.Models.WettbewerberPreisViewDto"
  }, {
    name: 'AcceptEinladung',
    bound: true,
    composable: false,
    parameters: { token: {type: 'Edm.String'} },
    return: "Edm.String"
  }, {
    name: 'GetUserStateForApp',
    bound: true,
    composable: false,
    return: "Mza.Service.Controllers.V1.Kunden.Models.UserStateDto"
  }, {
    name: 'GetFirmaPersonen',
    bound: true,
    composable: false,
    return: "Mza.Service.Controllers.V1.Kunden.Models.FirmaPersonenDto"
  }, {
    name: 'InputFilter',
    bound: true,
    composable: false,
    parameters: { value: {type: 'Edm.String'} },
    return: "Mza.Entities.Dtos.RegistrationFilterDto"
  }, {
    name: 'InputFilter',
    bound: true,
    composable: false,
    parameters: { value: {type: 'Edm.String'} },
    return: "Mza.Entities.Dtos.MarkenFilterDto"
  }, {
    name: 'Kundenzuordnung',
    bound: true,
    composable: false,
    parameters: { kundenNr: {type: 'Edm.Int32', nullable: false} },
    return: "entities.produkte.KundensortimentDto"
  }, {
    name: 'Registrierungszuordnung',
    bound: true,
    composable: false,
    parameters: { regId: {type: 'Edm.Int32', nullable: false} },
    return: "entities.produkte.KundensortimentDto"
  }, {
    name: 'InputFilter',
    bound: true,
    composable: false,
    parameters: { value: {type: 'Edm.String'} },
    return: "Mza.Entities.Dtos.MarkenFilterDto"
  }, {
    name: 'BookingTimesWithToken',
    bound: true,
    composable: false,
    parameters: { id: {type: 'Edm.Int32', nullable: false}, token: {type: 'Edm.String'}, startDate: {type: 'Edm.DateTimeOffset', nullable: false}, endDate: {type: 'Edm.DateTimeOffset', nullable: false} },
    return: "Mza.Service.Controllers.V1.Kunden.Models.BookingViewDto"
  }, {
    name: 'FreeBookingObjectsWithToken',
    bound: true,
    composable: false,
    parameters: { bookingTimeId: {type: 'Edm.Int32', nullable: false}, date: {type: 'Edm.DateTimeOffset', nullable: false}, token: {type: 'Edm.String'} },
    return: "entities.schema.BookingObject"
  }, {
    name: 'GetExtendsAttributes',
    bound: true,
    composable: false,
    parameters: { id: {type: 'Edm.Int32', nullable: false} },
    return: "Mza.Service.Models.ExtendsAttributesDto"
  }, {
    name: 'GetGroupTreeRefIds',
    bound: true,
    composable: false,
    parameters: { childId: {type: 'Edm.String'} },
    return: "Edm.Int32"
  }, {
    name: 'GetSalesTaxRate',
    bound: false,
    composable: false,
    parameters: { PostalCode: {type: 'Edm.Int32', nullable: false} },
    return: "Edm.Double"
  }, {
    name: 'GetAssets',
    bound: false,
    composable: false,
    parameters: { groupId: {type: 'Edm.Int32', nullable: false} },
    return: "Mza.Service.Models.AssetViewDto"
  }, {
    name: 'GetCustomers',
    bound: false,
    composable: false,
    parameters: { groupId: {type: 'Edm.Int32', nullable: false} },
    return: "services.CustomerViewDto"
  }, {
    name: 'GetAssetHist',
    bound: false,
    composable: false,
    parameters: { assetId: {type: 'Edm.Int32', nullable: false} },
    return: "entities.assets.Asset"
  }, {
    name: 'GetOverview',
    bound: false,
    composable: false,
    return: "Mza.Service.Models.AssetViewDto"
  }, {
    name: 'CreateCustomerList',
    bound: true,
    composable: false,
    parameters: { kundenListe: {type: 'Edm.String', collection: true} },
  }, {
    name: 'CreateCustomerListFromFilter',
    bound: true,
    composable: false,
  }, {
    name: 'SendEinladung',
    bound: true,
    composable: false,
    parameters: { firmaId: {type: 'Edm.Int32', nullable: false} },
  }, {
    name: 'BookingWithToken',
    bound: true,
    composable: false,
    parameters: { token: {type: 'Edm.String'}, bookingTimeId: {type: 'Edm.Int32', nullable: false}, isBooked: {type: 'Edm.Boolean', nullable: false}, date: {type: 'Edm.DateTimeOffset', nullable: false} },
  }, {
    name: 'MergeGroupTreesInOne',
    bound: true,
    composable: false,
    parameters: { groupTreeIds: {type: 'Edm.Int32', collection: true, nullable: false} },
  }, {
    name: 'ConvertFolderInGroup',
    bound: true,
    composable: false,
  }, {
    name: 'RemoveGroupTreeWithChildren',
    bound: true,
    composable: false,
  }, {
    name: 'RemoveGroupTreeWithChildrenAndGroups',
    bound: true,
    composable: false,
  }, {
    name: 'RemoveGroupTreeRefItem',
    bound: true,
    composable: false,
    parameters: { maingroupParentId: {type: 'Edm.Int32', nullable: false} },
  }],
  containers: [ContainerContainer]
} as SchemaConfig;