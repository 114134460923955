import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { CustomerGroupDataService, CustomerViewDto, Group, PhaseType, Status, StatusService, CustomerGroup, DataResult } from 'mza-api-service';
import { ODataEntitySetResource } from 'angular-odata';
import { GridDataResult, SelectAllCheckboxState, PageChangeEvent, DataStateChangeEvent, FilterService } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { State, CompositeFilterDescriptor, toODataString } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';
import { HttpParams } from '@angular/common/http';
import { flatten } from '@progress/kendo-angular-grid/dist/es2015/filtering/base-filter-cell.component';
import { NotificationService } from '@progress/kendo-angular-notification';

@Component({
  selector: 'gpv-kunde-gruppen-zuweisung',
  templateUrl: './kunde-gruppen-zuweisung.component.html',
  styleUrls: ['./kunde-gruppen-zuweisung.component.css']
})
export class KundeGruppenZuweisungComponent implements OnInit, OnChanges {

  @Input() groupId: number = 0;
  @Input() registrationsId: number;
  @Input() kundenNr: number;

  public _dataServiceCustomerGroup: CustomerGroupDataService;
  public filter: CompositeFilterDescriptor;
  
  public Customers: DataResult;
  public query: ODataEntitySetResource<any>;

  public sortimente: Observable<GridDataResult>;
  public mySelection: string[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  public isLoading: boolean = false;
  private hideAfter: number =3000;

  public state: State = {
    skip: 0,
    take: 50,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [{
        field: 'statusId',
        operator: 'gt',
        value: 0
    }]
    }
  };

  constructor(private service: CustomerGroupDataService, private _statusService: StatusService,
            private notificationService: NotificationService) {
    this._dataServiceCustomerGroup = service;
  }


  public statusItems: Array<Status> = [];


  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
    this.loadData();
  }


  private loadData() {
    this.isLoading = false;
    if (isNullOrUndefined(this.groupId))
      return;
      
    const queryStr = `${toODataString(this.state)}`;
    let select = "id,groupId,kundenNr,name,statusId,statusTypeId";
    /* let filter = "statusId gt 0";
    let  params = new HttpParams()
    .set('$select', select)
    .set('$filter', filter)
    .set('$top',this.state.take.toString())
    .set('$count', 'true'); */
    const opts = { params: new HttpParams({fromString: queryStr+"&$count=true&$orderby=kundenNr&$select="+select}) };
    this.isLoading = true;//{params: params}
    this._dataServiceCustomerGroup.odataService.getCustomers().call({groupId:this.groupId},"entities").subscribe((entities) => {
      //TODO: META-Count für die gesamtanzahl an Daten.
      this.Customers = new DataResult(entities,10);//meta.count
      this.isLoading = false;
    });



  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.loadData();
  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadData();
  }
  ngOnInit() {
    let reqStatus = this._statusService.entities();
    reqStatus.query.select("id,name");
    reqStatus.query.expand("type");

    reqStatus.query.filter("type/name eq 'Kundenanfrage'")
    reqStatus.get().subscribe(
      ({entities, meta}) => this.statusItems = entities
    );
  }
  public statusChange(value: number): void {
    const [filter] = flatten( this.state.filter).filter(x => x.field === 'statusId');
    
      if (!filter) {
        this.state.filter.filters.push({
          field: 'statusId',
          operator: 'gt',
          value: 0
      });
    }

    if(value > 0){
        filter.operator = "eq";
        filter.value = value;
    }
     else{
      filter.operator = "gt";
      filter.value = 0;
     } 
  this.loadData();
}

public filterCustomerChange(value: string): void {
  const [filter] = flatten( this.state.filter).filter(x => x.field === 'kundenNr');
  
    if (!filter) {
      this.state.filter.filters.push({
        field: 'kundenNr',
        operator: 'eq',
        value: parseInt(value)
    });
  }else{
    filter.value = parseInt(value);
  }

  if(isNullOrUndefined(value)){
    this.state.filter.filters.pop();
  }
this.loadData();
}

public statusCustomerChange(value: number, dataItem: CustomerViewDto): void {
  console.log(value,dataItem);

  let data = <CustomerGroup>{
    id: dataItem.id,
  groupId: dataItem.groupId,
  kundenNr: dataItem.kundenNr,
  statusId: dataItem.statusId,
  statusTypeId: dataItem.statusTypeId
  }
 

  if(data.id === 0) 
  {
    this._dataServiceCustomerGroup.odataService.create(data).subscribe(
      r => {
       this.saveMessageOk();
      },
      (error) => {  
      this.saveMessageError();
    }
    );
  }
  else if(data.statusId === 7){
    this._dataServiceCustomerGroup.odataService.destroy(data).subscribe(
      r => {
       this.saveMessageOk();
      },
      (error) => {  
      this.saveMessageError();
    }
    );
  }
  else{
    this._dataServiceCustomerGroup.odataService.assign({id: data.id},{ statusId: data.statusId}).subscribe(
      r => {
       this.saveMessageOk();
      },
      (error) => {  
      this.saveMessageError();
    }
    );
  }
  
}

  private saveMessageOk(){
    this.notificationService.show({
      content: 'Einstellung gespeichert',
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: this.hideAfter,
      //closable: true
  });
  }
  private saveMessageError(){
    this.notificationService.show({
      content: 'Fehler beim speichern der Einstellung',
      cssClass: 'save-error',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'right', vertical: 'top' },
      type: { style: 'error', icon: true },
     // hideAfter: this.hideAfter,
     closable: true
  });
  }


  public saveHandler({ sender, formGroup, rowIndex }) {
    if (formGroup.valid) {
      sender.closeRow(rowIndex);
    }
  }
  public saveChanges(grid: any): void {
    grid.closeCell();
    grid.cancelCell();

    /* if (!isNullOrUndefined(this.registrationsId) && this.registrationsId > 0)
     this._dataServiceKundensortimente.saveChanges(this.state,this._dataServiceKundensortimente.odataService.getODataQueryRegistrierungszuordnung(this.registrationsId) );
    else if (!isNullOrUndefined(this.kundenNr) && this.kundenNr > 0)
    this._dataServiceKundensortimente.saveChanges(this.state,this._dataServiceKundensortimente.odataService.getODataQueryKundenzuordnung(this.kundenNr) );
    
     */
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.state.filter = filter;
    this.loadData();
  }


  public cancelChanges(grid: any): void {
    grid.cancelCell();
  }
}
