import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
interface Item {
  text: string,
  value: number
}
@Component({
  selector: 'mza-beigaben-lagerort',
  templateUrl: './beigaben-lagerort.component.html',
  styleUrls: ['./beigaben-lagerort.component.css'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => BeigabenLagerortComponent),
      multi: true 
    }
  ]
})
export class BeigabenLagerortComponent implements OnInit, ControlValueAccessor  {
  writeValue(lagerort: number): void {
    //  this.art = bestellart;
      this.lagerort = lagerort;
    }
  
    onChange = (bestellart: number) => {};
    registerOnChange(fn: any): void {
      this.onChange = fn;
    }
  
    onTouched = () => {};
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    @Input() disabled = false;
    
    public lagerort: number;

  public lagerorte: Array<Item> = [
    { text: "Meiningen", value: 1 },
    { text: "Suhl", value: 2 },
    { text: "Meiningen/Suhl", value: 3 }
  ];

  constructor() { }

  ngOnInit() {
  }
  valueChanged(value: any) {
    //this.art = value;
    this.onChange(value);
  }
}
