import { Injectable, Injector } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { OidcSecurityService} from 'angular-auth-oidc-client';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private oidcSecurityService: OidcSecurityService;
    private isUserAuth: boolean = false;
    constructor(
        private router: Router,
        private injector: Injector
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = this.injector.get(OidcSecurityService);
        }

        const user = this.oidcSecurityService.getIsAuthorized().subscribe(
            isAuth => {
                this.isUserAuth = isAuth;
                console.log("AthGuard: ",isAuth);
                if (isAuth) {

                    // check if route is restricted by role
                 /*    if (route.data.roles && route.data.roles.indexOf(user.role) === -1) {
                        // role not authorised so redirect to home page
                        this.router.navigate(['/']);
                        return false;
                    } */
        
                    // authorised so return true
                    return true;
                }
        
                // not logged in so redirect to login page with the return url
                if (!this.isUserAuth) {
                    this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
                }
               // this.oidcSecurityService.authorize();
                return false;
            }

        );
        if (!this.isUserAuth) {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        }
        
        return  this.isUserAuth;
    }
}