import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { RegistrationGroup } from './registrationgroup.entity';
//#endregion

export const RegistrationGroupConfig = {
  name: "RegistrationGroup",
  annotations: [],
  fields: {
    registrationId: {type: 'Edm.Int32', nullable: false},
    groupId: {type: 'Edm.Int32'},
    isUserRequest: {type: 'Edm.Boolean', nullable: false},
    allowUserRequest: {type: 'Edm.Boolean', nullable: false},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    group: {type: 'entities.schema.Group', navigation: true, field: 'groupId', ref: 'id'},
    registration: {type: 'entities.schema.RegistrationInfo', navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<RegistrationGroup>;