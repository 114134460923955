import { Component, OnInit } from '@angular/core';
import { ProduktTypDataService , ProduktTyp, DataResult} from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import {  ODataOptions }from 'angular-odata';

@Component({
  selector: 'gpv-produkt-typen',
  templateUrl: './produkt-typen.component.html',
  styleUrls: ['./produkt-typen.component.css']
})
export class ProduktTypenComponent implements OnInit {

  
  public produkttypForm: FormGroup;
  public produkttypListe: Observable<DataResult>;
  //private query: ODataOptions;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};


constructor(public _dataServiceProdukttyp: ProduktTypDataService,
  private formBuilder: FormBuilder) { 
   this.createProdukttypForm  = this.createProdukttypForm.bind(this);
  
  }

  ngOnInit() {
  }

  public createProdukttypForm(args: any): FormGroup  {
    let data  = <ProduktTyp>{ id: 0,
                  //   description: "",
                        name: "",
    /*                      value: "",
                         sortValue: 0,
                         status: null */
              
                         };
    const item : ProduktTyp = args.isNew ? data : args.dataItem;

    this.produkttypForm = this.formBuilder.group({
      id: item.id ,
      name: [item.name, [Validators.maxLength(40), Validators.required] ],
/*       description: [item.description, [Validators.maxLength(250)] ],
      value: [item.value, [Validators.maxLength(40)] ],
      sortValue: [item.sortValue],   
      status: [item.status],  */           
   
  });
  return this.produkttypForm;
}

public saveChanges(grid: any): void {
  this._dataServiceProdukttyp.saveChanges(this.state);
}

public cancelChanges(grid: any): void {
  this._dataServiceProdukttyp.cancelChanges();
}

}
