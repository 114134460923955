import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { PropertyConfig } from './property.entity.config';
import { TypeConfig } from './type.entity.config';
//#endregion

export const RefSchema = {
  namespace: "entities.ref",
  enums: [],
  entities: [PropertyConfig,
    TypeConfig],
  callables: [],
  containers: []
} as SchemaConfig;