import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MarkenNameComponent } from './marken-name.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [MarkenNameComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    DropDownsModule
  ],exports: [MarkenNameComponent]
})
export class MarkenNameModule { }
