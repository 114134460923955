import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Asset } from './asset.entity';
//#endregion

export const AssetConfig = {
  name: "Asset",
  annotations: [],
  fields: {
    fileName: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    datatype: {type: 'services.DataType', nullable: false},
    dataformat: {type: 'services.DataFormat', nullable: false},
    tags: {type: 'Edm.String'},
    fileSize: {type: 'Edm.Decimal', nullable: false},
    videoformatId: {type: 'Edm.Int32'},
    imageformatId: {type: 'Edm.Int32'},
    documentformatId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    videoformat: {type: 'entities.assets.Videoformat', navigation: true, field: 'videoformatId', ref: 'id'},
    imageformat: {type: 'entities.assets.Imageformat', navigation: true, field: 'imageformatId', ref: 'id'},
    documentformat: {type: 'entities.assets.Documentformat', navigation: true, field: 'documentformatId', ref: 'id'},
    assetMetaDataList: {type: 'entities.assets.AssetMetaData', collection: true, navigation: true},
    artikelAssets: {type: 'entities.articles.ArtikelAsset', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Asset>;