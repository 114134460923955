import { Component, OnInit, Input } from '@angular/core';
import { Asset } from 'mza-api-service';
import { FormatHelper } from '../../../../shared/helpers/format-helper';

@Component({
  selector: 'gpv-dateiinfos',
  templateUrl: './dateiinfos.component.html',
  styleUrls: ['./dateiinfos.component.css']
})
export class DateiinfosComponent implements OnInit {

  @Input('asset') asset: Asset;  
  
  constructor() { }

  ngOnInit() {
  }
  public getPixelInCm(pixel,dpi){
    return ((pixel * 25.4)/dpi)/10;
  }

  public getFileSize(bytes,decimals){
    return FormatHelper.formatBytes(bytes,decimals);
  }
}
