import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ChartsDto } from './chartsdto.entity';
//#endregion

export const ChartsDtoConfig = {
  name: "ChartsDto",
  annotations: [],
  fields: {
    wettbewerb: {type: 'Mza.Service.Models.ChartItemDto', collection: true},
    vk: {type: 'Mza.Service.Models.ChartItemDto', collection: true},
    ek: {type: 'Mza.Service.Models.ChartItemDto', collection: true},
    sonderpreis: {type: 'Mza.Service.Models.ChartItemDto', collection: true},
    stueck: {type: 'Mza.Service.Models.ChartItemDto', collection: true}
  }
} as EntityConfig<ChartsDto>;