import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Asset } from '../entities/assets/asset.entity';
import { AssetViewDto } from '../Mza/Service/Models/assetviewdto.entity';
import { CustomerViewDto } from './customerviewdto.entity';
//#endregion

@Injectable()
export class ContainerService {
 
  constructor(protected client: ODataClient) { }

  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getSalesTaxRate(): ODataFunctionResource<{PostalCode: number}, number> {
    const resource = this.client.function<{PostalCode: number}, number>('services.GetSalesTaxRate');
    return resource;
  }
  public getAssets(): ODataFunctionResource<{groupId: number}, AssetViewDto> {
    const resource = this.client.function<{groupId: number}, AssetViewDto>('services.GetAssets');
    return resource;
  }
  public getCustomers(): ODataFunctionResource<{groupId: number}, CustomerViewDto> {
    const resource = this.client.function<{groupId: number}, CustomerViewDto>('services.GetCustomers');
    return resource;
  }
  public getAssetHist(): ODataFunctionResource<{assetId: number}, Asset> {
    const resource = this.client.function<{assetId: number}, Asset>('services.GetAssetHist');
    return resource;
  }
  public getOverview(): ODataFunctionResource<null, AssetViewDto> {
    const resource = this.client.function<null, AssetViewDto>('services.GetOverview');
    return resource;
  }
  //#endregion
}