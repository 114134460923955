import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { TradegateKurs } from './tradegatekurs.entity';
//#endregion

export const TradegateKursConfig = {
  name: "TradegateKurs",
  annotations: [],
  fields: {
    aktieId: {type: 'Edm.Int32'},
    dateTime: {type: 'Edm.DateTimeOffset', nullable: false},
    bidSize: {type: 'Edm.Decimal', nullable: false},
    bid: {type: 'Edm.Decimal', nullable: false},
    askSize: {type: 'Edm.Decimal', nullable: false},
    ask: {type: 'Edm.Decimal', nullable: false},
    stueck: {type: 'Edm.Decimal', nullable: false},
    umsatz: {type: 'Edm.Decimal', nullable: false},
    avg: {type: 'Edm.Decimal', nullable: false},
    last: {type: 'Edm.Decimal', nullable: false},
    high: {type: 'Edm.Decimal', nullable: false},
    low: {type: 'Edm.Decimal', nullable: false},
    delta: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    aktie: {type: 'entities.boerse.Aktie', navigation: true, field: 'aktieId', ref: 'id'}
  }
} as EntityConfig<TradegateKurs>;