
    <div  class="s-artikel-nr-mit-info">
        <div fxLayout="row" fxLayoutAlign="space-between end">
<kendo-textbox-container floatingLabel="Artikelnr" fxFlex="40%">
      <kendo-combobox #artikelFilter
      isRequired="isRequired"
      [data]="data"
      [value]="value"
      
      [textField]="'artikelNr'"
      [valueField]="'id'"
      [valuePrimitive]="true"
      [filterable]="true"
      (filterChange)="handleFilter($event)"
      (valueChange)="onValueChange($event)"
      [placeholder]="'Artikelnr wählen...'"
      >
      <ng-template kendoComboBoxItemTemplate  let-dataItem>
              <div [className]="dataItem.imShopSichtbar === true ? 'im-shop' : ''">
                  <p><b>{{ dataItem.artikelNr }}</b></p>
              </div>
            
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
              <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
          </ng-template>
      </kendo-combobox>
    </kendo-textbox-container>
 <div *ngIf="selectedItem">  
 <mza-artikel-bild [artikelNr]="selectedItem.artikelNr" fxFlex="20%"></mza-artikel-bild> 
        
    
   <div fxLayout="row" fxLayoutAlign="space-between center"  fxFlex="60%">   
    {{selectedItem.bezeichnung}}
        </div>
    </div>
    </div>        
</div>  
 