import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { CustomerGroup } from './customergroup.entity';
//#endregion

export const CustomerGroupConfig = {
  name: "CustomerGroup",
  annotations: [],
  fields: {
    kundenNr: {type: 'Edm.Int32', nullable: false},
    groupId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kunde: {type: 'services.Kunde', navigation: true},
    group: {type: 'entities.schema.Group', navigation: true, field: 'groupId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<CustomerGroup>;