import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
//import { FormsModule }    from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HauptgruppenMainModule } from './hauptgruppen/hauptgruppen-main/hauptgruppen-main.module';
import { HauptgruppenModule } from './hauptgruppen/hauptgruppen.module';
import { GruppenModule } from './gruppen/gruppen.module';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import {MatBadgeModule} from '@angular/material/badge';
import { ConfigService, AuthOidcHttpInterceptor, AuthOidcAccountModule, AccountModule } from 'mza-auth-service';
import { OidcConfigService } from 'angular-auth-oidc-client';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { MenuModule } from '@progress/kendo-angular-menu';

import {// MzaApiServiceModule,
        AssetService,
        AssetMetaDataService,
        AssetMetaDataDataService,
        MainGroupService, 
        MainGroupDataService,
        GroupService, 
        GroupDataService, 
        GroupTreeAssigmentService, 
        GroupTreeAssigmentDataService, 
        ArtikelService,
        ArtikelDataService,
        ArtikelGroupService,
        ArtikelGroupDataService,
        ArtikelGroupFilterSettingsService,
        ArtikelGroupFilterSettingsDataService,
        ArtikelAttributeService,
        ArtikelAttributeDataService,
        CustomerGroupService,
        CustomerGroupDataService,
        GroupAttributeService,
        GroupAttributeDataService,
        GroupTreeService,
        GroupTreeDataService,
        GroupTreeAttributeService,
        GroupTreeAttributeDataService,
        MarkenService,
        WarengruppenService,
        PropertyService,
        StatusService,
        HerstellerDataService,
        HerstellerService,
        ProduktTypDataService,
        ProduktTypService,
        MetaDataService,
        ReportService,
        ReportDataService,DataMappingService, DashboardService,DashboardAssigmentService,
        DashboardDataService,
        StatusTypeService,StatusGroupTreeService,DataMappingDataService
      } from 'mza-api-service';
import { ODataModule }from 'angular-odata';
import { HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AttributeComponent } from './attribute/attribute.component';
import { ParamMapService } from './core/paramMap.service';
import { registerLocaleData } from '@angular/common';
import  localeDe   from '@angular/common/locales/de';
import '@progress/kendo-angular-intl/locales/DE/all';
import { AssetsModule } from './assets/assets.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DrawerModule } from '@progress/kendo-angular-layout';
import { ProdukteModule } from './produkte/produkte.module';
import { WettbewerbModule } from './kunden/wettbewerb/wettbewerb.module';
import { NotificationModule, NotificationService } from '@progress/kendo-angular-notification';
import { ArtikelnrColumnComponent } from './shared/components/columns/artikelnr-column/artikelnr-column.component';
import { TreeViewGroupComponent } from './shared/components/tree-view-group/tree-view-group.component';
import '@angular/localize/init';
import { WarengruppenNrComponent } from './shared/components/warengruppen-nr/warengruppen-nr.component';

import * as likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import * as currencyData from 'cldr-data/supplemental/currencyData.json';
 
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as calendar from 'cldr-data/main/de/ca-gregorian.json';
import * as currencies from 'cldr-data/main/de/currencies.json';
import * as dateFields from 'cldr-data/main/de/dateFields.json';
import { IntlModule, load } from '@progress/kendo-angular-intl';
import { SettingsComponent } from './settings/settings.component';
import { ReportsModule } from './reports/reports.module';
import { DashboardsModule } from './dashboards/dashboards.module';

registerLocaleData(localeDe);


load(
  likelySubtags,
  weekData,
  currencyData,
  numbers,
  currencies,
  calendar,
  timeZoneNames
);

@NgModule({
  declarations: [
    AppComponent,
    AttributeComponent,
    SettingsComponent,
  //  ArtikelnrColumnComponent
    
 /*    HerstellerComponent,
    ProduktTypenComponent, */
   // AssetImageComponent,
    //AssetImagePreviewDirective,
  //  AssetImageDirective
  
  ],
  imports: [
    AppRoutingModule,
    AuthOidcAccountModule,// AccountModule,
    BrowserModule,
    IntlModule ,
  //  FormsModule,
    DrawerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MenuModule,
    HttpModule,
    HttpClientModule,
    GruppenModule,
    AssetsModule,
    HauptgruppenModule,
    ProdukteModule,
    HauptgruppenMainModule,
    WettbewerbModule,
    MatButtonModule, MatCheckboxModule,MatInputModule,MatBadgeModule,
    ButtonsModule,GridModule,DropDownListModule,ReportsModule,DashboardsModule,
  //  MzaApiServiceModule,    
    ODataModule.forRoot({
      serviceRootUrl: environment.rest_service_url,     
     // metadataUrl:environment.rest_service_url+'/$metadata',
      withCredentials: false //,
     /*  errorHandler: (error: HttpErrorResponse) => {
        return throwError(error);
      }  */
    }),
  ],
    providers: [
      OidcConfigService,
      {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthOidcHttpInterceptor,
      multi: true
    }, 
    { provide: LOCALE_ID, useValue: "de-DE" },
    AssetService,
    AssetMetaDataService,
    AssetMetaDataDataService,
    ArtikelService,
    ArtikelDataService,
    ArtikelGroupService,
    ArtikelGroupDataService,
    ArtikelGroupFilterSettingsService,
    ArtikelGroupFilterSettingsDataService,
    ArtikelAttributeService,
    ArtikelAttributeDataService,
    HerstellerService,
    HerstellerDataService,
    ProduktTypService,
    ProduktTypDataService,
    CustomerGroupService,
    CustomerGroupDataService,
    GroupAttributeService,
    GroupAttributeDataService,
    MainGroupService,
    MainGroupDataService,
    GroupService,
    GroupDataService,
    GroupTreeAssigmentService,
    GroupTreeAssigmentDataService,
    GroupTreeService,
    GroupTreeDataService,
    GroupTreeAttributeService,
    GroupTreeAttributeDataService,
    MarkenService,
    WarengruppenService,
    StatusService,
    StatusTypeService,
    StatusGroupTreeService,
    MetaDataService,
   // WarengruppenDataService,
    ParamMapService,
    PropertyService,
    NotificationService,
    ReportService,
    ReportDataService,
    DashboardService,
    DashboardDataService,
    DashboardAssigmentService,
    DataMappingService,
    DataMappingDataService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
