import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Documentformat } from './documentformat.entity';
//#endregion

export const DocumentformatConfig = {
  name: "Documentformat",
  annotations: [],
  fields: {
    title: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    assets: {type: 'entities.assets.Asset', collection: true, navigation: true}
  }
} as EntityConfig<Documentformat>;