import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Type } from './type.entity';
//#endregion

export const TypeConfig = {
  name: "Type",
  annotations: [],
  fields: {
    bezeichnung: {type: 'Edm.String'},
    kurzBezeichnung: {type: 'Edm.String'},
    value: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    properties: {type: 'entities.ref.Property', collection: true, navigation: true},
    metaDataList: {type: 'entities.assets.MetaData', collection: true, navigation: true}
  }
} as EntityConfig<Type>;