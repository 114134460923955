import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gpv-asset-select',
  templateUrl: './asset-select.component.html',
  styleUrls: ['./asset-select.component.scss']
})
export class AssetSelectComponent implements OnInit {

  public openedAsset = false;

  //public assetId: number = 0;
  @Output("assetId") assetId : EventEmitter<number> = new EventEmitter<number>(); 

  constructor() { }

  ngOnInit() {
  }
  public close() {
    this.openedAsset = false;
  }

  public open() {
    this.openedAsset = true;
  }

  public dataSaved = false;

  public onAssetChanges(value : number){
    this.assetId.emit(value);
    this.close();
   // this.assetId = value;
  }


  public submit() {
      this.dataSaved = true;
      this.close();
  }


}
