import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { ArtikelAsset } from '../entities/articles/artikelasset.entity';
import { ArtikelAttribute } from '../entities/articles/artikelattribute.entity';
import { ArtikelGroup } from '../entities/articles/artikelgroup.entity';
import { ChartItemDto } from '../Mza/Service/Models/chartitemdto.entity';
import { Artikel } from '../entities/artikel.entity';
import { ChartsDto } from '../Mza/Service/Models/chartsdto.entity';
import { BeigabenArtikel } from '../entities/produkte/beigabenartikel.entity';
import { BeigabenKunde } from '../entities/produkte/beigabenkunde.entity';
import { Hersteller } from '../entities/produkte/hersteller.entity';
import { ProduktTyp } from '../entities/produkte/produkttyp.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class ArtikelService extends ODataEntityService<Artikel> {
  constructor(protected client: ODataClient) {
    super(client, 'Artikel', 'entities.Artikel');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getEkHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]>('GetEkHist');
    return resource;
  }

  public getVkHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]>('GetVkHist');
    return resource;
  }
  public getWbHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]>('GetWbHist');
    return resource;
  }
  public getSTKHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]>('GetSTKHist');
    return resource;
  }
  public GetSonderPrHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date}, ChartItemDto[]>('GetSonderPrHist');
    return resource;
  }
  public getChartNavigationHist(): ODataFunctionResource<{artikelId: number, fromDate: Date, toDate: Date, timeInterval: number, isWB: boolean, isVK: boolean, isEK: boolean, isSP: boolean, isSTK: boolean}, ChartsDto> {
    const resource = this.entities().function<{artikelId: number, fromDate: Date, toDate: Date, timeInterval: number, isWB: boolean, isVK: boolean, isEK: boolean, isSP: boolean, isSTK: boolean}, ChartsDto>('GetChartNavigationHist');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public artikelgruppen(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<ArtikelGroup> {
    return this.entity(entity).navigationProperty<ArtikelGroup>('artikelgruppen');
  }
  public assets(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<ArtikelAsset> {
    return this.entity(entity).navigationProperty<ArtikelAsset>('assets');
  }
  public attributes(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<ArtikelAttribute> {
    return this.entity(entity).navigationProperty<ArtikelAttribute>('attributes');
  }
  public beigabenArtikel(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<BeigabenArtikel> {
    return this.entity(entity).navigationProperty<BeigabenArtikel>('beigabenArtikel');
  }
  public beigabenKunden(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<BeigabenKunde> {
    return this.entity(entity).navigationProperty<BeigabenKunde>('beigabenKunden');
  }
  public hersteller(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<Hersteller> {
    return this.entity(entity).navigationProperty<Hersteller>('hersteller');
  }
  public produkttyp(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<ProduktTyp> {
    return this.entity(entity).navigationProperty<ProduktTyp>('produkttyp');
  }
  public status(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Artikel>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
