import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { KundenFilter } from '../entities/customers/kundenfilter.entity';
import { BeigabenArtikel } from '../entities/produkte/beigabenartikel.entity';
import { BeigabenKunde } from '../entities/produkte/beigabenkunde.entity';
import { Beigabe } from '../entities/produkte/beigabe.entity';
import { Property } from '../entities/ref/property.entity';
//#endregion

@Injectable()
export class BeigabenService extends ODataEntityService<Beigabe> {
  constructor(protected client: ODataClient) {
    super(client, 'Beigaben', 'entities.produkte.Beigabe');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public beigabenArt(entity: EntityKey<Beigabe>): ODataNavigationPropertyResource<Property> {
    return this.entity(entity).navigationProperty<Property>('beigabenArt');
  }
  public beigabenArtikel(entity: EntityKey<Beigabe>): ODataNavigationPropertyResource<BeigabenArtikel> {
    return this.entity(entity).navigationProperty<BeigabenArtikel>('beigabenArtikel');
  }
  public beigabenKunden(entity: EntityKey<Beigabe>): ODataNavigationPropertyResource<BeigabenKunde> {
    return this.entity(entity).navigationProperty<BeigabenKunde>('beigabenKunden');
  }
  public kundenFilter(entity: EntityKey<Beigabe>): ODataNavigationPropertyResource<KundenFilter> {
    return this.entity(entity).navigationProperty<KundenFilter>('kundenFilter');
  }
  //#endregion
}
