import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WettbewerbpreiseComponent } from './wettbewerbpreise/wettbewerbpreise.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WettbewerberpreisDataDirective } from 'mza-api-service';

@NgModule({
  declarations: [WettbewerbpreiseComponent,WettbewerberpreisDataDirective],
  imports: [
    CommonModule,FormsModule,
    ReactiveFormsModule,GridModule
  ],
  exports: [WettbewerbpreiseComponent]
})
export class WettbewerbModule { }
