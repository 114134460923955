import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';



const routes: Routes = [    
            {
                path: 'unauthorized',
                component: UnauthorizedComponent
            }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthOidcAccountRoutingModule { }