import { Component, OnInit, ViewEncapsulation, ViewChild, Inject, HostListener } from '@angular/core';
//import { TreeViewComponent, NodeSelectEventArgs, DragAndDropEventArgs, BeforeOpenCloseMenuEventArgs, ContextMenuComponent, MenuEventArgs } from '@syncfusion/ej2-angular-navigations';
import { GroupTree, GroupTreeDataService,RefType,Report,ReportService,Dashboard,DashboardService, GroupTreeService, ArtikelGroupDataService, GroupTreeAssigmentService, MainGroupService, DataArrayConvert, Maingroup, StatusService } from 'mza-api-service';
import { State, process } from '@progress/kendo-data-query';
import { map, switchMap } from 'rxjs/operators';
import { Guid } from 'guid-typescript';
import { ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
//
import { ODataEntitySetResource } from 'angular-odata';
import { TreeViewComponent, SelectableSettings, TreeItemDragEvent, TreeItemDropEvent, DropPosition, TreeItemAddRemoveArgs } from '@progress/kendo-angular-treeview';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { HttpParams } from '@angular/common/http';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { ContextAnzeige, ContextCopy, ContextDelete } from '../../shared/types/context-types.enum';
import { TreeSettings } from '../../shared/models/treeviewgroup.model';



//import { SelectableSettings } from '@progress/kendo-angular-treeview';



 interface Settings{
   nameMaingroup: string;
   allowDragAndDrop : boolean ;
   allowTreeStructur : boolean ;
   allowMultiSelection : boolean ;
   allowGroupTreeAssignmentForParent: boolean;
   allowGroupTreeAssignment: boolean;
   allowGroupsInDifferentNodes: boolean;
   allowAddRemove: boolean;
   allowChecked: false,
   showValue: boolean;
   showDescription: boolean;
   useArtikel: boolean;
   useAttribute: boolean;
   useAssets: boolean;
   useKunden: boolean;
   usedMaingroups: string;
   usedReports: string;
   usedDashboards: string;
   usedStatusTypeId: number;
} 

@Component({
  selector: 'gpv-baum-liste',
  templateUrl: './baum-liste.component.html',
  styleUrls: ['./baum-liste.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BaumListeComponent implements OnInit {
 @ViewChild('tree',null)
  public tree: TreeViewComponent;

  public groupRefSelectedTreeItem : GroupTree;
  public groupRefSelectedTreeItemRef : GroupTree;

  @ViewChild('grouptreecontext', null) 
  public contextMenuGroupTree: ContextMenuComponent;

  public scrHeight:any;
  public scrWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
  }

  public Setting: Settings ={
    nameMaingroup: "",
    allowDragAndDrop : false,
    allowTreeStructur : false,
    allowMultiSelection : false,
    allowGroupTreeAssignmentForParent: false,
    allowGroupTreeAssignment: false,
    allowGroupsInDifferentNodes: false,
    allowAddRemove: false,
    allowChecked: false,
    showValue: false,
    showDescription: false,
    useArtikel: false,
    useAttribute:false,
    useAssets: false,
    useKunden:false,
    usedMaingroups: "",
    usedReports: "",
    usedDashboards: "",
    usedStatusTypeId: 0
  };

  
  public SettingTree: TreeSettings ={
    allowDragAndDrop : false,
    allowTreeStructur : false,
    allowMultiSelection : false,
    allowGroupTreeAssignmentForParent: false,
    allowGroupTreeAssignment: false,

    allowGroupsInDifferentNodes: false,
    allowAddRemove: false,
    allowChecked: false,
    showValue: true,
    showDescription: false,
    showBar: true,
    usedSorts: ""
  };

  public SettingTreeRef: TreeSettings ={
    allowDragAndDrop : false,
    allowTreeStructur : false,
    allowMultiSelection : false,
    allowGroupTreeAssignmentForParent: false,
    allowGroupTreeAssignment: false,
    allowGroupsInDifferentNodes: false,
    allowAddRemove: false,
    allowChecked: false,
    showValue: true,
    showDescription: false,  showBar: true,usedSorts:""
  };

  public selectedGroupTreeParent: GroupTree;
  public selectedDataItem: any;
  public dataItem : any;
/*   public selectedKeys: any[] = [];
  public expandedKeys: any[] = []; */
  public existsTmpGroup: boolean = false;


//FilterTree

public filterTree: Array<GroupTree> = [];
public selectedfilterTree: Array<GroupTree> = [];


    //SETTINGS 

  public selectableSettings: SelectableSettings = {
    mode: 'single'
  };



  public GroupTreeDataEntwurf: any[];
  public maingroupId: number;
  public MaingroupRefs: Array<Maingroup> = [];
  public ReportRefs: Array<Report> = [];
  public DashboardRefs: Array<Dashboard> = [];
  private statusCreateId: number = 0;
  public showRefArtikel: boolean = true;

  constructor(private dataService: GroupTreeService,  private route: ActivatedRoute, 
              public _dataServiceArtikelGroups: ArtikelGroupDataService,
              public _mainGroupService: MainGroupService,
              public _reportService: ReportService,
              public _dashboardService: DashboardService,
              private _groupTreeAssigmentService: GroupTreeAssigmentService,
              private _statusService: StatusService ) {

                this.getScreenSize();

  }

  public loadSettings(){
    if(isNullOrUndefined(this.maingroupId) )
      return;

    let queryStatus = this._statusService.entities();
    queryStatus.query.select("id");
    queryStatus.query.filter({typeId: {"eq" : 1}, phase:{"eq": 'PUBLISH_INTERN'}});
    queryStatus.query.top(1);
    queryStatus.get().subscribe(({entities,meta}) => {
      this.statusCreateId = entities[0].id;
    });
    let reqMainGroup = this._mainGroupService.entity(this.maingroupId);
    reqMainGroup.query.select(["name","allowGroupTreeAssignment", "allowTreeStructur", "allowGroupsInDifferentNodes","showValue","showDescription","useArtikel","useAttribute","useAssets","useKunden","usedMaingroups","usedReports","usedDashboards","usedSortReports","usedStatusTypeId" ]);
    reqMainGroup.get().subscribe(({entity,meta}) => {

     this.Setting.nameMaingroup = entity.name;
      this.Setting.allowTreeStructur   = entity.allowTreeStructur;  
      this.Setting.allowGroupTreeAssignment  = entity.allowGroupTreeAssignment;
      this.Setting.allowGroupsInDifferentNodes  = entity.allowGroupsInDifferentNodes;
      this.SettingTreeRef.allowGroupsInDifferentNodes = entity.allowGroupsInDifferentNodes;
      this.Setting.showValue = entity.showValue;
      this.Setting.showDescription = entity.showDescription;

      this.Setting.useArtikel = entity.useArtikel;
      this.Setting.useAssets = entity.useAssets;
      this.Setting.useAttribute = entity.useAttribute;
      this.Setting.useKunden = entity.useKunden;
      this.Setting.usedMaingroups = entity.usedMaingroups;
      this.Setting.usedReports = entity.usedReports;
      this.Setting.usedDashboards = entity.usedDashboards;
      this.Setting.usedStatusTypeId = entity.usedStatusTypeId

      this.SettingTree.usedSorts = entity.usedSortReports;

      this.loadMaingroupRefs();
      this.loadReportRefs();
      this.loadDashboardRefs();
    })
  }

  private loadMaingroupRefs(){
    if(isNullOrUndefined( this.Setting.usedMaingroups) || isNullOrEmptyString( this.Setting.usedMaingroups))
     return;
    let query = this._mainGroupService.entities();
    query.query.select(["id","name"]);
    let filter = "";
    filter = "id in ("+this.Setting.usedMaingroups+")"; 
    let  params = new HttpParams()
    .set('$filter', filter);

    query.get({params: params}).subscribe(({entities,meta}) => {
      this.MaingroupRefs = entities;
    })

  }
  private loadReportRefs(){
    if(isNullOrUndefined( this.Setting.usedReports) || isNullOrEmptyString( this.Setting.usedReports))
     return;
    let query = this._reportService.entities();
    query.query.select(["id","name"]);
    let filter = "";
    filter = "id in ("+this.Setting.usedReports+")"; 
    let  params = new HttpParams()
    .set('$filter', filter);

    query.get({params: params}).subscribe(({entities,meta}) => {
      this.ReportRefs = entities;
    })

  }

  private loadDashboardRefs(){
    if(isNullOrUndefined( this.Setting.usedDashboards) || isNullOrEmptyString( this.Setting.usedDashboards))
     return;
    let query = this._dashboardService.entities();
    query.query.select(["id","name"]);
    let filter = "";
    filter = "id in ("+this.Setting.usedDashboards+")"; 
    let  params = new HttpParams()
    .set('$filter', filter);

    query.get({params: params}).subscribe(({entities,meta}) => {
      this.DashboardRefs = entities;
    })

  }

  
ngOnInit() {

  this.maingroupId = +this.route.parent.snapshot.paramMap.get( "id" );

  this.loadSettings();
  }

 
  public selectedNodes: string[] = [];

  public groupId: number;
  public groupIds: number[];

  public nodeSelected({ index, dataItem }: any): void {
    //this.selectedKeys = [index];
    console.log("node selected Baum-Liste",dataItem,index );
    this.dataItem = index; // getDataFromDatabase
    this.selectedDataItem = dataItem; //this.getSelectedItem( dataItem.childId);//e.nodeData;
    this.selectedGroupTreeParent = dataItem;

    this.groupRefSelectedTreeItemRef = undefined;
 //   let hasGroupChildren = false; // Chekc has dataItem child
  //  this.allowParentZuordnung = this.isAllowMainGroupParentAssignment || hasGroupChildren ;
  console.log("get groupId from item",this.selectedDataItem );
    let reqGroupTreeAssigment = this._groupTreeAssigmentService.entities();
    reqGroupTreeAssigment.query.select("GroupId");
    reqGroupTreeAssigment.query.filter({groupTreeId: this.selectedDataItem.id});
    reqGroupTreeAssigment.get({withCount: false}).subscribe(({entities,meta}) => {
      console.log("anzahl ergebnise", meta.count )
      if(entities.length > 0){
         this.groupId = entities[0].groupId;
         this.groupIds = entities.map(m => m.groupId);
      }else{
        this.groupIds = undefined;
         this.groupId = null;
      }
         
    }
      );
    
};

public groupRefIds: Array<number> = null;
//GroupIds als Rpckgabe in die Componente rein
public nodeRefSelected({ index, dataItem }: any): void {
  //this.selectedKeys = [index];
 
  let reqGroupTreeAssigment = this._groupTreeAssigmentService.entities();
  reqGroupTreeAssigment.query.select("GroupId");
  reqGroupTreeAssigment.query.filter({groupTreeId: dataItem.id});
  reqGroupTreeAssigment.get({withCount: false}).subscribe(({entities,meta}) => {
    console.log("anzahl ergebnise", meta.count )
    if(entities.length > 0){
      
       this.groupRefIds = entities.map(m => m.groupId);
    }else{
       this.groupRefIds = null;
    }
       
  }
    );
  
};


/* public getSelectedItem(selectedId: any) : GroupTree{
  return this.GroupTreeData.find(e => e.childId === selectedId);
} */

public saveGruppe(){
  this.dataService.assign({id: this.selectedDataItem.id},{ name: this.selectedDataItem.name, value: this.selectedDataItem.value, 
    // isActive: this.selectedDataItem.isActive,
    image: this.selectedDataItem.image},null).subscribe(
    r => this.onDataBindTree()
  )
}
public rebindTree: boolean = false;
public onDataBindTree(){
  this.rebindTree = true;

}


public getMainGroupValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}

public getMainGroupName(id: number){
 return this.MaingroupRefs.find(x => x.id == id).name;
}

public getReportValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}

public getReportName(id: number){
 return this.ReportRefs.find(x => x.id == id).name;
}
public getDashboardValues(value:string){
  return DataArrayConvert.ConvertStringToIntList(value);
}

public getDashboardName(id: number){
 return this.DashboardRefs.find(x => x.id == id).name;
}



public onSelectedRefGroupChanged(item : GroupTree){
  console.log("onSelectedRefGroupChanged with Item:", item);
      this.groupRefSelectedTreeItem = item;
}

public onSelectedRefGroupChangedRefTree(item : GroupTree){
  console.log("onSelectedRefGroupChangedRefTree with Item:", item);
      //this.groupRefSelectedTreeItemRef = item;
     // this.selectedDataItem = this.getSelectedItem( item.childId)
     this.groupRefSelectedTreeItemRef = item;
}

}
