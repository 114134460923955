import { Component,  OnInit, Inject, ViewChild , EventEmitter, Output, Input } from '@angular/core';
import { isUndefined } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
@Component({
  selector: 'mza-barcode-name',
  templateUrl: './barcode-name.component.html',
  styleUrls: ['./barcode-name.component.css']
})
export class BarcodeNameComponent implements OnInit {
  @Input() value: string;
  @Input() disabled: boolean = false;

  @Output() barcodeTyp = new EventEmitter<string>();

  @ViewChild('barcodeType') public barcodeType: DropDownListComponent;

  public data: Array<string> = ["", "GTIN", "ISBN"];
  public selectedItem: string;

  constructor() { }

  ngOnInit() {
  }
  public onValueChange(value: string) {
    if(isNullOrEmptyString(value))
      this.barcodeTyp.emit(null);
    else
      this.barcodeTyp.emit(value);
}

}
