import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarkeDto, MarkenFilterDtoService, MarkenService, Artikel, ArtikelService, ArtikelGroupDataService, ArtikelGroup, WarengruppeDto, WarengruppenService, ArtikelGroupFilterSettingsService, ArtikelGroupFilterSettings, DataArrayConvert } from 'mza-api-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { GridDataResult, PageChangeEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ODataEntitySetResource } from 'angular-odata';

@Component({
  selector: 'gpv-gruppenartikel-liste',
  templateUrl: './gruppenartikel-liste.component.html',
  styleUrls: ['./gruppenartikel-liste.component.css']
})
export class GruppenartikelListeComponent implements OnInit {


  



  public isArtikelAllValueView: boolean = false;
  
  //WINDOW ARTIKEL
  public opened = false;
  public dataSaved = false;

  public close() {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }
 //ENDE


  @Input() groupId : number;


  //GRID 


  constructor( 
    public _dataServiceArtikelGroups: ArtikelGroupDataService, 
    private router: Router, private route: ActivatedRoute,private formBuilder: FormBuilder) {
   }

   
  




  ngOnInit() {
    //this.groupId = +this.route.parent.snapshot.paramMap.get( "id" );

    
    //this.loadData();

  }
  
  
 

public selectedArtikel : Artikel;
/* public onSelectedKeysChange(e) {
  const len = this.selectedGroupId.length;

  if (len === 1) {
    this.opened = true;
    let dataA = this.artikelService.entity(this.selectedGroupId[0]);

      dataA.select(["id","artikelNr", "WarengruppenNr", "SimsonArtikelNr", 
                    "MZArtikelNr","IWLArtikelNr", "EMWArtikelNr", "AlphaNr", 
                    "Originalteil", "Gesperrt","StVoZulassung", "ISBN", "GTIN",
                    "Qualitaetszeichen", "Internetmarke", "ImShopSichtbar",
                    "ServicepointArtikel","SortimentSimson","WeitereSimsonNr","SortimentMZ"
                    ,"WeitereMZNr" ,"SortimentIWL","WeitereIWLNr","SortimentEMW","WeitereEMWNr"
                    ,"SortimentZubehoer","SortimentWindhoff","SortimentUnitrike","WindhoffArtikelNr","UnitrikeArtikelNr","WeitereWindhoffNr","WeitereUnitrikeNr",
                    "ImportDatum"] );
      dataA.get().subscribe(res =>{
        this.selectedArtikel = res[0];
    });
  } else {
    this.opened = false;
  }
}
 */







}
