import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { ChartItemDtoConfig } from './chartitemdto.entity.config';
import { ChartsDtoConfig } from './chartsdto.entity.config';
import { ExtendsAttributesDtoConfig } from './extendsattributesdto.entity.config';
import { AssetViewDtoConfig } from './assetviewdto.entity.config';
import { DbObjectDtoConfig } from './dbobjectdto.entity.config';
import { ColumnDtoConfig } from './columndto.entity.config';
import { SortDtoConfig } from './sortdto.entity.config';
import { SortResultDtoConfig } from './sortresultdto.entity.config';
//#endregion

export const ModelsSchema = {
  namespace: "Mza.Service.Models",
  enums: [],
  entities: [ChartItemDtoConfig,
    ChartsDtoConfig,
    ExtendsAttributesDtoConfig,
    AssetViewDtoConfig,
    DbObjectDtoConfig,
    ColumnDtoConfig,
    SortDtoConfig,
    SortResultDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;