import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

  requestCounter: number = 0;
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercepted request ... ');
/*     request = request.clone({
      responseType: 'json',
      setHeaders: {
        Authorization: `Bearer token_value`,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }); */
    let atoken: string =  window.localStorage.getItem('access_token') || "";
    const authReq = request.clone({
      //  responseType: 'json',
        
        headers: request.headers.set('Prefer', 'return=representation'  ).set('Authorization', atoken).set('Access-Control-Allow-Origin', 'http://localhost:4200'),
        withCredentials: false
      });
    //console.log('Sending request with new header now ...');
   /*  return next.handle(authReq).catch((error, caught) => {
        //intercept the respons error and displace it to the console
        console.log('Error Occurred');
        console.log(error);
        //return the error to the method that called it
        return Observable.throw(error);
      }) as any; */
    return next.handle(authReq).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
       console.log('Error Occurred');
        console.log(err);
      }
    });
  }
}