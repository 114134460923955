import { NgModule } from '@angular/core';
import { BrowserModule }    from '@angular/platform-browser';
import { ODataModule } from 'odata-v4-ng';
import { HttpClientModule } from '@angular/common/http';
import { GridModule } from '@progress/kendo-angular-grid';
//import { DataDirectivesModule } from './data-directives/data-directives.module';

//import { BeigabenService } from './odata-services/services/beigaben.service'
//import { ODataServicesModule } from './odata-services';
@NgModule({
  declarations: [
  /*   ArtikelGroupBindingDirective,GroupBindingDirective,GroupTreeAttributeDataBindingDirective,MainGroupBindingDirective,ODataBindingDirective */
  ],
  imports: [
    ODataModule,
    BrowserModule,
    HttpClientModule,
    GridModule//,DataDirectivesModule
   // ODataServicesModule
  ],
  providers: [
/*     ArtikelGroupBindingDirective,GroupBindingDirective,GroupTreeAttributeDataBindingDirective,MainGroupBindingDirective,ODataBindingDirective
 //   BeigabenService */
  ],
  exports: []
})
export class MzaApiServiceModule { }
