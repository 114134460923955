import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { StatusGroupTree } from './statusgrouptree.entity';
//#endregion

export const StatusGroupTreeConfig = {
  name: "StatusGroupTree",
  annotations: [],
  fields: {
    groupTreeId: {type: 'Edm.Int32'},
    version: {type: 'Edm.Binary'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    groupTree: {type: 'entities.schema.GroupTree', navigation: true, field: 'groupTreeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<StatusGroupTree>;