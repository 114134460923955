import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Report } from '../entities/Reports/report.entity';
import { DataMapping } from '../entities/settings/datamapping.entity';
//#endregion

@Injectable()
export class DataMappingService extends ODataEntityService<DataMapping> {
  constructor(protected client: ODataClient) {
    super(client, 'DataMapping', 'entities.settings.DataMapping');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public report(entity: EntityKey<DataMapping>): ODataNavigationPropertyResource<Report> {
    return this.entity(entity).navigationProperty<Report>('report');
  }
  //#endregion
}
