import { Directive, Injectable, Input, Inject, forwardRef } from '@angular/core';
import { ODataBindingDirective } from './core/o-data-binding-directive';
import { ODataEntitySetResource } from 'angular-odata';

import { GridComponent } from '@progress/kendo-angular-grid';
import { AssetMetaDataDataService } from '../data-services/asset-meta-data-data.service';

@Injectable()
@Directive({
  selector: '[assetMetaDataBinding]'
})
export class AssetMetaDataDirective extends ODataBindingDirective{
  
  @Input('assetMetaDataBinding') query:  ODataEntitySetResource<any>;

  constructor(  @Inject(forwardRef(() => AssetMetaDataDataService)) dataService: AssetMetaDataDataService, @Inject(forwardRef(() => GridComponent))  grid: GridComponent) {
        super(dataService,grid);
      }
        public ngOnChanges( c: any ) : void {
 
          console.log( "Base-Query AssetMetaData:", this.query);
          this.rebind();
      }
}
