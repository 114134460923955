import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { isNullOrUndefined } from "util";

export class DataArrayConvert{

    static ConvertArrayToString(data: any): string{
    let value: string ="";
    if(!isNullOrUndefined(data) && data.length > 0 && Array.isArray(data) ){
          data.forEach( (item, index) => {
        if(isNullOrUndefined(item) || isNullOrEmptyString(item) ) data.splice(index,1);
      });
       value = data.join();
    }
     
  
    return value.replace(/NaN/i," ");
  }
    

  static ConvertStringToIntList(value: string) : number[]{
    if(isNullOrUndefined(value) || isNullOrEmptyString(value))
      return;
      
    let data: number[];
    if(value === null ||value ===undefined || (value.indexOf(",") < 0 && value.length  <= 0) )
      return [];
    
    if((value.indexOf(",") < 0))
      return  [+value];
      
    let values = value.split(",");
  
    if(values.length > 0 && values[0] !== "")
      data = values.map(Number);
    return data;
  }

  static ConvertStringToStringList(value: string) : string[]{
    let data: string[];
    if(value === null ||value ===undefined || (value.indexOf(",") < 0 && value.length  <= 0) )
      return [];
    
    if((value.indexOf(",") < 0))
      return  [value];
      
    let values = value.split(",");
  
    /* if(values.length > 0 && values[0] !== "")
      data = values.map(string); */
    return data;
  }
}

