import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gpv-kundengruppe-gruppen-zuweisung',
  templateUrl: './kundengruppe-gruppen-zuweisung.component.html',
  styleUrls: ['./kundengruppe-gruppen-zuweisung.component.css']
})
export class KundengruppeGruppenZuweisungComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
