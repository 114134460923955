import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelPreis } from './artikelpreis.entity';
//#endregion

export const ArtikelPreisConfig = {
  name: "ArtikelPreis",
  annotations: [],
  fields: {
    artikelId: {type: 'Edm.Int32', nullable: false},
    ek: {type: 'Edm.Decimal'},
    ekEffektiv: {type: 'Edm.Decimal'},
    ekDurchschnitt: {type: 'Edm.Decimal'},
    ekStkListe: {type: 'Edm.Decimal'},
    ekMenge: {type: 'Edm.Int32'},
    stuecklistenaufschlag: {type: 'Edm.Decimal'},
    vkDurchschnitt: {type: 'Edm.Decimal'},
    kalkulationsGruppeId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    kalkGruppe: {type: 'entities.produkte.KalkulationsGruppe', navigation: true, field: 'kalkulationsGruppeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<ArtikelPreis>;