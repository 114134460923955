import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { DashboardAssigment } from './dashboardassigment.entity';
//#endregion

export const DashboardAssigmentConfig = {
  name: "DashboardAssigment",
  annotations: [],
  fields: {
    dashboardId: {type: 'Edm.Int32'},
    reportId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    dashboard: {type: 'entities.schema.Dashboard', navigation: true, field: 'dashboardId', ref: 'id'},
    report: {type: 'entities.Reports.Report', navigation: true, field: 'reportId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<DashboardAssigment>;