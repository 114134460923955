import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { DataMapping } from './datamapping.entity';
//#endregion

export const DataMappingConfig = {
  name: "DataMapping",
  annotations: [],
  fields: {
    reportId: {type: 'Edm.Int32'},
    inputName: {type: 'Edm.String'},
    outputName: {type: 'Edm.String'},
    color: {type: 'Edm.String'},
    opacity: {type: 'Edm.String'},
    from: {type: 'Edm.String'},
    to: {type: 'Edm.String'},
    title: {type: 'Edm.String'},
    typ: {type: 'Edm.String'},
    sortValue: {type: 'Edm.Int32'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    report: {type: 'entities.Reports.Report', navigation: true, field: 'reportId', ref: 'id'}
  }
} as EntityConfig<DataMapping>;