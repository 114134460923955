import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ODataEntitySetResource } from 'angular-odata';
import { AssetMetaData, MetaData,Property, AssetMetaDataDataService, PropertyService, MetaDataService, AssetMetaDataService } from 'mza-api-service';
import { State } from '@progress/kendo-data-query';
import { isNullOrUndefined } from 'util';

interface MetaDataAsset{
  id: number,
  name : string,
  typeId: number,
  label: string,
  propertyId: number,
  assetId: number,
  metaDataAssetId: number,
  isNew: boolean
}

@Component({
  selector: 'gpv-meta-data',
  templateUrl: './meta-data.component.html',
  styleUrls: ['./meta-data.component.css']
})
export class MetaDataComponent implements OnInit {

  @Input('assetId') assetId: number = null;
  public metaDataForm: FormGroup;
  private metaDataList : Array<MetaData>;
  public query : ODataEntitySetResource<any>;
  public metaList: Array<MetaDataAsset>;
  public propertyItems: Array<Property>;
  public isLoading: boolean = false;

  public state: State = {
    skip: 0,
    take: 20,
    sort: [{
      field: 'sortValue',
      dir: 'asc'
    }]
};


  constructor(public _assetMetaDataService: AssetMetaDataService, 
    private _propertyService: PropertyService,
    private _metadataService: MetaDataService,
    private formBuilder: FormBuilder) { 
      this.createAssetMetaForm  = this.createAssetMetaForm.bind(this);
 
    }

    ngOnInit() {
      this.loadStaticData()
     }
     ngOnChanges(changes: SimpleChanges): void {
       this.loadData();
     }

    private loadStaticData(){
      if (isNullOrUndefined(this.assetId) )
      return;

      this.loadMetaData();
      this.loadProperties()
      


 
    }

  public createAssetMetaForm(args: any): FormGroup  {
    let data  = <MetaDataAsset>{ id: 0,
                         typeId: 0,
                         name: "",
                         assetId: this.assetId,
                         propertyId: 0,
                         label: "",
                         metaDataAssetId: 0,
                         isNew: true
                         };
    const item : MetaDataAsset = args.isNew ? data : args.dataItem;

    this.metaDataForm = this.formBuilder.group({
      id: item.id ,
      typeId: [item.typeId],   
      name:  [item.name],
      propertyId:  [item.propertyId],
      label: [item.label],
      assetId:  [this.assetId],
      metaDataAssetId :  [item.metaDataAssetId],
      isNew: [false]
  });
  return this.metaDataForm;
}

private loadData() {
  if (isNullOrUndefined(this.assetId) )
    return;
      this.isLoading= true;
      this.metaList= [];
      let reqAssetMetaData = this._assetMetaDataService.entities();
      reqAssetMetaData.query.select(["id","metaDataId","propertyId"]);     
      reqAssetMetaData.query.filter({assetId: this.assetId});
      //SORT Type.Sortvalue
      reqAssetMetaData.get().subscribe(({entities,meta}) => {
        this.metaDataList.map(item => {
            return {
               id: item.id,
               label: item.label,
               name: item.name,
               typeId: item.typeId,
               metaDataAssetId: 0,
               assetId: this.assetId,
               propertyId: 0,
               isNew: true
            }
          }).forEach(item => this.metaList.push(item));
          this.metaList.forEach(item => {
            entities.forEach(resItem => {
               if(item.id == resItem.metaDataId){
                item.propertyId = resItem.propertyId;
                item.metaDataAssetId = resItem.id;
                item.isNew = false;
               }
            });
           
          });
          this.isLoading= false;
      });
    //this.
  
}
private loadProperties() {
  if (isNullOrUndefined(this.assetId) )
    return;

    let query = this._propertyService.entities();
    query.select(["id","kurzBezeichnung","typeId"]);
    //FILER IN TYPEIDs hinzufügen
    query.get().subscribe(({entities,meta}) => {
     
      this.propertyItems = entities;

  });
}

private loadMetaData() {
  if (isNullOrUndefined(this.assetId)  ) //||  !isNullOrUndefined( this.metaDataList)
    return;

      let reqMetaData = this._metadataService.entities();
      reqMetaData.query.select(["id","label","name","typeId"]);
      reqMetaData.query.expand({type: {select: ["id","sortValue"]}});
      //SORT Type.Sortvalue
      reqMetaData.get().subscribe(({entities,meta}) => {
          this.metaDataList = entities;
          this.loadData();
      });
}

public getPropertyItems(typeId: number){
 // let data = new Array<Property>();
 // data.push({id: 0, kurzBezeichnung: "",typeId: typeId})
//  this.propertyItems.filter((f) => f.typeId === typeId).forEach(item => data.push(item));

 
 // return data;
 if( this.propertyItems.findIndex(f => f.id === 0) === -1)
      this.propertyItems.push({id: 0, kurzBezeichnung: "",typeId: typeId});

 return this.propertyItems.filter((f) => f.typeId === typeId);
}

public getPropertyName(id: number){
  let name = "";
  if(id === 0) return name;
  
 
  let data = this.propertyItems.find((f) => f.id === id);
  if(!isNullOrUndefined(data))
    name = data.kurzBezeichnung;
  return name;
}

public cellCloseHandler(args: any) {
  const { formGroup, dataItem } = args;
/* 
  if (!formGroup.valid) {
       // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
  } else if (formGroup.dirty) { */
    if(dataItem.isNew){
      this._assetMetaDataService.create({id: 0, propertyId: dataItem.propertyId,metaDataId: dataItem.id, assetId: dataItem.assetId}).subscribe();
    }else if(!dataItem.isNew && dataItem.propertyId === 0){
      this._assetMetaDataService.destroy({id: dataItem.metaDataAssetId}).subscribe();
  
    }else{
            this._assetMetaDataService.assign({id: dataItem.metaDataAssetId},{id: dataItem.metaDataAssetId, propertyId: dataItem.propertyId}).subscribe();

          }
      
  //}
}


}
