import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { WarengruppeDto } from './warengruppedto.entity';
//#endregion

export const WarengruppeDtoConfig = {
  name: "WarengruppeDto",
  annotations: [],
  fields: {
    warengruppennr: {type: 'Edm.String'},
    parentWarengrNr: {type: 'Edm.String'},
    bezeichnung: {type: 'Edm.String'},
    bezeichnungVoll: {type: 'Edm.String'},
    suchbegriffe: {type: 'Edm.String'},
    bezeichnungParent: {type: 'Edm.String'},
    sortierindex: {type: 'Edm.Int32'},
    artikelNrWGReferenzfoto: {type: 'Edm.String'},
    nurBeschriftung: {type: 'Edm.Byte'},
    bezeichungsBearbeiter: {type: 'Edm.String'},
    hasArtikel: {type: 'Edm.Boolean', nullable: false},
    hasChildren: {type: 'Edm.Boolean', nullable: false},
    bezeichnungsStatus: {type: 'Edm.Int32', nullable: false},
    checkBearbeiter: {type: 'Edm.Boolean', nullable: false},
    isBearbeiter: {type: 'Edm.Boolean', nullable: false},
    checkKontrolleure: {type: 'Edm.Boolean', nullable: false},
    isKontrolleure: {type: 'Edm.Boolean', nullable: false},
    statusColor: {type: 'Edm.String'},
    statusBeschreibung: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<WarengruppeDto>;