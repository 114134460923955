import { Injectable, Inject, Optional } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import {  CustomerGroupService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';



@Injectable({
  providedIn: 'root'
})
export class CustomerGroupDataService  extends GridDataService {
    
  public odataService: CustomerGroupService;
  
  
    constructor( @Inject(CustomerGroupService) service: CustomerGroupService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
