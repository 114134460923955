<p>Marke: </p>
<!-- <kendo-combobox #markenFilter
[data]="data"
[textField]="'BrandId'"
[valueField]="'BrandId'"
(valueChange)="onValueChange($event)"
[(ngModel)]="selectedItem"
[filterable]="true"
(filterChange)="handleFilter($event)"
[placeholder]="'Marke auswählen...'"
[disabled]="disabled"
>
<ng-template kendoComboBoxItemTemplate  let-dataItem>
        <div>
            <p>Id: <b>{{ dataItem.brandId }}</b></p>
            <p>{{ dataItem.name }}, {{dataItem.url}}</p>
        </div>
      
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
        <h4><span class="k-icon k-i-warning"></span><br /><br /> Keine Daten vorhanden, oder Eingabe zu kurz</h4>
    </ng-template>
</kendo-combobox> -->