import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Booking } from './booking.entity';
//#endregion

export const BookingConfig = {
  name: "Booking",
  annotations: [],
  fields: {
    start: {type: 'Edm.TimeOfDay', nullable: false},
    end: {type: 'Edm.TimeOfDay', nullable: false},
    userId: {type: 'Edm.Int32', nullable: false},
    registrationId: {type: 'Edm.Int32'},
    customerId: {type: 'Edm.Int32'},
    firmaId: {type: 'Edm.Int32'},
    day: {type: 'Edm.DateTimeOffset', nullable: false},
    occupiedCapacity: {type: 'Edm.Int32', nullable: false},
    freeCapacity: {type: 'Edm.Int32', nullable: false},
    bookingObjectId: {type: 'Edm.Int32'},
    bookingTimesId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    bookingObject: {type: 'entities.schema.BookingObject', navigation: true, field: 'bookingObjectId', ref: 'id'},
    bookingTime: {type: 'entities.schema.BookingTimes', navigation: true, field: 'bookingTimesId', ref: 'id'},
    firma: {type: 'entities.schema.Firma', navigation: true, field: 'firmaId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Booking>;