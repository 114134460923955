import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mza-warengruppen-nr',
  templateUrl: './warengruppen-nr.component.html',
  styleUrls: ['./warengruppen-nr.component.css']
})
export class WarengruppenNrComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
