import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input, forwardRef, OnDestroy } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined, isNullOrUndefined } from 'util';
import { Filter }from 'angular-odata';
import { FormGroup, FormBuilder, FormControl, ControlContainer, FormGroupDirective, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { ArtikelService, ArtikelbezeichnungsgeneratorService ,Artikel} from 'mza-api-service';

import { Subscription } from 'rxjs';

 interface ArtikelSelect{
artikelId: number,
artikelNr: string,
bezeichnung: string,
imShopSichtbar: boolean
}

@Component({
  selector: 'mza-artikel-nr-mit-info',
  templateUrl: './artikel-nr-mit-info.component.html',
  styleUrls: ['./artikel-nr-mit-info.component.css'],
  providers: [ArtikelService,{ 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => ArtikelNrMitInfoComponent),
    multi: true 
  }
/*   ,
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ArtikelNrMitInfoComponent),
    multi: true
  }*/
] 
})
export class ArtikelNrMitInfoComponent implements OnInit , ControlValueAccessor {

  onChange: any = () => {};
  onTouched: any = () => {};
  @Input('value') _value: number = 0;

  get value():number {
    return this._value;
  }
  set value(value :number) {
    this._value = value;
    this.dataBound(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
  

  @Input() isRequired: boolean = false;
  @Input() disabled: boolean = false;
  @Input() useOnlyShopArtikel = true;
  @Input() artikelId: number = 0;
  @Input() artikelNr: string = '';
  
  @Output() artikelNrChange = new EventEmitter<string>();

  @ViewChild('artikelFilter') public artikelFilter: ComboBoxComponent;

  public data: Array<Artikel> ;
  public selectedItem: ArtikelSelect;

// , private formBuilder: FormBuilder,private parentForm: FormGroupDirective,
  constructor(@Inject(ArtikelService) private artikelService: ArtikelService, @Inject(ArtikelbezeichnungsgeneratorService) private artikelBezService: ArtikelbezeichnungsgeneratorService) {

   }

  ngOnInit() {

  }

  private dataBound(value: number){
   
    if(value !== 0 ){
      console.log("Bound: ",this.value);
      let query = this.artikelService.entity(value);
      query.query.select("id,artikelNr");
      query.get().subscribe(({entity, meta}) =>{
        this.data = [] as Array<Artikel>;
        let artikel = <Artikel><unknown>{
          id: entity.id,
          artikelNr: entity.artikelNr
        }
     
        this.data.push(artikel) ;
        this.selectedItem = <ArtikelSelect>{
          artikelId: entity.id,
          artikelNr : entity.artikelNr
        };
        this.dataBoundBez(value);
    });
    }
  }
  private dataBoundBez(value: number){
 
    let reqArtikelBez = this.artikelBezService.entity(value);
    reqArtikelBez.query.select(["id","bezeichnungAlt"]);
    reqArtikelBez.get().subscribe(({entity}) =>{

   
    
      this.selectedItem.bezeichnung = entity.bezeichnungAlt

  });
  }
  

  handleFilter(value) {
    console.log("handleFiler:" , this.value);
    if (value.length >= 2) {

      let collection = this.artikelService.entities();
      collection.query.select(["id","artikelNr"]);
      collection.query.filter({artikelNr: {startswith: value}} );


      collection.get().subscribe(({entities, meta}) =>  this.data =entities
    );
  }else {
      this.artikelFilter.toggle(false);
  }
  }
/* 
  public currentArtikelNr(): string{
    if(isNullOrUndefined(this.form.controls.selected.value.artikelNr)){
          return this.selectedItem.artikelNr;
    }
    return this.form.controls.selected.value.artikelNr;
  }
 */

    public onValueChange(value: any) {
      console.log("VALUE CHANGE: " ,value)
      this.value = value;
 /*    if(isUndefined(value))
      this.artikelNrChange.emit(null);
    else{
        this.artikelNr = value.artikelNr;
        this.artikelNrChange.emit(value.artikelNr);
    } */
     
}
}
