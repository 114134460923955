import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Kundengruppe } from '../entities/customers/kundengruppe.entity';
import { Kundensegment } from '../entities/customers/kundensegment.entity';
import { Person } from '../entities/customers/person.entity';
import { Firma } from '../entities/schema/firma.entity';
import { RegistrationInfo } from '../entities/schema/registrationinfo.entity';
//#endregion

@Injectable()
export class RegistrationService extends ODataEntityService<RegistrationInfo> {
  constructor(protected client: ODataClient) {
    super(client, 'Registration', 'entities.schema.RegistrationInfo');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public firma(entity: EntityKey<RegistrationInfo>): ODataNavigationPropertyResource<Firma> {
    return this.entity(entity).navigationProperty<Firma>('firma');
  }
  public kundengruppe(entity: EntityKey<RegistrationInfo>): ODataNavigationPropertyResource<Kundengruppe> {
    return this.entity(entity).navigationProperty<Kundengruppe>('kundengruppe');
  }
  public kundensegment(entity: EntityKey<RegistrationInfo>): ODataNavigationPropertyResource<Kundensegment> {
    return this.entity(entity).navigationProperty<Kundensegment>('kundensegment');
  }
  public person(entity: EntityKey<RegistrationInfo>): ODataNavigationPropertyResource<Person> {
    return this.entity(entity).navigationProperty<Person>('person');
  }
  //#endregion
}
