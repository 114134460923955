import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Attribute } from '../entities/schema/attribute.entity';
import { ProduktTypAttribute } from '../entities/produkte/produkttypattribute.entity';
import { ProduktTyp } from '../entities/produkte/produkttyp.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class ProduktTypAttributeService extends ODataEntityService<ProduktTypAttribute> {
  constructor(protected client: ODataClient) {
    super(client, 'ProduktTypAttribute', 'entities.produkte.ProduktTypAttribute');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public attribute(entity: EntityKey<ProduktTypAttribute>): ODataNavigationPropertyResource<Attribute> {
    return this.entity(entity).navigationProperty<Attribute>('attribute');
  }
  public produktTyp(entity: EntityKey<ProduktTypAttribute>): ODataNavigationPropertyResource<ProduktTyp> {
    return this.entity(entity).navigationProperty<ProduktTyp>('produktTyp');
  }
  public status(entity: EntityKey<ProduktTypAttribute>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<ProduktTypAttribute>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
