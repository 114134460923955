import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { LeistungsprofilHaendler } from './leistungsprofilhaendler.entity';
//#endregion

export const LeistungsprofilHaendlerConfig = {
  name: "LeistungsprofilHaendler",
  annotations: [],
  fields: {
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<LeistungsprofilHaendler>;