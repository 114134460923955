import { Component, OnInit, ViewChild, ElementRef, OnChanges, AfterViewChecked, HostListener, Input, Output, EventEmitter, SimpleChanges, NgZone, Renderer2 } from '@angular/core';
import { TreeViewComponent, SelectableSettings, CheckableSettings, TreeItem, TreeItemLookup, TreeItemDropEvent, DropPosition, TreeItemAddRemoveArgs, ExpandStateService, NavigationService, NodeChildrenService, SelectionService, TreeViewLookupService, DataChangeNotificationService, TreeItemDragEvent, DropAction } from '@progress/kendo-angular-treeview';
import { GroupTreeService, GroupTreeAssigmentService, SortResultDto,SortDto,GroupTree, RefType, StatusService } from 'mza-api-service';
import { FormControl, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { ContextAnzeige, ContextDelete, ContextCopy } from '../../types/context-types.enum';
import {  TreeSettings } from '../../models/treeviewgroup.model';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { Hotkeys } from '../../services/hotkeys.service';
import { OrderPipe } from 'ngx-order-pipe';



/* interface Settings{
  allowDragAndDrop : boolean ;
  allowTreeStructur : boolean ;
  allowMultiSelection : boolean ;
  allowGroupTreeAssignmentForParent: boolean;
  allowGroupTreeAssignment: boolean;
  allowGroupsInDifferentNodes: boolean;
  allowDragAndDrop: boolean;
  allowChecked: boolean;
  showValue: boolean;
  showDescription: boolean;
}  */
interface Item {

  value: number,
  color: string,
  backgroundColor: string
}
@Component({
  selector: 'gpv-tree-view-group',
  templateUrl: './tree-view-group.component.html',
  styleUrls: ['./tree-view-group.component.css']
})
export class TreeViewGroupComponent extends TreeViewComponent  implements  OnInit, OnChanges, AfterViewChecked {
  //HTML ELEMENTE
  @ViewChild('inputTreeName')
  public input: ElementRef<HTMLInputElement>;

  @ViewChild('treeViewZw')
  public treeView: TreeViewComponent;

@ViewChild('grouptreecontext', null) 
public contextMenuGroupTree: ContextMenuComponent;

  public statusItem : Array<Item> = [{value: 26, color: "#46f70077", backgroundColor: "#46f70023"},{value: 27, color: "#E0B74C99", backgroundColor: "#E0B74C23"}]

  public getItemStatusColor(id: number):string{
   // return this.statusItem.find(f => f.value === statusId) ?  this.statusItem.find(f => f.value === statusId).color : "" ;
   return this.allGroupTreeRefIds.find(x => x === id) ? "#46f70077" : "";
  }
  public getItemStatusBackgroundColor(id: number):string{
   /*  if(this.sortResultData !== null && this.sortResultData.length > 0){
     let item = this.sortResultData.find(x => x.id === id);
     return item.level1Color;
    } */
     
    //return this.statusItem.find(f => f.value === id) ?  this.statusItem.find(f => f.value === id).backgroundColor : "" ;
    return this.allGroupTreeRefIds.find(x => x === id) ? "#46f70023" : "";
  }


  //FENSTER SETTINGS
  public scrHeight: any;
  public scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    console.log(this.scrHeight, this.scrWidth);
  }

  //INPUTS / OUTPUTS
  private _maingroupId: number;
  @Input("maingroupId") set maingroupId(value: number){
    this._maingroupId = value;
    this.onDataBindTree();
  }
  get maingroupId(): number {
  
    return this._maingroupId;

}
  private _rebind : boolean = false;
  @Input() set rebind(value:  boolean){
    this._rebind = value;
    if(value)
       this.onDataBindTree();
  }
  get rebind(): boolean {
  
    return this._rebind;

}
@Output() rebindChange = new EventEmitter<boolean>();

  @Input() maingroupIdRef: number;
  private _selectedGroupTree: GroupTree;
  @Input("selectedGroupTree") set selectedGroupTree(value: GroupTree) {
  
    this._selectedGroupTree = value;
    if(isNullOrUndefined(value))
      this.checkedKeys = [];
 
 }
 get selectedGroupTree(): GroupTree {
 
     return this._selectedGroupTree;
 
 }
  @Input() selectedGroupTreeParent: GroupTree;
  @Input("treeSettings") treeSettings: TreeSettings;
  @Output("onParentDataBindTree") onParentDataBindTree: EventEmitter<any> = new EventEmitter();
  @Output() selectedChanged = new EventEmitter<GroupTree>();
  @Output("groupRefSelectedChange") groupRefSelectedChange = new EventEmitter<GroupTree>();
 /*  @Input() groupRefSelected : GroupTree; */
  private _groupRefSelected: GroupTree;
    
  @Input("groupRefSelected") set groupRefSelected(value: GroupTree) {
  
     this._groupRefSelected = value;
     this.onSelectedRefGroupChanged(this._groupRefSelected);
  
  }
  get groupRefSelected(): GroupTree {
  
      return this._groupRefSelected;
  
  }


  //Propertys
  public GroupTreeData: any[];
  public parsedGroupTreeData: any[];
  private filterGroupTreeId: string = "";
  public field: Object;
  private statusCreateId: number = 3;
  //SELBE?
  public selectedDataItem: any;
  public dataItem : any;

  public selectedKeys: any[] = [];
  public expandedKeys: any[] = [];
  public selectedNodes: string[] = [];
  public groupId: number;

  public isToogleExpand: boolean = false;
  public isToogleFilter: boolean = false;
  public isToogleCheckedChilds: boolean = false;
  public isToogleFilterStatus: boolean = false;
  public isToogleFilterStatusOk: boolean = false;
  public isRenameActive: boolean = false;
  //EDIT
  public activeItem: GroupTree;
  public focusEditor: boolean;
  public focusIndex: string;
  public textFormControl: FormControl;
  
  // FILTER TREE
  public filterTree: Array<GroupTree> = [];
  public selectedfilterTree: Array<GroupTree> = [];

  //CONTEXT
  private contextItem: any;

  //SETTING CHECKED TREE
  //public enableCheck = true;
  public checkChildren = false;// true;
  public checkParents = false;//true;
  public checkOnClick = false;
  public checkMode: any = 'multiple';
  //public selectionMode: any = 'single';
  public checkedKeys: any[] = [];

  public get checkableSettings(): CheckableSettings {
    return {
        checkChildren: this.checkChildren,
        checkParents: this.checkParents,
        enabled: this.treeSettings.allowChecked,
        mode: this.checkMode,
        checkOnClick: this.checkOnClick
    };
  }
  
  public SettingDefault: TreeSettings ={
    allowDragAndDrop : false,
    allowTreeStructur : false,
    allowMultiSelection : isNullOrUndefined(this.hotkeys.isNotCRTL) ? false : this.hotkeys.isNotCRTL ,
    allowGroupTreeAssignmentForParent: false,
    allowGroupTreeAssignment: false,
    allowGroupsInDifferentNodes: false,
    allowAddRemove: true,
    allowChecked: false,
    showValue: false,
    showDescription: false,
    showBar: true,
    usedSorts: ""
  };
  // CONTEXT TREE 
  public  contextItemsAnzeige: any[] = [{ text: ContextAnzeige.Expands }, { text: ContextAnzeige.Collapse }];
  
  public selectableSettings: SelectableSettings = {
    mode: isNullOrUndefined(this.hotkeys.isNotCRTL) ?  'single' : this.hotkeys.isNotCRTL ? 'multiple':'single' 
  };
//MENU SORT
public iconSort: string = 'sort-asc';
public sorts: Array<SortDto> = [];
public dataSorts: Array<SortResultDto> = [];
public sortId: number = 0;
  //MENU BAR
  public iconZoom: string = 'zoom';
  public dataZoom: Array<any> = [{
      text: '100%',
      value: 3,
      click: () => { this.valueZoom = 3; }
  }, {
      text: '50%',
      value: '2',
      click: () => { this.valueZoom = 2; }
  }];
public valueZoom:Number =2;
  
  constructor(private groupTreeService: GroupTreeService,  private _statusService: StatusService,private hotkeys: Hotkeys,private orderPipe: OrderPipe,
    //TREEE EXTENDS:
    element: ElementRef<HTMLElement>, expandService: ExpandStateService, navigationService: NavigationService, nodeChildrenService: NodeChildrenService, selectionService: SelectionService, treeViewLookupService: TreeViewLookupService, ngZone: NgZone, renderer: Renderer2, dataChangeNotification: DataChangeNotificationService, localization: LocalizationService
  //  ,private _groupTreeAssigmentService: GroupTreeAssigmentService
    ){ 
      super(element,expandService,navigationService,nodeChildrenService,selectionService,treeViewLookupService,ngZone,renderer,dataChangeNotification,localization);
      this.getScreenSize();

      hotkeys.addShortcut({ keys: "control", description: "Add Widget" })
      //.pipe(take(2))
      .subscribe(r => {
        this.allowMultiSelectedTreeGroup = false;
        this.selectableSettings.mode =  'single' }  );
     

    hotkeys
      .deleteShortcut({ keys: "control", description: "Open Settings" })
      .subscribe( r =>  {
        this.allowMultiSelectedTreeGroup = true;
        this.selectableSettings.mode = 'multiple'
      
      });
   }

  ngOnInit() {
    this.onDataBindTree();
    this.loadSortReports();
    if(isNullOrUndefined( this.treeSettings)){
      this.treeSettings = this.SettingDefault;
    }
    if(this.maingroupId)
      this.expandedKeys =  ["/"+this.maingroupId+"/"];

      this.loadAllRefKeysFromRootGrouptree();
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.loadRefKeysFromSelectedGrouptree();
    if(!this.allowMultiSelectedTreeGroup)
       this.selectedKeys= [];
  }

  public ngAfterViewChecked(): void {
    // focus the edited node after save
    if (this.focusIndex && !this.textFormControl) {
        this.treeView.focus(this.focusIndex);
        this.focusIndex = null;
    }
  
    // focus first input on node edit
    if (this.focusEditor && this.input) {
        this.input.nativeElement.focus();
        this.focusEditor = false;
        console.log("focus");
    }
    console.log("focusEnd",this.focusEditor);
  }

  //FUNCTIONS

 private onLoadStatus(){
  let queryStatus = this._statusService.entities();
  queryStatus.select("id");
  queryStatus.filter({typeId: {"eq" : 1}, phase:{"eq": 'PUBLISH_INTERN'}});
  queryStatus.top(1);
  queryStatus.get().subscribe(({entities, meta}) => {
    this.statusCreateId = entities[0].id;
  });
 }


  public onSelectTreeContext({ item }): void {
    if (item.text.substring(0,6) === "Ordner") {
        this.addFolderAfter(this.contextItem.childId);
    } 
    else if (item.text.substring(0,6) === "Gruppe") {
      this.addGroupAfter(this.contextItem.childId);
    }
    else if (item.text == ContextDelete.Selected || item.text.substring(0,6) === "Lösche") {
      this.removeTreeItem(this.contextItem.childId);
  }
  else if (item.text == ContextDelete.SelectedAndChildren) {
    this.removeTreeItemAndChildren(this.contextItem.childId);
  }
  else if (item.text == "Mehrfachauswahl aktivieren") {
    this.allowMultiSelectedTreeGroup = true; //!this.allowMultiSelectedTreeGroup;
    this.selectableSettings.mode = 'multiple' ;
  }else if (item.text == "Mehrfachauswahl deaktivieren") {
    this.allowMultiSelectedTreeGroup = false ; //!this.allowMultiSelectedTreeGroup;
    this.selectableSettings.mode = 'single' ;
  }
  else if(item.text == "Umwandeln in Gruppe"){
    this.changeIcon(this.contextItem,"zip");
  } else if(item.text == "Umwandeln in Ordner"){
    this.changeIcon(this.contextItem,"folder");
  } 
  else if (item.text === ContextAnzeige.Expands){
     this.expandTreeChilds(this.contextItem.childId);
  }else if (item.text === ContextAnzeige.Collapse){
    this.collapseTreeChilds(this.contextItem.childId);
  }else if (item.text.substring(0,7) === "Auswahl"){
    this.mergeSelectedGroupsInDestinationGroup(this.contextItem.childId);
  }else if(item.text.substring(0,11) === "Filtern auf"){
    this.filterSelectedItem(this.contextItem  );//this.contextItem.name+ ' (' + this.contextItem.value +')'
  }else if(item.text === "Umstrukturieren aktivieren"){
   // this.filterSelectedItem(this.contextItem  );
   this.treeSettings.allowDragAndDrop = !this.treeSettings.allowDragAndDrop;
   //ENTWURF WÄRE DIE COMPONENTE MIT DEM ENTWURFSTATUS ZU LADEN
   //this.onDataBindTreeEntwurf();
    
  }else if(item.text === "Umstrukturieren deaktivieren"){
    
    this.treeSettings.allowDragAndDrop = !this.treeSettings.allowDragAndDrop;
  }else if(item.text === "Umbennenen"){
    this.isRenameActive = true;
    //this.form.controls['name'].disable();
    this.textFormControl.enable();
  }
  
    if (item.text === 'Remove') {
      //  this.removeItem(this.contextItem, this.data);
    }
  }

  public addFolderAfter(targetNodeId: string){
    let newGroupTreeItem = this.makeItem(this.createNodeID(),targetNodeId, "folder");
    //OPTIMIERUNG - ID in der DATASSOURCE HINZUFÜGEN VOM ERSTELLTEN ITEM, OHNE NEULADEN DES BAUMES
  /*   let item: { [key: string]: Object } = { id: nodeId, name: "Neue Gruppe " + this.index };
    this.tree.addNodes([item] ,selected,null);
    this.index++;
    this.GroupTreeData.push(newGroupTreeItem);
    this.tree.beginEdit(nodeId); */
    //this.addAfterSelectedNode(this.GroupTreeData,selected);
  }

  public addGroupAfter(targetNodeId: string){

    let newGroupTreeItem = this.makeItem(this.createNodeID(),targetNodeId, "zip");
  
    //OPTIMIERUNG - ID in der DATASSOURCE HINZUFÜGEN VOM ERSTELLTEN ITEM, OHNE NEULADEN DES BAUMES
  /*   let item: { [key: string]: Object } = { id: nodeId, name: "Neue Gruppe " + this.index };
    this.tree.addNodes([item] ,selected,null);
    this.index++;
    this.GroupTreeData.push(newGroupTreeItem);
    this.tree.beginEdit(nodeId); */
    //this.addAfterSelectedNode(this.GroupTreeData,selected);
  }

  /// AUSWAHL MIT KINDER LÖSCHEN
  ///NUR AUSWAHL LÖSCHEN
public removeTreeItem(targetNodeId: string) {      
  let item: GroupTree = this.getSelectedItemByChildId(targetNodeId); 
 
    this.removeGroupTreeItem(item);
    this.removeGroupTreeItemWithGroup(item);
  
};
private removeGroupTreeItem(item: GroupTree){
  if(!this.treeSettings.allowGroupTreeAssignment && item.icon !== "folder")
    return;

  this.groupTreeService.destroy(item).subscribe(
    r => {
      this.onDataBindTree();
    });
    
}
private removeGroupTreeItemWithGroup(item: GroupTree){
  if(this.treeSettings.allowGroupTreeAssignment || item.icon === "folder")
    return;

  this.groupTreeService.removeGroupTreeWithChildrenAndGroups(item.id).call(null,"property").subscribe(
    r => {
      this.onDataBindTree();
    });
    
}

public removeTreeItemAndChildren(targetNodeId: string) {      
  let item: GroupTree = this.getSelectedItemByChildId(targetNodeId); 
  this.removeGroupTreeWithChildren(item);
  this.removeGroupTreeWithChildrenAndGroup(item);
};
private removeGroupTreeWithChildren(item: GroupTree){
  if(!this.treeSettings.allowGroupTreeAssignment)
    return;

  this.groupTreeService.removeGroupTreeWithChildren(item.id).call(null,"entity").subscribe(
    r => {
      this.onDataBindTree();
    });
    
}

private mergeSelectedGroupsInDestinationGroup(targetNodeId: string){
  let item: GroupTree = this.getSelectedItemByChildId(targetNodeId); 
  this.groupTreeService.mergeGroupTreesInOne(item.id).call({groupTreeIds: this.getIdsBySelectedKeys()}).subscribe(
    r => {
      this.onDataBindTree();
    });
}

private removeGroupTreeWithChildrenAndGroup(item: GroupTree){
  if(this.treeSettings.allowGroupTreeAssignment)
    return;

  this.groupTreeService.removeGroupTreeWithChildrenAndGroups(item.id).call(null).subscribe(
    r => {
      this.onDataBindTree();
    });
    
}

private changeIcon(item: GroupTree, icon: string){
  if(isNullOrEmptyString( icon) )
    return;

  this.groupTreeService.assign({id:item.id},{icon: icon}).subscribe( r => {
    this.onDataBindTree();
  });

}


  public index: number = 0;
  private createNodeID(): string{
   return "t_" + this.index++;
  }
  private idxNewFolder = 1;
  private idxNewGroup = 1;
  private makeItem(childId: string, parentId: string, icon: string) : GroupTree {
    let txt : string;
    if(icon == "folder"){
      txt = "Neuer Ordner "+  this.idxNewFolder++;
    }else{
      txt = "Neue Gruppe "+  this.idxNewGroup++;
    }
   
    let item: GroupTree = { maingroupId: this.maingroupId, description: txt , id: 0, parentId: parentId, isActive: false,
      hasChild: false, name: txt , childId: childId ,level: 0,createdBy:"",value: "",sortValue: 0, icon: icon ,
      image:"",statusId: this.statusCreateId,
      useAttribute:false, useAssets:false, useKunden:false, useWettbewerber:false,
      useWettbewerberPreis:false, useArtikelPreis:false, useArtikelPreisVk:false, useRegistration:false, isSystemRelevant:false,
      isNodeEditAllow: false, isNodePrivate: false, privatePersonId: null,useArtikel: false,useAktie: false,

      refGroupTreeId: 0, refType: RefType.DEFAULT}; //Ubergabe Status optimieren
    this.groupTreeService.create(item).subscribe(
      r => {
       // item = r[0];
       /*  this.GroupTreeData.push(r[0]);
        this.index++;
        let item: { [key: string]: Object } = { id: r[0].childId, name: r[0].name, parentID: r[0].parentId };
        var selected  = this.selectedKeys[0];
        this.tree.addNodes([item] ,selected,null); */
        this.onDataBindTree();
        
        //this.onDataBindTreeEntwurf();
      }
    );
    return  item;
    //var id = 0 ; // Guid.create().toString();
    //return { description: txt , id: id, parentId: null, isActive: false,hasChild: false, name: txt , childId: "/" ,level: 0,createdBy:"",status: 0,value: "",sortValue: 0};
  };
 
private allowMultiSelectedTreeGroup: boolean = false;

public onNodeClick(e: any): void {
  if (e.type === 'contextmenu') {
      const originalEvent = e.originalEvent;

      originalEvent.preventDefault();
      //this.selectedNodeText =  e.item.dataItem.name;
      this.contextItem = e.item.dataItem;
      if(e.item.dataItem.icon ==='folder'){
        

        var contextItemsFolder: any[] = [{
       
        text: 'Ordner in ' + e.item.dataItem.name +' hinzufügen'
      }, {
        text: 'Gruppe in ' + e.item.dataItem.name +' hinzufügen'
      }, {
      text: 'Lösche ' + e.item.dataItem.name ,
      items: [{ text: ContextDelete.Selected }, { text: ContextDelete.SelectedAndChildren  }]
      },
      {text:  ( e.item.dataItem.icon === 'folder') ? 'Umwandeln in Gruppe' : 'Umwandeln in Ordner' },
      {text: "Knoten " + e.item.dataItem.name , items: this.contextItemsAnzeige},
      {text: "Filtern auf " + e.item.dataItem.name },
      {text: "Alle Gruppen in " + e.item.dataItem.name + "einfügen"}
    ];

      if(e.item.dataItem.value === 'root')
        contextItemsFolder.push( {text:  (this.treeSettings.allowDragAndDrop) ? 'Umstrukturieren deaktivieren' : 'Umstrukturieren aktivieren' });
      if(e.item.dataItem.value !== 'root')
        contextItemsFolder.push( {text: 'Umbennenen' });
     
        this.contextMenuGroupTree.items = contextItemsFolder;
      }
       
      
        if(e.item.dataItem.icon ==='zip') {
            var contextItemsGroup: any[] = [{
              text:  e.item.dataItem.name + ' kopieren',
              items: [{ text: ContextCopy.All }, { text: ContextCopy.WithAttribute  }, { text: ContextCopy.WithArticle }]
            }, {
            text: 'Lösche '+e.item.dataItem.name 
          },
          {text:  ( e.item.dataItem.icon === 'folder') ? 'Umwandeln in Gruppe' : 'Umwandeln in Ordner' },{
            text:  (this.allowMultiSelectedTreeGroup) ? 'Mehrfachauswahl deaktivieren' : 'Mehrfachauswahl aktivieren'
          },
          {text: 'Umbennenen' }
        ];
        if(this.selectedKeys.length > 0){
          //contextItemsGroup.push({text: (this.selectedKeys.length > 1) ? 'Auswahl in ' + e.item.dataItem.name+ ' zusammenfassen' : this.selectedKeys[0]+ ' in ' + e.item.dataItem.name+ ' zusammenfassen' });
          contextItemsGroup.push({text:  'Auswahl in ' + e.item.dataItem.name+ ' zusammenfassen'});

        }
          this.contextMenuGroupTree.items = contextItemsGroup;
      }
       
        
      this.contextMenuGroupTree.show({ left: originalEvent.pageX, top: originalEvent.pageY });
  }
}


  private expandCheckedTreeItems(): void {
    
    if(isNullOrUndefined(this.GroupTreeData)) return;

    this.expandedKeys = [];
    if(isNullOrUndefined( this.checkedKeys) || this.checkedKeys.length == 0 ) return;
    
    this.checkedKeys.forEach((key) => {
      console.log(key);
      let treeItem : GroupTree = this.GroupTreeData.find((x) => x.id === key);
      this.GroupTreeData.forEach((item) => {
          //&& treeItem.childId.length <= item.parentId.length
        if(!isNullOrUndefined(treeItem) && !isNullOrUndefined(item.parentId) && !isNullOrUndefined(treeItem.childId)  && treeItem.childId.substring(0,item.parentId.length) === item.parentId && this.expandedKeys.indexOf(item.parentId) === -1 )
        {
         // console.log("Expand",item.parentId,treeItem.childId);
          this.expandedKeys.push(item.parentId)
        }
       /*     if(!isNullOrUndefined(item.c) && 
               item.parentId.length >= childId.length &&
               item.parentId.substring(0,childId.length) === childId && this.expandedKeys.indexOf(item.parentId) === -1){
                 this.expandedKeys.push(item.parentId)
       
           } */
                  
         }, []);
    });
  }
  private expandTreeChilds(childId :string): void {
    this.GroupTreeData.forEach((item) => {
  
      if(!isNullOrUndefined(item.parentId) && 
          item.parentId.length >= childId.length &&
          item.parentId.substring(0,childId.length) === childId && this.expandedKeys.indexOf(item.parentId) === -1){
            this.expandedKeys.push(item.parentId) 
      }            
    }, []);
  }
  private collapseTreeChilds(childId :string): void {
    this.GroupTreeData.forEach((item) => {
  
      let itemIdx = this.expandedKeys.indexOf(item.parentId);
      if(!isNullOrUndefined(item.parentId) && 
          item.parentId.length >= childId.length &&
          item.parentId.substring(0,childId.length) === childId && itemIdx !== -1){
            this.expandedKeys.splice(itemIdx,1);
  
      }
             
    }, []);
  }


  private loadRefKeysFromSelectedGrouptree(){
    if(isNullOrUndefined(this.selectedGroupTree) ) return;

    let getGroupTreeRefIds = this.groupTreeService.getGroupTreeRefIds();
   getGroupTreeRefIds.call({childId: this.selectedGroupTree.childId},"entities")
    .subscribe( (data) => {
      this.checkedKeys = data.map(a => a);
      this.expandCheckedTreeItems();
      ////FILTER ERGEBNIS REF-MARKIERUNG 
      //( NUR GECHECKTE ANZEIGEN) 
      this.filterCheckedOnly = true;
      this.onToogleFilterStatusOk(true);
      //( AUF OBERSTE ELTERNKNOTEN FILTERN) 
      //this.filterRefRoot();
    });
   
  }
  private allGroupTreeRefIds: number[] =[];
  private loadAllRefKeysFromRootGrouptree(){
    

    let getGroupTreeRefIds = this.groupTreeService.getGroupTreeRefIds();
   getGroupTreeRefIds.call({childId: this.getMainGroupRoot()},"entities")
    .subscribe( (data) => {
      this.allGroupTreeRefIds = data.map(a => a);
     
    });
   
  }

  private getMainGroupRoot():string{
    return "/"+this.maingroupIdRef+"/";
  }

  public filterRefRoot(){
    let checkedItems : Array<GroupTree> = []
    this.checkedKeys.forEach((key) => {
      checkedItems.push( this.parsedGroupTreeData.find((x) => x.id === key));
     
    });
    this.filterSelectedItem(checkedItems[0] ,false);
     this.setRootForGroupTreeData();
  }

  public onDataBindTree(){
    let reqData = this.groupTreeService.entities();

    reqData.query.filter({maingroupId: this.maingroupId});
    reqData.query.filter().push({"statusid": {"gt": 2} }); // Umändern 14 dynamisch bestimmen
    if(this.filterGroupTreeId.length > 0)
      reqData.query.filter("startswith(childId,'"+this.filterGroupTreeId+"')");
     //query.filter({ChildId: {"startswith": this.filterGroupTreeId}});
reqData.query.orderBy("level, sortValue asc");
    reqData.get().subscribe( ({entities, meta}) => {

    this.GroupTreeData = entities;
    this.parsedGroupTreeData = entities;
    this.field = { dataSource: this.GroupTreeData ,id: 'id', childId: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' };
    //this.tree.expandAll();
    this.parsedGroupTreeData =  this.setRootForGroupTreeData()
    this.loadRefKeysFromSelectedGrouptree();
    
    this.rebind = false;
    this.rebindChange.emit(false);
   } );
 
  }

  public onDataBindSort(){
    if(this.sortId === 0) return;
    let request = this.groupTreeService.getSortResult();
  
    request.call({sortId:this.sortId, maingroupId: this.maingroupId},"entities").subscribe(
      (entities) => this.dataSorts = entities
    );
  }
  private loadSortReports() {
    let request = this.groupTreeService.getSorts();
  
    request.call({maingroupId: this.maingroupId},"entities").subscribe(
      (entities) => this.sorts = entities
    );
  }

  private setRootForGroupTreeData(){
    console.log("SetRoot:",this.filterGroupTreeId.length);
    if(this.filterGroupTreeId.length > 0){
      console.log(this.GroupTreeData.find(f => f.childId == this.filterGroupTreeId ));
      this.GroupTreeData.find(f => f.childId == this.filterGroupTreeId ).parentId = null;
   
    }
      
    return this.GroupTreeData;
  }

  public nodeSelected({ index, dataItem }: any): void {
    this.edit(dataItem,index);
    if(!this.allowMultiSelectedTreeGroup)
        this.selectedKeys = [dataItem.childId];
    else
       this.selectedKeys.push(dataItem.childId) ; 
    console.log("node selected tree view group",dataItem,index );
    this.dataItem = index; // getDataFromDatabase
    this.selectedDataItem = this.getSelectedItemByChildId( dataItem.childId);
    
    //this.checkedKeys = [dataItem.id];
    this.isToogleExpand = this.expandedKeys.includes(dataItem.childId);
    this.isToogleFilter =  this.selectedfilterTree.some( item => item.childId === dataItem.childId);
   
    this.isToogleCheckedChilds = (dataItem.refType === RefType.GROUP_REF_WITH_CHILD);
    console.log(dataItem,this.dataItem.refType === RefType.GROUP_REF_WITH_CHILD,this.isToogleCheckedChilds );

    this.selectionChange.emit({ index, dataItem });

    //LÖSCHE CHECKED 
    this.checkedKeys = [];
    //e.nodeData;
 //   let hasGroupChildren = false; // Chekc has dataItem child
  //  this.allowParentZuordnung = this.isAllowMainGroupParentAssignment || hasGroupChildren ;

/*   console.log("get groupId from item",this.selectedDataItem );

    let query = this._groupTreeAssigmentService.entities();
    query.select("GroupId");
    query.filter({groupTreeId: this.selectedDataItem.id});
    query.get({withCount: false}).subscribe(([r,odata]) => {
      console.log("anzahl ergebnise", odata.count )
      if(r.length > 0){
         this.groupId = r[0].groupId;
      }else{
         this.groupId = null;
      }
         
    }
      ); */
    /* if(!isNullOrUndefined(this.selectedDataItem)){
      this.query =this. _dataServiceArtikelGroups.getQuery();
      this.query.expand({artikel: {select: ["id","artikelNr"]}});
      this.query.filter({groupId: this.selectedDataItem.groupId}); 
     } */
};

public onSelectedRefGroupChanged(item: GroupTree ){
  if(isNullOrUndefined( item )) return; 
  console.log("SelRefGroupChanged in treeViiewGroup: ", item);
  
  
  let reqData = this.groupTreeService.entities();
  reqData.query.select("childId");
  reqData.query.filter({refGroupTreeId: item.id});
  reqData.query.filter().push({maingroupId: this.maingroupId});
  reqData.get({withCount: false}).subscribe(({entities, meta}) => {
    if(entities.length > 0){
       this.selectedKeys = entities.map(item => item.childId);
      this.expandSelectedRefTreeItems();
    }else{
      //MELDUNG PER NOTIFICATION AUSGEBEN,KNOTEN NOCHT VERGEBEN
    }
   
  
       
  }
    );
  }
  private expandSelectedRefTreeItems(): void {
    
    if(isNullOrUndefined(this.parsedGroupTreeData)) return;
  
  
    if(isNullOrUndefined( this.selectedKeys) || this.selectedKeys.length == 0 ) return;
    
    this.expandedKeys = [];
    this.selectedKeys.forEach((key) => {
      console.log("EXPAND:" , key);
      let treeItem : GroupTree = this.parsedGroupTreeData.find((x) => x.childId === key);
      this.parsedGroupTreeData.forEach((item) => {
          //&& treeItem.childId.length <= item.parentId.length
        if(!isNullOrUndefined(treeItem) && !isNullOrUndefined(item.parentId) && !isNullOrUndefined(treeItem.childId)  && treeItem.childId.substring(0,item.parentId.length) === item.parentId && this.expandedKeys.indexOf(item.parentId) === -1 )
        {
         // console.log("Expand",item.parentId,treeItem.childId);
          this.expandedKeys.push(item.parentId)
        }
       /*     if(!isNullOrUndefined(item.c) && 
               item.parentId.length >= childId.length &&
               item.parentId.substring(0,childId.length) === childId && this.expandedKeys.indexOf(item.parentId) === -1){
                 this.expandedKeys.push(item.parentId)
       
           } */
                  
         }, []);
    });
  }

 private getSelectedItem() : GroupTree{
   if(this.GroupTreeData === null || this.GroupTreeData === undefined) return null;
  return this.GroupTreeData.find(e => e.childId === this.selectedKeys[0]);
} 

public getIdsBySelectedKeys() : Array<number>{
  return   this.GroupTreeData.filter(({childId})  => this.selectedKeys.includes(childId)).map(m => m.id);
}

public getSelectedItemByChildId(selectedId: any) : GroupTree{
  return this.GroupTreeData.find(e => e.childId === selectedId);
}
public iconClass({ icon, childId}: any): any {
  return {
   /*   'k-i-file-ascx': (icon === 'zip'), */ 
      'k-i-folder': (icon === 'folder' && !this.isNodeExpanded(childId)),
      'k-i-folder-open': (icon === 'folder' && this.isNodeExpanded(childId)),
      'k-i-image-export': (icon === 'image-export'),
      'k-i-photo-camera': (icon === 'photo-camera'),
//      'k-i-html': isOfType(icon, 'html'),
 //     'k-i-image': isOfType(icon, 'jpg|png'),
      'k-icon': true
  };
}

public isNodeExpanded(childId: string): boolean{
  return this.expandedKeys.indexOf(childId) >= 0
}


public handleChecking(itemLookup: TreeItemLookup): void {
  if(isNullOrUndefined( this.maingroupIdRef))
    return;  // speichern nicht möglich meldung oder check nicht setzen
 
 
  let dataItem : GroupTree = [itemLookup.item][0].dataItem;
  console.log("handleChekced:", dataItem);
  if(this.isItemChecked(dataItem.id))
  {
      this.addGroupTreeItem(dataItem);
  }
  else{
    this.removeGroupTreeItemByGroupRef(dataItem);
  }

 //this.selectedGroupTreeId
 
}

private addGroupTreeItem(dataItem : GroupTree, refType: RefType = RefType.GROUP_REF){
        let item: GroupTree = { maingroupId: this.maingroupIdRef, description: dataItem.description , id: 0, parentId: this.selectedGroupTreeParent.childId,
          isActive: false,hasChild: false, name: dataItem.name , 
          childId: this.replaceMaingroupIdToRefId(dataItem.childId) ,level: 0,
          createdBy:"",value: dataItem.value,sortValue: dataItem.sortValue, 
          icon: dataItem.icon ,image: dataItem.image,statusId: dataItem.statusId,
          useAttribute:false, useAssets:false, useKunden:false, useWettbewerber:false,
    useWettbewerberPreis:false, useArtikelPreis:false, useArtikelPreisVk:false, useRegistration:false, isSystemRelevant:false,
    isNodeEditAllow: false, isNodePrivate: false, privatePersonId: null,useArtikel: false,useAktie: false,
          refGroupTreeId: dataItem.id, refType:  refType}; //Ubergabe Status optimieren
      this.groupTreeService.create(item).subscribe(
            r => {
                this.loadAllRefKeysFromRootGrouptree();
                this.onParentDataBindTree.emit();
            }
      );
}

private removeGroupTreeItemByGroupRef(dataItem : GroupTree, refType: RefType = RefType.GROUP_REF){
  console.log("removeGroupTreeItemByGroupRef",dataItem);
  let item: GroupTree = { maingroupId: this.maingroupIdRef, description: dataItem?.description  , id: this.selectedGroupTreeParent.id, parentId: this.selectedGroupTreeParent.childId,
    isActive: false,hasChild: false, name: dataItem.name , 
    childId: this.replaceMaingroupIdToRefId(dataItem.childId) ,level: 0,
    createdBy:"",value: dataItem.value,sortValue: dataItem.sortValue, 
    icon: dataItem.icon ,image: dataItem.image,statusId: dataItem.statusId,
    refGroupTreeId: dataItem.id, refType: refType,useAttribute:false, useAssets:false, useKunden:false, useWettbewerber:false,
    useWettbewerberPreis:false, useArtikelPreis:false, useArtikelPreisVk:false, useRegistration:false, isSystemRelevant:false,
    isNodeEditAllow: false, isNodePrivate: false, privatePersonId: null,useArtikel: false,useAktie: false}; 

    let reqRefItem = this.groupTreeService.removeGroupTreeRefItem( dataItem.id);
    reqRefItem.call({ maingroupParentId: this.maingroupIdRef})
    //this.dataService.destroy({id: item.id})
    .subscribe(
      r => {
        this.loadAllRefKeysFromRootGrouptree();
        this.onParentDataBindTree.emit();
      });

}

public isItemChecked(id: number) {
  return this.checkedKeys.indexOf(id) > -1 ? true  : false;
}

private replaceMaingroupIdToRefId(nodeValue: string): string{
  if(isNullOrUndefined( this.maingroupIdRef))
    return "";

  let values = nodeValue.split("/")
  console.log(values);
  if(values.length > 1)
    values[1] = this.maingroupIdRef.toString();
  return values.join("/");
}

//SERACH TREEVIEW
public searchTerm = '';
public onkeyup(value: string): void {
  this.parsedGroupTreeData = this.searchTreeViewItems(this.GroupTreeData, value);
}
public searchTreeViewItems(items: any[], term: string): any[] {
  return items.reduce((acc, item) => {
      if (this.contains(item.name, term)) {
          acc.push(item);
          this.searchParentNodesFromSearchResult(acc,item);
      } 
     /*  else if (item.items && item.items.length > 0) {
          let newItems = this.searchTreeViewItems(item.items, term);

          if (newItems.length > 0) {
            //id: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' 
          acc.push({ name: item.name, items: newItems });
          }
      } */

      return acc;
  }, []);
}

//Hat noch optimierungspotenzial. 
public searchParentNodesFromSearchResult(accSearch: any, itemFound: any){
  this.GroupTreeData.forEach((item) => {
   // console.log(itemFound.childId,item.parendId);
      if(itemFound.parentId=== item.childId ){
        if(!this.containsNode(accSearch, item.childId))
        {
          if(this.isToogleFilterStatus)
            item.statusId = 27
          accSearch.push(item);
        }
            
      
        this.searchParentNodesFromSearchResult(accSearch,item );
        return;
      }

  }, []);
}
public contains(text: string, term: string): boolean {
//  console.log(text,term);
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
}
public containsNode(accSearch: any,childId: string): boolean {
  let isFound= false;
  accSearch.forEach((item) => {
    if(item.childId === childId)
        isFound = true;
        return isFound;
        
  }, []);
    return isFound;
  }

//END SEARCH TREE VIEW

//SORT
private sortResultData: Array<SortResultDto> =null;
public onSortTree(id: number){
 
  console.log("sortTree", id);

    this.groupTreeService.getSortResult().call({sortId: id,maingroupId: this.maingroupId},"entities")
    .subscribe((entity) => {
      //odata-meta noch als property enthalten
        this.sortResultData = entity;
          this.sortTree();
    });
 

}

public getSortName(value: string) : string {
  return value.replace(/_/g, ' ').replace('usp','');
}

private sortTree(){
  this.sortResultData;
  this.parsedGroupTreeData =this.orderPipe.transform(this.setSortValues(this.GroupTreeData), "sortValue");
 
}
public setSortValues(items: any[]): any[] {
  return items.reduce((acc, item) => {
    item.sortValue = this.getSortValue(item.id);
     acc.push(item);
      

      return acc;
  }, []);
}

private getSortValue(id: number): number{
  if(this.sortResultData === null) return 0;
  let item = this.sortResultData.find(f => f.id === id);
  if(item === null || item === undefined) return 0;

  return  this.sortResultData.find(f => f.id === id).sortValue;
}


public getSortText(id: number): string{
  console.log("getSortText",id);
  if(this.sortResultData === null) return "";
  let item = this.sortResultData.find(f => f.id === id);
  console.log("getSortText item",item);
  if(item === null || item === undefined) return "";

  console.log(this.sortResultData.find(f => f.id === id).value);
  return  this.sortResultData.find(f => f.id === id).value;
}

public getProgressColors(id: number): string{
  if(this.sortResultData === null) return "";
  let item = this.sortResultData.find(f => f.id === id);
  if(item === null || item === undefined) return "";

  let value: string = "";
  if(item.level1To > 0)
    value += ",linear-gradient("+item.level1Color+","+item.level1Color+")";

  if(item.level2To > 0)
    value += ",linear-gradient("+item.level2Color+","+item.level2Color+")";

  if(item.level3To > 0)
    value += ",linear-gradient("+item.level3Color+","+item.level3Color+")";

  if(item.level4To > 0)
    value += ",linear-gradient("+item.level4Color+","+item.level4Color+")";
    
  if(item.level5To > 0)
    value += ",linear-gradient("+item.level5Color+","+item.level5Color+")";

  if(item.level6To > 0)
    value += ",linear-gradient("+item.level6Color+","+item.level6Color+")";
   
    return  value.substr(1);
}
public getProgressPrecents(id: number): string{

  if(this.sortResultData === null) return "";
  let item = this.sortResultData.find(f => f.id === id);
  if(item === null || item === undefined) return "";

  let value: string = "";
  if(item.level1To > 0)
    value += ","+item.level1To+"% 100%";

  if(item.level2To > 0)
    value += ","+item.level2To+"% 100%";

  if(item.level3To > 0)
  value += ","+item.level3To+"% 100%";

  if(item.level4To > 0)
  value += ","+item.level4To+"% 100%";
    
  if(item.level5To > 0)
  value += ","+item.level5To+"% 100%";

  if(item.level6To > 0)
    value += ","+item.level6To+"% 100%";

    return  value.substr(1);
}
//SORT END
  //FILTER UND EXPAND
  public onDeleteSelectedfilterTree(value){
    console.log(value);
      this.deleteSelectedFilterTree(value.dataItem.childId);
    }
    
    private deleteSelectedFilterTree(childId : string){
      this.selectedfilterTree.forEach( (item, index) => {
        if(item.childId === childId) this.selectedfilterTree.splice(index,1);
      });
        this.filterTree.forEach( (item, index) => {
          if(item.childId === childId) this.filterTree.splice(index,1);
        });
        console.log(this.selectedfilterTree.length);
        if(this.selectedfilterTree.length > 0){
          
          this.filterGroupTreeId = this.selectedfilterTree[this.selectedfilterTree.length - 1].childId; 
          console.log("New filterGroupTreeFromRemove", this.filterGroupTreeId,this.selectedfilterTree[this.selectedfilterTree.length - 1].childId);
        }else{
          this.filterGroupTreeId = "";
        }
           
           
        this.onDataBindTree();
    }
    
    private filterSelectedItem(item: any, rebind: boolean = true){
      this.filterGroupTreeId = item.childId;
      if(rebind)
         this.onDataBindTree();
      this.filterTree.push(item);
      this.selectedfilterTree.push(item);//this.contextItem.name+ ' (' + this.contextItem.value +')'
    }

    public onToogleFilterSelectedItem(value: boolean){
      console.log(value);
      this.isToogleFilter = value;
       if(value)
          this.filterSelectedItem(this.getSelectedItem() );
       else
         this.deleteSelectedFilterTree(this.getSelectedItem().childId )
     }

     public onToogleExpandSelectedItem(value: boolean){
       this.isToogleExpand = value;
       if(value)
          this.expandTreeChilds(this.getSelectedItem().childId );
       else
         this.collapseTreeChilds(this.getSelectedItem().childId );   
     }

     public onToogleCheckChildrenItem(value: boolean){
       if(isNullOrUndefined( this.maingroupIdRef))
         return; 
         //ToogleButton bleibt sonst Rot, da momentan der Status in der Datasource falsch ist.
      this.isToogleCheckedChilds = value;
       if(value)
          this.addGroupTreeItem(this.getSelectedItem(),RefType.GROUP_REF_WITH_CHILD);
       else
         this.removeGroupTreeItemByGroupRef(this.getSelectedItem(),RefType.GROUP_REF_WITH_CHILD);   
     }


     public onToogleFilterStatus(value: boolean){
      if(isNullOrUndefined( this.maingroupIdRef))
        return; 
        //ToogleButton bleibt sonst Rot, da momentan der Status in der Datasource falsch ist.
     this.isToogleFilterStatus = value;
  
    
      if(value){
        this.isToogleFilterStatusOk = false;
        this.onFilterRefIsSet(true);

      }        
      else
       this.onFilterRefIsSet(false);
    }

    public onToogleFilterStatusOk(value: boolean){
      if(isNullOrUndefined( this.maingroupIdRef))
        return; 
        //ToogleButton bleibt sonst Rot, da momentan der Status in der Datasource falsch ist.
     this.isToogleFilterStatusOk = value;
    
      if(value){
        this.isToogleFilterStatus= !value;
        this.onFilterRefIsSet(true);
      }
        
      else
        this.onFilterRefIsSet(false);  
    }


     //EDIT
     public handleEditorFocusOut(event: FocusEvent, editorContainer: HTMLElement): void {
      const focusTarget = event.relatedTarget as HTMLElement;
    
      // close the editor if the new focus target is not part of it
      if (!editorContainer.contains(focusTarget)) {
          this.cancel();
      }
    }
    public edit(item: any,index : string): void {
      console.log("Edit",item)
      // skip editing if same node is passed
      if (this.activeItem && this.activeItem === item) {
          return;
      }
    
      this.activeItem = item;
    
      this.focusEditor = true; // focus editor on next render
      this.focusIndex = index; // book keeping of the edited node index
    
      this.textFormControl = new FormControl({value: item.name, disabled: !this.isRenameActive}, Validators.required);
      console.log("Edit")
    }
    
    public cancel(): void {
      this.activeItem = null;
      this.textFormControl = null;
    }
     public save(): void {
  /*    let dataItem  = this.activeItem;
    
      // return focus to input if invalid
      if (this.textFormControl.invalid) {
          this.focusEditor = true;
          return;
      }
    
      // update the corresponding dataitem property
      dataItem.text = this.textFormControl.value;
    
      // close the editor
      this.cancel();*/
    } 
    
    public handleEditorEnter(event: KeyboardEvent): void {
      // prevent reopening the editor due to enter press
      event.stopPropagation();
    
      // persist the changes
      this.save();
    }


    //EDIT NODES
    public getDragStatus(action: DropAction, destinationItem: TreeItemLookup): string {
      if (!this.treeSettings.allowDragAndDrop ) {//isFile(destinationItem.item.dataItem.text)
          return 'k-i-cancel';
      }

      switch (action) {
          case DropAction.Add: return 'k-i-plus';
          case DropAction.InsertTop: return 'k-i-insert-up';
          case DropAction.InsertBottom: return 'k-i-insert-down';
          case DropAction.InsertMiddle: return 'k-i-insert-middle';
          case DropAction.Invalid:
          default: return 'k-i-cancel';
      }
  }

    public handleDrop(event: TreeItemDropEvent): void {
      if(!this.treeSettings.allowDragAndDrop){
        event.setValid(false);
        return;
      }


      if (event.destinationItem.item.dataItem.icon === "zip" && event.dropPosition === DropPosition.Over) {
        console.log("DropPosition.Over");
        event.setValid(false);
      }
     if (event.destinationItem.item.dataItem.parentId === event.sourceItem.item.dataItem.parentId && event.dropPosition > 0 ) {
          console.log("DropPosition.parentId",event);
          event.setValid(false);
          //dataItem kann direkt genutzt werden
          let replacePosItem: GroupTree = null;
          if(event.dropPosition === 1)
             replacePosItem =  this.GroupTreeData.find(e => e.childId === event.destinationItem.item.dataItem.childId);
          else{
            let indx = event.destinationItem.parent.children.findIndex(i => i.index === event.destinationItem.item.index);
            replacePosItem = event.destinationItem.parent.children[indx].dataItem;
          }
          let data =  this.GroupTreeData.find(e => e.childId === event.sourceItem.item.dataItem.childId);
          data.childId = replacePosItem.childId;
          this.groupTreeService.update({id: data.id},data).subscribe( r => this.onDataBindTree(),
          e => this.onDataBindTree())
    }
    /*   if (args.droppedNode.dataset.uid === "/"+this.maingroupId+"/") args.dropIndicator = 'e-no-drop';
      if (args.droppedNode.getElementsByClassName('folder').length === 0) args.dropIndicator = 'e-no-drop'; */
    }

    public handleAddItem(event: TreeItemAddRemoveArgs): void {
      if(!this.treeSettings.allowDragAndDrop){
          return;
      }
      let data =  this.GroupTreeData.find(e => e.childId === event.sourceItem.item.dataItem.childId);
      console.log(data);
    // if(event.dropPosition.toFixed === "e-drop-in"){
        data.parentId = event.destinationItem.item.dataItem.childId;
        this.groupTreeService.update({id: data.id},data).subscribe(
          r => this.onDataBindTree(),
          e => this.onDataBindTree()
        )
        //data.childId
        //data.sortValue = args.dropIndex;
      // }
    }

//FILTER AUF REF
private onFilterRefIsSet(isRefFilter: boolean): void {
  this.parsedGroupTreeData = this.filterRefStatus(this.GroupTreeData,isRefFilter);
  this.filterCheckedOnly = false;
}




public filterRefStatus(items: any[], isSetFilter: boolean): any[] {
  return items.reduce((acc, item) => {
      let isRef = this.isItemRefSet(item.id)
      if ((((isRef && this.isToogleFilterStatusOk) && this.isItemChecked(item.id) && this.filterCheckedOnly) // Alle Ref Filtern vom ParentTree
          || ((isRef && this.isToogleFilterStatusOk) && !this.filterCheckedOnly)     // Alle Refs filtern
          || (!isRef  && this.isToogleFilterStatus)  // Alle unbearbeitetet filtern
      || !isSetFilter )  ){//|| (isRef && this.isItemChecked(item.id) && this.filterCheckedOnly )  ) { // CHECK IS ITEM IN CHECKEDLIST + filterCheckedOnly
          
          acc.push(item);
          this.searchParentNodesFromSearchResult(acc,item);
      } 
     /*  else if (item.items && item.items.length > 0) {
          let newItems = this.searchTreeViewItems(item.items, term);

          if (newItems.length > 0) {
            //id: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' 
          acc.push({ name: item.name, items: newItems });
          }
      } */

      return acc;
  }, []);
}

    //FILTER STATUS: ERSETZT DURCH FILTERZUWEISUNG (REF), KANN ABER FÜR ZUKÜNFTIGE ERWEITERUNG AUF DEN GRUPPENSTATUS FILTER GENUTZT WERDEN
    private filterCheckedOnly : boolean = false;

     private onFilterStatusNotValue(value: number): void {
      this.parsedGroupTreeData = this.filterTreeViewItemsStatus(this.GroupTreeData, value);
      this.filterCheckedOnly = false;
    }
     public filterTreeViewItemsStatus(items: any[], status: number): any[] {
      return items.reduce((acc, item) => {
          if ((item.statusId !== status && !this.filterCheckedOnly ) || (item.statusId !== status && this.isItemChecked(item.id) && this.filterCheckedOnly )  ) { // CHECK IS ITEM IN CHECKEDLIST + filterCheckedOnly
              
              acc.push(item);
              this.searchParentNodesFromSearchResult(acc,item);
          } 
         /*  else if (item.items && item.items.length > 0) {
              let newItems = this.searchTreeViewItems(item.items, term);
    
              if (newItems.length > 0) {
                //id: 'childId', parentID: 'parentId', text: 'name', hasChildren: 'hasChild',value: "true" ,iconCss: 'icon' 
              acc.push({ name: item.name, items: newItems });
              }
          } */
    
          return acc;
      }, []);
    }


     public getStatusParentNodeNotCompletely(statusId: number):string{
      return this.statusItem.find(f => f.value === statusId) ?  this.statusItem.find(f => f.value === statusId).backgroundColor : "" ;
    } 

    public onSaveName(){
  
      if (this.textFormControl.invalid) {
          this.focusEditor = true;
          return;
      }

      // update the corresponding dataitem property
      this.activeItem.name = this.textFormControl.value;

      this.groupTreeService.assign({id: this.activeItem.id},{name : this.activeItem.name}).subscribe(
        r => {
          this.isRenameActive = false;
          this.textFormControl.disable();
          this.onDataBindTree();
        });
    }


    //REFERENZEN


    public OnClickSelectedRef(){
      console.log("OnClickSelectedRef",this.activeItem);
      this.groupRefSelectedChange.emit(this.activeItem);
    }

    public showMenuRefButtons(): boolean{
      return  isNullOrUndefined( this.maingroupIdRef) ? false : true;
    }
    public showActiveButtonRef(): boolean{
      return (this.isItemRefSet(this.activeItem.id)  || isNullOrUndefined( this.maingroupIdRef) ) && this.treeSettings.showBar ? true : false;
    }
    
    public showActiveButtonAdd(): boolean{
      return ( this.isItemRefSet(this.activeItem.id) &&  !this.treeSettings.allowGroupsInDifferentNodes)  || isNullOrUndefined( this.maingroupIdRef)? false : true;
    }
    public showActiveButtonRemove(): boolean{
      return this.isItemRefSet(this.activeItem.id) && !this.dialogOpened; 
    }

    public isItemRefSet(id : number) : boolean
    {
      return this.allGroupTreeRefIds.find(x => x === id) ? true : false;
    }

    //DIALOG
    public close(component) {
      this[component + 'Opened'] = false;
    }
    
    public open(component, activeItem) {
      console.log("open", this.activeItem , activeItem)
      this[component + 'Opened'] = true;
      this.selectedDialogItem = activeItem;
      
    }
    public dialogOpened = false;
    public selectedDialogItem = null;
    public action(status) {
      if(status)
        this.removeGroupTreeItemByGroupRef(this.selectedDialogItem)
      
        this.selectedDialogItem = null;
      this.dialogOpened = false;
    }
}
