import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { Firma } from '../entities/schema/firma.entity';
import { Booking } from '../entities/schema/booking.entity';
import { BookingObject } from '../entities/schema/bookingobject.entity';
import { BookingTimes } from '../entities/schema/bookingtimes.entity';
import { BookingViewDto } from '../Mza/Service/Controllers/V1/Kunden/Models/bookingviewdto.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class BookingService extends ODataEntityService<Booking> {
  constructor(protected client: ODataClient) {
    super(client, 'Booking', 'entities.schema.Booking');
  }

  
  
  //#region ODataApi Actions
  public bookingWithToken(entity: EntityKey<Booking>): ODataActionResource<{token: string, bookingTimeId: number, isBooked: boolean, date: Date}, any> {
    const resource = this.entity(entity).action<{token: string, bookingTimeId: number, isBooked: boolean, date: Date}, any>('services.BookingWithToken');
    return resource;
  }
  //#endregion
  //#region ODataApi Functions
  public bookingTimesWithToken(): ODataFunctionResource<{id: number, token: string, startDate: Date, endDate: Date}, BookingViewDto> {
    const resource = this.entities().function<{id: number, token: string, startDate: Date, endDate: Date}, BookingViewDto>('services.BookingTimesWithToken');
    return resource;
  }
  //#endregion
  //#region ODataApi Navigations
  public bookingObject(entity: EntityKey<Booking>): ODataNavigationPropertyResource<BookingObject> {
    return this.entity(entity).navigationProperty<BookingObject>('bookingObject');
  }
  public bookingTime(entity: EntityKey<Booking>): ODataNavigationPropertyResource<BookingTimes> {
    return this.entity(entity).navigationProperty<BookingTimes>('bookingTime');
  }
  public firma(entity: EntityKey<Booking>): ODataNavigationPropertyResource<Firma> {
    return this.entity(entity).navigationProperty<Firma>('firma');
  }
  public status(entity: EntityKey<Booking>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Booking>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
