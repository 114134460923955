import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Report } from './report.entity';
//#endregion

export const ReportConfig = {
  name: "Report",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    dbObject: {type: 'Edm.String'},
    title: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    filter: {type: 'Edm.String'},
    useEmailReport: {type: 'Edm.Boolean', nullable: false},
    userForEmailReport: {type: 'Edm.String'},
    isGroupIdRequired: {type: 'Edm.Boolean', nullable: false},
    time: {type: 'Edm.Duration'},
    date: {type: 'Edm.DateTimeOffset'},
    intervall: {type: 'Edm.Int32'},
    intervallType: {type: 'Edm.Int32'},
    componentType: {type: 'Edm.String'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    mappings: {type: 'entities.settings.DataMapping', collection: true, navigation: true},
    reportsAssigments: {type: 'services.DashboardAssigment', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Report>;