import { Component, OnInit, Input } from '@angular/core';
import { Asset } from 'mza-api-service';

@Component({
  selector: 'gpv-asset-infos',
  templateUrl: './asset-infos.component.html',
  styleUrls: ['./asset-infos.component.css']
})
export class AssetInfosComponent implements OnInit {

  @Input('asset') asset: Asset;  

  constructor() { }

  ngOnInit() {
  }

}
