import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainGroupService , Maingroup} from 'mza-api-service';
import { DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'gpv-gruppe-overview',
  templateUrl: './gruppe-overview.component.html',
  styleUrls: ['./gruppe-overview.component.css']
})
export class GruppeOverviewComponent implements OnInit {

  public maingroupId: number;

  constructor(private route: ActivatedRoute,  
    private router: Router,) { 
      
  }

  ngOnInit() {
  
    this.route.params.subscribe(params => {
      this.maingroupId = +params['id']; // (+) converts string 'id' to a number
      this.router.navigate([ '/gruppen/hauptgruppe/' + this.maingroupId+'/baum-ansicht'  ]);
    });

   /* if(this.route.snapshot.url. == '/hauptgruppen'){
 */
   
  }

}
