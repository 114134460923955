import { Component, OnInit, Inject, ViewChild , EventEmitter, Output, Input, forwardRef } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { isUndefined } from 'util';
import {  Filter }from 'angular-odata';
import { FormGroup, FormBuilder, FormControl, ControlContainer, FormGroupDirective, Validators, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CustomerFilterService, KundenFilter } from 'mza-api-service';




@Component({
  selector: 'mza-kunden-filter',
  templateUrl: './kunden-filter.component.html',
  styleUrls: ['./kunden-filter.component.css'],
  providers: [CustomerFilterService,{ 
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => KundenFilterComponent),
    multi: true 
  }]

})
export class KundenFilterComponent implements OnInit , ControlValueAccessor {

  onChange: any = () => {};
  onTouched: any = () => {};
  @Input('value') _value: number = 0;

  get value():number {
    return this._value;
  }
  set value(value :number) {
    this._value = value;
    this.dataBound(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

    setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }
  
  @Input() isRequired: boolean = false;
  @Input() disabled: boolean = false;
  @Input() kundenfilterId: number = 0;

  public form: FormGroup;

  @ViewChild('kundenfilterFilter') public kundenfilterFilter: ComboBoxComponent;

  public data: Array<KundenFilter>;
  public selectedItem: KundenFilter;

  constructor(@Inject(CustomerFilterService) private customerFilterService: CustomerFilterService) {

   }

  ngOnInit() {
   
  }
  private dataBound(value: number){
   
    if(value !== 0 ){
      console.log("Bound: ",this.value);
      let reqFilter = this.customerFilterService.entity(value);
      reqFilter.query.select(["id","bezeichnung"]);
      reqFilter.get().subscribe(({entity, meta}) =>{
        this.data = [] as Array<KundenFilter>;

        this.selectedItem = entity;
        this.data.push(entity) ;

    });
    }
  }
  handleFilter(value) {
    if (value.length >= 0) {

      let reqFilter = this.customerFilterService.entities();
      reqFilter.query.select(["id","bezeichnung"]);
      reqFilter.query.filter({bezeichnung: {contains: value}} );

      reqFilter.get().subscribe(({entities, meta}) =>  this.data = entities);
  }else {
      this.kundenfilterFilter.toggle(false);
  }
  }
  public onValueChange(value: any) {
    console.log("VALUE CHANGE: " ,value)
    this.value = value;  
  }

}
