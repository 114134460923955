import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Artikelbezeichnung } from './artikelbezeichnung.entity';
//#endregion

export const ArtikelbezeichnungConfig = {
  name: "Artikelbezeichnung",
  annotations: [],
  fields: {
    id: {type: 'Edm.Int64', key: true, ref: 'id', nullable: false},
    artikelId: {type: 'Edm.Int32'},
    sprachId: {type: 'Edm.Int32', nullable: false},
    artikelNr: {type: 'Edm.String'},
    kurzbezeichnung: {type: 'Edm.String'},
    bezeichnung: {type: 'Edm.String'},
    bezeichnungEdit: {type: 'Edm.String'},
    etikettenBezeichnung: {type: 'Edm.String'},
    etikettenBezeichnungEdit: {type: 'Edm.String'},
    produktbeschreibung: {type: 'Edm.String'},
    gesperrtBemerkung: {type: 'Edm.String'},
    beschreibung: {type: 'Edm.String'},
    bezeichnungAltBearbeitet: {type: 'Edm.String'},
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'}
  }
} as EntityConfig<Artikelbezeichnung>;