import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, SimpleChanges, OnChanges, forwardRef } from '@angular/core';
import { WarengruppenService, WarengruppeDto } from 'mza-api-service';
import { Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { SelectableSettings } from '@progress/kendo-angular-treeview';
import { isNullOrUndefined } from 'util';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'warengruppen-nr',
  templateUrl: './warengruppen-nr.component.html',
  styleUrls: ['./warengruppen-nr.component.css'],providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WarengruppenNrComponent),
      multi: true
    }
]
})
export class WarengruppenNrComponent implements OnInit,OnChanges,ControlValueAccessor, OnDestroy {
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  get warengruppenNr():string {
    return this.form.value;
  }
  set value(value :string) {
    console.log("setWarengruppenNr: ",value);

  // this.form.setValue({selected: value});
   this.form.setValue({selected: {warengruppennr: value}});

   this.selectedKeys = [value];

    this.onChange(value);
    this.onTouched();
  }
  writeValue(value): void {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
    }
    @Input("isSingleMode") isSingleMode: boolean = true;
    @Input() lieferantenNr: string = "";
    @Input() warengruppen: string[] = [];
    @Output() warengruppenNrChange = new EventEmitter<string[]>();

    public selection: SelectableSettings = { mode: 'multiple' };

  onChange: any = () => {};
  onTouched: any = () => {};
  form: FormGroup;
  subscriptions: Subscription[] = [];
  
  constructor( private _warengruppenService : WarengruppenService , private formBuilder: FormBuilder
  ) { 
    this.form = this.formBuilder.group({
      selected:  this.formBuilder.control({
        bezeichnung: [''], 
        warengruppennr: ['']
    })});

    this.subscriptions.push(

      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnInit() {
    this.form.setValue({selected: {warengruppennr: this.warengruppen}});
    this.selectedKeys =  this.warengruppen;
   this.onDataBoundWG();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.onDataBoundWG();
    console.log("WgNrChange:",this.warengruppen)
   // this.form.setValue({selected: this.warengruppe});
  //  this.form.setValue({selected: {warengruppennr: this.warengruppe}});
    this.selectedKeys = this.warengruppen;
 
  }
  public onRemove(e: ChipRemoveEvent): void {
    console.log('Remove event arguments: ', e);
    const index = this.selectedKeys.map(c => c.warengruppennr).indexOf(e.sender.label);
    this.selectedKeys.splice(index, 1);
    this.warengruppenNrChange.emit(this.selectedKeys);
}
  private onDataBoundWG(){
    if(isNullOrEmptyString( this.lieferantenNr)){
         let reqWG = this._warengruppenService.entities();
              reqWG.query.select(["warengruppennr","bezeichnung","parentWarengrNr"]);
              reqWG.query.orderBy("Warengruppennr")
              reqWG.get().subscribe(({entities,meta}) =>{
                  this.data = entities;
                  this.expandSelectedTreeChilds();
                // this.parsedDatadata = r;
        } );
    }
    //TODO: IST NOCH IN MZA_ODATA-SERVICE INTEGRIERT
 /*    else{
      let query = this._warengruppenService.getWarengruppenByLieferant(this.lieferantenNr);
     
      query.subscribe((entities) =>{
        let tmp : any =   entities;
        this.data =  tmp.value;
      
} );
    } */
 

    
  }

  private expandSelectedTreeChilds(): void {
    this.selectedKeys.forEach((selected) => {
      let treeItem : WarengruppeDto = this.data.find((x) => x.warengruppennr === selected);
      console.log("expandSelectedTreeChilds: ",treeItem, selected );
      this.data.forEach((item) => {
        console.log("expandSelectedTreeChilds item: ",treeItem, item );
        ///if( treeItem.parentWarengrNr === item.warengruppennr && this.expandedKeys.indexOf(item.parentWarengrNr) === -1 )
        if(!isNullOrUndefined(treeItem) && !isNullOrUndefined(item.parentWarengrNr) && !isNullOrUndefined(treeItem.warengruppennr)  && treeItem.warengruppennr.substring(0,item.parentWarengrNr.length) === item.parentWarengrNr && this.expandedKeys.indexOf(item.parentWarengrNr) === -1 )
        {
          console.log("addExpand",item.parentWarengrNr,treeItem.warengruppennr);
          this.expandedKeys.push(item.parentWarengrNr)
        }        
      }, []);

    });
  }
  
  public data: WarengruppeDto[] = [
];

private show: boolean = false;

private selectedKeys = [];
public  expandedKeys: any[] = [];
public onToggle(): void {
    this.show = !this.show;
}

public onSelectedGruppenValueCancel(){
  this.selectedKeys = [];
  
  this.warengruppenNrChange.emit(this.selectedKeys);
      
}

public onCancelDropDownValue(){
  console.log("onCancelDropDownValue")
 
 
  //this.form.setValue({selected:  ''});
  this.form.setValue({selected: {warengruppennr: undefined}});
  this.selectedKeys = [];
  this.warengruppenNrChange.emit(this.selectedKeys);
}

public handleSelection(event: any): void {
    this.show = false;
    console.log("handleWgNrChange:",event.dataItem.warengruppennr,this.selectedKeys)

      this.warengruppenNrChange.emit(this.selectedKeys);
      
}

public handleDropDownSelection(event: any): void {
  this.show = false;
  console.log("handleWgNrChange:",event.warengruppennr)

    this.warengruppenNrChange.emit(event.warengruppennr);
    
}

 /**
     * The field that holds the keys of the expanded nodes.
     */
    public keys: string[] = [];

    /**
     * A function that checks whether a given node index exists in the expanded keys collection.
     * If the index can be found, the node is marked as expanded.
     */
    public isExpanded = (dataItem: any, index: string) => {
        return this.keys.indexOf(index) > -1;
    }

    /**
     * A `collapse` event handler that will remove the node hierarchical index
     * from the collection, collapsing its children.
     */
    public handleCollapse(node) {
        this.keys = this.keys.filter(k => k !== node.index);
    }

    /**
     * An `expand` event handler that will add the node hierarchical index
     * to the collection, expanding the its children.
     */
    public handleExpand(node) {
        this.keys = this.keys.concat(node.index);
    }

    public children = (dataitem: any): Observable<any[]> => of(dataitem.items);
    public hasChildren = (dataitem: any): boolean => !!dataitem.items;
    
 /*    public parsedData: WarengruppeDto[] ;
    public searchTerm = '';

    public onkeyup(value: string): void {
      this.parsedData = this.search(this.data, value);
    }

    public search(items: any[], term: string): any[] {
      return items.reduce((acc, item) => {
            if (this.contains(item.Bezeichnung, term)) {
              acc.push(item);
            } else if (item.items && item.items.length > 0) {
              const newItems = this.search(item.items, term);

              if (newItems.length > 0) {
                    acc.push({ Bezeichnung: item.Bezeichnung, items: newItems });
              }
          }

            return acc;
        },                []);
    }

    public contains(Bezeichnung: string, term: string): boolean {
      return Bezeichnung.toLowerCase().indexOf(term.toLowerCase()) >= 0;
    } */
}
