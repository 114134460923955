import { Component, OnInit, Inject } from '@angular/core';
import { WarengruppeDto, WarengruppenService} from 'mza-api-service';



@Component({
  selector: 'mza-warengruppen-nr-multiselect',
  templateUrl: './warengruppen-nr-multiselect.component.html',
  styleUrls: ['./warengruppen-nr-multiselect.component.css'],
  providers: [WarengruppenService]
})
export class WarengruppenNrMultiselectComponent implements OnInit {
  
  public dataWG: WarengruppeDto[];

  constructor( @Inject(WarengruppenService) private  warengruppenService: WarengruppenService ) { }

  public virtual: any = {
    itemHeight: 28
  };

  ngOnInit() {
 /*    let odataWG = this.warengruppenService.entities();
    
    odataWG.select(["Warengruppennr","Bezeichnung"]);
    odataWG.get().subscribe(({entities, meta}) =>{
           this.dataWG = entities;
       }); */
  }

}
