import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtikelGroupBindingDirective } from './artikel-group-data-directive';
import { GroupBindingDirective } from './group-data-directive';
import { GroupTreeAttributeDataBindingDirective } from './group-tree-attribute-data-directive';
import { MainGroupBindingDirective } from './main-group-data-directive';
import { ODataBindingDirective } from './core/o-data-binding-directive';
import { CustomerGroupBindingDirective } from './customer-group-data-directive';
import { AssetMetaDataDirective } from './asset-meta-data.directive';
import { HerstellerDataBindingDirective } from './hersteller-data.directive';
import { ProduktTypDataBindingDirective } from './produkt-typ-data.directive';
import { ProduktTypAttributeDataDirective } from './produkt-typ-attribute-data.directive';
import { ProduktTypGroupDataDirective } from './produkt-typ-group-data.directive';
import { ArtikelBindingDirective } from './artikel-data.directive';
import { RegistrierungenDataDirective } from './registrierungen-data.directive';
import { WettbewerberDataDirective } from './wettbewerber-data.directive';
import { WettbewerberpreisDataDirective } from './wettbewerberpreis-data.directive';
import { ArtikelpreisDataDirective } from './artikelpreis-data.directive';
import { ArtikelpreisVkDataDirective } from './artikelpreis-vk-data.directive';
import { KalkulationsGruppeDataBindingDirective } from './kalkulationsgruppe-data.directive';
import { PersonDataBindingDirective } from './person-data.directive';


@NgModule({
  declarations: [ArtikelGroupBindingDirective,PersonDataBindingDirective, GroupBindingDirective,GroupTreeAttributeDataBindingDirective,MainGroupBindingDirective,ODataBindingDirective,CustomerGroupBindingDirective, AssetMetaDataDirective,  HerstellerDataBindingDirective, ProduktTypDataBindingDirective, ProduktTypAttributeDataDirective, ProduktTypGroupDataDirective, ArtikelBindingDirective, RegistrierungenDataDirective, WettbewerberDataDirective, WettbewerberpreisDataDirective, ArtikelpreisDataDirective, ArtikelpreisVkDataDirective,KalkulationsGruppeDataBindingDirective],
  imports: [
    CommonModule
  ], providers: []
  ,exports: [ArtikelGroupBindingDirective,PersonDataBindingDirective,GroupBindingDirective,GroupTreeAttributeDataBindingDirective,MainGroupBindingDirective,ODataBindingDirective,CustomerGroupBindingDirective,HerstellerDataBindingDirective,ProduktTypDataBindingDirective,KalkulationsGruppeDataBindingDirective]
})
export class DataDirectivesModule { }
