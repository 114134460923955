import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { PersonDto } from './persondto.entity';
//#endregion

export const PersonDtoConfig = {
  name: "PersonDto",
  annotations: [],
  fields: {
    anrede: {type: 'Edm.String'},
    titel: {type: 'Edm.String'},
    vorname: {type: 'Edm.String'},
    name: {type: 'Edm.String'},
    geburtsdatum: {type: 'Edm.DateTimeOffset', nullable: false},
    geburtsOrt: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<PersonDto>;