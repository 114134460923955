import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HerstellerComponent } from './hersteller/hersteller.component';

import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { GridModule } from '@progress/kendo-angular-grid';


import { HerstellerDataBindingDirective,ProduktTypDataBindingDirective } from 'mza-api-service';
import { ProduktTypenComponent } from './produkt-typen/produkt-typen.component';

@NgModule({
  declarations: [HerstellerComponent,ProduktTypenComponent,HerstellerDataBindingDirective,ProduktTypDataBindingDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TreeViewModule,
    InputsModule,
    FormsModule,
    ButtonsModule, MatButtonModule, MatCheckboxModule,MatInputModule,MatBadgeModule,
    GridModule
  ],exports:[HerstellerComponent,ProduktTypenComponent]
})
export class ProdukteModule { }
