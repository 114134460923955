import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarengruppenNrComponent } from './warengruppen-nr/warengruppen-nr.component';
import { WarengruppenNrMultiselectComponent } from './warengruppen-nr-multiselect/warengruppen-nr-multiselect.component';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WarengruppenService } from 'mza-api-service';

@NgModule({
  declarations: [WarengruppenNrComponent, WarengruppenNrMultiselectComponent],
  imports: [FormsModule, ReactiveFormsModule,BrowserModule, BrowserAnimationsModule, 
    CommonModule,ComboBoxModule, MultiSelectModule
  ],providers:[WarengruppenService],exports: [WarengruppenNrComponent, WarengruppenNrMultiselectComponent]
})
export class WarengruppenModule { }
