import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ExtendsAttributesDto } from './extendsattributesdto.entity';
//#endregion

export const ExtendsAttributesDtoConfig = {
  name: "ExtendsAttributesDto",
  annotations: [],
  fields: {
    groupTreeName: {type: 'Edm.String'},
    childId: {type: 'Edm.String'},
    attributeId: {type: 'Edm.Int32', nullable: false},
    attributeName: {type: 'Edm.String'},
    example: {type: 'Edm.String'},
    label: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', nullable: false}
  }
} as EntityConfig<ExtendsAttributesDto>;