import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { AssetGroupMetaData } from '../entities/assets/assetgroupmetadata.entity';
import { AssetGroup } from '../entities/assets/assetgroup.entity';
import { MetaData } from '../entities/assets/metadata.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class AssetGroupMetaDataService extends ODataEntityService<AssetGroupMetaData> {
  constructor(protected client: ODataClient) {
    super(client, 'AssetGroupMetaData', 'entities.assets.AssetGroupMetaData');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  //#endregion
  //#region ODataApi Navigations
  public assetGroup(entity: EntityKey<AssetGroupMetaData>): ODataNavigationPropertyResource<AssetGroup> {
    return this.entity(entity).navigationProperty<AssetGroup>('assetGroup');
  }
  public metaData(entity: EntityKey<AssetGroupMetaData>): ODataNavigationPropertyResource<MetaData> {
    return this.entity(entity).navigationProperty<MetaData>('metaData');
  }
  public status(entity: EntityKey<AssetGroupMetaData>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<AssetGroupMetaData>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  //#endregion
}
