import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mza-beigaben-info-settings',
  templateUrl: './beigaben-info-settings.component.html',
  styleUrls: ['./beigaben-info-settings.component.css']
})
export class BeigabenInfoSettingsComponent implements OnInit {

  @Input() beigabe: any;
  
  constructor() { }

  ngOnInit() {
  }

}
