import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelPreisVkService } from '../odata-services/services/artikelpreisvk.service';
import { NotificationService } from '@progress/kendo-angular-notification';


@Injectable({
  providedIn: 'root'
})
export class ArtikelpreisVkDataService extends GridDataService {
    
  public odataService: ArtikelPreisVkService;
  
  
    constructor(  @Inject(ArtikelPreisVkService)  service: ArtikelPreisVkService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
