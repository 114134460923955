import { Injectable, Inject } from '@angular/core';
import { GroupTreeAssigmentService } from '../odata-services/services/grouptreeassigment.service';
import { GridDataService } from './core/griddata.service';
import { NotificationService } from '@progress/kendo-angular-notification';


//import { Sortimentsauswahl } from '../models/sortimente/sortimentsauswahl.model';

@Injectable({
  providedIn: 'root'
})
export class GroupTreeAssigmentDataService extends GridDataService {
    
  public odataService: GroupTreeAssigmentService;
  
  
    constructor(@Inject(GroupTreeAssigmentService) service: GroupTreeAssigmentService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,'IsSelected',notificationService); 
   
      this.odataService = service;
    }
  
    //public SaveIsZugeordnet(item : Sortimentsauswahl, state: State) : void
  /*   public SaveIsZugeordnet(item : any, state: State) : void
    { 
        this.update(item);
        this.saveChanges(state);
    } */


}
