import { Injectable, Inject } from '@angular/core';
import { GridDataService } from './core/griddata.service';
import { ArtikelGroupFilterSettingsService } from '../odata-services';
import { NotificationService } from '@progress/kendo-angular-notification';

@Injectable({
  providedIn: 'root'
})
export class ArtikelGroupFilterSettingsDataService extends GridDataService {
    
  public odataService: ArtikelGroupFilterSettingsService;
  
  
    constructor(@Inject(ArtikelGroupFilterSettingsService) service: ArtikelGroupFilterSettingsService, @Inject(NotificationService) notificationService?: NotificationService) { 
      super(service,null,notificationService); 
      this.odataService = service;
    }
}
