import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { ArtikelAttribute } from './artikelattribute.entity';
//#endregion

export const ArtikelAttributeConfig = {
  name: "ArtikelAttribute",
  annotations: [],
  fields: {
    artikelId: {type: 'Edm.Int32'},
    attributeId: {type: 'Edm.Int32'},
    sortValue: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    artikel: {type: 'entities.Artikel', navigation: true, field: 'artikelId', ref: 'id'},
    attribute: {type: 'entities.schema.Attribute', navigation: true, field: 'attributeId', ref: 'id'},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<ArtikelAttribute>;