import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  ODataClient,
  ODataEntityService, 
  ODataEntity, 
  ODataEntities, 
  ODataProperty, 
  EntityKey,
  Duration,
  ODataEntityResource,
  ODataEntitySetResource,
  ODataNavigationPropertyResource,
  ODataActionResource,
  ODataFunctionResource,
  HttpOptions
} from 'angular-odata';

//#region ODataApi Imports
import { DataType } from './datatype.enum';
import { DataFormat } from './dataformat.enum';
import { ArtikelAsset } from '../entities/articles/artikelasset.entity';
import { Asset } from '../entities/assets/asset.entity';
import { AssetMetaData } from '../entities/assets/assetmetadata.entity';
import { Documentformat } from '../entities/assets/documentformat.entity';
import { Imageformat } from '../entities/assets/imageformat.entity';
import { Videoformat } from '../entities/assets/videoformat.entity';
import { AssetViewDto } from '../Mza/Service/Models/assetviewdto.entity';
import { Status } from '../entities/settings/status.entity';
import { StatusType } from '../entities/settings/statustype.entity';
//#endregion

@Injectable()
export class AssetService extends ODataEntityService<Asset> {
  constructor(protected client: ODataClient) {
    super(client, 'Asset', 'entities.assets.Asset');
  }

  
  
  //#region ODataApi Actions
  //#endregion
  //#region ODataApi Functions
  public getAssetHist(): ODataFunctionResource<{assetId: number}, Asset> {
    const resource = this.entities().function<{assetId: number}, Asset>('services.GetAssetHist');
    return resource;
  }
  public getOverview(): ODataFunctionResource<null, AssetViewDto> {
    const resource = this.entities().function<null, AssetViewDto>('services.GetOverview');
    return resource;
  }
 
  //#endregion
  //#region ODataApi Navigations
  public artikelAssets(entity: EntityKey<Asset>): ODataNavigationPropertyResource<ArtikelAsset> {
    return this.entity(entity).navigationProperty<ArtikelAsset>('artikelAssets');
  }
  public assetMetaDataList(entity: EntityKey<Asset>): ODataNavigationPropertyResource<AssetMetaData> {
    return this.entity(entity).navigationProperty<AssetMetaData>('assetMetaDataList');
  }
  public documentformat(entity: EntityKey<Asset>): ODataNavigationPropertyResource<Documentformat> {
    return this.entity(entity).navigationProperty<Documentformat>('documentformat');
  }
  public imageformat(entity: EntityKey<Asset>): ODataNavigationPropertyResource<Imageformat> {
    return this.entity(entity).navigationProperty<Imageformat>('imageformat');
  }
  public status(entity: EntityKey<Asset>): ODataNavigationPropertyResource<Status> {
    return this.entity(entity).navigationProperty<Status>('status');
  }
  public statusType(entity: EntityKey<Asset>): ODataNavigationPropertyResource<StatusType> {
    return this.entity(entity).navigationProperty<StatusType>('statusType');
  }
  public videoformat(entity: EntityKey<Asset>): ODataNavigationPropertyResource<Videoformat> {
    return this.entity(entity).navigationProperty<Videoformat>('videoformat');
  }
  //#endregion
}
