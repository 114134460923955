import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { Dashboard } from './dashboard.entity';
//#endregion

export const DashboardConfig = {
  name: "Dashboard",
  annotations: [],
  fields: {
    name: {type: 'Edm.String'},
    title: {type: 'Edm.String'},
    description: {type: 'Edm.String'},
    personId: {type: 'Edm.Int32'},
    statusId: {type: 'Edm.Int32'},
    statusTypeId: {type: 'Edm.Int32'},
    statusAt: {type: 'Edm.DateTimeOffset'},
    statusBy: {type: 'Edm.String'},
    createdBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false},
    reportsAssigments: {type: 'entities.schema.DashboardAssigment', collection: true, navigation: true},
    status: {type: 'entities.settings.Status', navigation: true, field: 'statusId', ref: 'id'},
    statusType: {type: 'entities.settings.StatusType', navigation: true, field: 'statusTypeId', ref: 'id'}
  }
} as EntityConfig<Dashboard>;