import { SchemaConfig } from 'angular-odata';

//#region ODataApi Imports
import { BeigabenArtikelConfig } from './beigabenartikel.entity.config';
import { BeigabenAusloeserConfig } from './beigabenausloeser.entity.config';
import { BeigabenKundeConfig } from './beigabenkunde.entity.config';
import { BeigabeConfig } from './beigabe.entity.config';
import { HerstellerConfig } from './hersteller.entity.config';
import { MarkeDtoConfig } from './markedto.entity.config';
import { ArtikelPreisConfig } from './artikelpreis.entity.config';
import { ArtikelPreisVkConfig } from './artikelpreisvk.entity.config';
import { KalkulationsGruppeConfig } from './kalkulationsgruppe.entity.config';
import { ProduktTypAttributeConfig } from './produkttypattribute.entity.config';
import { ProduktTypGroupConfig } from './produkttypgroup.entity.config';
import { ProduktTypConfig } from './produkttyp.entity.config';
import { KundensortimentDtoConfig } from './kundensortimentdto.entity.config';
import { SortimentDtoConfig } from './sortimentdto.entity.config';
//#endregion

export const ProdukteSchema = {
  namespace: "entities.produkte",
  enums: [],
  entities: [BeigabenArtikelConfig,
    BeigabenAusloeserConfig,
    BeigabenKundeConfig,
    BeigabeConfig,
    HerstellerConfig,
    MarkeDtoConfig,
    ArtikelPreisConfig,
    ArtikelPreisVkConfig,
    KalkulationsGruppeConfig,
    ProduktTypAttributeConfig,
    ProduktTypGroupConfig,
    ProduktTypConfig,
    KundensortimentDtoConfig,
    SortimentDtoConfig],
  callables: [],
  containers: []
} as SchemaConfig;