import { EntityConfig } from 'angular-odata';

//#region ODataApi Imports
import { LeistungsprofilHaendlerAngabefelder } from './leistungsprofilhaendlerangabefelder.entity';
//#endregion

export const LeistungsprofilHaendlerAngabefelderConfig = {
  name: "LeistungsprofilHaendlerAngabefelder",
  annotations: [],
  fields: {
    createdAt: {type: 'Edm.DateTimeOffset'},
    createdBy: {type: 'Edm.String'},
    changedAt: {type: 'Edm.DateTimeOffset'},
    changedBy: {type: 'Edm.String'},
    id: {type: 'Edm.Int32', key: true, ref: 'id', nullable: false}
  }
} as EntityConfig<LeistungsprofilHaendlerAngabefelder>;