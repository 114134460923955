import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarengruppenNrComponent } from './warengruppen-nr.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ButtonModule, ChipModule } from '@progress/kendo-angular-buttons';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { FlexModule } from '@angular/flex-layout';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    WarengruppenNrComponent],
  imports: [
    CommonModule,TreeViewModule,PopupModule,ButtonModule,FloatingLabelModule,FlexModule,DropDownListModule,FormsModule,ReactiveFormsModule,ChipModule
  ],exports:[WarengruppenNrComponent]
})
export class WarengruppenNrModule { }
