/*
 * Public API Surface of mza-api-service
 */
export * from './lib/data-directives/index';
export * from './lib/odata-services/index';
export * from './lib/data-services/index';
export * from './lib/mza-api-service.module';
export * from './lib/converters/data-array-convert';

/*
 * SERVICES
 */
/* export * from './lib/services/kunden.service';
export * from './lib/services/artikel/artikel.service';
export * from './lib/services/artikel/artikelbezeichnung/artikelbezeichnungsaufbau.service';
export * from './lib/services/registration.service';
export * from './lib/services/kundensortimente.service';
export * from './lib/services/sortimente/sortimentsgruppen-zuordnung.service';
export * from './lib/services/produkte/marken/marken.service'; */
//export * from './lib/odata-services/services/beigaben.service';
